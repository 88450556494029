import { PDFFont, PDFForm, PDFPage, rgb } from "pdf-lib";
import PDFCheckboxFieldComponent from "../components/CheckboxFieldComponent";
import PDFTextFieldComponent from "../components/TextFieldComponent";
import { CurrentDimensions } from "../model/CurrentDimensions";
import { PDFSectionProps } from "../model/PDFSectionProps";


const BiometricQuestionsSection = (props: PDFSectionProps) => {
  // Header style
  const headerFontSize = props.headerFontSize;
  const textHeight = 4 * headerFontSize;

  // Label Style
  const labelFontSize = props.labelFontSize;
  const labelHeight = 3 * labelFontSize;

  // Keep track of the height and width
  var currentDimensions = { height: props.heightPointer + textHeight+20, width: 0 } as CurrentDimensions;
  props.page.drawText('Biometric Questions', {
    x: 30,
    y: props.height - currentDimensions.height,
    size: headerFontSize,
    font: props.timesRomanFont,
    color: rgb(0, 0, 0),
    opacity: 1,
  });



  const heightLabel = {
    labelHeight: labelHeight,
    labelMargin: 30,
    labelText: "Height (ft):"
  };
  const heightField = {
    fieldHeight: 20,
    fieldId: 'height.ft.field',
    fieldMargin: 100,
    fieldWidth: 40,
    fieldValue: props.formValues.patientBiometricsHeightFt
  };

  currentDimensions = PDFTextFieldComponent({
    currentDimensions: currentDimensions,
    fieldData: heightField,
    labelData: heightLabel,
    font: props.timesRomanFont,
    fontSize: labelFontSize,
    form: props.form,
    page: props.page
  });

  const heightInLabel = {
    labelHeight: labelHeight,
    labelMargin: 30,
    labelText: "Height (in):"
  };
  const heightInField = {
    fieldHeight: 20,
    fieldId: 'height.in.field',
    fieldMargin: 100,
    fieldWidth: 40,
    fieldValue: props.formValues.patientBiometricsHeightIn
  };

  currentDimensions = PDFTextFieldComponent({
    currentDimensions: currentDimensions,
    fieldData: heightInField,
    labelData: heightInLabel,
    font: props.timesRomanFont,
    fontSize: labelFontSize,
    form: props.form,
    page: props.page,
  });

  const weightLabel = {
    labelHeight: labelHeight,
    labelMargin: 30,
    labelText: "Weight (lbs):"
  };
  const weightField = {
    fieldHeight: 20,
    fieldId: 'weight.field',
    fieldMargin: 100,
    fieldWidth: 40,
    fieldValue: props.formValues.patientBiometricsWeight
  };

  currentDimensions = PDFTextFieldComponent({
    currentDimensions: currentDimensions,
    fieldData: weightField,
    labelData: weightLabel,
    font: props.timesRomanFont,
    fontSize: labelFontSize,
    form: props.form,
    page: props.page,
  });

  const bmiLabel = {
    labelHeight: labelHeight,
    labelMargin: 30,
    labelText: "BMI:"
  };
  const bmiFielf = {
    fieldHeight: 20,
    fieldId: 'bmi.field',
    fieldMargin: 70,
    fieldWidth: 40,
    fieldValue: props.formValues.patientBiometricsBMI
  };

  currentDimensions = PDFTextFieldComponent({
    currentDimensions: currentDimensions,
    fieldData: bmiFielf,
    labelData: bmiLabel,
    font: props.timesRomanFont,
    fontSize: labelFontSize,
    form: props.form,
    page: props.page
  });


  return currentDimensions;
};

export default BiometricQuestionsSection;
