import { TextField, CircularProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import UsersService from 'api/users.api';
import React, { useState, useEffect } from 'react';

const UserMasquerade = () => {
  const [searchByField, setSearchByField] = useState('');
  const [userListOpen, setUserListOpen] = useState(false);
  const [users, setUsers] = useState<any[]>([]);
  const listLoading = userListOpen && users.length === 0;

  useEffect(() => {
    let active = true;
    if (!listLoading) {
      return undefined;
    }
    (async () => {
      const response = UsersService.searchProviders('');
    })();
  }, [listLoading]);

  useEffect(() => {
    if (!userListOpen) {
      setUsers([]);
    }
  }, [userListOpen]);

  return (
    <Autocomplete
      id="asynchronous-demo"
      style={{ width: 300 }}
      open={userListOpen}
      onOpen={() => {
        setUserListOpen(true);
      }}
      onClose={() => {
        setUserListOpen(false);
      }}
      getOptionSelected={(option: any, value: any) => option.name === value.name}
      getOptionLabel={(option: any) => option.name}
      options={users}
      loading={listLoading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Asynchronous"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {listLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default UserMasquerade;
