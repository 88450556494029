import { Box, Button, Card, Typography } from '@material-ui/core';
import WarnButton from 'components/atoms/warnButton/WarnButton';
import { ErrorMessage, Formik, FormikValues } from 'formik';
import { AUTH_ROUTES } from 'pages/auth/AuthRouter';
import ProviderAccessCard from 'pages/auth/components/ProviderAccessCard';
import { HOME_ROUTES } from 'pages/home/HomeRouter';
import React from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { isForgotPasswordLoadingSelector } from 'store/auth/auth.selectors';
import { forgotPasswordThunkAction } from 'store/auth/auth.slice';
import { useAppDispatch } from 'store/store';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { ResetPasswordSchema } from 'validations';

interface ForgotPasswordForm {
  email: string;
}

const MySwal = withReactContent(Swal);

const ResetPassword = () => {
  const dispatch = useAppDispatch();
  const isLoading = useSelector(isForgotPasswordLoadingSelector);
  const history = useHistory();

  const HandleResetPassword = (values: ForgotPasswordForm) => {
    dispatch(
      forgotPasswordThunkAction({
        data: values,
        onSuccess: (res) => {
          MySwal.fire('Success', 'Check your email for link to reset', 'success').then(() => {
            history.push(`${AUTH_ROUTES.root}${AUTH_ROUTES.signIn}`);
          });

          return Promise.resolve(res);
        },
      })
    );
  };

  return (
    <Container className="mt-5">
      <Row className="align-items-center">
        <Col md={6}>
          <ProviderAccessCard></ProviderAccessCard>
        </Col>
        <Col>
          <Card>
            <Typography className="text-center" variant="h2">
              Forgot your password?
            </Typography>
            <Formik
              initialValues={{ email: '' }}
              validationSchema={ResetPasswordSchema}
              onSubmit={HandleResetPassword}
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                errors,
                touched,
                isValid,
              }) => {
                const canSubmit = !isLoading && isValid;

                return (
                  <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formEmail">
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="Email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        isValid={touched.email && !errors.email}
                        isInvalid={!!errors.email}
                      />
                      <Form.Control.Feedback type="invalid">
                        <ErrorMessage name="email" component="div" data-testid="email-error" />
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Box display="flex" justifyContent="space-around">
                      <Button disabled={!canSubmit} type="submit">
                        Send Link
                      </Button>
                      <Button>Request OTP</Button>
                      <WarnButton component={Link} to={AUTH_ROUTES.root + AUTH_ROUTES.signIn}>
                        Go Back
                      </WarnButton>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ResetPassword;
