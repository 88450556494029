import React, { useState } from "react";
// Components
import { Typography, Card } from "@material-ui/core";
import { Row, Col, Form, Container, } from "react-bootstrap";
// Types
import { FormikProps, FormikValues, ErrorMessage, useFormikContext } from "formik";
// Util
import { getInputProps, getInputPropsMultiSelect } from "utils/constants/forms";

// Dates
import Moment from "moment";
import DatePicker from "react-datepicker";
import { isValidDate } from "utils/ValidationUtils";
import { MultiSelect } from "react-multi-select-component";

type CommonProps = {
  form: FormikProps<FormikValues>;
  patientName: "SocialHistoryQuestions";
};
type StatePropsType = {
  form: FormikProps<FormikValues>;
  state: [any, React.Dispatch<React.SetStateAction<any>>];
};

const SocialHistoryQuestions = ({ form, patientName }: CommonProps) => {

  // Grab what we need from formik without prop-drilling
  const { getFieldProps } = useFormikContext();
  const commonProps = {
    form,
    patientName,
  };


  return (
    <>
      <Container className="mt-4">
        <Row className="align-items-center">
          <Col>
            <Card>
              <Row>
                <Col>
                  <Typography variant="h4" className="text-center mt-5">
                    Motor Vehicle Accident Questions
                  </Typography>
                </Col>
              </Row>
              <Form.Row className="mt-5 mr-3 ml-3">
                <Col xs={12} md={6} className="my-1">
                  <Form.Label column>
                    Is your condition the result of a motor vehicle accident or
                    Personal Injury case?
                  </Form.Label>
                </Col>
                <Col xs={12} md={6} className="my-1">
                  <Form.Control
                    as="select"
                    custom
                    type="text"
                    placeholder="Select if you had XRay"
                    {...getInputProps("patientMvaSource", form)}
                  >
                    <option>-- Select --</option>
                    <option>No</option>
                    <option>MVA</option>
                    <option>WC</option>
                    <option>PI</option>
                  </Form.Control>
                  <ErrorMessage
                    name="patientMvaSource"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
              </Form.Row>
              {form.getFieldProps("patientMvaSource").value === "PI" ? (
                <PersonalInjurySection {...commonProps} />
              ) : null}
              {form.getFieldProps("patientMvaSource").value === "WC" ? (
                <WCSection {...commonProps} />
              ) : null}
              {form.getFieldProps("patientMvaSource").value === "MVA" ? (
                <MVASection {...commonProps} />
              ) : null}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SocialHistoryQuestions;

const PersonalInjurySection = ({ form, patientName }: CommonProps) => (
  <Form.Row className=" mr-3 ml-3">
    <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
      <Form.Label column>Attorney Name</Form.Label>
    </Col>
    <Col xs={12} md={6} className="my-1">
      <Form.Control
        type="text"
        placeholder="Attorney Name"
        {...getInputProps("patientMVAAttorneyName", form)}
      />
      <ErrorMessage
        name="patientMVAAttorneyName"
        component="div"
        data-testid="email-error"
      />
    </Col>
    <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
      <Form.Label column>Attorney Phone</Form.Label>
    </Col>
    <Col xs={12} md={6} className="my-1">
      <Form.Control
        type="text"
        placeholder="Attorney Phone"
        {...getInputProps("patientMVAAttorneyPhone", form)}
      />
      <ErrorMessage
        name="patientMVAAttorneyPhone"
        component="div"
        data-testid="email-error"
      />
    </Col>
  </Form.Row>
);

const WCSection = ({ form, patientName }: CommonProps) => (
  <Form.Row className=" mr-3 ml-3">
    <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
      <Form.Label column>WC Name</Form.Label>
    </Col>
    <Col xs={12} md={6} className="my-1">
      <Form.Control
        type="text"
        placeholder="WC Name"
        {...getInputProps("patientMVAWCName", form)}
      />
      <ErrorMessage
        name="patientMVAAttorneyName"
        component="div"
        data-testid="email-error"
      />
    </Col>
    <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
      <Form.Label column>WC Phone</Form.Label>
    </Col>
    <Col xs={12} md={6} className="my-1">
      <Form.Control
        type="text"
        placeholder="WC Phone"
        {...getInputProps("patientMVAWCPhone", form)}
      />
      <ErrorMessage
        name="patientMVAWCPhone"
        component="div"
        data-testid="email-error"
      />
    </Col>
  </Form.Row>
);

const MVASection = ({ form, patientName }: CommonProps) => {
  const roadConditionsOptions = [
    { label: "Dry", value: "Dry" },
    { label: "Wet", value: "Wet" },
    { label: "Rain", value: "Rain" },
    { label: "Dust Storm", value: "DustStorm" },
    { label: "Snow", value: "Snow" },
    { label: "Ice", value: "Ice" },
  ];
  const accidentrPlaceOptions = [
    { label: "Highway", value: "Highway" },
    { label: "Parking Lot", value: "ParkingLot" },
    { label: "At a Stop Light", value: "AtaStopLight" },
    {
      label: "Passing through an Intersection",
      value: "PassingthroughanIntersection",
    },
    { label: "At a Stop Sign", value: "AtaStopSign" },
  ];

  const vehicleTypeOptions = [
    { value: "Sedan", label: "Sedan" },
    { value: "PickUpTruck", label: "Pick-up Truck" },
    { value: "SUV", label: "SUV" },
    { value: "CrossOverSUV", label: "Cross-over SUV" },
    { value: "ElectricCar", label: "Electric Car" },
    { value: "Minivan", label: "Minivan" },
    { value: "SportsCar", label: "Sports Car" },
    { value: "CommercialVehicle", label: "Commercial Vehicle" },
    { value: "Semi", label: "Semi" },
  ];

  const vectorOptions = [
    { value: "RearEnded", label: "Rear-ended" },
    { value: "TBoned", label: "T-boned" },
    { value: "HitHeadOn", label: "Hit Head On" },
  ];

  const positionOptions = [
    { value: "Driver", label: "Driver" },
    { value: "Passenger", label: "Passenger" },
    { value: "Pedestrian", label: "Pedestrian" },
  ];

  const headPositionOptions = [
    { value: "LookingForward", label: "Looking Forward" },
    { value: "LookingInTheRearView", label: "Looking in the Rear View" },
    { value: "TurnedOrTiltedLeft", label: "Turned or Tlted Left" },
    { value: "TurnedOrTiltedRight", label: "Turned or Tilted Right" },
  ];

  const hittingHeadPosition = [
    { value: "SteeringWheel", label: "Steering Wheel" },
    { value: "SideWindow", label: "Side Window" },
    { value: "FrontWindshield", label: "Front Windshield" },
    { value: "NoReportedHeadImpact", label: "No Reported Head Impact" },
  ];

  const hospitalOptions = [
    { value: "No", label: "No" },
    { value: "Hospital", label: "Hospital" },
    { value: "ER", label: "ER" },
    { value: "UrgentCare", label: "Urgent Care" },
  ];

  const rideToHospitalOptions = [
    { value: "Ambulance", label: "Ambulance" },
    { value: "Drove", label: "Drove" },
    {
      value: "DrivenByAFriendOrFamilyMember",
      label: "Driven by Friend or Family Member",
    },
  ];

  const testPerformedOptions = [
    { value: "XRays", label: "X-Rays" },
    { value: "CTSpine", label: "CT Spine" },
    { value: "CTHead", label: "CT Head" },
    { value: "MRI", label: "MRI" },
  ];


  // Grab what we need from formik without prop-drilling
  const { getFieldProps } = useFormikContext();
  // Handle Date
  var dateOfAccident = getFieldProps("patientMVAAccidentDate").value;

  var date = null;
  if (isValidDate(dateOfAccident)) {
    const mDate = Moment(dateOfAccident, "YYYY-MM-DD");
    date = new Date(mDate.toDate());
  }
  const [accidentDate, setAccidentDate] = useState<Date | null>(date);

  // Handle Discharged Date
  var dateDischarged = getFieldProps("patientMVADateDischarged").value;

  date = null;
  if (isValidDate(dateDischarged)) {
    const mDate = Moment(dateDischarged, "YYYY-MM-DD");
    date = new Date(mDate.toDate());
  }
  const [dischargedDate, setDischargedDate] = useState<Date | null>(date);

  const [testPerformedSelected, setTestPerformedSelected] = useState(
    getFieldProps("patientMVAHowTestPerformed").value || []
  );
  return (
    <Form.Row className=" mr-3 ml-3">
      <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
        <Form.Label column>Date of Accident</Form.Label>
      </Col>
      <Col xs={12} md={6} className="my-1">
        <label>
          <DatePicker
            className="form-control"
            placeholderText="MM/dd/yyyy*"
            dateFormat="MM/dd/yyyy"
            isClearable={true}
            selected={accidentDate}
            onChange={(date) => {
              if (date == null || !Array.isArray(date)) {
                form.setFieldValue("patientMVAAccidentDate", Moment(date).format("YYYY-MM-DD"));
                setAccidentDate(date);
              }
            }
            }
          />
        </label>
        &nbsp;<i className="far fa-calendar-alt fa-2x" onClick={() => {

        }}></i>
      </Col>
      <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
        <Form.Label column>Time of Day</Form.Label>
      </Col>
      <Col xs={12} md={6} className="my-1">
        <Form.Control
          type="text"
          placeholder="Time of Day"
          {...getInputProps("patientMVATimeOfDay", form)}
        />
        <ErrorMessage
          name="patientMVATimeOfDay"
          component="div"
          data-testid="email-error"
        />
      </Col>
      <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
        <Form.Label column>Road Conditions</Form.Label>
      </Col>
      <Col xs={12} md={6} className="my-1">
        <Form.Control
          as="select"
          custom
          type="text"
          placeholder="Select if you had XRay"
          {...getInputProps("patientMVARoadContions", form)}
        >
          <option>-- Select --</option>
          {roadConditionsOptions.map((item: any, i: number) => {
            return <option>{item.label}</option>;
          })}
        </Form.Control>
        <ErrorMessage
          name="patientMvaSource"
          component="div"
          className="text-red-500 text-xs italic"
        />
      </Col>
      <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
        <Form.Label column>Where did the accident take place?</Form.Label>
      </Col>
      <Col xs={12} md={6} className="my-1">
        <Form.Control
          as="select"
          custom
          type="text"
          placeholder="Select if you had XRay"
          {...getInputProps("patientMVAAccidentPlace", form)}
        >
          <option>-- Select --</option>
          {accidentrPlaceOptions.map((item: any, i: number) => {
            return <option>{item.label}</option>;
          })}
        </Form.Control>
        <ErrorMessage
          name="patientMVAAccidentPlace"
          component="div"
          className="text-red-500 text-xs italic"
        />
      </Col>
      <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
        <Form.Label column>What type of vehicle where you driving?</Form.Label>
      </Col>
      <Col xs={12} md={6} className="my-1">
        <Form.Control
          as="select"
          custom
          type="text"
          placeholder="Select if you had XRay"
          {...getInputProps("patientMVAVehicleType", form)}
        >
          <option>-- Select --</option>
          {vehicleTypeOptions.map((item: any, i: number) => {
            return <option>{item.label}</option>;
          })}
        </Form.Control>
        <ErrorMessage
          name="patientMVAVehicleType"
          component="div"
          className="text-red-500 text-xs italic"
        />
      </Col>
      <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
        <Form.Label column>
          What type of vehicle did you collide with?
        </Form.Label>
      </Col>
      <Col xs={12} md={6} className="my-1">
        <Form.Control
          as="select"
          custom
          type="text"
          placeholder="Select if you had XRay"
          {...getInputProps("patientMVAVehicleCollided", form)}
        >
          <option>-- Select --</option>
          {vehicleTypeOptions.map((item: any, i: number) => {
            return <option>{item.label}</option>;
          })}
        </Form.Control>
        <ErrorMessage
          name="patientMVAVehicleCollided"
          component="div"
          className="text-red-500 text-xs italic"
        />
      </Col>
      <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
        <Form.Label column>What was the vector?</Form.Label>
      </Col>
      <Col xs={12} md={6} className="my-1">
        <Form.Control
          as="select"
          custom
          type="text"
          placeholder="Select if you had XRay"
          {...getInputProps("patientMVAVector", form)}
        >
          <option>-- Select --</option>
          {vectorOptions.map((item: any, i: number) => {
            return <option>{item.label}</option>;
          })}
        </Form.Control>
        <ErrorMessage
          name="patientMVAVector"
          component="div"
          className="text-red-500 text-xs italic"
        />
      </Col>
      <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
        <Form.Label column>What was the speed of the vehicle?</Form.Label>
      </Col>
      <Col xs={12} md={6} className="my-1">
        <Form.Control
          type="text"
          placeholder="Vehicle Speed (mph)"
          {...getInputProps("patientMVAVehicleSpeed", form)}
        />
        <ErrorMessage
          name="patientMVAVehicleSpeed"
          component="div"
          className="text-red-500 text-xs italic"
        />
      </Col>
      <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
        <Form.Label column>What position where you in?</Form.Label>
      </Col>
      <Col xs={12} md={6} className="my-1">
        <Form.Control
          as="select"
          custom
          type="text"
          placeholder="Select if you had XRay"
          {...getInputProps("patientMVAPosition", form)}
        >
          <option>-- Select --</option>
          {positionOptions.map((item: any, i: number) => {
            return <option>{item.label}</option>;
          })}
        </Form.Control>
        <ErrorMessage
          name="patientMVAPosition"
          component="div"
          className="text-red-500 text-xs italic"
        />
      </Col>
      <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
        <Form.Label column>
          If you were the Driver, were your hands on the steering wheel?{" "}
        </Form.Label>
      </Col>
      <Col xs={12} md={6} className="my-1">
        <Form.Check
          inline
          type="checkbox"
          className="mt-2"
          checked={getFieldProps("patientMVAHandsOnWheel").value || false }
          {...getInputProps("patientMVAHandsOnWheel", form)}
        />
      </Col>
      <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
        <Form.Label column>
          Were you restrained, as in using a seatbelt?
        </Form.Label>
      </Col>
      <Col xs={12} md={6} className="my-1">
        <Form.Check
          inline
          type="checkbox"
          className="mt-2"
          checked={
            getFieldProps("patientMVAUserRestrained").value || false
          }
          {...getInputProps("patientMVAUserRestrained", form)}
        />
      </Col>
      <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
        <Form.Label column>What was your head position?</Form.Label>
      </Col>
      <Col xs={12} md={6} className="my-1">
        <Form.Control
          as="select"
          custom
          type="text"
          placeholder="Select if you had XRay"
          {...getInputProps("patientMVAHeadPosition", form)}
        >
          <option>-- Select --</option>
          {headPositionOptions.map((item: any, i: number) => {
            return <option>{item.label}</option>;
          })}
        </Form.Control>
        <ErrorMessage
          name="patientMVAHeadPosition"
          component="div"
          className="text-red-500 text-xs italic"
        />
      </Col>
      <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
        <Form.Label column>Did you head hit anything?</Form.Label>
      </Col>
      <Col xs={12} md={6} className="my-1">
        <Form.Control
          as="select"
          custom
          type="text"
          placeholder="Select if you had XRay"
          {...getInputProps("patientMVAHeadHitAnything", form)}
        >
          <option>-- Select --</option>
          {hittingHeadPosition.map((item: any, i: number) => {
            return <option>{item.label}</option>;
          })}
        </Form.Control>
        <ErrorMessage
          name="patientMVAHeadHitAnything"
          component="div"
          className="text-red-500 text-xs italic"
        />
      </Col>
      <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
        <Form.Label column>Did you lose consciousness?</Form.Label>
      </Col>
      <Col xs={12} md={6} className="my-1">
        <Form.Check
          inline
          {...getInputProps("patientMVAUserLostConciousness", form)}
          checked={getFieldProps("patientMVAUserLostConciousness").value || false}
          type="checkbox"
          label=""
          className="mt-2"
        />
      </Col>
      {form.getFieldProps("patientMVAUserLostConciousness").value ? (
        <>
          <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
            <Form.Label column>For How Long?</Form.Label>
          </Col>
          <Col xs={12} md={6} className="my-1">
            <Form.Control
              type="text"
              placeholder="How Long"
              {...getInputProps("patientMVALostConsciousnessHowLong", form)}
            />
            <ErrorMessage
              name="patientMVALostConsciousnessHowLong"
              component="div"
              className="text-red-500 text-xs italic"
            />
          </Col>
        </>
      ) : null}
      <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
        <Form.Label column>
          Did the Police come to the scene of the MVA?
        </Form.Label>
      </Col>
      <Col xs={12} md={6} className="my-1">
        <Form.Check
          inline
          {...getInputProps("patientMVAPoliceCameToMVA", form)}
          checked={getFieldProps("patientMVAPoliceCameToMVA").value || false}
          type="checkbox"
          label=""
          className="mt-2"
        />
      </Col>
      {form.getFieldProps("patientMVAPoliceCameToMVA").value ? (
        <>
          <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
            <Form.Label column>Who was cited?</Form.Label>
          </Col>
          <Col xs={12} md={6} className="my-1">
            <Form.Control
              type="text"
              placeholder="Who was cited?"
              {...getInputProps("patientMVAWhoWasCited", form)}
            />
            <ErrorMessage
              name="patientMVAWhoWasCited"
              component="div"
              className="text-red-500 text-xs italic"
            />
          </Col>
        </>
      ) : null}
      <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
        <Form.Label column>
          Did you go to the Hospital, ER or Urgent Care following the MVA?
        </Form.Label>
      </Col>
      <Col xs={12} md={6} className="my-1">
        <Form.Control
          as="select"
          custom
          type="text"
          placeholder="Select if you had XRay"
          {...getInputProps("patientMVAHospitalOptions", form)}
        >
          <option>-- Select --</option>
          {hospitalOptions.map((item: any, i: number) => {
            return <option>{item.label}</option>;
          })}
        </Form.Control>
        <ErrorMessage
          name="patientMVAHospitalOptions"
          component="div"
          className="text-red-500 text-xs italic"
        />
      </Col>
      {form.getFieldProps("patientMVAHospitalOptions").value != "No" ? (
        <>
          <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
            <Form.Label column>How did you get there?</Form.Label>
          </Col>
          <Col xs={12} md={6} className="my-1">
            <Form.Control
              as="select"
              custom
              type="text"
              placeholder="Select if you had XRay"
              {...getInputProps("patientMVAHowRideToHospitalOptions", form)}
            >
              <option>-- Select --</option>
              {rideToHospitalOptions.map((item: any, i: number) => {
                return <option>{item.label}</option>;
              })}
            </Form.Control>
          </Col>
          <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
            <Form.Label column>Where you admitted?</Form.Label>
          </Col>
          <Col xs={12} md={6} className="my-1">
            <Form.Check
              inline
              {...getInputProps("patientMVAWasUserAdmitted", form)}
              checked={getFieldProps("patientMVAWasUserAdmitted").value || false}
              type="checkbox"
              label=""
              className="mt-2"
            />
          </Col>
        </>
      ) : null}
      {form.getFieldProps("patientMVAWasUserAdmitted").value ? (
        <>
          <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
            <Form.Label column>What date were you discharged?</Form.Label>
          </Col>
          <Col xs={12} md={6} className="my-1">
            <label>
              <DatePicker
                className="form-control"
                placeholderText="MM/dd/yyyy*"
                dateFormat="MM/dd/yyyy"
                isClearable={true}
                selected={dischargedDate}
                onChange={(date) => {
                  if (date == null || !Array.isArray(date)) {
                    form.setFieldValue("patientMVADateDischarged", Moment(date).format("YYYY-MM-DD"));
                    setDischargedDate(date);
                  }
                }
                }
              />
            </label>
            &nbsp;<i className="far fa-calendar-alt fa-2x" onClick={() => {

            }}></i>
          </Col>
          <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
            <Form.Label column>Were any test performed?</Form.Label>
          </Col>
          <Col xs={12} md={6} className="my-1">
         

        <MultiSelect
          options={testPerformedOptions}
          value={testPerformedSelected}
          onChange={(values: any) => {
            setTestPerformedSelected(values);
            form.setFieldValue("patientMVAHowTestPerformed", values);
          }}
          {...getInputPropsMultiSelect("patientMVAHowTestPerformed", form)}
          labelledBy="Select Pain Type"
        />
          </Col>
          <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
            <Form.Label column>Other details</Form.Label>
          </Col>
          <Col xs={12} md={6} className="my-1">
            <Form.Control
              as="textarea"
              rows={4}
              type="text"
              placeholder="Other details"
              {...getInputProps("patientMVAHospitalOtherDetails", form)}
            />
            <ErrorMessage
              name="patientMVAHospitalOtherDetails"
              component="div"
              className="text-red-500 text-xs italic"
            />
          </Col>
        </>
      ) : null}
      <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
        <Form.Label column>Were there any treatments administered?</Form.Label>
      </Col>
      <Col xs={12} md={6} className="my-1">
        <Form.Check
          inline
          {...getInputProps("patientMVAAnyTreatmnetsAdministered", form)}
          checked={getFieldProps("patientMVAAnyTreatmnetsAdministered").value || false}
          type="checkbox"
          label=""
          className="mt-2"
        />
      </Col>

      {form.getFieldProps("patientMVAAnyTreatmnetsAdministered").value ? (
        <>
          <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
            <Form.Label column>Other details</Form.Label>
          </Col>
          <Col xs={12} md={6} className="my-1">
            <Form.Control
              as="textarea"
              rows={4}
              type="text"
              placeholder="Other details"
              {...getInputProps(
                "patientMVAOtherTreatmentsAdministeredDetails",
                form
              )}
            />
            <ErrorMessage
              name="patientMVAOtherTreatmentsAdministeredDetails"
              component="div"
              className="text-red-500 text-xs italic"
            />
          </Col>
        </>
      ) : null}
      <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
        <Form.Label column>
          Were there any follow up recommendations or treatments?
        </Form.Label>
      </Col>
      <Col xs={12} md={6} className="my-1">
        <Form.Check
          inline
          {...getInputProps("patientMVAFollowUpRecommendations", form)}
          checked={getFieldProps("patientMVAFollowUpRecommendations").value || false}
          type="checkbox"
          label=""
          className="mt-2"
        />
      </Col>
      {form.getFieldProps("patientMVAFollowUpRecommendations").value ? (
        <>
          <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
            <Form.Label column>Other details</Form.Label>
          </Col>
          <Col xs={12} md={6} className="my-1">
            <Form.Control
              as="textarea"
              rows={4}
              type="text"
              placeholder="Other details"
              {...getInputProps(
                "patientMVAFollowUpRecommendationsDetails",
                form
              )}
            />
            <ErrorMessage
              name="patientMVAFollowUpRecommendationsDetails"
              component="div"
              className="text-red-500 text-xs italic"
            />
          </Col>
        </>
      ) : null}
    </Form.Row>
  );
};
