import { doGet, doPost } from 'api/carexGateway';
import { CancelToken } from 'axios';
import moment from 'moment';
import {
  CreateAccountPayload,
  ForgotPasswordPayload,
  ISignIn,
  SignInPayload,
  SignInResponse,
  UserData,
} from 'store/auth/auth.types';
import { TwoFactorAuth } from 'utils/model/TwoFactorAuth';

export enum CREATE_DOCTOR_ERR {
  dupe = 'ER_DUP_ENTRY',
  db = 'Database error!',
  rightToken = 'The correct security token is required.',
}

const AuthService = {
  signIn: async (payload: ISignIn, cancelToken?: CancelToken) => {
    try {
      const data = {
        email: payload.email,
        pass: payload.pass,
        timezone: moment().toISOString(),
      };

      const res = await doPost<SignInResponse, SignInPayload>('signIn', {
        data: { data },
        cancelToken,
      });

      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  getUserId: async (cancelToken?: CancelToken) => {
    try {
      const res = await doGet<UserData[]>('getUserId', {}, cancelToken);

      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  getTwoFactor: async (payload: TwoFactorAuth, cancelToken?: CancelToken ) => {
    try {
      const res = await doPost("getTwoFactor", {
        data: payload,
        cancelToken,
      });
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  },    
  generateTwoFactor: async (payload: TwoFactorAuth, cancelToken?: CancelToken ) => {
    try {
      const res = await doPost("generateTwoFactor", {
        data: payload,
        cancelToken,
      });
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  },    
  checkTwoFactor: async (payload: TwoFactorAuth, cancelToken?: CancelToken ) => {
    // Update to file Generation Save signature
    try {
      const res = await doPost("checkTwoFactor", {
        data: payload,
        cancelToken,
      });
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  }, 
  forgotPassword: async (payload: ForgotPasswordPayload, cancelToken?: CancelToken) => {
    try {
      const data = { data: JSON.stringify(payload) };
      const res = await doPost<any, { data: string }>('forgotPassword', { data, cancelToken });
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  requestOTP: async (email: string, cancelToken?: CancelToken) => {
    try {
      const bodyFormData = new FormData();
      bodyFormData.append('email', email);

      const res = await doPost('requestOTP', {
        data: bodyFormData,
        cancelToken,
      });

      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  getFileExport: async (payload?: string) => {
    try {
      const res = await doGet<any>('getFileExport', {});
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  },
};

export default AuthService;
