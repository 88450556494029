// Components & schema
import CheckCodeStep from "components/twoFactorAuthentication/checkCodeStep/CheckCodeStep";
import CheckCodeStepSchema from "components/twoFactorAuthentication/checkCodeStep/CheckCodeStep.schema";
import RetrieveInfoStep from "components/twoFactorAuthentication/retrieveInfoStep/RetrieveInfoStep";
import RetrieveInfoStepSchema from "components/twoFactorAuthentication/retrieveInfoStep/RetrieveInfoStep.schema";


// Types
import * as Yup from "yup";
export type Step = {
  /** Identifies the name of the step & key in initialValues
   * Can use an 'id' here, doesn't necessarily have to be a 'name' prop.
   */
  name: string;
  component: React.ElementType;
  validationSchema: Yup.SchemaOf<any>;
};

/** Considered the maximum possible steps for a user -- optionally perform filtering logic in `generateSteps` if you need to */
const baseSteps: Step[] = [
  {
    name: "RetrieveInformationStep",
    component: RetrieveInfoStep,
    validationSchema: RetrieveInfoStepSchema,
  },
  {
    name: "CheckCodeStep",
    component: CheckCodeStep,
    validationSchema: CheckCodeStepSchema,
  },
];

export const generateSteps = (): Step[] => {
  // Depending on the type of user, and the requirements for the form that are needed -- you can perform filtering logic here to omit steps that aren't
  // necessary for each case
  return baseSteps;
};

export const generateInitialValues = (
  filteredSteps: Step[],
  tokenId: string
) => {
  // These initial values are assumed to be `null`.
  // In more complex cases you may want to populate these values w/ data from an API, so you could do what you need to do in here
  const initialValues = filteredSteps.reduce((values, step) => {
    // If it first time, load data from Contact, if not, we should be able to load data from FIleGenerationTable
    return { ...values, [step.name]: null };
  }, {});


  return initialValues;
};

export const getStepSchema = (currentIndex: number, steps: Step[]) => {
  return steps[currentIndex].validationSchema;
};


