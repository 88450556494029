import { useEffect, useState } from "react";
// Components
import { Typography, Button, Card, Tooltip } from "@material-ui/core";
import { Row, Col, Form, Container } from "react-bootstrap";
// Types
import { ErrorMessage } from "formik";
// Util
import {
  getInputProps,
  StatePropsType,
  StateIteratorPropsType,
  CommonFormProps,
  IteratorHelperDB,
} from "utils/constants/forms";
// Hooks
import { useDocuments } from "utils/hooks/useDocuments.hook";
import { useFormikContext } from "formik";
import { useAppDispatch } from "store/store";
import { saveDocumentThunkAction } from "store/documents/documents.slice";

// Dates
import Moment from "moment";
import DatePicker from "react-datepicker";
import { isValidDate } from "utils/ValidationUtils";

// Swal
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

// Dropzone
import Dropzone, {
  IDropzoneProps,
  ILayoutProps,
} from "react-dropzone-uploader";

import "components/dropzone/Dropzone.styles.css";



const FirstName = ({ form }: CommonFormProps) => {
  const { getFieldProps } = useFormikContext();
  const dispatch = useAppDispatch();
  const MySwal = withReactContent(Swal);
  const { fileGenerationPhoneIndex, fileGenerationEmailIndex } = useDocuments();
  const [phonesArray, setPhonesArray] = useState<IteratorHelperDB[]>([]);
  const [emailArray, setEmailArray] = useState<IteratorHelperDB[]>([]);

  const patientName = getInputProps("patientName", form).value;
  var isPatientNameFromDB = false;
  if(patientName){
    isPatientNameFromDB = (patientName.length != 0);
  }
  const [patientNameFromDB, setPatientNameFromDB] = useState<boolean>(isPatientNameFromDB);



  const caseId = getFieldProps("caseId").value;
  const contactId = getFieldProps("contactId").value;
  const generationType = getFieldProps("generationType").value;

  // Handle DoB Date
  var formPatientDoB = getFieldProps("patientDoB").value;

  var date = null;
  if (isValidDate(formPatientDoB)) {
    const mPatientDoB = Moment(formPatientDoB, "YYYY-MM-DD");
    date = new Date(mPatientDoB.toDate());
  }
  const [patientDoB, setPatientDoB] = useState<Date | null>(date);

  // Use State selctor in slice and try to get the data??
  useEffect(() => {
    var phoneArray : IteratorHelperDB[]= [];
    if (fileGenerationPhoneIndex) {
      var phoneCount: number = fileGenerationPhoneIndex;
      for (var i = 0; i < phoneCount; i++) {
        phoneArray.push({index: i, comesFromDB: true});
      }
      setPhonesArray(phoneArray);
    }
  }, [fileGenerationPhoneIndex]);

  // Use State selector tog et email data
  useEffect(() => {
    var mEmailArray : IteratorHelperDB[]= [];
    if (fileGenerationEmailIndex) {
      var emailCount: number = fileGenerationEmailIndex;
      for (var i = 0; i < emailCount; i++) {
        mEmailArray.push({index: i, comesFromDB: true});
      }
      setEmailArray(mEmailArray);
    }
  }, [fileGenerationEmailIndex]);

  const getUploadParams: IDropzoneProps["getUploadParams"] = ({ file, meta }) => {
    console.log("file", file);
    console.log(file instanceof Blob);
    uploadToServer(file, file.type, file.name, "Driver's License");
    return ({
      url: "https://httpbin.org/post",
    })
  };

  const handleSubmit: IDropzoneProps["onSubmit"] = (files, allFiles) => {
    console.log(caseId, contactId);
    files.map((f) => {
      // we do something with file
      console.log(f.file instanceof Blob);
    });
    allFiles.forEach((f) => f.remove());
  };

  function uploadToServer(
    blob: Blob,
    type: string,
    name: string,
    fileDesc: string
  ) {
    //var blob = new Blob([arrayBuffer], { type: type });
    dispatch(
      saveDocumentThunkAction({
        data: {
          file: { fileData: blob, filename: name },
          contactId: contactId,
          caseId: caseId,
          fileTypeId: 5,
          fileDescription: fileDesc,
        },
        onSuccess: (res) => {
          return Promise.resolve(res);
        },
        onError: (err) => {
          MySwal.fire("Error", "Couldn't save File", "warning");
          return Promise.reject(err);
        },
      })
    );
  }

  const handleChangeStatus: IDropzoneProps["onChangeStatus"] = (
    { meta },
    status
  ) => {
    console.log(status, meta);
  };

  return (
    <>
      <Container className="mt-4">
        <Row className="align-items-center">
          <Col>
            <Card>
              <Row>
                <Col>
                  <Typography variant="h4" className="text-center mt-5">
                    Demographics Questions
                  </Typography>
                </Col>
              </Row>
              <Form.Row className="mt-5 mr-3 ml-3">
                <Col xs={12} md={8} className="my-1">
                  <Form.Control
                    type="text"
                    placeholder="Patient Full Name*"
                    readOnly={patientNameFromDB}
                    {...getInputProps("patientName", form)}
                  />
                  <ErrorMessage
                    name="patientName"
                    component="div" className="text-red-500 text-xs italic"

                  />
                </Col>
                <Col xs={12} md={4} className="my-1">
                  <label>
                    <DatePicker
                      className="form-control"
                      placeholderText="MM/dd/yyyy*"
                      dateFormat="MM/dd/yyyy"
                      isClearable={true}
                      selected={patientDoB}
                      onChange={(date) => {
                        if (date == null || !Array.isArray(date)) {
                          form.setFieldValue("patientDoB", Moment(date).format("MM/DD/YYYY"));
                          setPatientDoB(date);
                        }
                      }
                      }
                    />
                  </label>
                  &nbsp;<i className="far fa-calendar-alt fa-2x" onClick={() => {

                  }}></i>
                </Col>
              </Form.Row>
              <Form.Row className="mr-3 ml-3">
                <Col xs={10} md={11} className="my-1">
                  <Form.Control
                    type="text"
                    placeholder="Mailing Address" title="We need this in order to check your insurance benefits"
                    {...getInputProps("patientMailingAddress", form)}
                  />
                  <ErrorMessage
                    name="patientMailingAddress"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
                <Col xs={2} md={1} className="my-1 p-2">
                  <Tooltip arrow placement={"top"} title="We need this in order to check your insurance benefits">
                    <i className="fal fa-info-circle"></i>
                  </Tooltip>
                </Col>
              </Form.Row>
              <Form.Row className="mr-3 ml-3">
                <Col xs={12} md={4} className="my-1">
                  <Form.Control
                    type="text"
                    placeholder="City" title="We need this in order to check your insurance benefits"
                    {...getInputProps("patientCity", form)}
                  />
                  <ErrorMessage
                    name="patientCity"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
                <Col xs={12} md={4} className="my-1">
                  <Form.Control
                    type="text"
                    placeholder="State" title="We need this in order to check your insurance benefits"
                    {...getInputProps("patientState", form)}
                  />
                  <ErrorMessage
                    name="patientState"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>{" "}
                <Col xs={12} md={4} className="my-1">
                  <Form.Control
                    type="text"
                    placeholder="Zip" title="We need this in order to check your insurance benefits"
                    {...getInputProps("patientZip", form)}
                  />
                  <ErrorMessage
                    name="patientZip"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
              </Form.Row>
              <Form.Row className="mr-3 ml-3">
                <Col xs={12} md={12} className="my-1">
                  <Form.Label column>Mails:</Form.Label>
                </Col>
                <Col xs={12} md={6} className="my-1">
                  <Button
                    color="primary"
                    onClick={() => {
                      var newItem = (emailArray[emailArray.length - 1].index +
                        1) as number;
                      if (isNaN(newItem)) {
                        newItem = 0;
                      }
                      var item = {index: newItem, comesFromDB: false} as IteratorHelperDB;
                      setEmailArray([...emailArray, item]);
                      return;
                    }}
                  >
                    <i className="fas fa-plus"></i>
                  </Button>
                </Col>
              </Form.Row>
              {emailArray.map((item: IteratorHelperDB, i: number) => {
                const stateProps = {
                  form,
                  state: [emailArray, setEmailArray],
                  item: item,
                } as StateIteratorPropsType;
                return <EmailSection key={i} {...stateProps} />;
              })}
              <Form.Row className="mr-3 ml-3">
                <Col xs={12} md={12} className="my-1">
                  <Form.Label column>Phones:</Form.Label>
                </Col>
                <Col xs={12} md={6} className="my-1">
                  <Button
                    color="primary"
                    onClick={() => {
                      var newItem = (phonesArray[phonesArray.length - 1].index +
                        1) as number;
                      if (isNaN(newItem)) {
                        newItem = 0;
                      }

                      var item = {index: newItem, comesFromDB: false} as IteratorHelperDB;
                      setPhonesArray([...phonesArray, item]);
                      return;
                    }}
                  >
                    <i className="fas fa-plus"></i>
                  </Button>
                </Col>
              </Form.Row>
              {phonesArray.map((item: IteratorHelperDB, i: number) => {
                const stateProps = {
                  form,
                  state: [phonesArray, setPhonesArray],
                  item: item,
                } as StateIteratorPropsType;
                return <PhonesSection key={i} {...stateProps} />;
              })}
              <Form.Row className="mr-3 ml-3">
                <Col xs={12} md={4} className="my-1">
                  <Form.Control
                    as="select"
                    custom
                    type="text"
                    placeholder="Sex"
                    {...getInputProps("patientSex", form)}
                  >
                    <option>-- Select Sex --</option>
                    <option>M</option>
                    <option>F</option>
                  </Form.Control>
                  <ErrorMessage
                    name="patientSex"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
              </Form.Row>
              <Form.Row className="mr-3 ml-3">
                <Col xs={12} md={4} className="my-1">
                  <Form.Control
                    type="text"
                    placeholder="Driver's License #"
                    {...getInputProps("patientDriverLicense", form)}
                  />
                  <ErrorMessage
                    name="patientDriverLicense"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
                <Col xs={12} md={4} className="my-1">
                  <Form.Control
                    type="text"
                    placeholder="Driver's License State"
                    {...getInputProps("patientDriverLicenseState", form)}
                  />
                  <ErrorMessage
                    name="patientDriverLicenseState"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
              </Form.Row>
              <Form.Row className="mr-3 ml-3">
                {" "}
                <Col xs={12} md={3} className="my-1">
                  <Form.Label column>Upload Driver's License:</Form.Label>
                </Col>
                <Col xs={12} md={4} className="my-1">
                  <Dropzone
                    maxFiles={1}
                    multiple={false}
                    getUploadParams={getUploadParams}
                    onChangeStatus={handleChangeStatus}
                    canCancel={false}
                    //onSubmit={handleSubmit}
                    accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                    inputContent={(files, extra) =>
                      extra.reject
                        ? "Image or files only"
                        : "Click to add or drag File"
                    }
                    styles={{
                      dropzoneReject: {
                        borderColor: "red",
                        backgroundColor: "#DAA",
                      },
                      inputLabel: (files, extra) =>
                        extra.reject ? { color: "red" } : {},
                    }}
                  />
                </Col>
              </Form.Row>
              <Form.Row className="mr-3 ml-3">
                <Col xs={12} md={4} className="my-1">
                  <Form.Control
                    as="select"
                    custom
                    type="text"
                    placeholder="Marital Status"
                    {...getInputProps("patientMaritalStatus", form)}
                  >
                    <option>-- Select Marital Status --</option>
                    <option>Married</option>
                    <option>Single</option>
                    <option>Divorced</option>
                    <option>Widowed</option>
                  </Form.Control>
                  <ErrorMessage
                    name="patientMaritalStatus"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
              </Form.Row>
              <Form.Row className="mr-3 ml-3">
                <Col xs={12} md={4} className="my-1">
                  <Form.Control
                    type="text"
                    placeholder="Emergency Contact Name"
                    {...getInputProps("patientEmergencyContactName", form)}
                  />
                  <ErrorMessage
                    name="patientEmergencyContactName"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
                <Col xs={12} md={4} className="my-1">
                  <Form.Control
                    type="text"
                    placeholder="Emergency Contact Relationship"
                    {...getInputProps(
                      "patientEmergencyContactRelationship",
                      form
                    )}
                  />
                  <ErrorMessage
                    name="patientEmergencyContactRelationship"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
              </Form.Row>
              <Form.Row className="mr-3 ml-3">
                <Col xs={12} md={4} className="my-1">
                  <Form.Control
                    type="text"
                    placeholder="Emergency Contact Email"
                    {...getInputProps("patientEmergencyContactEmail", form)}
                  />
                  <ErrorMessage
                    name="patientEmergencyContactEmail"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
                <Col xs={12} md={4} className="my-1">
                  <Form.Control
                    type="text"
                    placeholder="Emergency Contact Phone"
                    {...getInputProps("patientEmergencyContactPhone", form)}
                  />
                  <ErrorMessage
                    name="patientEmergencyContactPhone"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
              </Form.Row>
              <Form.Row className="mr-3 ml-3 mb-4">
                <Col xs={11} md={4} className="my-1">
                  <Typography className="mt-1">
                    Were you referred to us by another provider?
                  </Typography>
                </Col>
                <Col xs={1} md={1} className="my-1">
                  <Form.Check
                    inline
                    type="checkbox"
                    className="mt-2"
                    checked={
                      getFieldProps("patientRefererredCheckbox").value || false
                    }
                    {...getInputProps("patientRefererredCheckbox", form)}
                  />
                </Col>
                {form.getFieldProps("patientRefererredCheckbox").value ? (
                  <>
                    <Col xs={12} md={4}>
                      <Form.Control
                        type="text"
                        placeholder="Provider Name"
                        {...getInputProps("providerName", form)}
                      />
                      <ErrorMessage
                        name="providerName"
                        component="div"
                        data-testid="email-error"
                      />
                    </Col>
                    <Col xs={12} md={3}>
                      <Form.Control
                        type="text"
                        placeholder="Provider Speciality"
                        {...getInputProps("providerSpeciality", form)}
                      />
                      <ErrorMessage
                        name="providerSpeciality"
                        component="div"
                        data-testid="email-error"
                      />
                    </Col>
                  </>
                ) : null}
              </Form.Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default FirstName;

const PhonesSection = ({
  form,
  state: [phonesArray, setPhonesArray],
  item
}: StateIteratorPropsType) => {
  return (
    <>
      <Form.Row className="mr-3 ml-3">
        <Col xs={12} md={3} className="my-1">
          <Form.Control
            type="text"
            placeholder={`Phone number ${item.index}`}
            readOnly={item.comesFromDB}
            {...getInputProps("patientPhoneNumber" + item.index, form)}
          />
          <ErrorMessage
            name={`patientPhoneNumber${item.index}`}
            component="div"
            data-testid="email-error"
          />
        </Col>

        <Col xs={12} md={1} className="my-1 pt-1">
          <Button
            color="secondary"
            onClick={() => {
              // Remove item from array
              setPhonesArray(
                phonesArray.filter(function (item: IteratorHelperDB) {
                  return item.index !== item.index;
                })
              );

              // Set all values to null
              form.setFieldValue("patientPhoneNumber" + item.index, null);
              return;
            }}
          >
            <i className="fas fa-trash-alt"></i>
          </Button>
        </Col>
      </Form.Row>
    </>
  );
};

const EmailSection = ({
  form,
  state: [emailArray, setEmailArray],
  item,
}: StateIteratorPropsType) => {

  return (
    <>
      <Form.Row className="mr-3 ml-3">
        <Col xs={12} md={3} className="my-1">
          <Form.Control
            type="text"
            placeholder={`Email ${item.index}`}
            readOnly={item.comesFromDB}
            {...getInputProps("patientEmail" + item.index, form)}
          />
          <ErrorMessage
            name={`patientEmail${item.index}`}
            component="div"
            data-testid="email-error"
          />
        </Col>

        <Col xs={12} md={1} className="my-1 pt-1">
          <Button
            color="secondary"
            onClick={() => {
              // Remove item from array
              setEmailArray(
                emailArray.filter(function (emailItem: IteratorHelperDB) {
                  return item.index !== emailItem.index;
                })
              );

              // Set all values to null
              form.setFieldValue("patientEmail" + item.index, null);
              return;
            }}
          >
            <i className="fas fa-trash-alt"></i>
          </Button>
        </Col>
      </Form.Row>
    </>
  );
};

// add type defs to custom LayoutComponent prop to easily inspect props passed to injected components
const Layout = ({
  input,
  previews,
  submitButton,
  dropzoneProps,
  files,
  extra: { maxFiles },
}: ILayoutProps) => {
  return (
    <div>
      {previews}

      <div {...dropzoneProps}>{files.length < maxFiles && input}</div>

      {files.length > 0 && submitButton}
    </div>
  );
};
