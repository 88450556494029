import { useSelector } from "react-redux";
import {
  fileExportSelector,
  fileGenerationSelector,
  isFileGenerationErrorSelector,
  isFileGenerationLoadingSelector,
  fileGenerationPhoneIndexSelector,
  fileGenerationEmailIndexSelector,
  isSavingFormDataSelector,
  screeningCaseSelector,
  isFileExportLoadingSelector,
  canSignHipaaSelector,
  canDownloadPdfSelector,
  documentSigningDateSelector,
  pdfBytesSelector,
  insurerListSelector,
} from "store/documents/documents.selectors";

export const useDocuments = () => {
  const fileExport = useSelector(fileExportSelector);
  const fileGeneration = useSelector(fileGenerationSelector);
  const isFileGenerationError = useSelector(isFileGenerationErrorSelector);
  const isFileGenerationLoading = useSelector(isFileGenerationLoadingSelector);
  const fileGenerationPhoneIndex = useSelector(fileGenerationPhoneIndexSelector);
  const fileGenerationEmailIndex = useSelector(fileGenerationEmailIndexSelector);
  const isSavingFormData = useSelector(isSavingFormDataSelector);
  const screeningCase = useSelector(screeningCaseSelector);
  const isFileExportLoading = useSelector(isFileExportLoadingSelector);
  const canSignHipaa = useSelector(canSignHipaaSelector);
  const canDownloadPdf = useSelector(canDownloadPdfSelector);
  const documentSigningDate = useSelector(documentSigningDateSelector);
  const pdfBytes = useSelector(pdfBytesSelector);
  const insurerList = useSelector(insurerListSelector);

  return {
    fileExport,
    fileGeneration,
    isFileGenerationError,
    isFileGenerationLoading,
    fileGenerationPhoneIndex,
    fileGenerationEmailIndex,
    isSavingFormData,
    screeningCase,
    isFileExportLoading,
    canSignHipaa,
    canDownloadPdf,
    documentSigningDate,
    pdfBytes, 
    insurerList,
  };
};
