import { AcroFieldFlags, PDFFont, PDFForm, PDFPage, rgb } from "pdf-lib";
import { CurrentDimensions } from "../model/CurrentDimensions";
import { PDFFieldCustom } from "../model/PDFFieldCustom";
import { PDFLabelCustom } from "../model/PDFLabelCustom";

interface PDFCheckboxFieldComponent {
    currentDimensions: CurrentDimensions,
    labelData: PDFLabelCustom,
    fieldData: PDFFieldCustom,
    page: PDFPage,
    form: PDFForm,
    font: PDFFont,
    fontSize: number,
    jumpLine?: boolean,
}

const PDFCheckboxFieldComponent = (props: PDFCheckboxFieldComponent) => {
    const { width, height } = props.page.getSize();
    props.page.drawText(props.labelData.labelText, {
        x: props.currentDimensions.width + props.labelData.labelMargin,
        y: height - props.currentDimensions.height - props.labelData.labelHeight,
        size: props.fontSize,
        font: props.font,
        color: rgb(0, 0, 0),
        opacity: 1,
    });

    var field = props.form.createCheckBox(props.fieldData.fieldId);
    field.addToPage(props.page, {
        x: props.currentDimensions.width + props.fieldData.fieldMargin,
        y: height - props.currentDimensions.height - props.labelData.labelHeight,
        font: props.font,
        width: props.fieldData.fieldWidth,
        height: props.fieldData.fieldHeight,
    });

    field.enableReadOnly();
    if (props.fieldData.fieldValue === true ||props.fieldData.fieldValue == "Yes") {
        field.check();
    }


    // We get the total width of the field, since it is the last item
    const finalWidth = props.jumpLine ? 0 : props.currentDimensions.width + props.fieldData.fieldMargin + props.fieldData.fieldWidth;
    // We choose the element with the highest height
    const elementHeight = props.jumpLine ? (props.labelData.labelHeight > props.fieldData.fieldHeight) ? props.labelData.labelHeight : props.fieldData.fieldHeight : 0;
    // Add the highest height to the current height and return it
    const finalHeight = props.currentDimensions.height + elementHeight / 2;
    return { width: finalWidth, height: finalHeight } as CurrentDimensions;
};

export default PDFCheckboxFieldComponent;
