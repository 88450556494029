import { useSelector } from "react-redux";
import {
  currentTokenSelector,
  currentUserSelector,
  twoFactorAuthSelector,
} from "store/auth/auth.selectors";

export const useAuth = () => {
  const auth = useSelector(currentTokenSelector);
  const user = useSelector(currentUserSelector);
  const twoFactorAuth = useSelector(twoFactorAuthSelector);
  return { user, auth, twoFactorAuth };
};
