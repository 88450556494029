import { PDFFont, PDFForm, PDFPage, rgb } from "pdf-lib";
import PDFCheckboxFieldComponent from "../components/CheckboxFieldComponent";
import PDFTextFieldComponent from "../components/TextFieldComponent";
import { CurrentDimensions } from "../model/CurrentDimensions";
import { PDFSectionProps } from "../model/PDFSectionProps";


const MedicationsSection = (props: PDFSectionProps) => {
    // Header style
    const headerFontSize = props.headerFontSize;
    const textHeight = 4 * headerFontSize;

    // Label Style
    const labelFontSize = props.labelFontSize;
    const labelHeight = 4 * labelFontSize;

    // Keep track of the height and width
    var currentDimensions = { height: props.heightPointer + textHeight, width: 0 } as CurrentDimensions;
    props.page.drawText('Medications & Alergies Questions', {
        x: 30,
        y: props.height - currentDimensions.height,
        size: headerFontSize,
        font: props.timesRomanFont,
        color: rgb(0, 0, 0),
        opacity: 1,
    });


    props.page.drawText('List all medications', {
        x: 30,
        y: props.height - currentDimensions.height - labelHeight,
        size: labelFontSize,
        font: props.timesRomanFont,
        color: rgb(0, 0, 0),
        opacity: 1,
    });

    currentDimensions.height = currentDimensions.height + labelHeight;
    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'medication.0.field',
            fieldMargin: 90,
            fieldWidth: 90,
            fieldValue: props.formValues.patientMStepMedication0
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Medication:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });
    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'strength.0.field',
            fieldMargin: 70,
            fieldWidth: 70,
            fieldValue: props.formValues.patientMStepStrength0
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 10,
            labelText: "Strength:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });
    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'dose.0.field',
            fieldMargin: 50,
            fieldWidth: 50,
            fieldValue: props.formValues.patientMStepDose0
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 10,
            labelText: "Dose:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });
    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'frequency.0.field',
            fieldMargin: 70,
            fieldWidth: 70,
            fieldValue: props.formValues.patientMStepFrequency0
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 10,
            labelText: "Frequency:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });



    props.page.drawText('Allergies to medications or materials', {
        x: 30,
        y: props.height - currentDimensions.height - labelHeight,
        size: labelFontSize,
        font: props.timesRomanFont,
        color: rgb(0, 0, 0),
        opacity: 1,
    });

    currentDimensions.height = currentDimensions.height + labelHeight;
    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'allergy.0.field',
            fieldMargin: 90,
            fieldWidth: 150,
            fieldValue: props.formValues.patientMedicationAllergy0
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Allergy:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });
    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'reaction.0.field',
            fieldMargin: 90,
            fieldWidth: 200,
            fieldValue: props.formValues.patientMedicationAllergyReaction0
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Reaction:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });


    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'preferred.pharmacy.name.field',
            fieldMargin: 170,
            fieldWidth: 110,
            fieldValue: props.formValues.patientMedicationsPreferredPharmName
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Preferred Pharmacy Name:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });
    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'preferred.pharmacy.phone.field',
            fieldMargin: 170,
            fieldWidth: 110,
            fieldValue: props.formValues.patientMedicationsPreferredPharmPhone
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Preferred Pharmacy Phone:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'preferred.pharmacy.address.field',
            fieldMargin: 170,
            fieldWidth: 110,
            fieldValue: props.formValues.patientMedicationsPreferredPharmAddress
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Preferred Pharmacy Address:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });
    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'preferred.pharmacy.city.field',
            fieldMargin: 170,
            fieldWidth: 110,
            fieldValue: props.formValues.patientMedicationsPreferredPharmCity
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Preferred Pharmacy City:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });


    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'preferred.pharmacy.state.field',
            fieldMargin: 170,
            fieldWidth: 110,
            fieldValue: props.formValues.patientMedicationsPreferredPharmState
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Preferred Pharmacy State:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });
    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'preferred.pharmacy.zip.field',
            fieldMargin: 170,
            fieldWidth: 110,
            fieldValue: props.formValues.patientMedicationsPreferredPharmZip
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Preferred Pharmacy Zip:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });

    return currentDimensions;
};

export default MedicationsSection;
