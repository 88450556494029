import { Box, Button, Card, Typography } from '@material-ui/core';
import WarnButton from 'components/atoms/warnButton/WarnButton';
import { ErrorMessage, Formik } from 'formik';
import { AUTH_ROUTES } from 'pages/auth/AuthRouter';
import ProviderAccessCard from 'pages/auth/components/ProviderAccessCard';
import { HOME_ROUTES } from 'pages/home/HomeRouter';
import React from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { isSignInLoadingSelector } from 'store/auth/auth.selectors';
import { signInThunkAction } from 'store/auth/auth.slice';
import { useAppDispatch } from 'store/store';
import { useAuth } from 'utils/hooks/useAuth.hook';
import { SignInSchema } from 'validations';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const initialValues = {
  email: '',
  password: '',
};

const SignIn: React.FC = (props) => {
  const dispatch = useAppDispatch();
  const isLoading = useSelector(isSignInLoadingSelector);

  const { auth } = useAuth();

  if (auth) {
    return <Redirect to={HOME_ROUTES.root.path}></Redirect>;
  }

  const handleSignIn = (values: typeof initialValues) => {
    dispatch(
      signInThunkAction({
        data: { email: values.email, pass: values.password },
        onError: (err) => {
          MySwal.fire('Login Error', 'Invalid login values', 'warning');
          return Promise.reject(err);
        },
      })
    );
  };

  return (
    <Container className="mt-5">
      <Row className="align-items-center">
        <Col>
          <Card>
            <Typography variant="h2" className="text-center">
            Please Log In
            </Typography>
            <Formik
              initialValues={initialValues}
              validationSchema={SignInSchema}
              onSubmit={handleSignIn}
            >
              {({ values, handleChange, handleBlur, handleSubmit, isValid, touched, errors }) => {
                const canSignIn = !isLoading && isValid;

                return (
                  <Form onSubmit={handleSubmit}>
                    <Form.Row>
                    <Form.Group controlId="formEmail"  as={Col}> 
                      <Form.Control 
                        type="email"
                        name="email"
                        placeholder="Email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        isValid={touched.email && !errors.email}
                        isInvalid={!!errors.email}
                      />
                      <Form.Control.Feedback type="invalid">
                        <ErrorMessage name="email" component="div" data-testid="email-error" />
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formPassword" as={Col}>
                      <Form.Control
                        type="password"
                        name="password"
                        placeholder="Password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        isValid={touched.password && !errors.password}
                        isInvalid={!!errors.password}
                      />
                      <Form.Control.Feedback type="invalid">
                        <ErrorMessage
                          name="password"
                          component="div"
                          data-testid="password-error"
                        />
                      </Form.Control.Feedback>
                    </Form.Group>
                    </Form.Row>
                    <Box display="flex" justifyContent="space-around">
                      <Button disabled={!canSignIn} type="submit">
                        Sign In
                      </Button>
                      <Button component={Link} to={AUTH_ROUTES.root + AUTH_ROUTES.resetPassword}>
                        Reset Password
                      </Button>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default SignIn;
