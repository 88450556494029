import { Action, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import localForage from 'localforage';
import { useDispatch } from 'react-redux';
import { persistReducer, persistStore } from 'redux-persist';
import { ThunkAction } from 'redux-thunk';

import rootReducer, { RootState } from './rootReducer';

type WhitelistPersist = [keyof RootState];
const whitelist: WhitelistPersist = ['auth'];
//const whitelist: WhitelistPersist = [''];
const persistConfig = {
  key: 'root',
  storage: localForage,
 // whitelist: whitelist as any, //Things u want to persist
  blacklist: [], //Things u dont
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export const persistor = persistStore(store);

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default;
    store.replaceReducer(newRootReducer);
  });
}

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export default store;
