import React, { useState } from "react";
// Components
import { Typography, Button, Card, Tooltip } from "@material-ui/core";
import { Row, Col, Form, Container } from "react-bootstrap";
import {MultiSelect} from "react-multi-select-component";
// Types
import {
  FormikProps,
  FormikValues,
  ErrorMessage,
  useFormikContext,
} from "formik";
// Util
import { getInputProps, getInputPropsMultiSelect } from "utils/constants/forms";

type FirstNameProps = {
  form: FormikProps<FormikValues>;
  patientName: "OtherMedicalHistoryQuestions";
};

type StatePropsType = {
  form: FormikProps<FormikValues>;
  state: [any, React.Dispatch<React.SetStateAction<any>>];
};

type DynamicPropsType = {
  form: FormikProps<FormikValues>;
  state: [any, React.Dispatch<React.SetStateAction<any>>];
  index: number;
};

const OtherMedicalHistoryQuestions = ({
  form,
  patientName,
}: FirstNameProps) => {
  // Grab what we need from formik without prop-drilling
  const { getFieldProps } = useFormikContext();

  const commonProps = {
    form,
    patientName,
  };

  const [sleepApneaSelected, setSleepApneaSelected] = useState(
    getFieldProps("patientOMHSleepApneaOptions").value || []
  );
  const sleepApneaProps = {
    form,
    state: [sleepApneaSelected, setSleepApneaSelected],
  } as StatePropsType;

  const medicalConditionsOptions = [
    { label: "High Blood Pressure", value: "HighBloodPressure" },
    { label: "High Cholesterol", value: "HighCholesterol" },
    { label: "Gout", value: "Gout" },
    { label: "Stroke", value: "Stroke" },
    { label: "Heart Failure", value: "Heart Failure" },
    { label: "Diabetes", value: "Diabetes" },
    { label: "GERD", value: "GERD" },
    { label: "Brain Tumor", value: "Brain Tumor" },
    { label: "Heart Attack", value: "Heart Attack" },
    { label: "Fibromyalgia", value: "Fibromyalgia" },
    { label: "Bleeding Disorder", value: "Bleeding Disorder" },
    { label: "Alzheimer's", value: "Alzheimers" },
    { label: "Angina/Chest Pain", value: "Angina/ChestPain" },
    { label: "RSD/CRPS", value: "RSD/CRPS" },
    { label: "Emphysema/COPD", value: "Emphysema/COPD" },
    { label: "Dementia", value: "Dementia" },
    { label: "Irregular Heartbeat", value: "Irregular Heartbeat" },
    { label: "Osteoarthritis", value: "Osteoarthritis" },
    { label: "Asthma", value: "Asthma" },
    { label: "Parkinson's Disease", value: "Parkinsons Disease" },
    { label: "Valve Replacement", value: "ValveReplacement" },
    { label: "Rheumatoid Arthritis", value: "RheumatoidArthritis" },
    { label: "Seizure", value: "Seizure" },
    { label: "Depression", value: "Depression" },
    { label: "Anxiety", value: "Anxiety" },
    { label: "Neuropathy", value: "Neuropathy" },
    { label: "Headaches", value: "Headaches" },
    { label: "Hepatitis", value: "Hepatitis" },
    { label: "HIV/AIDS", value: "HIV/AIDS" },
    { label: "Tuberculosis", value: "Tuberculosis" },
    { label: "Kidney Disease", value: "KidneyDisease" },
    { label: "Thyroid Disorder", value: "ThyroidDisorder" },
    { label: "Blood Clots*(where+when)", value: "BloodClots" },
    { label: "Cancer*(type)", value: "Cancer" },
    { label: "Serious Injuries*(explain)", value: "SeriousInjuries" },
    { label: "Other*(explain)", value: "Other" },
    { label: "None of the Above", value: "None" },
  ];
  const [medicalConditionsSelected, setMedicalConditionsSelected] = useState(
    getFieldProps("patientOMHMedicalConditions").value || []
  );

  const familyHistoryOptions = [
    { label: "High Blood Pressure", value: "HighBloodPressure" },
    { label: "Diabetes", value: "Diabetes" },
    { label: "Cancer", value: "Cancer" },
    { label: "Coronary Artery Disease", value: "CoronaryArteryDisease" },
    { label: "Stroke", value: "Stroke" },
    { label: "Bleeding Disorder", value: "BleedingDisorder" },
    { label: "Blood Clots", value: "BloodClots" },
    { label: "Heart Attack", value: "HeartAttack" },
    { label: "Other", value: "Other" },
  ];
  //patientOMHFamilyHistory
  const [familyHistorySelected, setFamilyHistorySelected] = useState(
    getFieldProps("patientOMHFamilyHistory").value || []
  );

  const [previusSpineSurgeryArray, setPreviusSpineSurgeryArray] = useState<any>(
    []
  );

  const [implantedDevicesSelected, setImplantedDevicesSelected] = useState(
    getFieldProps("patientOMHImplantedDevicesOptions").value || []
  );

  const implantedDevicesProps = {
    form,
    state: [implantedDevicesSelected, setImplantedDevicesSelected],
  } as StatePropsType;


  return (
    <>
      <Container className="mt-4">
        <Row className="align-items-center">
          <Col>
            <Card>
              <Row>
                <Col>
                  <Typography variant="h4" className="text-center mt-5">
                    Other Medical History Questions
                  </Typography>
                </Col>
              </Row>
              <Form.Row className="mt-5 mr-3 ml-3">
                <Col xs={12} md={6} className="my-1">
                  <Form.Label column>
                    Do you have a Primary Care Provider?
                  </Form.Label>
                </Col>
                <Col xs={12} md={6} className="my-1">
                  <Form.Control
                    as="select"
                    custom
                    type="text"
                    placeholder="Select if you had XRay"
                    {...getInputProps("patientOMHHasPCP", form)}
                  >
                    <option>-- Select --</option>
                    <option>Yes</option>
                    <option>No</option>
                  </Form.Control>
                  <ErrorMessage
                    name="patientOMHHasPCP"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
              </Form.Row>
              {form.getFieldProps("patientOMHHasPCP").value === "Yes" ? (
                <PCPSection {...commonProps} />
              ) : null}
              <Form.Row className="mr-3 ml-3">
                <Col xs={12} md={6} className="my-1">
                  <Form.Label column>
                    Medical Conditions you have, or have had in the past:
                  </Form.Label>
                </Col>
                <Col xs={12} md={6} className="my-1">
                  <MultiSelect
                    options={medicalConditionsOptions}
                    value={medicalConditionsSelected}
                    onChange={(values: any) => {
                      setMedicalConditionsSelected(values);
                      form.setFieldValue("patientOMHMedicalConditions", values);
                    }}
                    {...getInputPropsMultiSelect(
                      "patientOMHMedicalConditions",
                      form
                    )}
                    labelledBy="Select Pain Type"
                  />
                </Col>
              </Form.Row>
              <Form.Row className="mr-3 ml-3">
                <Col xs={12} md={6} className="my-1">
                  <Form.Label column>
                    Have you been diagnosed with obstructive sleep apnea?
                  </Form.Label>
                </Col>
                <Col xs={12} md={6} className="my-1">
                  <Form.Control
                    as="select"
                    custom
                    type="text"
                    placeholder="Select if you had XRay"
                    {...getInputProps("patientOMHHasSleepApnea", form)}
                  >
                    <option>-- Select --</option>
                    <option>Yes</option>
                    <option>No</option>
                  </Form.Control>
                  <ErrorMessage
                    name="patientOMHHasSleepApnea"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
              </Form.Row>
              {form.getFieldProps("patientOMHHasSleepApnea").value === "Yes" ? (
                <SleepApneaSection {...sleepApneaProps} />
              ) : null}
              <Form.Row className="mr-3 ml-3">
                <Col xs={12} md={6} className="my-1">
                  <Form.Label column>Family History:</Form.Label>
                </Col>
                <Col xs={10} md={5} className="my-1">
                  <MultiSelect
                    options={familyHistoryOptions}
                    value={familyHistorySelected}
                    onChange={(values: any) => {
                      setFamilyHistorySelected(values);
                      form.setFieldValue("patientOMHFamilyHistory", values);
                    }}
                    {...getInputPropsMultiSelect(
                      "patientOMHFamilyHistory",
                      form
                    )}
                    labelledBy="Select Pain Type"
                  />
                </Col>
                <Col xs={2} md={1} className="my-1 p-2">
                  <Tooltip title="What ever options selected will be applied.">
                    <i className="fal fa-info-circle"></i>
                  </Tooltip>
                </Col>
              </Form.Row>
              <Form.Row className="mr-3 ml-3">
                <Col xs={12} md={12} className="my-1">
                  <Form.Label column>
                    Please list any previous non-spine surgeries you have had:
                  </Form.Label>
                </Col>
              </Form.Row>
              <Form.Row className="mr-3 ml-3 d-flex justify-content-center">
                <Button
                  color="primary"
                  onClick={() => {
                    var newItem = (previusSpineSurgeryArray[
                      previusSpineSurgeryArray.length - 1
                    ] + 1) as number;
                    if (isNaN(newItem)) {
                      newItem = 0;
                    }
                    setPreviusSpineSurgeryArray([
                      ...previusSpineSurgeryArray,
                      newItem,
                    ]);
                    return;
                  }}
                >
                <i className="fas fa-plus"></i>
                </Button>
              </Form.Row>
                {previusSpineSurgeryArray.map((item: number, i: number) => {
                  const stateProps = {
                    form,
                    state: [
                      previusSpineSurgeryArray,
                      setPreviusSpineSurgeryArray,
                    ],
                    index: item,
                  } as DynamicPropsType;
                  return <PreviuousSurgerySection key={i} {...stateProps} />;
                })}
              <Form.Row className="mr-3 ml-3">
                <Col xs={12} md={6} className="my-1">
                  <Form.Label column>
                    Have you had any complications with anesthesia?
                  </Form.Label>
                </Col>
                <Col xs={12} md={6} className="my-1">
                  <Form.Control
                    as="select"
                    custom
                    type="text"
                    placeholder="Select if you had XRay"
                    {...getInputProps(
                      "patientOMHHasAnesthesiaComplications",
                      form
                    )}
                  >
                    <option>-- Select --</option>
                    <option>Yes</option>
                    <option>No</option>
                  </Form.Control>
                  <ErrorMessage
                    name="patientOMHHasAnesthesiaComplications"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
              </Form.Row>
              {form.getFieldProps("patientOMHHasAnesthesiaComplications")
                .value === "Yes" ? (
                <AnesthesiaComplicationsSection {...commonProps} />
              ) : null}
              <Form.Row className="mr-3 ml-3">
                <Col xs={12} md={6} className="my-1">
                  <Form.Label column>
                    Have you had any cardiac or vascular stents placed in the
                    past 12 months?
                  </Form.Label>
                </Col>
                <Col xs={12} md={6} className="my-1">
                  <Form.Control
                    as="select"
                    custom
                    type="text"
                    placeholder="Select if you had XRay"
                    {...getInputProps("patientOMHHasHadStents", form)}
                  >
                    <option>-- Select --</option>
                    <option>Yes</option>
                    <option>No</option>
                  </Form.Control>
                  <ErrorMessage
                    name="patientOMHHasHadStents"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
              </Form.Row>
              {form.getFieldProps("patientOMHHasHadStents").value === "Yes" ? (
                <StentSection {...commonProps} />
              ) : null}
              <Form.Row className="mr-3 ml-3">
                <Col xs={12} md={6} className="my-1">
                  <Form.Label column>
                    Do you have any implanted devices?
                  </Form.Label>
                </Col>
                <Col xs={12} md={6} className="my-1">
                  <Form.Control
                    as="select"
                    custom
                    type="text"
                    placeholder="Select if you had XRay"
                    {...getInputProps("patientOMHHasImplantedDevices", form)}
                  >
                    <option>-- Select --</option>
                    <option>Yes</option>
                    <option>No</option>
                  </Form.Control>
                  <ErrorMessage
                    name="patientOMHHasImplantedDevices"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
              </Form.Row>
              {form.getFieldProps("patientOMHHasImplantedDevices").value ===
              "Yes" ? (
                <ImplantedDevicesSection {...implantedDevicesProps} />
              ) : null}
              <Form.Row className="mr-3 ml-3">
                <Col xs={12} md={12} className="my-1">
                  <Form.Label column>
                    Please list anything else it is important for us to know!
                  </Form.Label>
                </Col>
              </Form.Row>
              <Form.Row className="mr-3 ml-3">
                <Col xs={12} md={12} className="my-1">
                  <Form.Control
                    as="textarea"
                    rows={8}
                    type="text"
                    placeholder="Describe anything important!"
                    {...getInputProps("patientOMHAnythingImportant", form)}
                  />
                  <ErrorMessage
                    name="patientOMHAnythingImportant"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
              </Form.Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default OtherMedicalHistoryQuestions;

const PCPSection = ({ form, patientName }: FirstNameProps) => (
  <Form.Row className=" mr-3 ml-3">
    <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
      <Form.Label column>PCP Name</Form.Label>
    </Col>
    <Col xs={12} md={6} className="my-1">
      <Form.Control
        type="text"
        placeholder="PCP Name"
        {...getInputProps("patientOMHPCPName", form)}
      />
      <ErrorMessage
        name="patientOMHPCPName"
        component="div"
        data-testid="email-error"
      />
    </Col>
    <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
      <Form.Label column>PM Doctor Address</Form.Label>
    </Col>
    <Col xs={12} md={6} className="my-1">
      <Form.Control
        type="text"
        placeholder="PCP Address"
        {...getInputProps("patientOMHPCPAddress", form)}
      />
      <ErrorMessage
        name="patientOMHPCPAddress"
        component="div"
        className="text-red-500 text-xs italic"
      />
    </Col>
    <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
      <Form.Label column>PCP Phone</Form.Label>
    </Col>
    <Col xs={12} md={6} className="my-1">
      <Form.Control
        type="text"
        placeholder="PCP Phone"
        {...getInputProps("patientOMHPCPPhone", form)}
      />
      <ErrorMessage
        name="patientOMHPCPPhone"
        component="div"
        data-testid="email-error"
      />
    </Col>
  </Form.Row>
);

const SleepApneaSection = ({
  form,
  state: [sleepApneaSelected, setSleepApneaSelected],
}: StatePropsType) => {
  const sleepApneaOptions = [
    { label: "BIBAP", value: "BIBAP" },
    { label: "CPAP", value: "CPAP" },
    { label: "No", value: "No" },
  ];
  return (
    <Form.Row className="mr-3 ml-3">
      <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
        <Form.Label column>Do you use BIBAP or CPAP?</Form.Label>
      </Col>
      <Col xs={12} md={6} className="my-1">
        <MultiSelect
          options={sleepApneaOptions}
          value={sleepApneaSelected}
          onChange={(values: any) => {
            setSleepApneaSelected(values);
            form.setFieldValue("patientOMHSleepApneaOptions", values);
          }}
          {...getInputPropsMultiSelect("patientOMHSleepApneaOptions", form)}
          labelledBy="Select Pain Type"
        />
      </Col>
    </Form.Row>
  );
};

const PreviuousSurgerySection = ({
  form,
  state: [previusSpineSurgeryArray, setPreviusSpineSurgeryArray],
  index,
}: DynamicPropsType) => {
  return (
    <>
    <Card className="mt-2" variant="outlined">
      <Form.Row className="mr-3 ml-3 text-right">
      <Col xs={12} md={2} className="my-1">
        <Form.Control
          type="date"
          placeholder={`Date ${index}`}
          {...getInputProps("patientOMHPrevSurgDate" + index, form)}
        />
        <ErrorMessage
          name={`patientOMHPrevSurgDate${index}`}
          component="div"
          data-testid="email-error"
        />
      </Col>
      <Col xs={12} md={3} className="my-1">
        <Form.Control
          type="text"
          placeholder={`Type of Surgery ${index}`}
          {...getInputProps("patientOMHPrevSurgType" + index, form)}
        />
        <ErrorMessage
          name={`patientOMHPrevSurgType${index}`}
          component="div"
          data-testid="email-error"
        />
      </Col>
      <Col xs={12} md={3} className="my-1">
        <Form.Control
          type="text"
          placeholder={`Doctor/Facility ${index}`}
          {...getInputProps("patientOMHPrevSurgDoctor" + index, form)}
        />
        <ErrorMessage
          name={`patientOMHPrevSurgDoctor${index}`}
          component="div"
          data-testid="email-error"
        />
      </Col>
      <Col xs={12} md={3} className="my-1">
        <Form.Control
          type="text"
          placeholder={`Complications ${index}`}
          {...getInputProps("patientOMHPrevSurgComplications" + index, form)}
        />
        <ErrorMessage
          name={`patientOMHPrevSurgComplications${index}`}
          component="div"
          data-testid="email-error"
        />
      </Col>

      <Col xs={12} md={1} className="my-1 pt-1">
        <Button
          color="secondary"
          onClick={() => {
            // Remove item from array
            setPreviusSpineSurgeryArray(
              previusSpineSurgeryArray.filter(function (item: number) {
                return item !== index;
              })
            );

            // Set all values to null
            form.setFieldValue("patientOMHPrevSurgDate" + index, null);
            form.setFieldValue("patientOMHPrevSurgType" + index, null);
            form.setFieldValue("patientOMHPrevSurgDoctor" + index, null);
            form.setFieldValue("patientOMHPrevSurgComplications" + index, null);
            return;
          }}
        >
        <i className="fas fa-trash-alt"></i>
        </Button>
      </Col>
      </Form.Row>
      </Card>
    </>
  );
};

const AnesthesiaComplicationsSection = ({
  form,
  patientName,
}: FirstNameProps) => {
  return (
    <Form.Row className="mr-3 ml-3">
      <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
        <Form.Label column>Type of complication</Form.Label>
      </Col>
      <Col xs={12} md={6} className="my-1">
        <Form.Control
          type="text"
          placeholder="Anesthesia Complication"
          {...getInputProps("patientOMHAnesthesiaComplication", form)}
        />
        <ErrorMessage
          name="patientOMHAnesthesiaComplication"
          component="div"
          data-testid="email-error"
        />
      </Col>
    </Form.Row>
  );
};

const StentSection = ({ form, patientName }: FirstNameProps) => {
  return (
    <Form.Row className="mr-3 ml-3">
      <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
        <Form.Label column>Is it a drug-eluting stent?</Form.Label>
      </Col>
      <Col xs={12} md={6} className="my-1">
        <Form.Control
          as="select"
          custom
          type="text"
          placeholder="Select if you had XRay"
          {...getInputProps("patientOMHIsDrugElutingStent", form)}
        >
          <option>-- Select --</option>
          <option>Yes</option>
          <option>No</option>
          <option>Not Sure</option>
        </Form.Control>
        <ErrorMessage
          name="patientOMHIsDrugElutingStent"
          component="div"
          className="text-red-500 text-xs italic"
        />
      </Col>
    </Form.Row>
  );
};

const ImplantedDevicesSection = ({
  form,
  state: [implantedDevicesSelcted, setImplantedDevicesSelected],
}: StatePropsType) => {
  const implantedDevicesOptions = [
    { label: "Spinal Cord Stimulator", value: "SpinalCordStimulator" },
    { label: "Pacemaker", value: "Pacemaker" },
    { label: "Defibrillator", value: "Defibrillator" },
    { label: "Pain Medication Pump", value: "PainMedicationPump" },
    { label: "Glucose Monitor", value: "GlucoseMonitor" },
    { label: "Insulin Pump", value: "InsulinPump" },
    { label: "Other", value: "Other" },
  ];
  return (
    <Form.Row className="mr-3 ml-3">
      <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
        <Form.Label column>Please select all that apply:</Form.Label>
      </Col>
      <Col xs={12} md={6} className="my-1">
        <MultiSelect
          options={implantedDevicesOptions}
          value={implantedDevicesSelcted}
          onChange={(values: any) => {
            setImplantedDevicesSelected(values);
            form.setFieldValue("patientOMHImplantedDevicesOptions", values);
          }}
          {...getInputPropsMultiSelect(
            "patientOMHImplantedDevicesOptions",
            form
          )}
          labelledBy="Select Pain Type"
        />
      </Col>
    </Form.Row>
  );
};
