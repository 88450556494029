import careexpandTheme from 'utils/constants/themes/careExpandTheme.constant';
import { CETheme } from 'utils/constants/themes/theme.types';

const sonospineTheme: CETheme = {
  colors: {
    ...careexpandTheme.colors,
    primary: {
      base: '#3f51b5',
      100: '#56bdfa', // $malibu-blue
      200: '#2196F3', // $dodger-blue
      300: '#009efc', // $deep-sky-blue
      400: '#3f51b5', // alt green light
      500: '#5b8def', // $cornflower-blue
      600: '#3667ac', // $azure-blue
    },
  },
  images: {
    logo: '/assets/images/logos/sonospine_logo.png',
  },
  overrides: {},
};

export default sonospineTheme;
