// React
import { useEffect, useState } from "react";
// Components
import { Typography, Card, Button, CardHeader, Tooltip } from "@material-ui/core";
import { Row, Col, Form, Container } from "react-bootstrap";
// Types
import { FormikProps, FormikValues, ErrorMessage, useFormikContext } from "formik";
// Util
import {
  getInputProps, getNumberOfElements, MultiSelectStateProps, StatePropsType,

} from "utils/constants/forms";

// Dates
import Moment from "moment";
import DatePicker from "react-datepicker";
import { isValidDate } from "utils/ValidationUtils";



type FirstNameProps = {
  form: FormikProps<FormikValues>;
  patientName: "SpineSurgeryQuestions";
  index?: any
};

const SpineSurgeryQuestions = ({ form, patientName }: FirstNameProps) => {
  const commonProps = {
    form,
    patientName,
  };
  const { getFieldProps } = useFormikContext();
  const [spineSurgeryHistory, setSpineSurgeryHistory] = useState<any>([]);
  const [fillInHistory, setFillInHistory] = useState(true);
  useEffect(() => {
    if (fillInHistory) {
      setFillInHistory(false);
      var mspineSurgeryHistory = [];
      var mspineSurgeryCount: number = getNumberOfElements(
        "patientSSPrevSurgeryDate",
        getFieldProps
      );
      for (var i = 0; i < mspineSurgeryCount; i++) {
        mspineSurgeryHistory.push(i);
      }
      if (!mspineSurgeryHistory.length) {
        mspineSurgeryHistory.push(0);
      }
      setSpineSurgeryHistory(mspineSurgeryHistory);
    }
  }, [fillInHistory]);
  return (
    <>
      <Container className="mt-4">
        <Row className="align-items-center">
          <Col>
            <Card>
              <Row>
                <Col>
                  <Typography variant="h4" className="text-center mt-5">
                    Spine Surgery Questions
                  </Typography>
                </Col>
              </Row>
              <Form.Row className="mt-5 mr-3 ml-3">
                <Col xs={12} md={6} className="my-1">
                  <Form.Label column>
                    Have you seen a Spine Surgeon?
                  </Form.Label>
                </Col>
                <Col xs={12} md={6} className="my-1">
                  <Form.Control
                    as="select"
                    custom
                    type="text"
                    placeholder="Select if you had XRay"
                    {...getInputProps("patientSTHasSeenSpineSurgeon", form)}
                  >
                    <option>-- Select --</option>
                    <option>Yes</option>
                    <option>No</option>
                  </Form.Control>
                  <ErrorMessage
                    name="patientSTHasSeenSpineSurgeon"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
              </Form.Row>
              {form.values.patientSTHasSeenSpineSurgeon === "Yes" ? (
                <SpineSurgeonSection {...commonProps} />
              ) : null}
              <Form.Row className="mt-5 mr-3 ml-3">
                <Col xs={12} md={6} className="my-1">
                  <Form.Label column>Have you had previous back or neck surgery?
                  </Form.Label>
                </Col>
                <Col xs={12} md={5} className="my-1">
                  <Form.Control
                    as="select"
                    custom
                    type="text"
                    placeholder="Select if you had XRay"
                    {...getInputProps("patientSTHasHadPrevSurgery", form)}
                  >
                    <option>-- Select --</option>
                    <option>Yes</option>
                    <option>No</option>
                  </Form.Control>
                  <ErrorMessage
                    name="patientSTHasHadPrevSurgery"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
                {form.values.patientSTHasHadPrevSurgery === "Yes" ? (
                  <>
                    <Col xs={12} md={1} className="my-1 pt-1">
                      <Button
                        color="primary"
                        onClick={() => {
                          var newItem = (spineSurgeryHistory[spineSurgeryHistory.length - 1] +
                            1) as number;
                          if (isNaN(newItem)) {
                            newItem = 0;
                          }
                          setSpineSurgeryHistory([...spineSurgeryHistory, newItem]);
                          return;
                        }}
                      >
                        <i className="fas fa-plus"></i>
                      </Button>
                    </Col>
                  </>
                ) : null}
              </Form.Row>
              {form.values.patientSTHasHadPrevSurgery === "Yes" ? (
                <>
                  {spineSurgeryHistory.map((item: number, i: number) => {
                    const stateProps = {
                      form,
                      state: [spineSurgeryHistory, setSpineSurgeryHistory],
                      index: item,
                    } as MultiSelectStateProps;

                    return <PreviousSurgerySection key={i} {...commonProps} {...stateProps} />;
                  })}
                </>
              ) : null}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SpineSurgeryQuestions;

const SpineSurgeonSection = ({ form, patientName }: FirstNameProps) => {
  return (
    <Form.Row className=" mr-3 ml-3">
      <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
        <Form.Label column>Surgeon Name</Form.Label>
      </Col>
      <Col xs={12} md={6} className="my-1">
        <Form.Control
          type="text"
          placeholder="Surgeon Name"
          {...getInputProps("patientSSSurgeonName", form)}
        />
        <ErrorMessage
          name="patientSSSurgeonName"
          component="div"
          data-testid="email-error"
        />
      </Col>
      <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
        <Form.Label column>Surgery Practice Name</Form.Label>
      </Col>
      <Col xs={12} md={6} className="my-1">
        <Form.Control
          type="text"
          placeholder="Surgery Practice Name"
          {...getInputProps("patientSSSurgeryPracticeName", form)}
        />
        <ErrorMessage
          name="patientSSSurgeryPracticeName"
          component="div"
          data-testid="email-error"
        />
      </Col>
    </Form.Row>
  );
};
const PreviousSurgerySection = ({
  form,
  state: [spineSurgeryHistory, setSpineSurgeryHistory],
  index,
}: StatePropsType) => {
  // Grab what we need from formik without prop-drilling
  const { getFieldProps } = useFormikContext();
  // Handle Date
  var patientSSPrevSurgeryDate = getFieldProps("patientSSPrevSurgeryDate"+index).value;

  var date = null;
  if (isValidDate(patientSSPrevSurgeryDate)) {
    const mDate = Moment(patientSSPrevSurgeryDate, "YYYY-MM-DD");
    date = new Date(mDate.toDate());
  }
  const [surgeryDate, setSurgeryDate] = useState<Date | null>(date);

  return (
    <Card className="mt-2" variant="outlined">
      <CardHeader
        action={
          <Button
            color="secondary"
            onClick={() => {
              // Remove item from array
              setSpineSurgeryHistory(
                spineSurgeryHistory.filter(function (item: number) {
                  return item !== index;
                })
              );
              // Set all values to null
              setSurgeryDate(null);
              form.setFieldValue("patientSSPrevSurgeryDate" + index, null);
              form.setFieldValue("patientSSPrevSurgeonName" + index, null);
              form.setFieldValue("patientSSPrevSurgeryPracticeName" + index, null);
              form.setFieldValue("patientSSPrevSurgerySegment" + index, null);
              form.setFieldValue("patientSSPrevSurgeryType" + index, null);
              form.setFieldValue("patientSSPrevSurgeryDidItHelp" + index, null);
              form.setFieldValue("patientSSPrevSurgeryDescription" + index, null);
              return;
            }}
          >
            <i className="fas fa-trash-alt"></i>
          </Button>
        }
      />
      <Form.Row className="mr-3 ml-3">
        <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
          <Form.Label column title="It's ok if you don't recall an exact date, just make your best guess">Prior Surgery Date</Form.Label>
        </Col>
        <Col xs={10} md={5} className="my-1">
          <label>
            <DatePicker
              className="form-control"
              placeholderText="MM/dd/yyyy*"
              dateFormat="MM/dd/yyyy"
              isClearable={true}
              selected={surgeryDate}
              onChange={(date) => {
                if (date == null || !Array.isArray(date)) {
                  form.setFieldValue("patientSSPrevSurgeryDate" + index, Moment(date).format("YYYY-MM-DD"));
                  setSurgeryDate(date);
                }
              }
              }
            />
          </label>
          &nbsp;<i className="far fa-calendar-alt fa-2x" onClick={() => {

          }}></i>
        </Col>
        <Col xs={2} md={1} className="my-1 p-2">
          <Tooltip arrow placement={"top"} title="It's ok if you don't recall an exact date, just make your best guess">
            <i className="fal fa-info-circle"></i>
          </Tooltip>
        </Col>
        <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
          <Form.Label column>Surgeon Name</Form.Label>
        </Col>
        <Col xs={12} md={6} className="my-1">
          <Form.Control
            type="text"
            placeholder="Surgeon Name"
            {...getInputProps("patientSSPrevSurgeonName" + index, form)}
          />
          <ErrorMessage
            name="patientSSPrevSurgeonName"
            component="div"
            data-testid="email-error"
          />
        </Col>
        <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
          <Form.Label column>Surgery Practice Name</Form.Label>
        </Col>
        <Col xs={12} md={6} className="my-1">
          <Form.Control
            type="text"
            placeholder="Surgery Practice Name"
            {...getInputProps("patientSSPrevSurgeryPracticeName" + index, form)}
          />
          <ErrorMessage
            name="patientSSPrevSurgeryPracticeName"
            component="div"
            data-testid="email-error"
          />
        </Col>
        <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
          <Form.Label column>Previous Surgery Segment</Form.Label>
        </Col>
        <Col xs={12} md={6} className="my-1">
          <Form.Control
            as="select"
            custom
            type="text"
            placeholder="Select Surgery Segment"
            {...getInputProps("patientSSPrevSurgerySegment" + index, form)}
          >
            <option>-- Select --</option>
            <option>Lumbar</option>
            <option>Cervical</option>
            <option>Thoracic</option>
          </Form.Control>
          <ErrorMessage
            name="patientSSPrevSurgerySegment"
            component="div"
            data-testid="email-error"
          />
        </Col>
        <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
          <Form.Label column>Previous Surgery Type</Form.Label>
        </Col>
        <Col xs={12} md={6} className="my-1">
          <Form.Control
            as="select"
            custom
            type="text"
            placeholder="Select Surgery Type"
            {...getInputProps("patientSSPrevSurgeryType" + index, form)}
          >
            <option>-- Select --</option>
            <option>Decompression</option>
            <option>Fusion</option>
            <option>Not Sure</option>
          </Form.Control>
          <ErrorMessage
            name="patientSSPrevSurgeryType"
            component="div"
            data-testid="email-error"
          />
        </Col>
        <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
          <Form.Label column>Did the surgery help?</Form.Label>
        </Col>
        <Col xs={12} md={6} className="my-1">
          <Form.Control
            type="text"
            placeholder="Did it help?"
            {...getInputProps("patientSSPrevSurgeryDidItHelp" + index, form)}
          />
          <ErrorMessage
            name="patientSSPrevSurgeryDidItHelp"
            component="div"
            data-testid="email-error"
          />
        </Col>
        <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
          <Form.Label column>Description</Form.Label>
        </Col>
        <Col xs={12} md={6} className="my-1">
          <Form.Control
            type="text"
            placeholder="Description"
            {...getInputProps("patientSSPrevSurgeryDescription" + index, form)}
          />
          <ErrorMessage
            name="patientSSPrevSurgeryDescription"
            component="div"
            data-testid="email-error"
          />
        </Col>
      </Form.Row>
    </Card>
  );
};

function getFieldProps(arg0: string, getFieldProps: any): number {
  throw new Error("Function not implemented.");
}

