import { RootState } from 'store/rootReducer';

// auth selectors
export const currentUserSelector = (state: RootState) => state.auth.user;
export const twoFactorAuthSelector = (state: RootState) => state.auth.twoFactorAuth;
export const currentTokenSelector = (state: RootState) => state.auth.auth;
export const isSignInLoadingSelector = (state: RootState) => state.auth.isSignInLoading;
export const isGetUserIdLoadingSelector = (state: RootState) => state.auth.isGetUserIdLoading;
export const isForgotPasswordLoadingSelector = (state: RootState) =>
  state.auth.isForgotPasswordLoading;
export const isCreateAccountLoadingSelector = (state: RootState) =>
  state.auth.isCreateAccountLoading;
