import React, { useState } from "react";
// Components
import { Typography, Button, Card, CardHeader } from "@material-ui/core";
import { Row, Col, Form, Container } from "react-bootstrap";
// Types
import { FormikProps, FormikValues, ErrorMessage } from "formik";
// Util
import { getInputProps } from "utils/constants/forms";

type FirstNameProps = {
  form: FormikProps<FormikValues>;
  patientName: "MedicationsQuestions";
};

type StatePropsType = {
  form: FormikProps<FormikValues>;
  state: [any, React.Dispatch<React.SetStateAction<any>>];
  index: number;
};

const MedicationsQuestions = ({ form, patientName }: FirstNameProps) => {
  const [medicationsArray, setMedicationsArray] = useState<any>([]);
  const [allergiesArray, setAllergiesArray] = useState<any>([]);

  return (
    <>
      <Container className="mt-4">
        <Row className="align-items-center">
          <Col>
            <Card>
              <Row>
                <Col>
                  <Typography variant="h4" className="text-center mt-5">
                    Medications & Alergies Questions
                  </Typography>
                </Col>
              </Row>
              <Form.Row className="mt-5 mr-3 ml-3">
                <Col xs={12} md={6} className="my-1">
                  <Form.Label column>
                    List ALL of your prescribed medications and include OTC
                    (over the counter) medications as well as vitamins,
                    supplements, herbs and pain relievers
                  </Form.Label>
                </Col>
                <Col xs={12} md={6} className="my-1">
                  <Form.Control
                    as="select"
                    custom
                    type="text"
                    placeholder="Select if you had XRay"
                    {...getInputProps("patientMedicationsHasPrescribed", form)}
                  >
                    <option>-- Select --</option>
                    <option>Yes</option>
                    <option>None</option>
                  </Form.Control>
                  <ErrorMessage
                    name="patientMedicationsHasPrescribed"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
                </Form.Row>
                <Form.Row className="mr-3 ml-3 d-flex justify-content-center">
                  <Button
                    color="primary"
                    onClick={() => {
                      var newItem = (medicationsArray[
                        medicationsArray.length - 1
                      ] + 1) as number;
                      if (isNaN(newItem)) {
                        newItem = 0;
                      }
                      setMedicationsArray([...medicationsArray, newItem]);
                      return;
                    }}
                  >
                    <i className="fas fa-plus"></i>
                  </Button>
                </Form.Row>
                
                {medicationsArray.map((item: number, i: number) => {
                  const stateProps = {
                    form,
                    state: [medicationsArray, setMedicationsArray],
                    index: item,
                  } as StatePropsType;
                  return <PreviousSurgerySection key={i} {...stateProps} />;
                })}
              <Form.Row className="mt-5 mr-3 ml-3">
                <Col xs={12} md={6} className="my-1">
                  <Form.Label column>
                    Allergies to medications or materials (ie latex, adhesives,
                    contrast dye, iodine/shellfish)
                  </Form.Label>
                </Col>
                <Col xs={12} md={6} className="my-1">
                  <Form.Control
                    as="select"
                    custom
                    type="text"
                    placeholder="Select if you had XRay"
                    {...getInputProps("patientMedicationsHasAlergies", form)}
                  >
                    <option>-- Select --</option>
                    <option>Yes</option>
                    <option>None</option>
                  </Form.Control>
                  <ErrorMessage
                    name="patientMedicationsHasAlergies"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
                
                </Form.Row>
                <Form.Row className="mr-3 ml-3 d-flex justify-content-center">
                <Button
                  color="primary"
                  onClick={() => {
                    var newItem = (allergiesArray[allergiesArray.length - 1] +
                      1) as number;
                    if (isNaN(newItem)) {
                      newItem = 0;
                    }
                    setAllergiesArray([...allergiesArray, newItem]);
                    return;
                  }}
                >
                <i className="fas fa-plus"></i>
                </Button>
                
                </Form.Row>
                {allergiesArray.map((item: number, i: number) => {
                  const stateProps = {
                    form,
                    state: [allergiesArray, setAllergiesArray],
                    index: item,
                  } as StatePropsType;
                  return <AllergiesSection key={i} {...stateProps} />;
                })}
              <Form.Row className="mt-5 mr-3 ml-3">
                <Col xs={12} md={{ span: 6, offset: 0 }} className="my-1">
                  <Form.Label column>Preferred Pharmacy Name</Form.Label>
                </Col>
                <Col xs={12} md={6} className="my-1">
                  <Form.Control
                    type="text"
                    placeholder="Preferred Pharmacy Name"
                    {...getInputProps(
                      "patientMedicationsPreferredPharmName",
                      form
                    )}
                  />
                  <ErrorMessage
                    name="patientMedicationsPreferredPharmName"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
                <Col xs={12} md={{ span: 6, offset: 0 }} className="my-1">
                  <Form.Label column>Preferred Pharmacy Phone</Form.Label>
                </Col>
                <Col xs={12} md={6} className="my-1">
                  <Form.Control
                    type="text"
                    placeholder="Preferred Pharmacy Name"
                    {...getInputProps(
                      "patientMedicationsPreferredPharmPhone",
                      form
                    )}
                  />
                  <ErrorMessage
                    name="patientMedicationsPreferredPharmPhone"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
                <Col xs={12} md={{ span: 6, offset: 0 }} className="my-1">
                  <Form.Label column>Preferred Pharmacy Address</Form.Label>
                </Col>
                <Col xs={12} md={6} className="my-1">
                  <Form.Control
                    type="text"
                    placeholder="Preferred Pharmacy Address"
                    {...getInputProps(
                      "patientMedicationsPreferredPharmAddress",
                      form
                    )}
                  />
                  <ErrorMessage
                    name="patientMedicationsPreferredPharmAddress"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
                <Col xs={12} md={{ span: 6, offset: 0 }} className="my-1">
                  <Form.Label column>Preferred Pharmacy City</Form.Label>
                </Col>
                <Col xs={12} md={6} className="my-1">
                  <Form.Control
                    type="text"
                    placeholder="Preferred Pharmacy City"
                    {...getInputProps(
                      "patientMedicationsPreferredPharmCity",
                      form
                    )}
                  />
                  <ErrorMessage
                    name="patientMedicationsPreferredPharmCity"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
                <Col xs={12} md={{ span: 6, offset: 0 }} className="my-1">
                  <Form.Label column>Preferred Pharmacy State</Form.Label>
                </Col>
                <Col xs={12} md={6} className="my-1">
                  <Form.Control
                    type="text"
                    placeholder="Preferred Pharmacy State"
                    {...getInputProps(
                      "patientMedicationsPreferredPharmState",
                      form
                    )}
                  />
                  <ErrorMessage
                    name="patientMedicationsPreferredPharmState"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
                <Col xs={12} md={{ span: 6, offset: 0 }} className="my-1">
                  <Form.Label column>Preferred Pharmacy Zip</Form.Label>
                </Col>
                <Col xs={12} md={6} className="my-1">
                  <Form.Control
                    type="text"
                    placeholder="Preferred Pharmacy Zip"
                    {...getInputProps(
                      "patientMedicationsPreferredPharm`Zip`",
                      form
                    )}
                  />
                  <ErrorMessage
                    name="patientMedicationsPreferredPharmZip"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
              </Form.Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default MedicationsQuestions;

const PreviousSurgerySection = ({
  form,
  state: [medicationsArray, setMedicationsArray],
  index,
}: StatePropsType) => {
  return (
    <>
      <Card className="mt-2" variant="outlined">
        <Form.Row className="mr-3 ml-3 text-right">
          <Col xs={12} md={3} className="my-1">
            <Form.Control
              type="text"
              placeholder={`Medication ${index}`}
              {...getInputProps("patientMStepMedication" + index, form)}
            />
            <ErrorMessage
              name={`patientMStepMedication${index}`}
              component="div"
              data-testid="email-error"
            />
          </Col>
          <Col xs={12} md={3} className="my-1">
            <Form.Control
              type="text"
              placeholder={`Strength ${index}`}
              {...getInputProps("patientMStepStrength" + index, form)}
            />
            <ErrorMessage
              name={`patientMStepStrength${index}`}
              component="div"
              data-testid="email-error"
            />
          </Col>
          <Col xs={12} md={3} className="my-1">
            <Form.Control
              type="text"
              placeholder={`Dose ${index}`}
              {...getInputProps("patientMStepDose" + index, form)}
            />
            <ErrorMessage
              name={`patientMStepDose${index}`}
              component="div"
              data-testid="email-error"
            />
          </Col>
          <Col xs={12} md={2} className="my-1">
            <Form.Control
              type="text"
              placeholder={`Frequency ${index}`}
              {...getInputProps("patientMStepFrequency" + index, form)}
            />
            <ErrorMessage
              name={`patientMStepFrequency${index}`}
              component="div"
              data-testid="email-error"
            />
          </Col>
          <Col xs={12} md={1} className="my-1 pt-1">
            <Button
              color="secondary"
              onClick={() => {
                // Remove item from array
                setMedicationsArray(
                  medicationsArray.filter(function (item: number) {
                    return item !== index;
                  })
                );

                // Set all values to null
                form.setFieldValue("patientMStepMedication" + index, null);
                form.setFieldValue("patientMStepStrength" + index, null);
                form.setFieldValue("patientMStepDose" + index, null);
                form.setFieldValue("patientMStepFrequency" + index, null);
                return;
              }}
            >
              <i className="fas fa-trash-alt"></i>
            </Button>
          </Col>
        </Form.Row>
      </Card>
    </>
  );
};

const AllergiesSection = ({
  form,
  state: [allergiesArray, setAllergiesArray],
  index,
}: StatePropsType) => {
  return (
    <>
    <Card className="mt-2" variant="outlined">
      <Form.Row className="mr-3 ml-3 text-right">
      <Col xs={12} md={3} className="my-1">
        <Form.Control
          type="text"
          placeholder={`Allergy ${index}`}
          {...getInputProps("patientMedicationAllergy" + index, form)}
        />
        <ErrorMessage
          name={`patientMedicationAllergy${index}`}
          component="div"
          data-testid="email-error"
        />
      </Col>
      <Col xs={12} md={8} className="my-1">
        <Form.Control
          type="text"
          placeholder={`Reaction ${index}`}
          {...getInputProps("patientMedicationAllergyReaction" + index, form)}
        />
        <ErrorMessage
          name={`patientMedicationAllergyReaction${index}`}
          component="div"
          data-testid="email-error"
        />
      </Col>

      <Col xs={12} md={1} className="my-1 pt-1">
        <Button
          color="secondary"
          onClick={() => {
            // Remove item from array
            setAllergiesArray(
              allergiesArray.filter(function (item: number) {
                return item !== index;
              })
            );

            // Set all values to null
            form.setFieldValue("patientMedicationAllergy" + index, null);
            form.setFieldValue(
              "patientMedicationAllergyReaction" + index,
              null
            );
            return;
          }}
        >
        <i className="fas fa-trash-alt"></i>
        </Button>
      </Col>
      </Form.Row>
      </Card>
    </>
  );
};
