import React, { useEffect } from "react";
// Components
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

type SpinnerProps = {
};

const CustomSpinner = ({}: SpinnerProps) => {
  // Types of spinners: https://mhnpd.github.io/react-loader-spinner/
  // https://www.npmjs.com/package/react-loader-spinner
   return (
    <Loader
      type="Rings"
      color="#00BFFF"
      height={100}
      width={100}
      //timeout={3000} //3 secs
    />
  );
};

export default CustomSpinner;
