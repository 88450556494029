import { useState } from "react";
// Components
import { Typography, Card } from "@material-ui/core";
import { Row, Col, Form, Container } from "react-bootstrap";
import {MultiSelect} from "react-multi-select-component";
// Types
import {
  FormikProps,
  FormikValues,
  ErrorMessage,
  useFormikContext,
} from "formik";
// Util
import { getInputProps, getInputPropsMultiSelect } from "utils/constants/forms";

type FirstNameProps = {
  form: FormikProps<FormikValues>;
  patientName: "SpineTreatmentQuestions";
};

const SpineTreatmentQuestions = ({ form, patientName }: FirstNameProps) => {
  const commonProps = {
    form,
    patientName,
  };

  return (
    <>
      <Container className="mt-4">
        <Row className="align-items-center">
          <Col>
            <Card>
              <Row>
                <Col>
                  <Typography variant="h4" className="text-center mt-5">
                    Spine Treatment History
                  </Typography>
                </Col>
              </Row>
              <Form.Row className="mt-5 mr-3 ml-3">
                <Col xs={12} md={6} className="my-1">
                  <Form.Label column>
                    Have you seen a pain management specialist?
                  </Form.Label>
                </Col>
                <Col xs={12} md={6} className="my-1">
                  <Form.Control
                    as="select"
                    custom
                    type="text"
                    placeholder="Select if you had XRay"
                    {...getInputProps("patientSTHasSeenPm", form)}
                  >
                    <option>-- Select --</option>
                    <option>Yes</option>
                    <option>No</option>
                  </Form.Control>
                  <ErrorMessage
                    name="patientSTHasSeenPm"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
              </Form.Row>
              {form.values.patientSTHasSeenPm === "Yes" ? (
                <PMSection {...commonProps} />
              ) : null}
              <Form.Row className="mt-5 mr-3 ml-3">
                <Col xs={12} md={6} className="my-1">
                  <Form.Label column>
                    Have you had injections in the past?
                  </Form.Label>
                </Col>
                <Col xs={12} md={6} className="my-1">
                  <Form.Control
                    as="select"
                    custom
                    type="text"
                    placeholder="Select if you had XRay"
                    {...getInputProps("patientSTHasHadInjections", form)}
                  >
                    <option>-- Select --</option>
                    <option>Yes</option>
                    <option>No</option>
                  </Form.Control>
                  <ErrorMessage
                    name="patientSTHasHadInjections"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
              </Form.Row>
              {form.values.patientSTHasHadInjections === "Yes" ? (
                <InjectionsSection {...commonProps} />
              ) : null}
              <Form.Row className="mt-5 mr-3 ml-3">
                <Col xs={12} md={6} className="my-1">
                  <Form.Label column>
                    Have you tried Physical Therapy in the past year?
                  </Form.Label>
                </Col>
                <Col xs={12} md={6} className="my-1">
                  <Form.Control
                    as="select"
                    custom
                    type="text"
                    placeholder="Select if you had XRay"
                    {...getInputProps("patientSTHasTriedPT", form)}
                  >
                    <option>-- Select --</option>
                    <option>Yes</option>
                    <option>No</option>
                  </Form.Control>
                  <ErrorMessage
                    name="patientSTHasTriedPT"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
              </Form.Row>
              {form.values.patientSTHasTriedPT === "Yes" ? (
                <PTSection {...commonProps} />
              ) : null}
              <Form.Row className="mt-5 mr-3 ml-3">
                <Col xs={12} md={6} className="my-1">
                  <Form.Label column>
                    Have you tried Chiropractic in the past year?
                  </Form.Label>
                </Col>
                <Col xs={12} md={6} className="my-1">
                  <Form.Control
                    as="select"
                    custom
                    type="text"
                    placeholder="Select if you had XRay"
                    {...getInputProps("patientSTHasTriedChiro", form)}
                  >
                    <option>-- Select --</option>
                    <option>Yes</option>
                    <option>No</option>
                  </Form.Control>
                  <ErrorMessage
                    name="patientSTHasTriedChiro"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
              </Form.Row>
              {form.values.patientSTHasTriedChiro === "Yes" ? (
                <ChiroSection {...commonProps} />
              ) : null}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SpineTreatmentQuestions;

const PMSection = ({ form, patientName }: FirstNameProps) => (
  <Form.Row className=" mr-3 ml-3">
    <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
      <Form.Label column>PM Doctor Name</Form.Label>
    </Col>
    <Col xs={12} md={6} className="my-1">
      <Form.Control
        type="text"
        placeholder="Doctor Name"
        {...getInputProps("patientSTDoctorName", form)}
      />
      <ErrorMessage
        name="patientSTDoctorName"
        component="div"
        data-testid="email-error"
      />
    </Col>
    <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
      <Form.Label column>PM Doctor Address</Form.Label>
    </Col>
    <Col xs={12} md={6} className="my-1">
      <Form.Control
        type="text"
        placeholder="Doctor Address"
        {...getInputProps("patientSTDoctorAddress", form)}
      />
      <ErrorMessage
        name="patientSTDoctorAddress"
        component="div"
        className="text-red-500 text-xs italic"
      />
    </Col>
    <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
      <Form.Label column>PM Doctor Phone</Form.Label>
    </Col>
    <Col xs={12} md={6} className="my-1">
      <Form.Control
        type="text"
        placeholder="PM Doctor Phone"
        {...getInputProps("patientSTDoctorPhone", form)}
      />
      <ErrorMessage
        name="patientSTDoctorPhone"
        component="div"
        data-testid="email-error"
      />
    </Col>
  </Form.Row>
);

const InjectionsSection = ({ form, patientName }: FirstNameProps) => {
  // Grab what we need from formik without prop-drilling
  const { getFieldProps } = useFormikContext();
  const inyectionTypeOptions = [
    { label: "Epidural Steroid Injection (ESI)", value: "ESI" },
    { label: "Trigger Point (TPI)", value: "TPI" },
    { label: "Nerve Root Blocks", value: "NRB" },
    { label: "Not Sure", value: "NotSure" },
  ];
  const [inyectionTypeSelected, setInyectionTypeSelected] = useState(
    getFieldProps("patientSTInyectionTypes").value || []
  );

  return (
    <Form.Row className=" mr-3 ml-3">
      <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
        <Form.Label column>How Many Injections</Form.Label>
      </Col>
      <Col xs={12} md={6} className="my-1">
        <Form.Control
          type="text"
          placeholder="Injections #"
          {...getInputProps("patientSTHowManyInjections", form)}
        />
        <ErrorMessage
          name="patientSTHowManyInjections"
          component="div"
          data-testid="email-error"
        />
      </Col>
      <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
        <Form.Label column>What type of injections?</Form.Label>
      </Col>
      <Col xs={12} md={6} className="my-1">
        <MultiSelect
          options={inyectionTypeOptions}
          value={inyectionTypeSelected}
          onChange={(values: any) => {
            setInyectionTypeSelected(values);
            form.values.patientSTInyectionTypes = values;
          }}
          {...getInputPropsMultiSelect("patientSTInyectionTypes", form)}
          labelledBy="Select Pain Type"
        />
      </Col>
      <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
        <Form.Label column>
          Did you have pain relief with injections?
        </Form.Label>
      </Col>
      <Col xs={12} md={6} className="my-1">
        <Form.Control
          as="select"
          custom
          type="text"
          placeholder="Did you have pain relief"
          {...getInputProps("patientSTPainRelief", form)}
        >
          <option>-- Select --</option>
          <option>Yes</option>
          <option>No</option>
        </Form.Control>
        <ErrorMessage
          name="patientSTPainRelief"
          component="div"
          className="text-red-500 text-xs italic"
        />
      </Col>
    </Form.Row>
  );
};

const PTSection = ({ form, patientName }: FirstNameProps) => {
  // Grab what we need from formik without prop-drilling
  const { getFieldProps } = useFormikContext();
  return (
    <Form.Row className=" mr-3 ml-3">
      <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
        <Form.Label column>
          Physical Therapist was too painful to continue
        </Form.Label>
      </Col>
      <Col xs={12} md={6} className="my-1">
        <Form.Check
          inline
          {...getInputProps("patientSTPTTooPainful", form)}
          checked={getFieldProps("patientSTPTTooPainful").value || false}
          type="checkbox"
          className="mt-3"
        />
      </Col>
      <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
        <Form.Label column>Physical Therapist Name</Form.Label>
      </Col>
      <Col xs={12} md={6} className="my-1">
        <Form.Control
          type="text"
          placeholder="Physical Therapist Name"
          {...getInputProps("patientSTPTName", form)}
        />
        <ErrorMessage
          name="patientSTPTName"
          component="div"
          data-testid="email-error"
        />
      </Col>
      <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
        <Form.Label column>Physical Therapist Practice Name</Form.Label>
      </Col>
      <Col xs={12} md={6} className="my-1">
        <Form.Control
          type="text"
          placeholder="Physical Therapist Practice Name"
          {...getInputProps("patientSTPTPracticeName", form)}
        />
        <ErrorMessage
          name="patientSTPTPracticeName"
          component="div"
          data-testid="email-error"
        />
      </Col>
      <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
        <Form.Label column>Physical Therapist Address</Form.Label>
      </Col>
      <Col xs={12} md={6} className="my-1">
        <Form.Control
          type="text"
          placeholder="Physical Therapist Address"
          {...getInputProps("patientSTPTAddress", form)}
        />
        <ErrorMessage
          name="patientSTPTAddress"
          component="div"
          data-testid="email-error"
        />
      </Col>
      <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
        <Form.Label column>Physical Therapist Phone</Form.Label>
      </Col>
      <Col xs={12} md={6} className="my-1">
        <Form.Control
          type="text"
          placeholder="Physical Therapist Phone"
          {...getInputProps("patientSTPTPhone", form)}
        />
        <ErrorMessage
          name="patientSTPTPhone"
          component="div"
          data-testid="email-error"
        />
      </Col>
      <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
        <Form.Label column>
          About how long did you try PT (in weeks)?
        </Form.Label>
      </Col>
      <Col xs={12} md={6} className="my-1">
        <Form.Control
          type="text"
          placeholder="Week #"
          {...getInputProps("patientSTPTWeekNumber", form)}
        />
        <ErrorMessage
          name="patientSTPTWeekNumber"
          component="div"
          data-testid="email-error"
        />
      </Col>
      <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
        <Form.Label column>About how many visits?</Form.Label>
      </Col>
      <Col xs={12} md={6} className="my-1">
        <Form.Control
          type="text"
          placeholder="Visits #"
          {...getInputProps("patientSTPTVisitsNumber", form)}
        />
        <ErrorMessage
          name="patientSTPTVisitsNumber"
          component="div"
          data-testid="email-error"
        />
      </Col>
      <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
        <Form.Label column>Did it help?</Form.Label>
      </Col>
      <Col xs={12} md={6} className="my-1">
        <Form.Control
          as="select"
          custom
          type="text"
          placeholder="Select if you had XRay"
          {...getInputProps("patientSTPtHelped", form)}
        >
          <option>-- Select --</option>
          <option>Yes</option>
          <option>No</option>
        </Form.Control>
        <ErrorMessage
          name="patientSTPtHelped"
          component="div"
          className="text-red-500 text-xs italic"
        />
      </Col>
      <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
        <Form.Label column>Comments</Form.Label>
      </Col>
      <Col xs={12} md={6} className="my-1">
        <Form.Control
          type="text"
          placeholder="Comments"
          {...getInputProps("patientSTPTComments", form)}
        />
        <ErrorMessage
          name="patientSTPTComments"
          component="div"
          data-testid="email-error"
        />
      </Col>
    </Form.Row>
  );
};

const ChiroSection = ({ form, patientName }: FirstNameProps) => {
  return (
    <Form.Row className=" mr-3 ml-3">
      <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
        <Form.Label column>Chiropractor Name</Form.Label>
      </Col>
      <Col xs={12} md={6} className="my-1">
        <Form.Control
          type="text"
          placeholder="Chiropractor Name"
          {...getInputProps("patientSTChiroName", form)}
        />
        <ErrorMessage
          name="patientSTChiroName"
          component="div"
          data-testid="email-error"
        />
      </Col>
      <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
        <Form.Label column>Chiropractor Practice Name</Form.Label>
      </Col>
      <Col xs={12} md={6} className="my-1">
        <Form.Control
          type="text"
          placeholder="Chiropractort Practice Name"
          {...getInputProps("patientSTChiroPracticeName", form)}
        />
        <ErrorMessage
          name="patientSTChiroPracticeName"
          component="div"
          data-testid="email-error"
        />
      </Col>
      <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
        <Form.Label column>Chiropractor Address</Form.Label>
      </Col>
      <Col xs={12} md={6} className="my-1">
        <Form.Control
          type="text"
          placeholder="Chiropractor Address"
          {...getInputProps("patientSTChiroAddress", form)}
        />
        <ErrorMessage
          name="patientSTChiroAddress"
          component="div"
          data-testid="email-error"
        />
      </Col>
      <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
        <Form.Label column>Chiropractor Phone</Form.Label>
      </Col>
      <Col xs={12} md={6} className="my-1">
        <Form.Control
          type="text"
          placeholder="Chiropractor Phone"
          {...getInputProps("patientSTChiroPhone", form)}
        />
        <ErrorMessage
          name="patientSTChiroPhone"
          component="div"
          data-testid="email-error"
        />
      </Col>
      <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
        <Form.Label column>
          About how long did you try Chiropractic (in weeks)?
        </Form.Label>
      </Col>
      <Col xs={12} md={6} className="my-1">
        <Form.Control
          type="text"
          placeholder="Week #"
          {...getInputProps("patientSTChiroWeekNumber", form)}
        />
        <ErrorMessage
          name="patientSTChiroWeekNumber"
          component="div"
          data-testid="email-error"
        />
      </Col>
      <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
        <Form.Label column>About how many visits?</Form.Label>
      </Col>
      <Col xs={12} md={6} className="my-1">
        <Form.Control
          type="text"
          placeholder="Visits #"
          {...getInputProps("patientSTChiroVisitsNumber", form)}
        />
        <ErrorMessage
          name="patientSTChiroVisitsNumber"
          component="div"
          data-testid="email-error"
        />
      </Col>
      <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
        <Form.Label column>Did it help?</Form.Label>
      </Col>
      <Col xs={12} md={6} className="my-1">
        <Form.Control
          as="select"
          custom
          type="text"
          placeholder="Select if you had XRay"
          {...getInputProps("patientSTChiroHelped", form)}
        >
          <option>-- Select --</option>
          <option>Yes</option>
          <option>No</option>
        </Form.Control>
        <ErrorMessage
          name="patientSTChiroHelped"
          component="div"
          className="text-red-500 text-xs italic"
        />
      </Col>
      <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
        <Form.Label column>Comments</Form.Label>
      </Col>
      <Col xs={12} md={6} className="my-1">
        <Form.Control
          type="text"
          placeholder="Comments"
          {...getInputProps("patientSTChiroComments", form)}
        />
        <ErrorMessage
          name="patientSTChiroComments"
          component="div"
          data-testid="email-error"
        />
      </Col>
    </Form.Row>
  );
};
