import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthService from "api/auth.api";
import { AxiosResponse } from "axios";
import jwtDecode from "jwt-decode";
import moment from "moment-timezone";
import { CreateAccountForm } from "pages/auth/views/createAccount/CreateAccount";
import {
  AuthState,
  CEThunkPayload,
  CreateAccountPayload,
  ForgotPasswordPayload,
  ISignIn,
  UserTokenData,
} from "store/auth/auth.types";
import { TwoFactorAuth } from "utils/model/TwoFactorAuth";

const initialState: AuthState = {
  twoFactorAuth: undefined,
  isSignInLoading: false,
  isGetUserIdLoading: false,
  isForgotPasswordLoading: false,
  isCreateAccountLoading: false,
  auth: undefined,
  user: undefined,
  isFileExportLoading: false,
};

const payloadFactory = (
  payload: CEThunkPayload<any>,
  service: Promise<any>
) => {
  const success = payload.onSuccess
    ? payload.onSuccess
    : (res: AxiosResponse) => Promise.resolve(res);
  const error = payload.onError
    ? payload.onError
    : (err: AxiosResponse) => Promise.reject(err);
  return service.then(success).catch(error);
};

export const signInThunkAction = createAsyncThunk(
  "auth/SignIn",
  (payload: CEThunkPayload<ISignIn>) =>
    payloadFactory(payload, AuthService.signIn(payload.data))
);

export const getUserIdThunkAction = createAsyncThunk(
  "auth/getUserId",
  (payload: CEThunkPayload<undefined>) =>
    payloadFactory(payload, AuthService.getUserId())
);

export const forgotPasswordThunkAction = createAsyncThunk(
  "auth/forgotPassword",
  (payload: CEThunkPayload<ForgotPasswordPayload>) =>
    payloadFactory(payload, AuthService.forgotPassword(payload.data))
);
export const getTwoFactorAuthThunkAction = createAsyncThunk(
  "auth/TwoFactorAuth/Get",
  (payload: CEThunkPayload<TwoFactorAuth>) =>
    payloadFactory(payload, AuthService.getTwoFactor(payload.data))
);

export const generateTwoFactorAuthThunkAction = createAsyncThunk(
  "auth/TwoFactorAuth/Generate",
  (payload: CEThunkPayload<TwoFactorAuth>) =>
    payloadFactory(payload, AuthService.generateTwoFactor(payload.data))
);

export const checkTwoFactorAuthThunkAction = createAsyncThunk(
  "auth/TwoFactorAuth/Check",
  (payload: CEThunkPayload<TwoFactorAuth>) =>
    payloadFactory(payload, AuthService.checkTwoFactor(payload.data))
);

export const fileExportThunkAction = createAsyncThunk(
  "auth/SignIn",
  (payload: CEThunkPayload<undefined>) =>
    payloadFactory(payload, AuthService.getFileExport())
);

const authUserSlice = createSlice({
  name: "authUser",
  initialState,
  reducers: {
    logOut: (state) => {
      state.auth = undefined;
      state.user = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signInThunkAction.pending, (state) => {
      state.isSignInLoading = true;
    });
    builder.addCase(signInThunkAction.fulfilled, (state, { payload }) => {
      state.isSignInLoading = false;
      const parsedToken = jwtDecode<UserTokenData>(payload.token);
      state.auth = { token: payload.token, tokenData: parsedToken };
    });
    builder.addCase(signInThunkAction.rejected, (state) => {
      state.isSignInLoading = false;
    });
    builder.addCase(getUserIdThunkAction.pending, (state) => {
      state.isGetUserIdLoading = true;
    });
    builder.addCase(getUserIdThunkAction.fulfilled, (state, { payload }) => {
      state.user = payload[0];
      state.isGetUserIdLoading = false;
    });
    builder.addCase(getUserIdThunkAction.rejected, (state, action) => {
      state.auth = undefined;
      state.user = undefined;
      state.isGetUserIdLoading = false;
    });
    builder.addCase(forgotPasswordThunkAction.pending, (state) => {
      state.isForgotPasswordLoading = true;
    });
    builder.addCase(
      forgotPasswordThunkAction.fulfilled,
      (state, { payload }) => {
        state.isForgotPasswordLoading = false;
      }
    );
    builder.addCase(forgotPasswordThunkAction.rejected, (state, action) => {
      state.isForgotPasswordLoading = false;
    });
    builder.addCase(generateTwoFactorAuthThunkAction.pending, (state) => {
    });
    builder.addCase(
      generateTwoFactorAuthThunkAction.fulfilled,
      (state, { payload }) => {
      }
    );
    builder.addCase(
      generateTwoFactorAuthThunkAction.rejected,
      (state) => {
      }
    );
    builder.addCase(checkTwoFactorAuthThunkAction.pending, (state) => {
    });
    builder.addCase(
      checkTwoFactorAuthThunkAction.fulfilled,
      (state, { payload }) => {       
        state.twoFactorAuth = payload;
      }
    );
    builder.addCase(checkTwoFactorAuthThunkAction.rejected, (state) => {
    });
    
    builder.addCase(getTwoFactorAuthThunkAction.pending, (state) => {
    });
    builder.addCase(
      getTwoFactorAuthThunkAction.fulfilled,
      (state, { payload }) => {
        state.twoFactorAuth = payload;
      }
    );
    builder.addCase(
      getTwoFactorAuthThunkAction.rejected,
      (state) => {
      }
    );
  },
});

export const { logOut } = authUserSlice.actions;
export default authUserSlice.reducer;
