import { makeStyles } from '@material-ui/core';
import { currentHostSettings } from 'utils/currentHost.utils';

export const useStyles = makeStyles((theme) => ({
  loadingText: {
    fontSize: '20px',
    display: 'block',
    textAlign: 'center',
    width: '100%',
  },
  mainBackground: {
    background: theme.palette.primary.main,
    borderBottom: `5px solid ${theme.palette.primary.light}`,
    height: '56px',
    color: '#fff',
    width: '100%',
  },
  containerRow: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '1140px',
    height: '100%',
    margin: '0 auto',
  },
  headerImage: {
    width: '110px',
    height: '40px',
    background: `url(${currentHostSettings.theme.images.logo}) no-repeat center`,
    backgroundSize: 'contain',
    textIndent: '-9999px',
    display: 'inline-block',
    marginRight: '24px',
  },
  adminSwitcher: {
    marginRight: '16px',

    '& input': {
      color: '#000',
      borderColor: '#fff',
      borderRadius: '6px',
      width: '160px',
    },

    '& .results-box': {
      display: 'none',
      position: 'absolute',
      maxHeight: '150px',
      overflow: 'auto',
      padding: '10px',
      zIndex: '999',
      backgroundColor: '#fff',
      width: '160px',
      border: '1px solid #eee',
      boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.075)',
    },
  },
  userMenuToggler: {
    position: 'relative',
    minWidth: '100px',

    '& button': {
      background: 'transparent',
      border: 'none',
      color: '#fff',
      fontSize: '16px',
      marginLeft: 'auto',
      display: 'block',

      '& .nav-icon': {
        verticalAlign: 'middle',
        fontSize: '24px',
        marginLeft: '12px',
      },
    },
    '& ul': {
      position: 'absolute',
      top: '38px',
      left: 0,
      right: 0,
      margin: 0,
      padding: 0,
      background: '#fff',
      listStyle: 'none',

      '& li': {
        '& a, & button': {
          width: '100%',
          display: 'block',
          padding: '8px',
          textDecoration: 'none',
          textAlign: 'left',
          fontSize: '18px',
          color: theme.palette.secondary.main,

          '&:hover': {
            background: theme.palette.secondary.main,
            color: '#fff',
          },
        },
      },
    },
  },
  navMenu: {
    flex: 1,

    '& ul': {
      listStyle: 'none',
      margin: 0,
      padding: 0,

      '& li': {
        display: 'inline-block',
        height: '100%',

        '& a': {
          color: '#fff',
          textDecoration: 'none',
          lineHeight: '56px',
          padding: '0 16px',
          fontSize: '16px',
        },

        '&.active': {
          borderBottom: '3px solid #fff',
        },
      },
    },
  },
}));
// export const headerStyle = (theme) => (),
