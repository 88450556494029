import React, { MutableRefObject, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
// Components
import { Typography, Button, Card } from "@material-ui/core";
import { Row, Col, Form, Container } from "react-bootstrap";
import { Formik, FormikProps, FormikValues } from "formik";
import { useFormikContext } from "formik";
import Navigation from "components/forms/navigation/Navigation";
import Progress from "components/forms/progress/Progress";
// Util
import { generateSteps, generateInitialValues, getStepSchema } from "./steps";
import Moment from "moment";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { MissingOnValueCheckError, PDFDocument } from "pdf-lib";
// API Imports
import { useAppDispatch } from "store/store";
import {
  getFileGenerationThunkAction,
  getScreeningCaseByCaseIdThunkAction,
  saveFormDataThunkAction,
  getPdfBytesThunkAction,
  saveDocumentThunkAction,
  getInsuranceCarrierValuesThunkAction
} from "store/documents/documents.slice";
import { useEffect } from "react";
import { useDocuments } from "utils/hooks/useDocuments.hook";
import { ContactPhone } from "utils/model/ContactPhone";
import { ContactEmail } from "utils/model/ContactEmail";
import { FileGeneration } from "utils/model/FileGeneration";
import { ScreeningCase } from "utils/model/ScreeningCase";
// MultiSelect Helpers
import {
  getOptionByLabel,
  getOptionByValue,
  painLocationOptionsArray,
  extremitiesOptionsArray,
  activitiesOptionsArray,
  activitiesDecreaseArray,
} from "utils/form/multiSelectOptions";
import { Option } from "react-multi-select-component/dist/lib/interfaces";
import CustomSpinner from "components/spinner/CustomSpinner";

const Questionnaire: React.FC = (props: any) => {
  const tokenId = props.match.params.tokenId;
  const dispatch = useAppDispatch();
  const [steps] = useState(generateSteps());
  const [initialValues] = useState(generateInitialValues(steps, tokenId));
  const [currentIndex, setCurrentIndex] = useState(0);
  const history = useHistory();
  const {
    fileGeneration,
    screeningCase,
    isFileGenerationError,
    isFileGenerationLoading,
    isSavingFormData,
    pdfBytes,
  } = useDocuments();
  const formikRef = useRef<any>();
  const MySwal = withReactContent(Swal);

  const goNext = () => {
    // We should save the form here
    var values = formikRef.current.values;
    dispatch(
      saveFormDataThunkAction({
        data: {
          caseId: values.caseId,
          contactId: values.contactId,
          generationType: values.generationType,
          hasAgreedToS: values.hasAgreedToS ? 1 : 0,
          formData: JSON.stringify(formikRef.current.values),
        },
        onSuccess: (res) => {
          setCurrentIndex((oldIndex) => oldIndex + 1);
          return Promise.resolve(res);
        },
        onError: (err) => {
          console.log("error", err);
          MySwal.fire("Error", "Error Saving Data", "warning");
          return Promise.reject(err);
        },
      })
    );
  };

  const goBack = () => {
    setCurrentIndex((oldIndex) => oldIndex - 1);
  };

  const goSubmit = () => {
    // We should save the form here
    var values = formikRef.current.values;
    dispatch(
      saveFormDataThunkAction({
        data: {
          caseId: values.caseId,
          contactId: values.contactId,
          generationType: values.generationType,
          hasAgreedToS: values.hasAgreedToS ? 1 : 0,
          formData: JSON.stringify(formikRef.current.values),
        },
        onSuccess: (res) => {
          saveToPdf();
          return Promise.resolve(res);
        },
        onError: (err) => {
          MySwal.fire("Error", "Error Submitting the Form", "warning");
          console.log("error", err);
          return Promise.reject(err);
        },
      })
    );
  };

  async function saveToPdf() {
    dispatch(
      // Fills the pdfBytes
      getPdfBytesThunkAction({
        data: "HIPAA",
        onSuccess: (res) => {
          return Promise.resolve(res);
        },
        onError: (err) => {
          MySwal.fire("Error", "Unknown Error", "warning");
          return Promise.reject(err);
        },
      })
    );
  }

  const handleInitialValues = (tokenId: string) => {
    dispatch(
      getFileGenerationThunkAction({
        data: { tokenId: tokenId },
        onError: (err) => {
          console.log("error", err);
          MySwal.fire("Error", "Error retrieving the form", "warning");
          return Promise.reject(err);
        },
      })
    );
  };

  const handleInsuranceCarrierValues = () => {
    console.log("handle insurance carrier values")
    dispatch(

      getInsuranceCarrierValuesThunkAction({
        data: "",
        onError: (err) => {
          console.log("error", err);
          MySwal.fire("Error", "Error retrieving the form", "warning");
          return Promise.reject(err);
        },
      })
    );
  };

  useEffect(() => {
    handleInitialValues(tokenId);
    handleInsuranceCarrierValues();
  }, []);

  useEffect(() => {
    if (pdfBytes) {
      if(pdfBytes.byteLength){
        fillPdf(pdfBytes);}
    }
  }, [pdfBytes]);

  async function fillPdf(pdfData: ArrayBuffer) {
    const formik = formikRef.current;
    const pdfDoc = await PDFDocument.load(pdfData);

    const form = pdfDoc.getForm();

    // Retrieve the PDF fields
    const patientName = form.getTextField("Patient Name");
    patientName.setText(formik.getFieldProps("PatientName").value);

    const dateOfBirth = form.getTextField("Date of Birth_af_date");
    var patientDoB = Moment(
      formik.getFieldProps("PatientDoB").value,
      "MM/DD/YYYY"
    );
    dateOfBirth.setText(patientDoB.format("DD/MM/YYYY"));

    const phoneNumber = form.getTextField("Phone #");
    phoneNumber.setText(formik.getFieldProps("PhoneNumber").value);

    const mri = form.getTextField("MRI");
    mri.setText(formik.getFieldProps("MriFacility").value);

    const mriNumber = form.getTextField("mri #");
    const painMd = form.getTextField("Pain MD");
    const painMdnumber = form.getTextField("PM #");
    const pt = form.getTextField("PT");
    const ptNumber = form.getTextField("PT #");
    const chiro = form.getTextField("chiro");
    const chrioNumber = form.getTextField("chiro #");
    const pcp = form.getTextField("PCP");
    const pcpNumber = form.getTextField("PCP #");
    const otherSpecialist = form.getTextField("Text2");
    const otherSpecialistNumber = form.getTextField("Text3");
    const entireRecord = form.getCheckBox("Check Box19");
    const medicalRecords = form.getCheckBox("Check Box18");
    const billingRecords = form.getCheckBox("Check Box22");
    const otherInformation = form.getCheckBox("Check Box24");
    const otherInformationText = form.getTextField("Text25");
    const furtherMedicalCare = form.getCheckBox("Check Box38");
    const changinPhysicians = form.getCheckBox("Check Box37");
    const insurance = form.getCheckBox("Check Box43");
    const otherPurpose = form.getCheckBox("Check Box31");
    const otherPurposeText = form.getTextField("Text44");
    const research = form.getCheckBox("Check Box40");
    const attorney = form.getCheckBox("Check Box39");
    const personalUse = form.getCheckBox("Check Box30");
    const agreement = form.getCheckBox("Check Box4");
    const personalSignature = form.getTextField("Text46");
    const dateAgreement = form.getTextField("Text47");
    const printedName = form.getTextField("Text48");

    const phiFacilities = form.getTextField("Text PHI 1");
    const phiPatientName = form.getTextField("Text PHI FirstNAme");
    const phiPatientLastName = form.getTextField("Text PHI LastName");
    const phiPatientPhone = form.getTextField("Text PHI Phone Number");
    const phiPatientMail = form.getTextField("Text PHI Email");
    const phiPatientMailingAddress = form.getTextField(
      "Text PHI MailingAddress"
    );
    const phiMedicalRecords = form.getCheckBox("CheckboxPHImd");
    const phiTreatmentPlan = form.getCheckBox("CheckboxPHItp");
    const phiAppointments = form.getCheckBox("CheckboxPHIappt");
    const phiOther = form.getCheckBox("CheckboxPHIOther");
    const phiOtherText = form.getTextField("TextPHIOtherInformation");
    const phiOtherPurpose = form.getTextField("TextPHIOtherPurpose");
    const phiExpirationDate = form.getTextField("TextPHIExpiryDate");
    const phiExpiryOther = form.getTextField("TextPhiExpiryOtherReason");

    mriNumber.setText(formik.getFieldProps("MriFacilityNumber").value);
    painMd.setText(formik.getFieldProps("PmMd").value);
    painMdnumber.setText(formik.getFieldProps("PmMdNumber").value);
    pt.setText(formik.getFieldProps("PhysicalTherapy").value);
    ptNumber.setText(formik.getFieldProps("PhysicalTherapyNumber").value);
    chiro.setText(formik.getFieldProps("ChiroCare").value);
    chrioNumber.setText(formik.getFieldProps("ChiroCareNumber").value);
    pcp.setText(formik.getFieldProps("PrimaryCarePhysician").value);
    pcpNumber.setText(formik.getFieldProps("PrimaryCarePhysicianNumber").value);
    otherSpecialist.setText(formik.getFieldProps("OtherSpecialist").value);
    otherSpecialistNumber.setText(
      formik.getFieldProps("OtherSpecialistNumber").value
    );

    formik.getFieldProps("EntireRecord").value
      ? entireRecord.check()
      : entireRecord.uncheck();

    medicalRecords.check();

    formik.getFieldProps("BillingRecords").value
      ? billingRecords.check()
      : billingRecords.uncheck();
    formik.getFieldProps("OtherRecords").value
      ? otherInformation.check()
      : otherInformation.uncheck();
    otherInformationText.setText(
      formik.getFieldProps("OtherRecordsText").value
    );
    furtherMedicalCare.check();
    formik.getFieldProps("ChangingPhysicans").value
      ? changinPhysicians.check()
      : changinPhysicians.uncheck();
    formik.getFieldProps("Insurance").value
      ? insurance.check()
      : insurance.uncheck();
    formik.getFieldProps("OtherPurpose").value
      ? otherPurpose.check()
      : otherPurpose.uncheck();
    otherPurposeText.setText(formik.getFieldProps("OtherPurposeText").value);
    formik.getFieldProps("Research").value
      ? research.check()
      : research.uncheck();
    formik.getFieldProps("Attorney").value
      ? attorney.check()
      : attorney.uncheck();
    formik.getFieldProps("PersonalUse").value
      ? personalUse.check()
      : personalUse.uncheck();
    formik.getFieldProps("AgreeConfidentiality").value
      ? agreement.check()
      : agreement.uncheck();

    const mPersonalSignature =
      "ESIGNED By " +
      formik.getFieldProps("PatientName").value +
      " on " +
      Moment(fileGeneration?.signatureDataUpdated).format("MM-DD-YYYY");
    personalSignature.setText(mPersonalSignature);
    dateAgreement.setText(Moment(new Date()).format("MM-DD-YYYY"));
    printedName.setText(formik.getFieldProps("PatientName").value);

    phiFacilities.setText(formik.getFieldProps("phiFacilities").value);
    phiPatientName.setText(formik.getFieldProps("phiPatientName").value);
    phiPatientLastName.setText(
      formik.getFieldProps("phiPatientLastName").value
    );
    phiPatientPhone.setText(formik.getFieldProps("phiPatientPhone").value);
    phiPatientMail.setText(formik.getFieldProps("phiPatientMail").value);
    phiPatientMailingAddress.setText(
      formik.getFieldProps("phiPatientMailingAddress").value
    );
    formik.getFieldProps("phiMedicalRecords").value
      ? phiMedicalRecords.check()
      : phiMedicalRecords.uncheck();
    formik.getFieldProps("phiTreatmentPlan").value
      ? phiTreatmentPlan.check()
      : phiTreatmentPlan.uncheck();
    formik.getFieldProps("phiAppointments").value
      ? phiAppointments.check()
      : phiAppointments.uncheck();
    formik.getFieldProps("phiOther").value
      ? phiOther.check()
      : phiOther.uncheck();
    phiOtherText.setText(formik.getFieldProps("phiOtherText").value);
    phiOtherPurpose.setText(formik.getFieldProps("phiOtherPurpose").value);
    var phiDate = Moment(
      formik.getFieldProps("phiExpirationDate").value,
      "YYYY-MM-DD"
    ).isValid()
      ? Moment(formik.getFieldProps("phiExpirationDate").value).format(
          "YYYY-MM-DD"
        )
      : "";
    phiExpirationDate.setText(phiDate);
    phiExpiryOther.setText(formik.getFieldProps("phiExpiryOther").value);

    const pdfBytes = await pdfDoc.save();
    uploadToServer(pdfBytes, "pdf");
    MySwal.fire({
      title: "Success",
      html: "Document Signed!",
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#757575",
      cancelButtonText: "OK!",
      confirmButtonText: "Download!",
    }).then((result) => {
      if (result.value) {
        download(pdfBytes, "pdf");
      }
    });
  }

  function uploadToServer(arrayBuffer: BlobPart, type: string) {
    var blob = new Blob([arrayBuffer], { type: type });
    dispatch(
      saveDocumentThunkAction({
        data: {
          file: { fileData: blob, filename: "HipaaForm.pdf" },
          contactId: fileGeneration?.contactId,
          caseId: fileGeneration?.caseId,
          fileTypeId: 15,
          fileName: "HipaaForm.pdf",
          fileDescription: "User Generated Form",
        },
        onSuccess: (res) => {
          return Promise.resolve(res);
        },
        onError: (err) => {
          MySwal.fire("Error", "Couldn't save File", "warning");
          return Promise.reject(err);
        },
      })
    );
  }

  function download(arrayBuffer: BlobPart, type: string) {
    var blob = new Blob([arrayBuffer], { type: type });
    var url = URL.createObjectURL(blob);
    var link = document.createElement("a");
    link.href = url;
    link.download = "HipaaForm.pdf";
    link.dispatchEvent(new MouseEvent("click"));
  }

  useEffect(() => {
    if (isFileGenerationError) {
      // redirigir a una pagina
      //history.push(`/NotFound`);
    }
  }, [isFileGenerationError]);

  // Prepopulate Demographics section
  useEffect(() => {
    if (fileGeneration) {
      // Prefill the two checkboxes
      formikRef.current.setFieldValue("MedicalRecords", true);
      formikRef.current.setFieldValue("FurtherMedicalCare", true);
      // it is doing something weird and not populating the save???
      if (fileGeneration?.formData) {
        formikRef.current.setValues(JSON.parse(fileGeneration?.formData || ""));
      } else {
        loadHipaaQuestions(formikRef, fileGeneration);
      }
      formikRef.current.setFieldValue("caseId", fileGeneration?.caseId);
      formikRef.current.setFieldValue("contactId", fileGeneration?.contactId);
      formikRef.current.setFieldValue(
        "generationType",
        fileGeneration?.generationType
      );
      formikRef.current.setFieldValue(
        "hasAgreedToS",
        fileGeneration?.hasAgreedToS == 1
      );
    }
  }, [fileGeneration]);

  const renderCurrentStep = (form: FormikProps<FormikValues>) => {
    const step = steps[currentIndex];

    // opportunity to extend commonProps here with other relevant information
    const commonProps = {
      name: step.name,
      form,
    };

    const StepComponent = step.component;

    return (
      <>
        <StepComponent {...commonProps} />{" "}
        <Navigation
          maxSteps={steps.length}
          currentIndex={currentIndex}
          onClickNext={goNext}
          onClickBack={goBack}
          onClickSubmit={goSubmit}
        />
      </>
    );
  };

  const renderLoading = () => {
    return (
      <>
        <Container className="mt-4">
          <Row className="align-items-center">
            <Col>
              <Card>
                <Row>
                  <Col>
                    <Typography variant="h4" className="text-center mt-5">
                      <CustomSpinner></CustomSpinner>
                    </Typography>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  const renderError = () => {
    return (
      <>
        <Container className="mt-4">
          <Row className="align-items-center">
            <Col>
              <Card>
                <Row>
                  <Col>
                    <Typography variant="h2" className="text-center mt-5">
                      Sorry, the form you are looking for couldn't be found.
                    </Typography>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  const handleSubmitQuestionnaire = (values: FormikValues) => {
    // Opportunity to perform API call here
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(10);
      }, 2000);
    }).then(() => {
      //history.push(`/questionnaire/results`, { values });
      //console.log("values: ", values);
    });
  };

  const checkFileGenerationError = () => {
    return false;
  };

  return (
    <>
      <Progress steps={steps} currentIndex={currentIndex} />

      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={getStepSchema(currentIndex, steps)}
        onSubmit={handleSubmitQuestionnaire}
        validateOnMount
      >
        {(form) => {
          return (
            <>
              {checkFileGenerationError()
                ? renderError()
                : isFileGenerationLoading || isSavingFormData
                ? renderLoading()
                : renderCurrentStep(form)}
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default Questionnaire;

const loadHipaaQuestions = (
  formikRef: React.MutableRefObject<any>,
  fileGeneration: FileGeneration
) => {
  formikRef.current.setFieldValue(
    "PatientName",
    fileGeneration?.contact?.firstName +
      " " +
      fileGeneration?.contact?.lastName,
    true
  );
  const patientNameFromDB = (fileGeneration?.contact?.firstName || fileGeneration?.contact?.lastName) ? true : false;
  formikRef.current.setFieldValue(
    "patientNameFromDB",
    patientNameFromDB,
    true
  );
  formikRef.current.setFieldValue(
    "PatientDoB",
    Moment(fileGeneration?.contact?.doB).format("MM/DD/YYYY"),
    true
  );
  const contactPhone = fileGeneration?.contact?.contactPhones
    ? fileGeneration?.contact?.contactPhones[0].phone
    : "";
  formikRef.current.setFieldValue("PhoneNumber", contactPhone);
};
