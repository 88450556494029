import { AxiosRequestConfig } from 'axios';

type ValueOf<T> = T[keyof T];
// BEWARE
// Due to https://github.com/Microsoft/TypeScript/issues/13195 and https://stackoverflow.com/questions/54489817/typescript-partialt-type-without-undefined
// AvailableParams has string | undefined
type AvailableParams = Partial<Record<ValueOf<typeof QUERY_PARAMS>, string>>;

export type ServiceNames = keyof ReturnType<typeof apiBaseEndpoints>;

export interface QueryParamsResolver extends AxiosRequestConfig {
  query?: string;
  params?: AvailableParams;
}

export const resolveUrl = (
  serviceName: ServiceNames,
  { query = '', params = {} }: QueryParamsResolver
): string => {
  const serviceMapping = apiBaseEndpoints();
  const path = serviceMapping[serviceName];

  const url = Object.keys(params).reduce((acc, param) => {
    return acc.replace(param, (params as any)[param]);
  }, path);

  return query ? `${url}?${query}` : url;
};

export enum QUERY_PARAMS {
  id = ':id',
  tokenId = ':tokenId',
  caseId = ':caseId',
  documentType = ':documentType'
}

export const apiBaseEndpoints = () => {
  const signIn = '/signIn';
  const getTwoFactor = '/Account/TwoFactor/Get';
  const generateTwoFactor = '/Account/TwoFactor/Generate';
  const checkTwoFactor = '/Account/TwoFactor/Check';
  const getUserId = '/getUserId';
  const createDoctor = '/createDoctor';
  const forgotPassword = '/forgotPassword';
  const requestOTP = '/requestOTP';
  const searchProviders = '/searchProvidersByNPIOrName';
  const getFileExport = "/Documents/FileExport";
  const getFileGeneration = "/Documents/FileGeneration";
  const saveFormData = "/Documents/SaveFormData";
  const saveSignatureData = "/Documents/SaveSignatureData";
  const getScreeningCaseByCaseId = "/Screening/ScreeningCase/:caseId";
  const signPdf = "/Documents/FileSignature/save";
  const saveFile = "/File";
  const pdfBytes = "/Documents/GetDocument/:documentType";  
  const getInsurers = "/Dictionary/Insurer"

  return {
    signIn,
    getTwoFactor,
    generateTwoFactor,
    checkTwoFactor,
    getUserId,
    createDoctor,
    forgotPassword,
    requestOTP,
    searchProviders,
    getFileExport,
    getFileGeneration,
    saveFormData,
    saveSignatureData,
    getScreeningCaseByCaseId,
    signPdf,
    saveFile,
    pdfBytes,
    getInsurers
  };
};
