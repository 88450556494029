import { PDFFont, PDFForm, PDFPage, rgb } from "pdf-lib";
import PDFCheckboxFieldComponent from "../components/CheckboxFieldComponent";
import PDFTextFieldComponent from "../components/TextFieldComponent";
import { CurrentDimensions } from "../model/CurrentDimensions";
import { PDFSectionProps } from "../model/PDFSectionProps";


const SocialHistooryQuestionsSection = (props: PDFSectionProps) => {
    // Header style
    const headerFontSize = props.headerFontSize;
    const textHeight = 4 * headerFontSize;

    // Label Style
    const labelFontSize = props.labelFontSize;
    const labelHeight = 4 * labelFontSize;

    // Keep track of the height and width
    var currentDimensions = { height: props.heightPointer + textHeight, width: 0 } as CurrentDimensions;
    props.page.drawText('Social History Questions', {
        x: 30,
        y: props.height - currentDimensions.height,
        size: headerFontSize,
        font: props.timesRomanFont,
        color: rgb(0, 0, 0),
        opacity: 1,
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'sh.work.status.field',
            fieldMargin: 110,
            fieldWidth: 90,
            fieldValue: props.formValues.patientSHWorkStatus
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Work Status:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });
    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'work.where.field',
            fieldMargin: 140,
            fieldWidth: 110,
            fieldValue: props.formValues.patientSHWorkPlace
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Where do you work?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });
    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'work.type.field',
            fieldMargin: 130,
            fieldWidth: 100,
            fieldValue: props.formValues.patientSHWorkType
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 10,
            labelText: "What type of work?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine:true,
    });

    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'use.tobacco.field',
            fieldMargin: 140,
            fieldWidth: 10,
            fieldValue: props.formValues.patientSHUsesTobacco
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Do you use Tobacco?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });


    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'cigarretes.per.day.field',
            fieldMargin: 180,
            fieldWidth: 130,
            fieldValue: props.formValues.patientSHCigarettesPerDay
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "How many cigarettes per day?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'how.many.cigars.day.field',
            fieldMargin: 170,
            fieldWidth: 130,
            fieldValue: props.formValues.patientSHCigarsPerDay
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "How many cigars per day?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine:true,
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'how.many.chews.day.field',
            fieldMargin: 200,
            fieldWidth: 180,
            fieldValue: props.formValues.patientSHDipsPerDay
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "How many dips or chews per day?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine:true,
    });


    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'drink.alcohol.field',
            fieldMargin: 150,
            fieldWidth: 10,
            fieldValue: props.formValues.patientSHDrinksAlcohol
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Do you drink Alcohol?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });
    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'how.many.drinks.week.field',
            fieldMargin: 170,
            fieldWidth: 170,
            fieldValue: props.formValues.patientSHDrinksPerWeek
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "How many drinks per week?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine:true,
    });

    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'substance.abuse.field',
            fieldMargin: 300,
            fieldWidth: 10,
            fieldValue: props.formValues.patientSHSubstanceAbuse
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Do you have a personal history of substance abuse?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'abuse.alcohol.field',
            fieldMargin: 110,
            fieldWidth: 50,
            fieldValue: (props.formValues.patientSHSubstanceAbuseSelected == "Alcohol").toString()
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Alcohol"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'abuse.drugs.field',
            fieldMargin: 110,
            fieldWidth: 50,
            fieldValue: (props.formValues.patientSHSubstanceAbuseSelected == "Drugs").toString()
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Illegal Drugs"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'abuse.rx.drugs.field',
            fieldMargin: 80,
            fieldWidth: 50,
            fieldValue: (props.formValues.patientSHSubstanceAbuseSelected == "RxDrugs").toString()
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Rx Drugs"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });


    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'trouble.sleeping.field',
            fieldMargin: 180,
            fieldWidth: 10,
            fieldValue: props.formValues.patientSHTroubleSleeping
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Do you have trouble sleeping?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });


    props.page.drawText('Do you have a history of any of the following psychological diagnoses?', {
        x: 30,
        y: props.height - currentDimensions.height - labelHeight,
        size: labelFontSize,
        font: props.timesRomanFont,
        color: rgb(0, 0, 0),
        opacity: 1,
    });

    currentDimensions.height = currentDimensions.height + labelHeight;


    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'pd.add.field',
            fieldMargin: 130,
            fieldWidth: 10,
            fieldValue: props.formValues.patientSHDiagnosesADHD
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "ADD/ADHD/OCD"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });

    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'pd.bipolar.field',
            fieldMargin: 100,
            fieldWidth: 10,
            fieldValue: props.formValues.patientSHDiagnosesBipolar
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "bipolar"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });


    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'pd.schizophrenia.field',
            fieldMargin: 110,
            fieldWidth: 10,
            fieldValue: props.formValues.patientSHDiagnosesSchizophrenia
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Schizophrenia"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });

    
    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'pd.depression.field',
            fieldMargin: 150,
            fieldWidth: 10,
            fieldValue: props.formValues.patientSHDiagnosesDepression
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Depression/Anxiety"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });

    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'suicidal.field',
            fieldMargin: 300,
            fieldWidth: 10,
            fieldValue: props.formValues.patientSHDiagnosesNone
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Have you recently thought about hurting yourself?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });

    return currentDimensions;
};

export default SocialHistooryQuestionsSection;
