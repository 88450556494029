import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  iframe: {
    width: '100%',
    height: 'calc(100vh - 84px)',
    border: 'none',
  },
  hidden: {
    visibility: "hidden",
  }
});
