import * as Yup from "yup";
import moment from "moment";
import { getOptionByLabel, painLocationOptionsArray } from "utils/form/multiSelectOptions";

const rePhoneNumber = /^([0-9]*(\.\d)?)|10$/;



const patientChiefComplaintPainLevelToday = Yup.string().matches(
    rePhoneNumber,
    "Invalid Number"
).nullable();

const patientCCPainLocation = Yup.string().test(
    'empty-check',
    'Please select a value',
    (value) => {
        //  con False vemos el error
        console.log("algo", value);
        var result = false;
        if (value) {
            result = true;
        }
        return result;
    }).nullable();

const patientCCPainMostConcern = Yup.string().test(
    'empty-check',
    'Please select a value',
    (value) => {
        //  con False vemos el error
        var result = false;
        if (getOptionByLabel(painLocationOptionsArray, value)) {
            result = true;
        }
        return result;
    }).nullable();

export default Yup.object({
    patientChiefComplaintPainLevelToday,
    //patientCCPainLocation,
    patientCCPainMostConcern
});
