import { PDFFont, PDFForm, PDFPage, rgb } from "pdf-lib";
import { CurrentDimensions } from "../model/CurrentDimensions";
import { PDFFieldCustom } from "../model/PDFFieldCustom";
import { PDFLabelCustom } from "../model/PDFLabelCustom";

interface PDFTextFieldComponentProps {
    currentDimensions: CurrentDimensions,
    labelData: PDFLabelCustom,
    fieldData: PDFFieldCustom,
    page: PDFPage,
    form: PDFForm,
    font: PDFFont,
    fontSize: number,
    jumpLine?: boolean,
}

const PDFTextMultiLineFieldComponent = (props: PDFTextFieldComponentProps) => {
    const { width, height } = props.page.getSize();
    var finalLabelHeight = props.labelData.labelHeight - 7
    props.page.drawText(props.labelData.labelText, {
        x: props.currentDimensions.width + props.labelData.labelMargin,
        y: height - props.currentDimensions.height - finalLabelHeight,
        size: props.fontSize,
        font: props.font,
        color: rgb(0, 0, 0),
        opacity: 1,
    });
    var finalFieldHeight = props.labelData.labelHeight - 7 + props.fieldData.fieldHeight;
    if (props.fieldData.fieldValue) {
        props.page.drawText(props.fieldData.fieldValue.toString(), {
            x: props.currentDimensions.width + props.fieldData.fieldMargin,
            y: height - props.currentDimensions.height - props.labelData.labelHeight + 7,
            size: props.fontSize,
            font: props.font,
            color: rgb(30 / 255, 144 / 255, 255 / 255),
            opacity: 1,
        });
    }else {
        props.page.drawText('-', {
            x: props.currentDimensions.width + props.fieldData.fieldMargin,
            y: height - props.currentDimensions.height - props.labelData.labelHeight + 7,
            size: props.fontSize,
            font: props.font,
            color: rgb(30 / 255, 144 / 255, 255 / 255),
            opacity: 1,
        });
    }

    
    // We get the total width of the field, since it is the last item
    const finalWidth = props.jumpLine ? 0 : props.currentDimensions.width + props.fieldData.fieldMargin + props.fieldData.fieldWidth;
    // We choose the element with the highest height
    const elementHeight = props.jumpLine ? (finalLabelHeight > finalFieldHeight) ? finalLabelHeight: finalFieldHeight : 0;
    // Add the highest height to the current height and return it
    const finalHeight = props.currentDimensions.height + elementHeight;
    return { width: finalWidth, height: finalHeight } as CurrentDimensions;
};

export default PDFTextMultiLineFieldComponent;
