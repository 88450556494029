import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
//import DocumentHipaa from "./views/DocumentHipaa";
import HipaaQuestions from "./views/HipaaQuestions/HipaaQuestions";
import HealthHistoryQuestions from "./views/HealthHistoryQuestions/HealthHistoryQuestions";
import DocumentScreeningQuestions from "./views/ScreeningQuestions/DocumentsScreeningQuestions";
import NotFound from 'pages/notFound/views/NotFound';

export const DOCUMENTS_ROUTES = {
  root: "/documents",
  hipaa: "/hipaa",
  screening: "/screening",  
  healthHistory: "/healthHistory"
};

const DocumentsRouter = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route
        exact path={`${match.path}${DOCUMENTS_ROUTES.hipaa}/:tokenId`}
        component={HipaaQuestions}
      ></Route>      
      <Route
        exact path={`${match.path}${DOCUMENTS_ROUTES.healthHistory}/:tokenId`}
        component={HealthHistoryQuestions}
      ></Route>
      <Route
        exact path={`${match.path}${DOCUMENTS_ROUTES.screening}/:tokenId`}
        component={DocumentScreeningQuestions}
      ></Route>      
      
    </Switch>
  );
};

export default DocumentsRouter;
