// Components & schema
/*
import GeneralQuestionsStep from "./generalQuestionsStep/GeneralQuestionsStep";
import GeneralQuestionsStepSchema from "./generalQuestionsStep/GeneralQuestionsStep.schema";
import BiometricsQuestionsStep from "./biometricsStep/BiometricsStep";
import BiometricsQuestionsStepSchema from "./biometricsStep/BiometricsStep.schema";
import ChiefComplaintStep from "./chiefComplaintStep/ChiefComplaintStep";
import ChiefComplaintStepSchema from "./chiefComplaintStep/ChiefComplaintStep.schema";
import ImagingStudiesQuestions from "./imagingStudiesStep/ImagingStudiesStep";
import ImagingStudiesStepSchema from "./imagingStudiesStep/ImagingStudiesStep.schema";
import SpineTreatmentStep from "./spineTreatmentStep/SpineTreatmentStep";
import SpineTreatmentStepSchema from "./spineTreatmentStep/SpineTreatmentStep.schema";
import SpineSurgeryHistoryStep from "./spineSurgeryHistory/SpineSurgeryHistoryStep";
import SpineSurgeryHistoryStepSchema from "./spineSurgeryHistory/SpineSurgeryHistoryStep.schema";
import MotorVehicleAccidentStep from "./motorVehicleAccidentStep/MotorVehicleAccidentStep";
import MotorVehicleAccidentStepSchema from "./motorVehicleAccidentStep/MotorVehicleAccidentStep.schema";
import InsuranceStepSchema from "./insuranceStep/InsuranceStep.schema";
import InsuranceStep from "./insuranceStep/InsuranceStep";
*/
import GeneralQuestionsStep from "components/forms/steps/generalQuestionsStep/GeneralQuestionsStep";
import GeneralQuestionsStepSchema from "components/forms/steps/generalQuestionsStep/GeneralQuestionsStep.schema";
import BiometricsQuestionsStep from "components/forms/steps/biometricsStep/BiometricsStep";
import BiometricsQuestionsStepSchema from "components/forms/steps/biometricsStep/BiometricsStep.schema";
import ChiefComplaintStep from "components/forms/steps/chiefComplaintStep/ChiefComplaintStep";
import ChiefComplaintStepSchema from "components/forms/steps/chiefComplaintStep/ChiefComplaintStep.schema";
import ImagingStudiesQuestions from "components/forms/steps/imagingStudiesStep/ImagingStudiesStep";
import ImagingStudiesStepSchema from "components/forms/steps/imagingStudiesStep/ImagingStudiesStep.schema";
import SpineTreatmentStep from "components/forms/steps/spineTreatmentStep/SpineTreatmentStep";
import SpineTreatmentStepSchema from "components/forms/steps/spineTreatmentStep/SpineTreatmentStep.schema";
import SpineSurgeryHistoryStep from "components/forms/steps/spineSurgeryHistory/SpineSurgeryHistoryStep";
import SpineSurgeryHistoryStepSchema from "components/forms/steps/spineSurgeryHistory/SpineSurgeryHistoryStep.schema";
import MotorVehicleAccidentStep from "components/forms/steps/motorVehicleAccidentStep/MotorVehicleAccidentStep";
import MotorVehicleAccidentStepSchema from "components/forms/steps/motorVehicleAccidentStep/MotorVehicleAccidentStep.schema";
import InsuranceStepSchema from "components/forms/steps/insuranceStep/InsuranceStep.schema";
import InsuranceStep from "components/forms/steps/insuranceStep/InsuranceStep";


// Types
import * as Yup from "yup";
export type Step = {
  /** Identifies the name of the step & key in initialValues
   * Can use an 'id' here, doesn't necessarily have to be a 'name' prop.
   */
  name: string;
  component: React.ElementType;
  validationSchema: Yup.SchemaOf<any>;
};

/** Considered the maximum possible steps for a user -- optionally perform filtering logic in `generateSteps` if you need to */
const baseSteps: Step[] = [
  {
    name: "DemographicsQuestions",
    component: GeneralQuestionsStep,
    validationSchema: GeneralQuestionsStepSchema,
  },
  {
    name: "BiometricsQuestions",
    component: BiometricsQuestionsStep,
    validationSchema: BiometricsQuestionsStepSchema,
  },
  {
    name: "ChiefComplaintQuestions",
    component: ChiefComplaintStep,
    validationSchema: ChiefComplaintStepSchema,
  },
  {
    name: "ImagingStudiesQuestions",
    component: ImagingStudiesQuestions,
    validationSchema: ImagingStudiesStepSchema,
  },
  {
    name: "SpineTreatmentQuestions",
    component: SpineTreatmentStep,
    validationSchema: SpineTreatmentStepSchema,
  },
  {
    name: "SpineSurgeryHisotry",
    component: SpineSurgeryHistoryStep,
    validationSchema: SpineSurgeryHistoryStepSchema,
  },
  {
    name: "MotorVehicleAccidentQuestions",
    component: MotorVehicleAccidentStep,
    validationSchema: MotorVehicleAccidentStepSchema,
  },
  {
    name: "InsuranceQuestions",
    component: InsuranceStep,
    validationSchema: InsuranceStepSchema,
  },
];

export const generateSteps = (): Step[] => {
  // Depending on the type of user, and the requirements for the form that are needed -- you can perform filtering logic here to omit steps that aren't
  // necessary for each case
  return baseSteps;
};

export const generateInitialValues = (
  filteredSteps: Step[],
  tokenId: string
) => {
  // These initial values are assumed to be `null`.
  // In more complex cases you may want to populate these values w/ data from an API, so you could do what you need to do in here
  const initialValues = filteredSteps.reduce((values, step) => {
    // If it first time, load data from Contact, if not, we should be able to load data from FIleGenerationTable
    return { ...values, [step.name]: null };
  }, {});


  return initialValues;
};

export const getStepSchema = (currentIndex: number, steps: Step[]) => {
  return steps[currentIndex].validationSchema;
};


