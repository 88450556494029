import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import DocumentsService from "api/documents.api";
import { AxiosResponse } from "axios";
import jwtDecode from "jwt-decode";
import moment from "moment-timezone";
// import { truncate } from "node:fs";
import { CreateAccountForm } from "pages/auth/views/createAccount/CreateAccount";
import {
  DocumentsState,
  IFIleExportGet,
  CEThunkPayload,
} from "store/documents/documents.types";
import { FileGeneration } from "utils/model/FileGeneration";
import { FileSignature } from "utils/model/FileSignature";
import { Insurer } from "utils/model/Insurer";
import { MultiPartPackage } from "utils/model/MultiPartPackage";
import { ScreeningCase } from "utils/model/ScreeningCase";
import { canDownloadPdfSelector } from "./documents.selectors";

const initialState: DocumentsState = {
  fileExport: undefined,
  fileGeneration: undefined,
  isFileGenerationLoading: false,
  isFileGenerationError: false,
  fileGenerationPhoneIndex: undefined,
  fileGenerationEmailIndex: undefined,
  isSavingFormData: false,
  isFileExportLoading: false,
  canSignHipaa: false,
  canDownloadPdf: false,
  documentSigningDate: undefined,
  pdfBytes: undefined,
  insurerList: []
};

const payloadFactory = (
  payload: CEThunkPayload<any>,
  service: Promise<any>
) => {
  const success = payload.onSuccess
    ? payload.onSuccess
    : (res: AxiosResponse) => Promise.resolve(res);
  const error = payload.onError
    ? payload.onError
    : (err: AxiosResponse) => Promise.reject(err);
  return service.then(success).catch(error);
};

export const fileExportThunkAction = createAsyncThunk(
  "documents/Hipaa",
  (payload: CEThunkPayload<IFIleExportGet>) =>
    payloadFactory(payload, DocumentsService.getFileExport(payload.data))
);

export const getFileGenerationThunkAction = createAsyncThunk(
  "documents/FileGeneration/load",
  (payload: CEThunkPayload<FileGeneration>) =>
    payloadFactory(payload, DocumentsService.getFileGeneration(payload.data))
);

export const getInsuranceCarrierValuesThunkAction = createAsyncThunk(
  "documents/Insurance/InsuranceCarrierValues",
  (payload: CEThunkPayload<String>) =>
    payloadFactory(payload, DocumentsService.getInsuranceCarrierValues())
);

export const saveFormDataThunkAction = createAsyncThunk(
  "documents/SaveFormData",
  (payload: CEThunkPayload<FileGeneration>) =>
    payloadFactory(payload, DocumentsService.saveFormData(payload.data))
);

export const saveSignatureDataThunkAction = createAsyncThunk(
  "documents/SaveSignatureData",
  (payload: CEThunkPayload<FileGeneration>) =>
    payloadFactory(payload, DocumentsService.saveSignatureData(payload.data))
);

export const getScreeningCaseByCaseIdThunkAction = createAsyncThunk(
  "documents/GetScreeningCaseByCaseId",
  (payload: CEThunkPayload<number>) =>
    payloadFactory(
      payload,
      DocumentsService.getScreeningCaseByCaseId(payload.data)
    )
);

export const getPdfBytesThunkAction = createAsyncThunk(
  "documents/getPdfBytes",
  (payload: CEThunkPayload<string>) =>
    payloadFactory(payload, DocumentsService.getPdfBytes(payload.data))
);

export const saveDocumentThunkAction = createAsyncThunk(
  "documents/saveDocument",
  (payload: CEThunkPayload<MultiPartPackage>) =>
    payloadFactory(payload, DocumentsService.saveDocument(payload.data))
);

export const signDocumentThunkAction = createAsyncThunk(
  "documents/signDocument",
  (payload: CEThunkPayload<FileSignature>) =>
    payloadFactory(payload, DocumentsService.signDocument(payload.data))
);

const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fileExportThunkAction.pending, (state) => {
      state.isFileExportLoading = true;
    });
    builder.addCase(fileExportThunkAction.fulfilled, (state, { payload }) => {
      state.fileExport = payload;
      state.isFileExportLoading = false;
    });
    builder.addCase(fileExportThunkAction.rejected, (state) => {
      state.isFileExportLoading = false;
    });

    //GetFileGeneration
    builder.addCase(getFileGenerationThunkAction.pending, (state) => {
      state.isFileGenerationLoading = true;
    });
    builder.addCase(
      getFileGenerationThunkAction.fulfilled,
      (state, { payload }) => {
        state.fileGeneration = payload;
        state.fileGenerationPhoneIndex = payload.contact?.contactPhones?.length;
        state.fileGenerationEmailIndex = payload.contact?.contactEmails?.length;
        state.isFileGenerationLoading = false;
        // Id case, contact or generation type are undefined, set error
        if(payload.caseId == undefined || payload.contactId == undefined || payload.generationType == undefined){
        //  console.log(payload.caseId == undefined, payload.contactId == undefined, payload.generationType == undefined);
         // console.log({payload})
         // state.isFileGenerationError = true;
        }
      }
    );
    builder.addCase(getFileGenerationThunkAction.rejected, (state) => {
      state.isFileGenerationLoading = false;
      //state.isFileGenerationError = true;
    });

    //SaveFormData
    builder.addCase(saveFormDataThunkAction.pending, (state) => {
      state.isSavingFormData = true;
    });
    builder.addCase(saveFormDataThunkAction.fulfilled, (state, { payload }) => {
      state.fileGeneration = payload;
      state.isSavingFormData = false;
    });
    builder.addCase(saveFormDataThunkAction.rejected, (state) => {
      state.isSavingFormData = false;
    });

    //SaveSignatureData
    builder.addCase(saveSignatureDataThunkAction.pending, (state) => {
      state.isSavingFormData = true;
    });
    builder.addCase(
      saveSignatureDataThunkAction.fulfilled,
      (state, { payload }) => {
        state.fileGeneration = payload;
        state.isSavingFormData = false;
      }
    );
    builder.addCase(saveSignatureDataThunkAction.rejected, (state) => {
      state.isSavingFormData = false;
    });

    //GetScreeningCaseByCaseId
    builder.addCase(getScreeningCaseByCaseIdThunkAction.pending, (state) => {});
    builder.addCase(
      getScreeningCaseByCaseIdThunkAction.fulfilled,
      (state, { payload }) => {
        state.screeningCase = payload;
      }
    );
    builder.addCase(
      getScreeningCaseByCaseIdThunkAction.rejected,
      (state) => {}
    );

    // SignDocument
    builder.addCase(signDocumentThunkAction.pending, (state) => {
      state.canDownloadPdf = false;
    });
    builder.addCase(signDocumentThunkAction.fulfilled, (state, { payload }) => {
      state.canDownloadPdf = true;
      state.documentSigningDate = new Date();
    });
    builder.addCase(signDocumentThunkAction.rejected, (state) => {
      state.canDownloadPdf = false;
    });

    // documents/getPdfBytes
    builder.addCase(getPdfBytesThunkAction.pending, (state) => {
      state.pdfBytes = undefined;
    });
    builder.addCase(getPdfBytesThunkAction.fulfilled, (state, { payload }) => {
      state.pdfBytes = payload;
    });
    builder.addCase(getPdfBytesThunkAction.rejected, (state) => {
      state.pdfBytes = undefined;
    });


    // Dictionary/Insurance
    builder.addCase(getInsuranceCarrierValuesThunkAction.pending, (state) => {
      state.insurerList = [];
    });
    builder.addCase(getInsuranceCarrierValuesThunkAction.fulfilled, (state, { payload }) => {
      state.insurerList = payload;
    });
    builder.addCase(getInsuranceCarrierValuesThunkAction.rejected, (state) => {
      state.insurerList = [];
    });
  },
});

export default documentsSlice.reducer;
