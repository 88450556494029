import React, {  useState } from "react";
// Components
import {   Typography, Button, Card} from "@material-ui/core";
import { Row, Col, Form, Container } from "react-bootstrap";
import {MultiSelect} from "react-multi-select-component";
// Types
import { FormikProps, FormikValues, ErrorMessage, useFormikContext} from "formik";
// Util
import { getInputProps, getInputPropsMultiSelect } from "utils/constants/forms";

type CommonProps = {
  form: FormikProps<FormikValues>;
  patientName: "SocialHistoryQuestions";
};
type StatePropsType = {
  form: FormikProps<FormikValues>;
  state: [any, React.Dispatch<React.SetStateAction<any>>];
};

const SocialHistoryQuestions = ({ form, patientName }: CommonProps) => {
  // Grab what we need from formik without prop-drilling
  const { getFieldProps } = useFormikContext();

  const commonProps = {
    form,
    patientName,
  };

  const [substanceAbuseSelected, substanceAbuseSeleted] = useState(
    getFieldProps("patientSHSubstanceAbuseSelected").value || []
  );
  const substanceAbuseProps = {
    form,
    state: [substanceAbuseSelected, substanceAbuseSeleted],
  } as StatePropsType;
  return (
    <>
      <Container className="mt-4">
        <Row className="align-items-center">
          <Col>
            <Card>
              <Row>
                <Col>
                  <Typography variant="h4" className="text-center mt-5">
                    Social History Questions
                  </Typography>
                </Col>
              </Row>
              <Form.Row className="mt-5 mr-3 ml-3">
                <Col xs={12} md={6} className="my-1">
                  <Form.Label column>Work status</Form.Label>
                </Col>
                <Col xs={12} md={6} className="my-1">
                  <Form.Control
                    as="select"
                    custom
                    type="text"
                    placeholder="Select if you had XRay"
                    {...getInputProps("patientSHWorkStatus", form)}
                  >
                    <option>-- Select --</option>
                    <option>Employed</option>
                    <option>Unemployed</option>
                    <option>Disabled</option>
                    <option>Retired</option>
                  </Form.Control>
                  <ErrorMessage
                    name="patientSHWorkStatus"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
              </Form.Row>
              {form.getFieldProps("patientSHWorkStatus").value ===
              "Employed" ? (
                <EmplyedSection {...commonProps} />
              ) : null}
              <Form.Row className="mt-5 mr-3 ml-3">
                <Col xs={12} md={6} className="my-1">
                  <Form.Label column>Do you use tobacco?</Form.Label>
                </Col>
                <Col xs={12} md={6} className="my-1">
                  <Form.Control
                    as="select"
                    custom
                    type="text"
                    placeholder="Select if you had XRay"
                    {...getInputProps("patientSHUsesTobacco", form)}
                  >
                    <option>-- Select --</option>
                    <option>Yes</option>
                    <option>No</option>
                  </Form.Control>
                  <ErrorMessage
                    name="patientSHUsesTobacco"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
              </Form.Row>
              {form.getFieldProps("patientSHUsesTobacco").value === "Yes" ? (
                <TobaccoSection {...commonProps} />
              ) : null}
              {form.getFieldProps("patientSHUsesTobacco").value === "No" ? (
                <TobaccoFreeSection {...commonProps} />
              ) : null}
              {form.getFieldProps("patientSHUsesTobacco").value === "No" &&
              form.getFieldProps("patientSHDidUseToSmoke").value === "Yes" ? (
                <TobaccoHistorySection {...commonProps} />
              ) : null}
              <Form.Row className="mt-5 mr-3 ml-3">
                <Col xs={12} md={6} className="my-1">
                  <Form.Label column>Do you drink alcohol?</Form.Label>
                </Col>
                <Col xs={12} md={6} className="my-1">
                  <Form.Control
                    as="select"
                    custom
                    type="text"
                    placeholder="Select if you had XRay"
                    {...getInputProps("patientSHDrinksAlcohol", form)}
                  >
                    <option>-- Select --</option>
                    <option>Yes</option>
                    <option>No</option>
                  </Form.Control>
                  <ErrorMessage
                    name="patientSHUsesTobacco"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
              </Form.Row>
              {form.getFieldProps("patientSHDrinksAlcohol").value === "Yes" ? (
                <AlcoholSection {...commonProps} />
              ) : null}
              <Form.Row className="mt-5 mr-3 ml-3">
                <Col xs={12} md={6} className="my-1">
                  <Form.Label column>
                    Do you currently use recreational or street drugs?
                  </Form.Label>
                </Col>
                <Col xs={12} md={6} className="my-1">
                  <Form.Control
                    as="select"
                    custom
                    type="text"
                    placeholder="Select if you had XRay"
                    {...getInputProps("patientSHStreetDrugs", form)}
                  >
                    <option>-- Select --</option>
                    <option>Yes</option>
                    <option>No</option>
                  </Form.Control>
                  <ErrorMessage
                    name="patientSHStreetDrugs"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
              </Form.Row>
              <Form.Row className="mt-5 mr-3 ml-3">
                <Col xs={12} md={6} className="my-1">
                  <Form.Label column>
                    Do you have a personal history of substance abuse?
                  </Form.Label>
                </Col>
                <Col xs={12} md={6} className="my-1">
                  <Form.Control
                    as="select"
                    custom
                    type="text"
                    placeholder="Select if you had XRay"
                    {...getInputProps("patientSHSubstanceAbuse", form)}
                  >
                    <option>-- Select --</option>
                    <option>Yes</option>
                    <option>No</option>
                  </Form.Control>
                  <ErrorMessage
                    name="patientSHSubstanceAbuse"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
              </Form.Row>
              {form.getFieldProps("patientSHSubstanceAbuse").value === "Yes" ? (
                <SubstanceAbuseSection {...substanceAbuseProps} />
              ) : null}
              <Form.Row className="mt-5 mr-3 ml-3">
                <Col xs={12} md={6} className="my-1">
                  <Form.Label column>Do you have trouble sleeping?</Form.Label>
                </Col>
                <Col xs={12} md={6} className="my-1">
                  <Form.Control
                    as="select"
                    custom
                    type="text"
                    placeholder="Select if you had XRay"
                    {...getInputProps("patientSHTroubleSleeping", form)}
                  >
                    <option>-- Select --</option>
                    <option>Yes</option>
                    <option>No</option>
                  </Form.Control>
                  <ErrorMessage
                    name="patientSHTroubleSleeping"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
              </Form.Row>
              <Form.Row className="mt-5 mr-3 ml-3">
                <Col xs={12} md={6} className="my-1">
                  <Form.Label column>
                    Do you have a history of any of the following psychological
                    diagnoses?
                  </Form.Label>
                </Col>
                <Col xs={12} md={6} className="my-1">
                  <Form.Check
                    inline
                    {...getInputProps("patientSHDiagnosesADHD", form)}
                    checked={getFieldProps("patientSHDiagnosesADHD").value || false}
                    type="checkbox"
                    label="ADD/ADHD/OCD "
                    className="m-3"
                  />
                  <Form.Check
                    inline
                    {...getInputProps("patientSHDiagnosesBipolar", form)}
                    checked={getFieldProps("patientSHDiagnosesBipolar").value || false}
                    type="checkbox"
                    label="Bipolar"
                    className="m-3"
                  />
                  <Form.Check
                    inline
                    {...getInputProps("patientSHDiagnosesSchizophrenia", form)}
                    checked={getFieldProps("patientSHDiagnosesSchizophrenia").value || false}
                    type="checkbox"
                    label="Schizophrenia"
                    className="m-3"
                  />
                  <Form.Check
                    inline
                    {...getInputProps("patientSHDiagnosesDepression", form)}
                    checked={getFieldProps("patientSHDiagnosesDepression").value || false}
                    type="checkbox"
                    label="Depression/Anxiety"
                    className="m-3"
                  />
                  <Form.Check
                    inline
                    {...getInputProps("patientSHDiagnosesNone", form)}
                    checked={getFieldProps("patientSHDiagnosesNone").value || false}
                    type="checkbox"
                    label="None of the above"
                    className="m-3"
                  />
                </Col>
              </Form.Row>
              <Form.Row className="mt-5 mr-3 ml-3">
                <Col xs={12} md={6} className="my-1">
                  <Form.Label column>Have you recently thought about hurting yourself?</Form.Label>
                </Col>
                <Col xs={12} md={6} className="my-1">
                  <Form.Control
                    as="select"
                    custom
                    type="text"
                    placeholder="Select if you had XRay"
                    {...getInputProps("patientSHSuicidal", form)}
                  >
                    <option>-- Select --</option>
                    <option>Yes</option>
                    <option>No</option>
                  </Form.Control>
                  <ErrorMessage
                    name="patientSHSuicidal"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
              </Form.Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SocialHistoryQuestions;

const EmplyedSection = ({ form, patientName }: CommonProps) => (
  <Form.Row className=" mr-3 ml-3">
    <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
      <Form.Label column>Where do you work?</Form.Label>
    </Col>
    <Col xs={12} md={6} className="my-1">
      <Form.Control
        type="text"
        placeholder="Where do you work?"
        {...getInputProps("patientSHWorkPlace", form)}
      />
      <ErrorMessage
        name="patientSHWorkPlace"
        component="div"
        data-testid="email-error"
      />
    </Col>
    <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
      <Form.Label column>What type of work do you do?</Form.Label>
    </Col>
    <Col xs={12} md={6} className="my-1">
      <Form.Control
        type="text"
        placeholder="Type of work"
        {...getInputProps("patientSHWorkType", form)}
      />
      <ErrorMessage
        name="patientSHWorkType"
        component="div"
        className="text-red-500 text-xs italic"
      />
    </Col>
  </Form.Row>
);

const TobaccoSection = ({ form, patientName }: CommonProps) => (
  <Form.Row className=" mr-3 ml-3">
    <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
      <Form.Label column>How many cigarettes per day?</Form.Label>
    </Col>
    <Col xs={12} md={6} className="my-1">
      <Form.Control
        type="text"
        placeholder="Cigarettes per day"
        {...getInputProps("patientSHCigarettesPerDay", form)}
      />
      <ErrorMessage
        name="patientSHCigarettesPerDay"
        component="div"
        data-testid="email-error"
      />
    </Col>
    <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
      <Form.Label column>How many cigars per day?</Form.Label>
    </Col>
    <Col xs={12} md={6} className="my-1">
      <Form.Control
        type="text"
        placeholder="Cigars per day"
        {...getInputProps("patientSHCigarsPerDay", form)}
      />
      <ErrorMessage
        name="patientSHCigarsPerDay"
        component="div"
        className="text-red-500 text-xs italic"
      />
    </Col>
    <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
      <Form.Label column>How many dips or chews per day?</Form.Label>
    </Col>
    <Col xs={12} md={6} className="my-1">
      <Form.Control
        type="text"
        placeholder="Dips or Chews per day"
        {...getInputProps("patientSHDipsPerDay", form)}
      />
      <ErrorMessage
        name="patientSHDipsPerDay"
        component="div"
        className="text-red-500 text-xs italic"
      />
    </Col>
  </Form.Row>
);

const TobaccoFreeSection = ({ form, patientName }: CommonProps) => (
  <Form.Row className=" mr-3 ml-3">
    <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
      <Form.Label column>Did you use to some?</Form.Label>
    </Col>
    <Col xs={12} md={6} className="my-1">
      <Form.Control
        as="select"
        custom
        type="text"
        placeholder="Select if you had XRay"
        {...getInputProps("patientSHDidUseToSmoke", form)}
      >
        <option>-- Select --</option>
        <option>Yes</option>
        <option>No</option>
      </Form.Control>
      <ErrorMessage
        name="patientSHDidUseToSmoke"
        component="div"
        className="text-red-500 text-xs italic"
      />
    </Col>
  </Form.Row>
);

const TobaccoHistorySection = ({ form, patientName }: CommonProps) => (
  <Form.Row className=" mr-3 ml-3">
    <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
      <Form.Label column>What year did you quit smoking?</Form.Label>
    </Col>
    <Col xs={12} md={6} className="my-1">
      <Form.Control
        type="text"
        placeholder="year"
        {...getInputProps("patientSHTobaccoStopSmokingYear", form)}
      />
      <ErrorMessage
        name="patientSHTobaccoStopSmokingYear"
        component="div"
        data-testid="email-error"
      />
    </Col>
  </Form.Row>
);

const AlcoholSection = ({ form, patientName }: CommonProps) => (
  <Form.Row className=" mr-3 ml-3">
    <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
      <Form.Label column>How many drinks per week?</Form.Label>
    </Col>
    <Col xs={12} md={6} className="my-1">
      <Form.Control
        type="text"
        placeholder="Drinks per week"
        {...getInputProps("patientSHDrinksPerWeek", form)}
      />
      <ErrorMessage
        name="patientSHTobaccoStopSmokingYear"
        component="div"
        data-testid="email-error"
      />
    </Col>
  </Form.Row>
);

const SubstanceAbuseSection = ({
  form,
  state: [substanceAbuseSelected, setSubstanceAbuseSelected],
}: StatePropsType) => {
  const substanceAbuseOptions = [
    { label: "Alcohol", value: "Alcohol" },
    { label: "Illegal Drugs", value: "IllegalDrugs" },
    { label: "Rx Drugs", value: "RxDrugs" },
  ];
  return (
    <Form.Row className=" mr-3 ml-3">
      <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
        <Form.Label column>What type?</Form.Label>
      </Col>
      <Col xs={12} md={6} className="my-1">
        <MultiSelect
          options={substanceAbuseOptions}
          value={substanceAbuseSelected}
          onChange={(values: any) => {
            setSubstanceAbuseSelected(values);
            form.setFieldValue("patientSHSubstanceAbuseSelected", values);
          }}
          {...getInputPropsMultiSelect("patientSHSubstanceAbuseSelected", form)}
          labelledBy="Select Pain Type"
        />
      </Col>
    </Form.Row>
  );
};
