import moment from "moment";
import { PDFFont, PDFForm, PDFPage, rgb } from "pdf-lib";
import PDFCheckboxFieldComponent from "../components/CheckboxFieldComponent";
import PDFTextFieldComponent from "../components/TextFieldComponent";
import { CurrentDimensions } from "../model/CurrentDimensions";
import { PDFSectionProps } from "../model/PDFSectionProps";


const DemographicQuestionsSection = (props: PDFSectionProps) => {
  // Header style
  const headerFontSize = props.headerFontSize;
  const textHeight = 4 * headerFontSize;

  // Label Style
  const labelFontSize = props.labelFontSize;
  const labelHeight = 4 * labelFontSize;

  // Keep track of the height and width
  var currentDimensions = { height: props.heightPointer + textHeight, width: 0 } as CurrentDimensions;
  props.page.drawText('Demographic Questions', {
    x: 30,
    y: props.height - currentDimensions.height,
    size: headerFontSize,
    font: props.timesRomanFont,
    color: rgb(0, 0, 0),
    opacity: 1,
  });

  props.page.drawText("Today's Date:", {
    x: props.width - 200,
    y: props.height - currentDimensions.height,
    size: labelFontSize,
    font: props.timesRomanFont,
    color: rgb(0, 0, 0),
    opacity: 1,
  });


  const today = new Date();

  props.page.drawText(today.toLocaleDateString("en-US"), {
    x: props.width - 130,
    y: props.height - currentDimensions.height,
    size: labelFontSize,
    font: props.timesRomanFont,
    color: rgb(30 / 255, 144 / 255, 255 / 255),
    opacity: 1,
  });
  //  const todayDateField = props.form.createTextField('today.date.field');

  /*todayDateField.addToPage(props.page, {
    x: props.width - 130,
    y: props.height - currentDimensions.height,
    font: props.timesRomanFont,
    width: 80,
    height: 20,
  });
  */


  const patientNameLabel = {
    labelHeight: labelHeight,
    labelMargin: 30,
    labelText: "Name:"
  };
  const patientNameField = {
    fieldHeight: 20,
    fieldId: 'patient.name.field',
    fieldMargin: 80,
    fieldWidth: 180,
    fieldValue: props.formValues.patientName
  };

  currentDimensions = PDFTextFieldComponent({
    currentDimensions: currentDimensions,
    fieldData: patientNameField,
    labelData: patientNameLabel,
    font: props.timesRomanFont,
    fontSize: labelFontSize,
    form: props.form,
    page: props.page
  });

  const dobLabel = {
    labelHeight: labelHeight,
    labelMargin: 30,
    labelText: "Date of Birth:"
  };
  const dobField = {
    fieldHeight: 20,
    fieldId: 'date.of.birth.field',
    fieldMargin: 120,
    fieldWidth: 40,
    fieldValue: moment(props.formValues.patientDoB, "YYYY-MM-DD").format("MM/DD/YYYY")
  };

  currentDimensions = PDFTextFieldComponent({
    currentDimensions: currentDimensions,
    fieldData: dobField,
    labelData: dobLabel,
    font: props.timesRomanFont,
    fontSize: labelFontSize,
    form: props.form,
    page: props.page,
  });

  const sexLabel = {
    labelHeight: labelHeight,
    labelMargin: 30,
    labelText: "Sex:"
  };
  const sexField = {
    fieldHeight: 20,
    fieldId: 'sex.field',
    fieldMargin: 80,
    fieldWidth: 40,
    fieldValue: props.formValues.patientSex
  };

  currentDimensions = PDFTextFieldComponent({
    currentDimensions: currentDimensions,
    fieldData: sexField,
    labelData: sexLabel,
    font: props.timesRomanFont,
    fontSize: labelFontSize,
    form: props.form,
    page: props.page,
    jumpLine: true
  });

  const mailingLabel = {
    labelHeight: labelHeight,
    labelMargin: 30,
    labelText: "Mailing Address:"
  };
  const mailingField = {
    fieldHeight: 20,
    fieldId: 'mailing.address.field',
    fieldMargin: 120,
    fieldWidth: 400,
    fieldValue: props.formValues.patientMailingAddress
  };

  currentDimensions = PDFTextFieldComponent({
    currentDimensions: currentDimensions,
    fieldData: mailingField,
    labelData: mailingLabel,
    font: props.timesRomanFont,
    fontSize: labelFontSize,
    form: props.form,
    page: props.page,
    jumpLine: true
  });

  const cityLabel = {
    labelHeight: labelHeight,
    labelMargin: 30,
    labelText: "City:"
  };
  const cityField = {
    fieldHeight: 20,
    fieldId: 'city.field',
    fieldMargin: 80,
    fieldWidth: 40,
    fieldValue: props.formValues.patientCity
  };

  currentDimensions = PDFTextFieldComponent({
    currentDimensions: currentDimensions,
    fieldData: cityField,
    labelData: cityLabel,
    font: props.timesRomanFont,
    fontSize: labelFontSize,
    form: props.form,
    page: props.page
  });

  const stateLabel = {
    labelHeight: labelHeight,
    labelMargin: 30,
    labelText: "State:"
  };
  const stateField = {
    fieldHeight: 20,
    fieldId: 'state.field',
    fieldMargin: 80,
    fieldWidth: 40,
    fieldValue: props.formValues.patientState
  };

  currentDimensions = PDFTextFieldComponent({
    currentDimensions: currentDimensions,
    fieldData: stateField,
    labelData: stateLabel,
    font: props.timesRomanFont,
    fontSize: labelFontSize,
    form: props.form,
    page: props.page
  });

  const zipLabel = {
    labelHeight: labelHeight,
    labelMargin: 30,
    labelText: "Zip:"
  };
  const zipField = {
    fieldHeight: 20,
    fieldId: 'zip.field',
    fieldMargin: 80,
    fieldWidth: 40,
    fieldValue: props.formValues.patientCity
  };

  currentDimensions = PDFTextFieldComponent({
    currentDimensions: currentDimensions,
    fieldData: zipField,
    labelData: zipLabel,
    font: props.timesRomanFont,
    fontSize: labelFontSize,
    form: props.form,
    page: props.page,
  });

  const maritalStatusLabel = {
    labelHeight: labelHeight,
    labelMargin: 30,
    labelText: "Marital Status:"
  };
  const maritalStatusField = {
    fieldHeight: 20,
    fieldId: 'marital.status.field',
    fieldMargin: 120,
    fieldWidth: 100,
    fieldValue: props.formValues.patientMaritalStatus
  };

  currentDimensions = PDFTextFieldComponent({
    currentDimensions: currentDimensions,
    fieldData: maritalStatusField,
    labelData: maritalStatusLabel,
    font: props.timesRomanFont,
    fontSize: labelFontSize,
    form: props.form,
    page: props.page,
    jumpLine: true
  });

  const mailLabel = {
    labelHeight: labelHeight,
    labelMargin: 30,
    labelText: "Mail:"
  };
  const mailField = {
    fieldHeight: 20,
    fieldId: 'mail.field',
    fieldMargin: 80,
    fieldWidth: 150,
    fieldValue: props.formValues.patientEmail0
  };

  currentDimensions = PDFTextFieldComponent({
    currentDimensions: currentDimensions,
    fieldData: mailField,
    labelData: mailLabel,
    font: props.timesRomanFont,
    fontSize: labelFontSize,
    form: props.form,
    page: props.page
  });

  const phoneLabel = {
    labelHeight: labelHeight,
    labelMargin: 30,
    labelText: "Phone:"
  };
  const phoneField = {
    fieldHeight: 20,
    fieldId: 'phone.field',
    fieldMargin: 80,
    fieldWidth: 80,
    fieldValue: props.formValues.patientPhoneNumber0
  };

  currentDimensions = PDFTextFieldComponent({
    currentDimensions: currentDimensions,
    fieldData: phoneField,
    labelData: phoneLabel,
    font: props.timesRomanFont,
    fontSize: labelFontSize,
    form: props.form,
    page: props.page,
    jumpLine: true
  });
  // Add more mails and phones programatically


  const driverLicenseNumberLabel = {
    labelHeight: labelHeight,
    labelMargin: 30,
    labelText: "Driver's License #:"
  };
  const driverLicenseNumberField = {
    fieldHeight: 20,
    fieldId: 'driver.license.number.field',
    fieldMargin: 130,
    fieldWidth: 150,
    fieldValue: props.formValues.patientDriverLicense
  };

  currentDimensions = PDFTextFieldComponent({
    currentDimensions: currentDimensions,
    fieldData: driverLicenseNumberField,
    labelData: driverLicenseNumberLabel,
    font: props.timesRomanFont,
    fontSize: labelFontSize,
    form: props.form,
    page: props.page
  });

  const driverLicenseStateLabel = {
    labelHeight: labelHeight,
    labelMargin: 30,
    labelText: "Driver's License State:"
  };
  const driverLicenseStateField = {
    fieldHeight: 20,
    fieldId: 'driver.license.state.field',
    fieldMargin: 150,
    fieldWidth: 150,
    fieldValue: props.formValues.patientDriverLicenseState
  };

  currentDimensions = PDFTextFieldComponent({
    currentDimensions: currentDimensions,
    fieldData: driverLicenseStateField,
    labelData: driverLicenseStateLabel,
    font: props.timesRomanFont,
    fontSize: labelFontSize,
    form: props.form,
    page: props.page,
    jumpLine: true
  });



  const emergencyContactNameLabel = {
    labelHeight: labelHeight,
    labelMargin: 30,
    labelText: "Emergency Contact Name:"
  };
  const emergencyContactNameField = {
    fieldHeight: 20,
    fieldId: 'emergency.contact.name.field',
    fieldMargin: 180,
    fieldWidth: 100,
    fieldValue: props.formValues.patientEmergencyContactName
  };

  currentDimensions = PDFTextFieldComponent({
    currentDimensions: currentDimensions,
    fieldData: emergencyContactNameField,
    labelData: emergencyContactNameLabel,
    font: props.timesRomanFont,
    fontSize: labelFontSize,
    form: props.form,
    page: props.page,
  });

  const emergencyContactRelationshipLabel = {
    labelHeight: labelHeight,
    labelMargin: 30,
    labelText: "Emergency Contact Relationship:"
  };
  const emergencyContactRelationshipField = {
    fieldHeight: 20,
    fieldId: 'emergency.contact.relationship.field',
    fieldMargin: 200,
    fieldWidth: 80,
    fieldValue: props.formValues.patientEmergencyContactRelationship
  };

  currentDimensions = PDFTextFieldComponent({
    currentDimensions: currentDimensions,
    fieldData: emergencyContactRelationshipField,
    labelData: emergencyContactRelationshipLabel,
    font: props.timesRomanFont,
    fontSize: labelFontSize,
    form: props.form,
    page: props.page,
    jumpLine: true
  });


  const emergencyContactEmailLabel = {
    labelHeight: labelHeight,
    labelMargin: 30,
    labelText: "Emergency Contact Email:"
  };
  const emergencyContactEmailField = {
    fieldHeight: 20,
    fieldId: 'emergency.contact.email.field',
    fieldMargin: 180,
    fieldWidth: 100,
    fieldValue: props.formValues.patientEmergencyContactEmail
  };

  currentDimensions = PDFTextFieldComponent({
    currentDimensions: currentDimensions,
    fieldData: emergencyContactEmailField,
    labelData: emergencyContactEmailLabel,
    font: props.timesRomanFont,
    fontSize: labelFontSize,
    form: props.form,
    page: props.page,
  });

  const emergencyContactPhoneLabel = {
    labelHeight: labelHeight,
    labelMargin: 30,
    labelText: "Emergency Contact Phone:"
  };
  const emergencyContactPhoneField = {
    fieldHeight: 20,
    fieldId: 'emergency.contact.phone.field',
    fieldMargin: 200,
    fieldWidth: 80,
    fieldValue: props.formValues.patientEmergencyContactPhone
  };

  currentDimensions = PDFTextFieldComponent({
    currentDimensions: currentDimensions,
    fieldData: emergencyContactPhoneField,
    labelData: emergencyContactPhoneLabel,
    font: props.timesRomanFont,
    fontSize: labelFontSize,
    form: props.form,
    page: props.page,
    jumpLine: true
  });

  const isReferringProviderLabel = {
    labelHeight: labelHeight,
    labelMargin: 30,
    labelText: "Were you referred to us by another provider?"
  };
  const isReferringProviderField = {
    fieldHeight: 10,
    fieldId: 'referred.provider.field',
    fieldMargin: 260,
    fieldWidth: 10,
    fieldValue: props.formValues.patientRefererredCheckbox
  };

  currentDimensions = PDFCheckboxFieldComponent({
    currentDimensions: currentDimensions,
    fieldData: isReferringProviderField,
    labelData: isReferringProviderLabel,
    font: props.timesRomanFont,
    fontSize: labelFontSize,
    form: props.form,
    page: props.page,
    jumpLine: true
  });
  const providerNameLabel = {
    labelHeight: labelHeight,
    labelMargin: 30,
    labelText: "Provider Name:"
  };
  const providerNameField = {
    fieldHeight: 20,
    fieldId: 'provider.name.field',
    fieldMargin: 180,
    fieldWidth: 100,
    fieldValue: props.formValues.providerName
  };

  currentDimensions = PDFTextFieldComponent({
    currentDimensions: currentDimensions,
    fieldData: providerNameField,
    labelData: providerNameLabel,
    font: props.timesRomanFont,
    fontSize: labelFontSize,
    form: props.form,
    page: props.page,
  });

  const providerSpecialityLabel = {
    labelHeight: labelHeight,
    labelMargin: 30,
    labelText: "Provider Speciality:"
  };
  const providerSpecialityField = {
    fieldHeight: 20,
    fieldId: 'provider.speciality.field',
    fieldMargin: 180,
    fieldWidth: 100,
    fieldValue: props.formValues.providerSpeciality
  };

  currentDimensions = PDFTextFieldComponent({
    currentDimensions: currentDimensions,
    fieldData: providerSpecialityField,
    labelData: providerSpecialityLabel,
    font: props.timesRomanFont,
    fontSize: labelFontSize,
    form: props.form,
    page: props.page,
  });

  return currentDimensions;
};

export default DemographicQuestionsSection;
