import { PDFFont, PDFForm, PDFPage, rgb } from "pdf-lib";
import { reduceOptions } from "utils/form/multiSelectOptions";
import PDFCheckboxFieldComponent from "../components/CheckboxFieldComponent";
import PDFTextFieldComponent from "../components/TextFieldComponent";
import { CurrentDimensions } from "../model/CurrentDimensions";
import { PDFSectionProps } from "../model/PDFSectionProps";


const SpineTreatmentHistorySection = (props: PDFSectionProps) => {
    // Header style
    const headerFontSize = props.headerFontSize;
    const textHeight = 4 * headerFontSize;

    // Label Style
    const labelFontSize = props.labelFontSize;
    const labelHeight = 4 * labelFontSize;

    // Keep track of the height and width
    var currentDimensions = { height: props.heightPointer + textHeight, width: 0 } as CurrentDimensions;
    props.page.drawText('Spine Treatment History Questions', {
        x: 30,
        y: props.height - currentDimensions.height,
        size: headerFontSize,
        font: props.timesRomanFont,
        color: rgb(0, 0, 0),
        opacity: 1,
    });

    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'seen.pm.specialist.field',
            fieldMargin: 260,
            fieldWidth: 10,
            fieldValue: props.formValues.patientSTHasSeenPm
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Have you seen a pain management specialist?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });


    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'pm.specialist.name.field',
            fieldMargin: 140,
            fieldWidth: 70,
            fieldValue: props.formValues.patientSTDoctorName
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "PM Doctor Name"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });


    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'pm.specialist.address.field',
            fieldMargin: 150,
            fieldWidth: 140,
            fieldValue: props.formValues.patientSTDoctorAddress
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "PM Doctor Address"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'pm.specialist.phone.field',
            fieldMargin: 140,
            fieldWidth: 70,
            fieldValue: props.formValues.patientSTDoctorPhone
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "PM Doctor Phone"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });



    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'had.inyections.field',
            fieldMargin: 230,
            fieldWidth: 10,
            fieldValue: props.formValues.patientSTHasHadInjections
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Have you had injections in the past?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });

    
    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'had.pain.relief.field',
            fieldMargin: 230,
            fieldWidth: 10,
            fieldValue: props.formValues.patientSTPainRelief
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Did you have pain relief with injections?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });


    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'how.many.inyections.field',
            fieldMargin: 140,
            fieldWidth: 70,
            fieldValue: props.formValues.patientSTHowManyInjections
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "How Many Injections?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });


    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'inyection.type.field',
            fieldMargin: 150,
            fieldWidth: 200,
            fieldValue: reduceOptions(props.formValues.patientSTInyectionTypes)
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "What type of injections?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });



    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'had.tried.pt.field',
            fieldMargin: 280,
            fieldWidth: 10,
            fieldValue: props.formValues.patientSTHasTriedPT
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Have you tried Physical Therapy in the past year?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });

    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'did.pt.help.field',
            fieldMargin: 110,
            fieldWidth: 10,
            fieldValue: props.formValues.patientSTPtHelped
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Did it help?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });

    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'was.pt.too.painful',
            fieldMargin: 220,
            fieldWidth: 10,
            fieldValue: props.formValues.patientSTPTTooPainful
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Was PT was too painful to continue?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });



    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'pt.name.field',
            fieldMargin: 80,
            fieldWidth: 120,
            fieldValue: props.formValues.patientSTPTName
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "PT Name:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });


    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'pt.practice.name.field',
            fieldMargin: 150,
            fieldWidth: 200,
            fieldValue: props.formValues.patientSTPTPracticeName
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "PT Practice Name:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });



    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'pt.phone.field',
            fieldMargin: 80,
            fieldWidth: 120,
            fieldValue: props.formValues.patientSTPTPhone
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "PT Phone:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });


    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'pt.practice.address.field',
            fieldMargin: 150,
            fieldWidth: 200,
            fieldValue: props.formValues.patientSTPTAddress
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "PT Address:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });


    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'how.long.pt.field',
            fieldMargin: 140,
            fieldWidth: 70,
            fieldValue: props.formValues.patientSTPTWeekNumber
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "How many weeks?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });


    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'how.many.visits.field',
            fieldMargin: 150,
            fieldWidth: 200,
            fieldValue: props.formValues.patientSTPTVisitsNumber
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "How many visits?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'pt.comments.field',
            fieldMargin: 140,
            fieldWidth: 420,
            fieldValue: props.formValues.patientSTPTComments
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Comments:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });



    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'had.tried.chiro.field',
            fieldMargin: 280,
            fieldWidth: 10,
            fieldValue: props.formValues.patientSTHasTriedChiro
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Have you tried Chiropractic in the past year?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });

    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'did.chiro.help.field',
            fieldMargin: 110,
            fieldWidth: 10,
            fieldValue: props.formValues.patientSTChiroHelped
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Did it help?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });


    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'chiro.name.field',
            fieldMargin: 140,
            fieldWidth: 70,
            fieldValue: props.formValues.patientSTChiroName
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Chiropractor Name:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });


    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'chiro.practice.name.field',
            fieldMargin: 190,
            fieldWidth: 150,
            fieldValue: props.formValues.patientSTChiroPracticeName
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Chiropractor Practice Name:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });



    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'chiro.phone.field',
            fieldMargin: 140,
            fieldWidth: 70,
            fieldValue: props.formValues.patientSTChiroPhone
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Chiropractor Phone:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });


    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'chiro.practice.address.field',
            fieldMargin: 150,
            fieldWidth: 200,
            fieldValue: props.formValues.patientSTChiroAddress
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Chiropractor Address:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });


    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'how.long.chiro.field',
            fieldMargin: 140,
            fieldWidth: 70,
            fieldValue: props.formValues.patientSTChiroWeekNumber
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "How many weeks?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });


    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'how.many.visits.chiro.field',
            fieldMargin: 150,
            fieldWidth: 200,
            fieldValue: props.formValues.patientSTChiroVisitsNumber
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "How many visits?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'chiro.comments.field',
            fieldMargin: 140,
            fieldWidth: 420,
            fieldValue: props.formValues.patientSTChiroComments
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Comments:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });
    return currentDimensions;
};

export default SpineTreatmentHistorySection;
