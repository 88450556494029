import CreateAccount from 'pages/auth/views/createAccount/CreateAccount';
import ResetPassword from 'pages/auth/views/reset-password/ResetPassword';
import SignIn from 'pages/auth/views/signIn/SignIn';
import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import TwoFactorAuth from './views/twoFactorAuth/TwoFactorAuth';

export const AUTH_ROUTES = {
  root: '/auth',
  signIn: '/sign-in',
  createAccount: '/create-account',
  resetPassword: '/reset-password',
  twoFactor: '/twoFactor',
};

const AuthRouter: React.FC = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.path}/`}>
        <Redirect to={`${match.path}${AUTH_ROUTES.signIn}`}></Redirect>
      </Route>
      <Route path={`${match.path}${AUTH_ROUTES.signIn}`} component={SignIn}></Route>
      <Route path={`${match.path}${AUTH_ROUTES.createAccount}`} component={CreateAccount}></Route>
      <Route path={`${match.path}${AUTH_ROUTES.resetPassword}`} component={ResetPassword}></Route>
      <Route path={`${match.path}${AUTH_ROUTES.twoFactor}`} component={TwoFactorAuth}></Route>
    </Switch>
  );
};

export default AuthRouter;
