import { Box, Button, Card, Typography } from '@material-ui/core';
import { CREATE_DOCTOR_ERR } from 'api/auth.api';
import WarnButton from 'components/atoms/warnButton/WarnButton';
import { ErrorMessage, Formik } from 'formik';
import { AUTH_ROUTES } from 'pages/auth/AuthRouter';
import ProviderAccessCard from 'pages/auth/components/ProviderAccessCard';
import React from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { isCreateAccountLoadingSelector } from 'store/auth/auth.selectors';
//import { createAccountThunkAction } from 'store/auth/auth.slice';
import { useAppDispatch } from 'store/store';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { CreateAccountSchema } from 'validations';

export interface CreateAccountForm {
  name: string;
  lastname: string;
  email: string;
  language: string;
  phone: string;
  securityToken: string;
}

const initialValues: CreateAccountForm = {
  name: '',
  lastname: '',
  email: '',
  phone: '',
  language: '',
  securityToken: '',
};

const MySwal = withReactContent(Swal);

const CreateAccount: React.FC = () => {
  const dispatch = useAppDispatch();
  const isLoading = useSelector(isCreateAccountLoadingSelector);
  const history = useHistory();

  const handleCreateAccount = (values: CreateAccountForm) => {
    /*
    
    if (!isLoading) {
      dispatch(
        createAccountThunkAction({
          data: values,
          onSuccess: (res) => {
            MySwal.fire({
              title: 'Account Created',
              text: '',
              icon: 'success',
              confirmButtonText: 'Ok',
            }).then(() => {
              history.push(`${AUTH_ROUTES.root}${AUTH_ROUTES.signIn}`);
            });

            return Promise.resolve(res);
          },
          onError: (err) => {
            switch (err.message) {
              case CREATE_DOCTOR_ERR.dupe: {
                MySwal.fire({
                  title: 'Error',
                  text:
                    'That email already exists in the system. Please contact support or try another email address.',
                  icon: 'error',
                });
                break;
              }
              case CREATE_DOCTOR_ERR.db: {
                MySwal.fire({
                  title: 'Error',
                  text:
                    'There was a server error. Please try again or contact us via support@careexpand.com.',
                  icon: 'error',
                });
                break;
              }
              case CREATE_DOCTOR_ERR.rightToken: {
                MySwal.fire({
                  title: 'Error',
                  text: 'The correct security token is required.',
                  icon: 'error',
                });
                break;
              }
              default: {
                MySwal.fire({
                  title: 'Error',
                  text:
                    'There was a server error. Please try again or contact us via support@careexpand.com.',
                  icon: 'error',
                });
                break;
              }
            }
            return Promise.reject(err);
          },
        })
      );
    }
    */
  };

  return (
    <Container>
      <Row className="align-items-center mt-5">
        <Col md={6}>
          <ProviderAccessCard></ProviderAccessCard>
        </Col>
        <Col>
          <Card>
            <Typography className="text-center">Forgot your password?</Typography>
            <p className="text-center">
              <small>All fields are required</small>
            </p>
            <Formik
              initialValues={initialValues}
              validationSchema={CreateAccountSchema}
              onSubmit={handleCreateAccount}
            >
              {({ values, handleChange, handleBlur, handleSubmit, isValid, touched, errors }) => {
                const canSubmit = !isLoading && isValid;

                return (
                  <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formFirstName">
                      <Form.Control
                        name="name"
                        placeholder="First Name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        isValid={touched.name && !errors.name}
                        isInvalid={touched.name && !!errors.name}
                      />
                      <Form.Control.Feedback type="invalid">
                        <ErrorMessage name="name" component="div" data-testid="first-error" />
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formLastName">
                      <Form.Control
                        name="lastname"
                        placeholder="Last Name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastname}
                        isValid={touched.lastname && !errors.lastname}
                        isInvalid={touched.lastname && !!errors.lastname}
                      />
                      <Form.Control.Feedback type="invalid">
                        <ErrorMessage name="lastname" component="div" data-testid="last-error" />
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formEmail">
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="Email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        isValid={touched.email && !errors.email}
                        isInvalid={touched.email && !!errors.email}
                      />
                      <Form.Control.Feedback type="invalid">
                        <ErrorMessage name="email" component="div" data-testid="email-error" />
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formPhone">
                      <Form.Control
                        type="phone"
                        name="phone"
                        placeholder="Phone Number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phone}
                        isValid={touched.phone && !errors.phone}
                        isInvalid={touched.phone && !!errors.phone}
                      />
                      <Form.Control.Feedback type="invalid">
                        <ErrorMessage name="phone" component="div" data-testid="phone-error" />
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formLanguage">
                      <Form.Control
                        as="select"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="language"
                        value={values.language}
                        isValid={touched.language && !errors.language}
                        isInvalid={touched.language && !!errors.language}
                        data-testid="select-language"
                      >
                        <option value="en-us">English (US)</option>
                        <option value="es-es">Español (España)</option>
                        <option value="es-mx">Español (Mexico)</option>
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        <ErrorMessage
                          name="language"
                          component="div"
                          data-testid="language-error"
                        />
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formSecurityToken">
                      <Form.Control
                        name="securityToken"
                        placeholder="Security Token"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.securityToken}
                        isValid={touched.securityToken && !errors.securityToken}
                        isInvalid={touched.securityToken && !!errors.securityToken}
                      />
                      <Form.Control.Feedback type="invalid">
                        <ErrorMessage
                          name="securityToken"
                          component="div"
                          data-testid="token-error"
                        />
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Box display="flex" justifyContent="space-around">
                      <Button disabled={!canSubmit} type="submit">
                        Register
                      </Button>
                      <WarnButton component={Link} to={AUTH_ROUTES.root + AUTH_ROUTES.signIn}>
                        Go Back
                      </WarnButton>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateAccount;
