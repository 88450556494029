import { QueryParamsResolver, resolveUrl, ServiceNames } from 'api/gatewayEndpoints';
import { apiReport } from 'utils/sentryReports';
import axios, { AxiosError, CancelToken } from 'axios';
import { useHostConfigs } from 'utils/hooks/useHostConfigs';

//const hostConfig = useHostConfigs();

export const baseAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_SERVER_URL,
  // One minute timeout to avoid ghost calls getting
  // BEWARE, this can break file uploads, we need to change this param manually for fileUpload endpoints
  timeout: 60000,
  headers: { 'Content-Type': 'application/json' },
});

export const baseAxiosInstanceMultipart = axios.create({
  baseURL: process.env.REACT_APP_API_SERVER_URL,
  // One minute timeout to avoid ghost calls getting
  // Since it breaks fileUploads, we assign the correct header
  timeout: 60000,
  headers: { 'Content-Type': 'multipart/form-data' },
});

export const updateApiToken = (token: string) => {
  baseAxiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

const handleError = (error: AxiosError) => {
  apiReport(error);
  return Promise.reject(error.response);
};

baseAxiosInstance.interceptors.response.use(undefined, handleError);

export const getCancelRequestToken = () => axios.CancelToken.source();

export interface WithBodyData<T> extends QueryParamsResolver {
  data: T;
  cancelToken?: CancelToken;
}

export const doGet = <T>(
  serviceName: ServiceNames,
  config: QueryParamsResolver,
  cancelToken?: CancelToken
) => baseAxiosInstance.get<T>(resolveUrl(serviceName, config), { cancelToken, responseType: config.responseType });

export const doPost = <T, BodyPayload>(
  serviceName: ServiceNames,
  config: WithBodyData<BodyPayload>
) =>
  baseAxiosInstance.post<T>(resolveUrl(serviceName, config), config.data, {
    cancelToken: config.cancelToken,
  });

  export const doPostMultipart = <T, BodyPayload>(
    serviceName: ServiceNames,
    config: WithBodyData<BodyPayload>
  ) =>
    baseAxiosInstanceMultipart.post<T>(resolveUrl(serviceName, config), config.data, {
      cancelToken: config.cancelToken,
    });

export const doPut = <T, BodyPayload>(
  serviceName: ServiceNames,
  config: WithBodyData<BodyPayload>
) =>
  baseAxiosInstance.put<T>(resolveUrl(serviceName, config), config.data, {
    cancelToken: config.cancelToken,
  });

export const doPatch = async <T, BodyPayload>(
  serviceName: ServiceNames,
  config: WithBodyData<BodyPayload>
) =>
  baseAxiosInstance.patch<T>(resolveUrl(serviceName, config), config.data, {
    cancelToken: config.cancelToken,
  });

export const doDelete = (
  serviceName: ServiceNames,
  config: QueryParamsResolver,
  cancelToken?: CancelToken
) =>
  baseAxiosInstance.delete(resolveUrl(serviceName, config), {
    cancelToken,
  });
