import { useEffect, useState } from "react";
// Components
import {
  Typography,
  Card,
  Button,
  CardHeader,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Row, Col, Form, Container } from "react-bootstrap";
import MultiSelect from "react-multi-select-component";
// Types
import {
  FormikProps,
  FormikValues,
  ErrorMessage,
  useFormikContext,
} from "formik";
// Util
import {
  getInputProps,
  getInputPropsMultiSelect,
  getNumberOfElements,
  MultiSelectStateProps,
  StatePropsType,
} from "utils/constants/forms";
import { spineAreaOptionsArray } from "utils/form/multiSelectOptions";
import { Option } from "react-multi-select-component/dist/lib/interfaces";
import "components/forms/steps/imagingStudiesStep/ImagingStudiesStep.styles.css";
//pages/documents/views/HealthHistoryQuestions/steps/imagingStudiesStep/ImagingStudiesStep.styles.css";
// Dropzone
import Dropzone, {
  IDropzoneProps,
  ILayoutProps,
} from "react-dropzone-uploader";
import "components/dropzone/Dropzone.styles.css";
import { useAppDispatch } from "store/store";
import { saveDocumentThunkAction } from "store/documents/documents.slice";
// Swal
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

type FirstNameProps = {
  form: FormikProps<FormikValues>;
  patientName: "ImagingStudiesQuestions";
};

const ImagingStudiesQuestions = ({ form, patientName }: FirstNameProps) => {
  // Grab what we need from formik without prop-drilling
  const { getFieldProps } = useFormikContext();

  const [mriArray, setMriArray] = useState<any>([]);
  const [fillInMRI, setFillInMRI] = useState(true);
  useEffect(() => {
    if (fillInMRI) {
      setFillInMRI(false);
      var mMriArray = [];
      var mriCount: number = getNumberOfElements(
        "patientISMriArea",
        getFieldProps
      );
      for (var i = 0; i < mriCount; i++) {
        mMriArray.push(i);
      }
      if (!mMriArray.length) {
        mMriArray.push(0);
      }
      setMriArray(mMriArray);
    }
  }, [fillInMRI]);

  const [ctScanArray, setCtScanArray] = useState<any>([]);
  const [fillInCtScan, setFillInCtScan] = useState(true);
  useEffect(() => {
    if (fillInCtScan) {
      setFillInCtScan(false);
      var mCtScanArray = [];
      var mriCount: number = getNumberOfElements(
        "patientISCtArea",
        getFieldProps
      );
      for (var i = 0; i < mriCount; i++) {
        mCtScanArray.push(i);
      }
      if (!mCtScanArray.length) {
        mCtScanArray.push(0);
      }
      setCtScanArray(mCtScanArray);
    }
  }, [fillInCtScan]);

  const [xRayArray, setXRayArray] = useState<any>([]);
  const [fillInXRay, setFillInXRay] = useState(true);
  useEffect(() => {
    if (fillInXRay) {
      setFillInXRay(false);
      var mXRayArray = [];
      var mriCount: number = getNumberOfElements(
        "patientISXrayArea",
        getFieldProps
      );
      for (var i = 0; i < mriCount; i++) {
        mXRayArray.push(i);
      }
      if (!mXRayArray.length) {
        mXRayArray.push(0);
      }
      setXRayArray(mXRayArray);
    }
  }, [fillInXRay]);

  return (
    <>
      <Container className="mt-4">
        <Row className="align-items-center">
          <Col>
            <Card>
              <Row>
                <Col>
                  <Typography variant="h4" className="text-center mt-5">
                    Imaging Studies Questions
                  </Typography>
                </Col>
              </Row>
              <Form.Row className="mt-5 mr-3 ml-3">
                <Col xs={12} md={6} className="my-1">
                  <Form.Label column>
                    Have you had an MRI? Please tell us about your most recent
                    one.
                  </Form.Label>
                </Col>
                <Col xs={12} md={5} className="my-1">
                  <Form.Control
                    as="select"
                    custom
                    type="text"
                    placeholder="Select if you had MRI"
                    {...getInputProps("patientISHadMri", form)}
                  >
                    <option>-- Select --</option>
                    <option>Yes</option>
                    <option>No</option>
                  </Form.Control>
                  <ErrorMessage
                    name="patientISHadMri"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
                {form.getFieldProps("patientISHadMri").value == "Yes" ? (
                  <>
                    <Col xs={12} md={1} className="my-1 pt-1">
                      <Button
                        color="primary"
                        onClick={() => {
                          var newItem = (mriArray[mriArray.length - 1] +
                            1) as number;
                          if (isNaN(newItem)) {
                            newItem = 0;
                          }
                          setMriArray([...mriArray, newItem]);
                          return;
                        }}
                      >
                        <i className="fas fa-plus"></i>
                      </Button>
                    </Col>
                  </>
                ) : null}
              </Form.Row>
              {form.getFieldProps("patientISHadMri").value == "Yes" ? (
                <>
                  {mriArray.map((item: number, i: number) => {
                    const stateProps = {
                      form,
                      state: [mriArray, setMriArray],
                      index: item,
                    } as MultiSelectStateProps;

                    return <MRISection key={i} {...stateProps} />;
                  })}
                </>
              ) : null}
              <Form.Row className="mt-5 mr-3 ml-3">
                <Col xs={12} md={6} className="my-1">
                  <Form.Label column title="It's ok if you don't recall an exact date, just make your best guess">
                    Have you had a CT Scan? Please tell us about your most
                    recent one.
                  </Form.Label>
                </Col>
                <Col xs={12} md={5} className="my-1">
                  <Form.Control
                    as="select"
                    custom
                    type="text"
                    placeholder="Select if you had CT Scan"
                    {...getInputProps("patientISHadCt", form)}
                  >
                    <option>-- Select --</option>
                    <option>Yes</option>
                    <option>No</option>
                  </Form.Control>
                  <ErrorMessage
                    name="patientISHadCt"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
                {form.getFieldProps("patientISHadCt").value == "Yes" ? (
                  <>
                    <Col xs={12} md={1} className="my-1 pt-1">
                      <Button
                        color="primary"
                        onClick={() => {
                          var newItem = (ctScanArray[ctScanArray.length - 1] +
                            1) as number;
                          if (isNaN(newItem)) {
                            newItem = 0;
                          }
                          setCtScanArray([...ctScanArray, newItem]);
                          return;
                        }}
                      >
                        <i className="fas fa-plus"></i>
                      </Button></Col>
                  </>
                ) : null}
              </Form.Row>
              {form.getFieldProps("patientISHadCt").value == "Yes" ? (
                <>
                  {ctScanArray.map((item: number, i: number) => {
                    const stateProps = {
                      form,
                      state: [ctScanArray, setCtScanArray],
                      index: item,
                    } as MultiSelectStateProps;

                    return <CTScanSection key={i} {...stateProps} />;
                  })}
                </>
              ) : null}
              <Form.Row className="mt-5 mr-3 ml-3">
                <Col xs={12} md={6} className="my-1">
                  <Form.Label column>
                    Have you had an XRay of the spine? Please tell us about your
                    most recent one.
                  </Form.Label>
                </Col>
                <Col xs={12} md={5} className="my-1">
                  <Form.Control
                    as="select"
                    custom
                    type="text"
                    placeholder="Select if you had XRay"
                    {...getInputProps("patientISHadXray", form)}
                  >
                    <option>-- Select --</option>
                    <option>Yes</option>
                    <option>No</option>
                  </Form.Control>
                  <ErrorMessage
                    name="patientISHadXray"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
                {form.getFieldProps("patientISHadXray").value == "Yes" ? (
                  <>
                    <Col xs={12} md={1} className="my-1 pt-1">
                      <Button
                        color="primary"
                        onClick={() => {
                          var newItem = (xRayArray[xRayArray.length - 1] +
                            1) as number;
                          if (isNaN(newItem)) {
                            newItem = 0;
                          }
                          setXRayArray([...xRayArray, newItem]);
                          return;
                        }}
                      >
                        <i className="fas fa-plus"></i>
                      </Button></Col>

                  </>
                ) : null}
              </Form.Row>
              {form.getFieldProps("patientISHadXray").value == "Yes" ? (
                <>

                  {xRayArray.map((item: number, i: number) => {
                    const stateProps = {
                      form,
                      state: [xRayArray, setXRayArray],
                      index: item,
                    } as MultiSelectStateProps;

                    return <XRaySection key={i} {...stateProps} />;
                  })}
                </>
              ) : null}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ImagingStudiesQuestions;

const MRISection = ({
  form,
  state: [mriArray, setMriArray],
  index,
}: StatePropsType) => {
  const { handleChange, getFieldProps, setFieldValue } = useFormikContext();

  const caseId = getFieldProps("caseId").value;
  const contactId = getFieldProps("contactId").value;
  const generationType = getFieldProps("generationType").value;
  const dispatch = useAppDispatch();
  const MySwal = withReactContent(Swal);
  const spineAreaOptions = spineAreaOptionsArray;
  const [mriSpineAreaSelected, setMriSpineAreaSelected] = useState(
    getFieldProps("patientISMriArea" + index).value || []
  );

  const getUploadParams: IDropzoneProps["getUploadParams"] = ({
    file,
    meta,
  }) => {
    uploadToServer(file, file.type, file.name, "MRI Report");
    return {
      url: "https://httpbin.org/post",
    };
  };

  const handleSubmit: IDropzoneProps["onSubmit"] = (files, allFiles) => {
    console.log(files.map((f) => f.meta));
    allFiles.forEach((f) => f.remove());
  };

  const handleChangeStatus: IDropzoneProps["onChangeStatus"] = (
    { meta },
    status
  ) => {
    console.log(status, meta);
  };
  function uploadToServer(
    blob: Blob,
    type: string,
    name: string,
    fileDesc: string
  ) {
    //var blob = new Blob([arrayBuffer], { type: type });
    dispatch(
      saveDocumentThunkAction({
        data: {
          file: { fileData: blob, filename: name },
          contactId: contactId,
          caseId: caseId,
          fileTypeId: 5,
          fileDescription: fileDesc,
        },
        onSuccess: (res) => {
          return Promise.resolve(res);
        },
        onError: (err) => {
          MySwal.fire("Error", "Couldn't save File", "warning");
          return Promise.reject(err);
        },
      })
    );
  }

  return (
    <Card className="mt-2" variant="outlined">
      <CardHeader
        action={
          <Button
            color="secondary"
            onClick={() => {
              // Remove item from array
              setMriArray(
                mriArray.filter(function (item: number) {
                  return item !== index;
                })
              );
              // Set all values to null
              setMriSpineAreaSelected([]);
              form.setFieldValue("patientISMriArea" + index, null);
              form.setFieldValue("patientISMriDate" + index, null);
              form.setFieldValue("patientISMriReport" + index, null);
              form.setFieldValue("patientISMriCenterName" + index, null);
              form.setFieldValue("patientISMriCenterPhone" + index, null);
              form.setFieldValue("patientISMriCenterAddress" + index, null);

              return;
            }}
          >
            <i className="fas fa-trash-alt"></i>
          </Button>
        }
      />
      <Form.Row className="mr-3 ml-3 ">
        <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
          <Form.Label column>Area of Spine</Form.Label>
        </Col>
        <Col xs={12} md={6} className="my-1">
          <Form.Control
            as="select"
            custom
            type="text"
            placeholder="Area of Spine"
            {...getInputProps("patientISMriArea" + index, form)}
          >
            <option>-- Select area --</option>
            {spineAreaOptions.map((item: Option, i: number) => {
              return <option key={i}>{item.label}</option>;
            })}
          </Form.Control>
        </Col>
        <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
          <Form.Label column title="It's ok if you don't recall an exact date, just make your best guess">Date</Form.Label>
        </Col>
        <Col xs={10} md={5} className="my-1">
          <Form.Control
            type="date"
            placeholder="Date" title="It's ok if you don't recall an exact date, just make your best guess"
            {...getInputProps("patientISMriDate" + index, form)}
          />
          <ErrorMessage
            name={`patientISMriDate${index}`}
            component="div"
            className="text-red-500 text-xs italic"
          />
        </Col>
        <Col xs={2} md={1} className="my-1 p-2">
          <Tooltip arrow placement={"top"} title="It's ok if you don't recall an exact date, just make your best guess">
            <i className="fal fa-info-circle"></i>
          </Tooltip>
        </Col>
        <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
          <Form.Label column >
            Please upload the Written MRI Report if you have it.
          </Form.Label>
        </Col>
        <Col xs={12} md={6} className="my-1">
          <Dropzone
            maxFiles={1}
            multiple={false}
            getUploadParams={getUploadParams}
            onChangeStatus={handleChangeStatus}
            canCancel={false}
            //onSubmit={handleSubmit}
            accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
            inputContent={(files, extra) =>
              extra.reject ? "Image or files only" : "Click to add or drag File"
            }
            styles={{
              dropzoneReject: {
                borderColor: "red",
                backgroundColor: "#DAA",
              },
              inputLabel: (files, extra) =>
                extra.reject ? { color: "red" } : {},
            }}
          />
        </Col>
        <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
          <Form.Label column>Imaging Center Name</Form.Label>
        </Col>
        <Col xs={12} md={6} className="my-1">
          <Form.Control
            type="text"
            placeholder={`Imaging Center Name`}
            {...getInputProps("patientISMriCenterName" + index, form)}
          />
          <ErrorMessage
            name={`patientISMriCenterName${index}`}
            component="div"
            className="text-red-500 text-xs italic"
          />
        </Col>
        <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
          <Form.Label column>Imaging Center Phone</Form.Label>
        </Col>
        <Col xs={12} md={6} className="my-1">
          <Form.Control
            type="text"
            placeholder={`Imaging Center Phone`}
            {...getInputProps("patientISMriCenterPhone" + index, form)}
          />
          <ErrorMessage
            name={`patientISMriCenterPhone${index}`}
            component="div"
            className="text-red-500 text-xs italic"
          />
        </Col>
        <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
          <Form.Label column>Imaging Center Address</Form.Label>
        </Col>
        <Col xs={12} md={6} className="my-1">
          <Form.Control
            type="text"
            placeholder={`Imaging Center Address`}
            {...getInputProps("patientISMriCenterAddress" + index, form)}
          />
          <ErrorMessage
            name={`patientISMriCenterAddress${index}`}
            component="div"
            className="text-red-500 text-xs italic"
          />
        </Col>
      </Form.Row>
    </Card>
  );
};

const CTScanSection = ({
  form,
  state: [ctScanArray, setCtScanArray],
  index,
}: StatePropsType) => {
  const { getFieldProps, setFieldValue } = useFormikContext();
  const spineAreaOptions = spineAreaOptionsArray;
  const [ctScanAreaSelected, setCtScanAreaSelected] = useState(
    getFieldProps("patientISCtArea" + index).value || []
  );

  const caseId = getFieldProps("caseId").value;
  const contactId = getFieldProps("contactId").value;
  const generationType = getFieldProps("generationType").value;
  const dispatch = useAppDispatch();
  const MySwal = withReactContent(Swal);

  const getUploadParams: IDropzoneProps["getUploadParams"] = ({
    file,
    meta,
  }) => {
    uploadToServer(file, file.type, file.name, "CT Report");
    return {
      url: "https://httpbin.org/post",
    };
  };
  const handleSubmit: IDropzoneProps["onSubmit"] = (files, allFiles) => {
    console.log(files.map((f) => f.meta));
    allFiles.forEach((f) => f.remove());
  };

  const handleChangeStatus: IDropzoneProps["onChangeStatus"] = (
    { meta },
    status
  ) => {
    console.log(status, meta);
  };

  function uploadToServer(
    blob: Blob,
    type: string,
    name: string,
    fileDesc: string
  ) {
    //var blob = new Blob([arrayBuffer], { type: type });
    dispatch(
      saveDocumentThunkAction({
        data: {
          file: { fileData: blob, filename: name },
          contactId: contactId,
          caseId: caseId,
          fileTypeId: 5,
          fileDescription: fileDesc,
        },
        onSuccess: (res) => {
          return Promise.resolve(res);
        },
        onError: (err) => {
          MySwal.fire("Error", "Couldn't save File", "warning");
          return Promise.reject(err);
        },
      })
    );
  }
  return (
    <Card className="mt-2" variant="outlined">
      <CardHeader
        action={
          <Button
            color="secondary"
            onClick={() => {
              // Remove item from array
              setCtScanArray(
                ctScanArray.filter(function (item: number) {
                  return item !== index;
                })
              );
              // Set all values to null
              setCtScanAreaSelected([]);
              form.setFieldValue("patientISCtArea" + index, null);
              form.setFieldValue("patientISCtDate" + index, null);
              form.setFieldValue("patientISCtReport" + index, null);
              form.setFieldValue("patientISCtCenterName" + index, null);
              form.setFieldValue("patientISCtCenterPhone" + index, null);
              form.setFieldValue("patientISCtCenterAddress" + index, null);

              return;
            }}
          >
            <i className="fas fa-trash-alt"></i>
          </Button>
        }
      />
      <Form.Row className="mr-3 ml-3">
        <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
          <Form.Label column>Area of Spine</Form.Label>
        </Col>
        <Col xs={12} md={6} className="my-1">
          <Form.Control
            as="select"
            custom
            type="text"
            placeholder="Area of most Concern"
            {...getInputProps("patientISCtArea" + index, form)}
          >
            <option>-- Select area --</option>
            {spineAreaOptions.map((item: Option, i: number) => {
              return <option key={i}>{item.label}</option>;
            })}
          </Form.Control>
        </Col>
        <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
          <Form.Label column>Date</Form.Label>
        </Col>
        <Col xs={10} md={5} className="my-1">
          <Form.Control
            type="date"
            placeholder="Date"
            {...getInputProps("patientISCtDate" + index, form)}
          />
          <ErrorMessage
            name={`patientISCtDate${index}`}
            component="div"
            className="text-red-500 text-xs italic"
          />
        </Col>
        <Col xs={2} md={1} className="my-1 p-2">
          <Tooltip arrow placement={"top"} title="It's ok if you don't recall an exact date, just make your best guess">
            <i className="fal fa-info-circle"></i>
          </Tooltip>
        </Col>
        <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
          <Form.Label column>
            Please upload the Written CT Scan Report if you have it.
          </Form.Label>
        </Col>
        <Col xs={12} md={6} className="my-1">
          <Dropzone
            maxFiles={1}
            multiple={false}
            getUploadParams={getUploadParams}
            onChangeStatus={handleChangeStatus}
            canCancel={false}
            //onSubmit={handleSubmit}
            accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
            inputContent={(files, extra) =>
              extra.reject ? "Image or files only" : "Click to add or drag File"
            }
            styles={{
              dropzoneReject: {
                borderColor: "red",
                backgroundColor: "#DAA",
              },
              inputLabel: (files, extra) =>
                extra.reject ? { color: "red" } : {},
            }}
          />
        </Col>
        <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
          <Form.Label column>Imaging Center Name</Form.Label>
        </Col>
        <Col xs={12} md={6} className="my-1">
          <Form.Control
            type="text"
            placeholder={`Imaging Center Name`}
            {...getInputProps("patientISCtCenterName" + index, form)}
          />
          <ErrorMessage
            name={`patientISCtCenterName${index}`}
            component="div"
            className="text-red-500 text-xs italic"
          />
        </Col>
        <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
          <Form.Label column>Imaging Center Phone</Form.Label>
        </Col>
        <Col xs={12} md={6} className="my-1">
          <Form.Control
            type="text"
            placeholder={`Imaging Center Phone`}
            {...getInputProps("patientISCtCenterPhone" + index, form)}
          />
          <ErrorMessage
            name={`patientISCtCenterPhone${index}`}
            component="div"
            className="text-red-500 text-xs italic"
          />
        </Col>
        <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
          <Form.Label column>Imaging Center Address</Form.Label>
        </Col>
        <Col xs={12} md={6} className="my-1">
          <Form.Control
            type="text"
            placeholder={`Imaging Center Address`}
            {...getInputProps("patientISCtCenterAddress" + index, form)}
          />
          <ErrorMessage
            name={`patientISCtCenterAddress${index}`}
            component="div"
            className="text-red-500 text-xs italic"
          />
        </Col>
      </Form.Row>
    </Card>
  );
};

const XRaySection = ({
  form,
  state: [xRayArray, setXRayArray],
  index,
}: StatePropsType) => {
  const { handleChange, getFieldProps, setFieldValue } = useFormikContext();
  const caseId = getFieldProps("caseId").value;
  const contactId = getFieldProps("contactId").value;
  const generationType = getFieldProps("generationType").value;
  const dispatch = useAppDispatch();
  const MySwal = withReactContent(Swal);

  const spineAreaOptions = spineAreaOptionsArray;
  const [xrayAreaSelected, setXrayAreaSelected] = useState(
    getFieldProps("patientISXrayArea" + index).value || []
  );
  const getUploadParams: IDropzoneProps["getUploadParams"] = ({
    file,
    meta,
  }) => {
    uploadToServer(file, file.type, file.name, "CT Report");
    return {
      url: "https://httpbin.org/post",
    };
  };

  const handleSubmit: IDropzoneProps["onSubmit"] = (files, allFiles) => {
    console.log(files.map((f) => f.meta));
    allFiles.forEach((f) => f.remove());
  };

  const handleChangeStatus: IDropzoneProps["onChangeStatus"] = (
    { meta },
    status
  ) => {
    console.log(status, meta);
  };

  function uploadToServer(
    blob: Blob,
    type: string,
    name: string,
    fileDesc: string
  ) {
    //var blob = new Blob([arrayBuffer], { type: type });
    dispatch(
      saveDocumentThunkAction({
        data: {
          file: { fileData: blob, filename: name },
          contactId: contactId,
          caseId: caseId,
          fileTypeId: 5,
          fileDescription: fileDesc,
        },
        onSuccess: (res) => {
          return Promise.resolve(res);
        },
        onError: (err) => {
          MySwal.fire("Error", "Couldn't save File", "warning");
          return Promise.reject(err);
        },
      })
    );
  }

  return (
    <Card className="mt-2" variant="outlined">
      <CardHeader
        action={
          <Button
            color="secondary"
            onClick={() => {
              // Remove item from array
              setXRayArray(
                xRayArray.filter(function (item: number) {
                  return item !== index;
                })
              );
              // Set all values to null
              setXrayAreaSelected([]);
              form.setFieldValue("patientISXrayArea" + index, null);
              form.setFieldValue("patientISXrayDate" + index, null);
              form.setFieldValue("patientISXRayReport" + index, null);
              form.setFieldValue("patientISXRayCenterName" + index, null);
              form.setFieldValue("patientISXRayCenterPhone" + index, null);
              form.setFieldValue("patientISXRayCenterAddress" + index, null);

              return;
            }}
          >
            <i className="fas fa-trash-alt"></i>
          </Button>
        }
      />
      <Form.Row className="mr-3 ml-3">
        <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
          <Form.Label column>Area of Spine</Form.Label>
        </Col>
        <Col xs={12} md={6} className="my-1">
          <Form.Control
            as="select"
            custom
            type="text"
            placeholder="Area of most Concern"
            {...getInputProps("patientISXrayArea" + index, form)}
          >
            <option>-- Select area --</option>
            {spineAreaOptions.map((item: Option, i: number) => {
              return <option key={i}>{item.label}</option>;
            })}
          </Form.Control>
        </Col>
        <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
          <Form.Label column>Date</Form.Label>
        </Col>
        <Col xs={10} md={5} className="my-1">
          <Form.Control
            type="date"
            placeholder="Date"
            {...getInputProps("patientISXrayDate" + index, form)}
          />
          <ErrorMessage
            name={`patientISXrayDate${index}`}
            component="div"
            className="text-red-500 text-xs italic"
          />
        </Col>
        <Col xs={2} md={1} className="my-1 p-2">
          <Tooltip arrow placement={"top"} title="It's ok if you don't recall an exact date, just make your best guess">
            <i className="fal fa-info-circle"></i>
          </Tooltip>
        </Col>
        <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
          <Form.Label column>
            Please upload the Written XRay Report if you have it.
          </Form.Label>
        </Col>
        <Col xs={12} md={6} className="my-1">
          <Dropzone
            maxFiles={1}
            multiple={false}
            getUploadParams={getUploadParams}
            onChangeStatus={handleChangeStatus}
            canCancel={false}
            //onSubmit={handleSubmit}
            accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
            inputContent={(files, extra) =>
              extra.reject ? "Image or files only" : "Click to add or drag File"
            }
            styles={{
              dropzoneReject: {
                borderColor: "red",
                backgroundColor: "#DAA",
              },
              inputLabel: (files, extra) =>
                extra.reject ? { color: "red" } : {},
            }}
          />
        </Col>
        <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
          <Form.Label column>Imaging Center Name</Form.Label>
        </Col>
        <Col xs={12} md={6} className="my-1">
          <Form.Control
            type="text"
            placeholder={`Imaging Center Name`}
            {...getInputProps("patientISXRayCenterName" + index, form)}
          />
          <ErrorMessage
            name={`patientISCtCenterName${index}`}
            component="div"
            className="text-red-500 text-xs italic"
          />
        </Col>
        <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
          <Form.Label column>Imaging Center Phone</Form.Label>
        </Col>
        <Col xs={12} md={6} className="my-1">
          <Form.Control
            type="text"
            placeholder={`Imaging Center Phone`}
            {...getInputProps("patientISXRayCenterPhone" + index, form)}
          />
          <ErrorMessage
            name={`patientISXRayCenterPhone${index}`}
            component="div"
            className="text-red-500 text-xs italic"
          />
        </Col>
        <Col xs={12} md={{ span: 5, offset: 1 }} className="my-1">
          <Form.Label column>Imaging Center Address</Form.Label>
        </Col>
        <Col xs={12} md={6} className="my-1">
          <Form.Control
            type="text"
            placeholder={`Imaging Center Address`}
            {...getInputProps("patientISXRayCenterAddress" + index, form)}
          />
          <ErrorMessage
            name={`patientISXRayCenterAddress${index}`}
            component="div"
            className="text-red-500 text-xs italic"
          />
        </Col>
      </Form.Row>
    </Card>
  );
};
