import moment from "moment";
import { PDFFont, PDFForm, PDFPage, rgb } from "pdf-lib";
import PDFCheckboxFieldComponent from "../components/CheckboxFieldComponent";
import PDFTextFieldComponent from "../components/TextFieldComponent";
import { CurrentDimensions } from "../model/CurrentDimensions";
import { PDFSectionProps } from "../model/PDFSectionProps";


const SpineSurgerySection = (props: PDFSectionProps) => {
    // Header style
    const headerFontSize = props.headerFontSize;
    const textHeight = 4 * headerFontSize;

    // Label Style
    const labelFontSize = props.labelFontSize;
    const labelHeight = 4 * labelFontSize;

    // Keep track of the height and width
    var currentDimensions = { height: props.heightPointer + textHeight, width: 0 } as CurrentDimensions;
    props.page.drawText('Spine SurgeryQuestions', {
        x: 30,
        y: props.height - currentDimensions.height,
        size: headerFontSize,
        font: props.timesRomanFont,
        color: rgb(0, 0, 0),
        opacity: 1,
    });


    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'has.seen.ss.field',
            fieldMargin: 200,
            fieldWidth: 10,
            fieldValue: props.formValues.patientSTHasSeenSpineSurgeon
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Have you seen a Spine Surgeon?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'surgeon.name.field',
            fieldMargin: 110,
            fieldWidth: 90,
            fieldValue: props.formValues.patientSSSurgeonName
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Surgeon Name:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });
    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'surgeon.practice.field',
            fieldMargin: 150,
            fieldWidth: 220,
            fieldValue: props.formValues.patientSSSurgeryPracticeName
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Surgery Practice Name:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });



    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'had.previous.back.surgery.field',
            fieldMargin:300,
            fieldWidth: 10,
            fieldValue: props.formValues.patientSTHasHadPrevSurgery
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Have you had previous back or neck surgery?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });

    
    var patientSSPrevSurgeryDate = '-';
    if (moment(props.formValues.patientSSPrevSurgeryDate, "YYYY-MM-DD").isValid()) { 
        patientSSPrevSurgeryDate = moment(props.formValues.patientSSPrevSurgeryDate, "YYYY-MM-DD").format("MM/DD/YYYY") 
    }

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'prior.surgery.date.field',
            fieldMargin: 150,
            fieldWidth: 90,
            fieldValue: patientSSPrevSurgeryDate
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Prior Surgery Date:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });
    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'surgery.practice.field',
            fieldMargin: 150,
            fieldWidth: 170,
            fieldValue: props.formValues.patientSSPrevSurgeonName
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Surgery Practice Name:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });


    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'prev.surgeon.name.field',
            fieldMargin: 110,
            fieldWidth: 90,
            fieldValue: props.formValues.patientSSPrevSurgeryPracticeName
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Surgeon Name:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });
    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'previous.surgery.type.field',
            fieldMargin: 180,
            fieldWidth: 175,
            fieldValue: props.formValues.patientSSPrevSurgeryType
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Previous Surgery Type:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'did.surgery.help.field',
            fieldMargin: 110,
            fieldWidth: 90,
            fieldValue: props.formValues.patientSSPrevSurgeryDidItHelp
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Did it help?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });
    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'description.surgery.field',
            fieldMargin: 130,
            fieldWidth: 225,
            fieldValue: props.formValues.patientSSPrevSurgeryDescription
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Description:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });


    return currentDimensions;
};

export default SpineSurgerySection;
