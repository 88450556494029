// Components
import { Typography, Card } from "@material-ui/core";
import { Row, Col, Form, Container } from "react-bootstrap";
// Types
import { ErrorMessage, useFormikContext } from "formik";
// Util
import {
  CommonFormProps,
  getInputProps,
  getInputPropsCustomOnChange,
} from "utils/constants/forms";
import ReactCodeInput from "react-code-input";

const CheckCodeStep = ({ form }: CommonFormProps) => {
  // Grab what we need from formik without prop-drilling
  const { handleChange, getFieldProps, setFieldValue } = useFormikContext();

  return (
    <>
      <Container className="mt-4">
        <Row className="align-items-center">
          <Col>
            <Card>
              <Row>
                <Col>
                  <Typography variant="h4" className="text-center mt-5">
                    Please login using two factor authentication
                  </Typography>
                </Col>
              </Row>
              <Form.Row className="mr-3 ml-3 mb-3">
                <Col xs={12} md={12} className="my-1">
                  <Typography variant="h6" className="text-left mt-5">
                    Please fill in the code that has been sent:
                  </Typography>
                </Col>
              </Form.Row>
              <Form.Row className="mt-5 mr-3 ml-3">
                <Col xs={12} md={12} className="my-1 text-center">
                  <ReactCodeInput
                    name="reactCodeInput"
                    type="text"
                    fields={6}
                    inputMode="numeric"
                    onChange={(code) => {
                      setFieldValue("reactCodeInput", code);
                    }}
                  />
                </Col>
              </Form.Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CheckCodeStep;
