import * as Yup from "yup";
import moment from "moment";

const rePhoneNumber = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

const patientName = Yup.string()
  .label("Patient Name")
  .min(2, "First name must have 2 characters")
  .required("Required");



const patientDoB = Yup.string()
  .required()
  .label("Patient Date of Birth")
  .test(
    "PatientDoB",
    "Patient Date Of Birth must be a valid date and have the following format: MM/DD/YYYY",
    (value) => {
      var aDate = moment(value, "MM/DD/YYYY", true);
      var isValid = aDate.isValid();
      return isValid;
    }
  );

const patientMailingAddress = Yup.string().nullable();

const patientEmail0 = Yup.string().label("email").email("Invalid email").nullable();
const patientEmail1 = Yup.string().label("email").email("Invalid email").nullable();
const patientEmail2 = Yup.string().label("email").email("Invalid email").nullable();
const patientEmail3 = Yup.string().label("email").email("Invalid email").nullable();
const patientEmail4 = Yup.string().label("email").email("Invalid email").nullable();
const patientEmail5 = Yup.string().label("email").email("Invalid email").nullable();
const patientEmail6 = Yup.string().label("email").email("Invalid email").nullable();
const patientEmail7 = Yup.string().label("email").email("Invalid email").nullable();
const patientEmail8 = Yup.string().label("email").email("Invalid email").nullable();
const patientEmail9 = Yup.string().label("email").email("Invalid email").nullable();
const patientEmail10 = Yup.string().label("email").email("Invalid email").nullable();
const patientCity = Yup.string().nullable();
const patientState = Yup.string().nullable();
const patientZip = Yup.string().nullable();
const patientPhoneNumber = Yup.string().matches(
  rePhoneNumber,
  "Invalid Phone Number"
).nullable();
const patientSex = Yup.string().nullable();
const patientDriverLicense = Yup.string().nullable();
const patientDriverLicenseState = Yup.string().nullable();
const patientMaritalStatus = Yup.string().nullable();
const patientEmergencyContactRelationship = Yup.string().nullable();
const patientEmergencyContactEmail = Yup.string()
  .label("email")
  .email("Invalid email").nullable();
const patientEmergencyContactPhone = Yup.string().matches(
  rePhoneNumber,
  "Invalid Phone Number"
).nullable();


export default Yup.object({
  patientName,
  patientDoB,
  patientMailingAddress,
  patientEmail0,
  patientEmail1,
  patientEmail2,
  patientEmail3,
  patientEmail4,
  patientEmail5,
  patientEmail6,
  patientEmail7,
  patientEmail8,
  patientEmail9,
  patientEmail10,
  patientCity,
  patientState,
  patientZip,
  patientPhoneNumber,
  patientSex,
  patientDriverLicense,
  patientDriverLicenseState,
  patientMaritalStatus,
  patientEmergencyContactRelationship,
  patientEmergencyContactEmail,
  patientEmergencyContactPhone,
});
