import moment from "moment";
import { PDFFont, PDFForm, PDFPage, rgb } from "pdf-lib";
import PDFCheckboxFieldComponent from "../components/CheckboxFieldComponent";
import PDFTextFieldComponent from "../components/TextFieldComponent";
import { CurrentDimensions } from "../model/CurrentDimensions";
import { PDFSectionProps } from "../model/PDFSectionProps";


const MVASection = (props: PDFSectionProps) => {
    // Header style
    const headerFontSize = props.headerFontSize;
    const textHeight = 4 * headerFontSize;

    // Label Style
    const labelFontSize = props.labelFontSize;
    const labelHeight = 4 * labelFontSize;

    // Keep track of the height and width
    var currentDimensions = { height: props.heightPointer + textHeight, width: 0 } as CurrentDimensions;
    props.page.drawText('Motor Vehicle Accident Questions', {
        x: 30,
        y: props.height - currentDimensions.height,
        size: headerFontSize,
        font: props.timesRomanFont,
        color: rgb(0, 0, 0),
        opacity: 1,
    });

    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'mva.is.pi.field',
            fieldMargin: 200,
            fieldWidth: 10,
            fieldValue: props.formValues.patientMvaSource
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Is your condition result of a PI?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'mva.pi.attorney.name.field',
            fieldMargin: 120,
            fieldWidth: 90,
            fieldValue: props.formValues.patientMVAAttorneyName
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Attorney Name:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });
    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'mva.pi.attorney.phone.field',
            fieldMargin: 120,
            fieldWidth: 90,
            fieldValue: props.formValues.patientMVAAttorneyPhone
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 10,
            labelText: "Attorney Phone:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });

    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'mva.is.wc',
            fieldMargin: 200,
            fieldWidth: 10,
            fieldValue: props.formValues.patientMvaSource
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Is your condition result of a WC?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });


    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'mva.wc.name.field',
            fieldMargin: 120,
            fieldWidth: 90,
            fieldValue: props.formValues.patientMVAWCName
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "WC Name:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });
    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'mva.wc.phone.field',
            fieldMargin: 120,
            fieldWidth: 90,
            fieldValue: props.formValues.patientMVAWCPhone
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 10,
            labelText: "WC Phone:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });

    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'mva.is.mva.field',
            fieldMargin: 200,
            fieldWidth: 10,
            fieldValue: props.formValues.patientMvaSource
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Is your condition result of MVA?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });

    
    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'mva.accident.date.field',
            fieldMargin: 120,
            fieldWidth: 90,
            fieldValue: props.formValues.patientMVAAccidentDate
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Date of Accident:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'mva.time.of.day.field',
            fieldMargin: 100,
            fieldWidth: 100,
            fieldValue: props.formValues.patientMVATimeOfDay
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Time of Day:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });


    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'mva.road.conditions.field',
            fieldMargin: 120,
            fieldWidth: 90,
            fieldValue: props.formValues.patientMVARoadContions
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Road Conditions:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true,
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'mva.place.field',
            fieldMargin: 200,
            fieldWidth: 90,
            fieldValue: props.formValues.patientMVAAccidentPlace
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Where did the accident take place?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'mva.vehicle.type.driving.field',
            fieldMargin: 250,
            fieldWidth: 90,
            fieldValue: props.formValues.patientMVAVehicleType
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "What type of vehicle where you driving?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true,
    });


    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'mva.vehicle.type.collided.field',
            fieldMargin: 250,
            fieldWidth: 90,
            fieldValue: props.formValues.patientMVAVehicleCollided
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "What type of vehicle did you collide with?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'mva.vector.field',
            fieldMargin: 120,
            fieldWidth: 90,
            fieldValue: props.formValues.patientMVAVector
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "What was the vector?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine:true,
    });


    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'mva.vehicle.speed.field',
            fieldMargin: 120,
            fieldWidth: 90,
            fieldValue: props.formValues.patientMVAVehicleSpeed
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "What was the speed of the vehicle?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'mva.position.field',
            fieldMargin: 120,
            fieldWidth: 90,
            fieldValue: props.formValues.patientMVAPosition
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "What position where you in?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine:true,
    });



    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'mva.hands.on.wheel.field',
            fieldMargin: 120,
            fieldWidth: 90,
            fieldValue: props.formValues.patientMVAHandsOnWheel
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "were your hands on the steering wheel?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'mva.seatbelt.field',
            fieldMargin: 120,
            fieldWidth: 90,
            fieldValue: props.formValues.patientMVAUserRestrained
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Were you restrained, as in using a seatbelt?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine:true,
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'mva.head.postion.field',
            fieldMargin: 120,
            fieldWidth: 90,
            fieldValue: props.formValues.patientMVAHeadPosition
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "What was your head position?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'mva.hit.anything.field',
            fieldMargin: 120,
            fieldWidth: 90,
            fieldValue: props.formValues.patientMVAHeadHitAnything
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Did you head hit anything?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine:true,
    });



    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'mva.lost.consciousness.field',
            fieldMargin: 120,
            fieldWidth: 90,
            fieldValue: props.formValues.patientMVAUserLostConciousness
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Did you lose consciousness?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'mva.lost.consciousness.how.long.field',
            fieldMargin: 120,
            fieldWidth: 90,
            fieldValue: props.formValues.patientMVALostConsciousnessHowLong
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "For How Long?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine:true,
    });


    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'mva.police.came.field',
            fieldMargin: 120,
            fieldWidth: 90,
            fieldValue: props.formValues.patientMVAPoliceCameToMVA
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Did the Police come to the scene of the MVA?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'mva.how.was.cited.field',
            fieldMargin: 120,
            fieldWidth: 90,
            fieldValue: props.formValues.patientMVAWhoWasCited
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Who was cited?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine:true,
    });


    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'mva.went.hospital.field',
            fieldMargin: 120,
            fieldWidth: 90,
            fieldValue: props.formValues.patientMVAHospitalOptions
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Did you go to the Hospital, ER or Urgent Care?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'mva.how.did.you.get.hospital.field',
            fieldMargin: 120,
            fieldWidth: 90,
            fieldValue: props.formValues.patientMVAHowRideToHospitalOptions
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "How did you get there?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine:true,
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'mva.addmitted.hospital.field',
            fieldMargin: 120,
            fieldWidth: 90,
            fieldValue: props.formValues.patientMVAWasUserAdmitted
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Where you admitted?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
    });

    var patientMVADateDischarged = '-';
    if (moment(props.formValues.patientMVADateDischarged, "YYYY-MM-DD").isValid()) { 
        patientMVADateDischarged = moment(props.formValues.patientMVADateDischarged, "YYYY-MM-DD").format("MM/DD/YYYY") 
    }
    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'mva.dicharged.date.hospital.field',
            fieldMargin: 120,
            fieldWidth: 90,
            fieldValue:  patientMVADateDischarged
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "What date were you discharged?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine:true,
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'mva.tests.performed.field',
            fieldMargin: 120,
            fieldWidth: 90,
            fieldValue: props.formValues.patientMVAHowTestPerformed
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Were any test performed?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'mva.test.performed.details.field',
            fieldMargin: 120,
            fieldWidth: 90,
            fieldValue: props.formValues.patientMVAHospitalOtherDetails
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Other details"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine:true,
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'mva.treatments.administered.field',
            fieldMargin: 120,
            fieldWidth: 90,
            fieldValue: props.formValues.patientMVAAnyTreatmnetsAdministered
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Were there any treatments administered?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'mva.treatmnets.administered.details.field',
            fieldMargin: 120,
            fieldWidth: 90,
            fieldValue: props.formValues.patientMVAOtherTreatmentsAdministeredDetails
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Other details"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine:true,
    });

    return currentDimensions;
};

export default MVASection;
