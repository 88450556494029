import React, { useState } from "react";
import {
  Form,
  InputGroup,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import SignatureCanvas from "react-signature-canvas";
import { Typography, Button } from "@material-ui/core";
import { useAppDispatch } from "store/store";
import { saveSignatureDataThunkAction } from "store/documents/documents.slice";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { FileGenerationProps } from "utils/constants/forms";
import { conformsTo } from "lodash";

const MySwal = withReactContent(Swal);
interface DocumentSignatureProps {
  signState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  tokenId: string;
}

const DocumentSignature: React.FC<FileGenerationProps> = ({
  contactId,
  caseId,
  fileGenerationType,
  onClickSubmit,
  hasAgreedToS,
  isValid
}) => {
  const dispatch = useAppDispatch();
  const [show, setShow] = useState(false);
  const [accepted, setAccepted] = useState(hasAgreedToS);


  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const signCanvas = React.useRef() as React.MutableRefObject<any>;
  const signatureText = React.useRef() as React.MutableRefObject<any>;
  const clear = () => signCanvas.current.clear();

  const submitSignature = () => {
    // We do whatever each form wants to do before submit sign
    onClickSubmit();

    // Submit the sign
    var stringCanvas = signCanvas.current
      .getTrimmedCanvas()
      .toDataURL("image/png");
    dispatch(
      saveSignatureDataThunkAction({
        data: {
          caseId: caseId,
          contactId: contactId,
          generationType: fileGenerationType,
          signatureData: stringCanvas,
          hasAgreedToS: accepted ? 1: 0
        },
        onSuccess: (res) => {
          handleClose();
          return Promise.resolve(res);
        },
        onError: (err) => {
          MySwal.fire("Error", "Unknown Error", "warning");
          return Promise.reject(err);
        },
      })
    );
  };

  const write = (text: string) => {
    clear();
    var canvas = signCanvas.current;
    var context = canvas.getCanvas().getContext("2d");
    context.textAlign = "start";
    var height = context.canvas.height;

    context.font = " 78px Kunstler Script";
    context.fillText(text, 10, height / 2);
  };

  const signForm = () => {
    clear();
    var signature = signatureText.current.value;
    write(signature);
  };

  const renderTooltip = (props: any) => {
    var tooltipText =
      "Please click the Authorization box and complete the required fields to sign ";
    if (true) {
      tooltipText = "Sign the Form!";
    }
    return (
      <Tooltip id="button-tooltip" {...props}>
        {tooltipText}
      </Tooltip>
    );
  };


  return (
    <>
      <OverlayTrigger overlay={renderTooltip}>
        <span className="d-inline-block">
          <Button color="primary" disabled={!isValid} onClick={handleShow}>
            Sign&nbsp;<i className="fas fa-signature"></i>
          </Button>
        </span>
      </OverlayTrigger>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Signature</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Typography className="mb-1">
            Please sign this form by drawing your signature:
          </Typography>
          <div
            style={{
              border: "1px solid black",
              width: "intrinsic",
              height: 200,
            }}
          >
            <SignatureCanvas
              canvasProps={{
                width: "max-content",
                height: "max-content",
                className: "sigCanvas",
              }}
              ref={signCanvas}
            />
          </div>
          <div>
            <Typography className="mt-3 mb-1">
              Or if you prefer you can type your signaure:
            </Typography>
            <InputGroup className="">
              <Form.Control
                ref={signatureText}
                type="text"
                name="lastname"
                placeholder="Signature"
              />
              <Button color="primary" onClick={signForm}>
                Sign &nbsp; <i className="fas fa-file-signature"></i>
              </Button>
            </InputGroup>
          </div>
          <div className="mt-4">
          <Typography variant="caption" className="">
             *Acceptance
            </Typography>
          </div>
          <div>
            <Form.Check
              type="checkbox"
              name="acceptance"
              checked={accepted}
              onChange={(value) => {
                setAccepted(!accepted);
              }}
              label={
                <>
                  I Accept&nbsp;
                    <a target="_blank" href="https://sonospinesurgery.com/user-agreement/">Terms of Service</a>
                    &nbsp;and&nbsp;
                    <a target="_blank" href="https://sonospinesurgery.com/privacy-policy/">Privacy Policy</a>
                </>
              }
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button color="primary" onClick={clear}>
            Clear &nbsp; <i className="fas fa-broom"></i>
          </Button>
          <Button color="primary" disabled={!accepted} onClick={submitSignature}>
            Submit &nbsp;<i className="fas fa-upload"></i>
          </Button>
          <Button color="secondary" onClick={handleClose}>
            Cancel&nbsp; <i className="far fa-times-circle"></i>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DocumentSignature;
