import React, { MutableRefObject, useRef, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
// Components
import { Typography, Button, Card } from "@material-ui/core";
import { Row, Col, Form, Container } from "react-bootstrap";
import { Formik, FormikProps, FormikValues } from "formik";
import { useFormikContext } from "formik";
import Navigation from "components/twoFactorAuthentication/navigation/Navigation";
import Progress from "components/forms/progress/Progress";
// Util
import { generateSteps, generateInitialValues, getStepSchema } from "./steps";
import Moment from "moment";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
// API Imports
import { useAppDispatch } from "store/store";
import {
  getFileGenerationThunkAction,
  getScreeningCaseByCaseIdThunkAction,
  saveFormDataThunkAction,
} from "store/documents/documents.slice";
import { useEffect } from "react";
import { useDocuments } from "utils/hooks/useDocuments.hook";
import { ContactPhone } from "utils/model/ContactPhone";
import { ContactEmail } from "utils/model/ContactEmail";
import { FileGeneration } from "utils/model/FileGeneration";
import { ScreeningCase } from "utils/model/ScreeningCase";
// MultiSelect Helpers
import {
  getOptionByLabel,
  getOptionByValue,
  painLocationOptionsArray,
  extremitiesOptionsArray,
  activitiesOptionsArray,
  activitiesDecreaseArray,
} from "utils/form/multiSelectOptions";
import { Option } from "react-multi-select-component/dist/lib/interfaces";
import CustomSpinner from "components/spinner/CustomSpinner";
import {
  getTwoFactorAuthThunkAction,
  generateTwoFactorAuthThunkAction,
  checkTwoFactorAuthThunkAction,
} from "store/auth/auth.slice";
import { useAuth } from "utils/hooks/useAuth.hook";

import { DOCUMENTS_ROUTES } from "pages/documents/DocumentsRouter";
import { conformsTo } from "lodash";
import { canDownloadPdfSelector } from "store/documents/documents.selectors";
import { string } from "yup/lib/locale";

const TwoFactorAuth: React.FC = (props: any) => {
  const tokenId = props.location.state.tokenId;
  const pathLocation = props.location.state.pathLocation;
  const [redirect, setRedirect] = useState<string>("");
  const dispatch = useAppDispatch();
  const [steps] = useState(generateSteps());
  const [initialValues] = useState(generateInitialValues(steps, tokenId));
  const [currentIndex, setCurrentIndex] = useState(0);
  const history = useHistory();
  const {
    fileGeneration,
    screeningCase,
    isFileGenerationError,
    isFileGenerationLoading,
    isSavingFormData,
  } = useDocuments();
  const { twoFactorAuth } = useAuth();
  const formikRef = useRef<any>();
  const MySwal = withReactContent(Swal);

  // Since TwoFactor has 2 pages, go next is only used in the first page
  const goNext = () => {
    var values = formikRef.current.values;
    const notificationType = values.twoFactorMethodSelection;
    var itemToSend = "";
    if (notificationType == "email") {
      itemToSend = values.twoFactorEmailOriginal;
    }
    if (notificationType == "phone") {
      itemToSend = values.twoFactorNumberOriginal;
    }
    dispatch(
      generateTwoFactorAuthThunkAction({
        data: {
          contactId: values.contactId,
          notificationType: notificationType,
          notifiedTo: itemToSend,
        },
        onSuccess: (res: any) => {
          setCurrentIndex((oldIndex) => oldIndex + 1);
          return Promise.resolve(res);
        },
        onError: (err: any) => {
          console.log("error", err);
          MySwal.fire("Error", "Error Saving Data", "warning");
          return Promise.reject(err);
        },
      })
    );
    // We should register the two factor code here
  };

  const goBack = () => {
    setCurrentIndex((oldIndex) => oldIndex - 1);
  };

  // Since TwoFactor has 2 pages, go submit is only used in the second page
  const goSubmit = () => {
    // We should save the form here
    var values = formikRef.current.values;
    var code = values.reactCodeInput;
    if (isNaN(code)) {
      MySwal.fire(
        "Error",
        "The verification code must contain only numbers",
        "warning"
      );
    }
    dispatch(
      checkTwoFactorAuthThunkAction({
        data: {
          contactId: values.contactId,
          code: parseInt(code),
          isAdmin: false,
        },
        onSuccess: (res: any) => {
          return Promise.resolve(res);
        },
        onError: (err: any) => {
          MySwal.fire("Error", "Error Validating Verification Code", "warning");
          console.log("error", err);
          return Promise.reject(err);
        },
      })
    );
  };

  useEffect(() => {
    if (twoFactorAuth && twoFactorAuth.contactId != 0) {
      redirectToPage();
    }
    /*
    if (twoFactorAuthResult) {
      // we got a result if the object is not null means we got a ok result
      if (twoFactorAuth) {
        redirectToPage();
      } else {
      }
      */

    //history.push(pathLocation);
    // redirect directly to the component without calling the url, since the url with go to the private router again
    //}
  }, [twoFactorAuth]);

  const redirectToPage = () => {
    var pathLocationSliced = pathLocation.split("/");
    var adminString = pathLocationSliced[pathLocationSliced.length - 2];
    var codeString = pathLocationSliced[pathLocationSliced.length - 1];

    
    var linkToRedirect = pathLocation;
    if (adminString == "admin" && !isNaN(codeString)) {
      // si admin
      var foundWhere = pathLocation.search("/admin");
      linkToRedirect = pathLocation.slice(0, foundWhere);
    }     
    //setRedirect(linkToRedirect);
    history.push(linkToRedirect);
  };

  /*
   * Maybe we can also check if the session is active for that token... so you can skip this twofactor if the session is active
   */
  const initializeTwoFactor = (tokenId: string) => {
    dispatch(
      getFileGenerationThunkAction({
        data: { tokenId: tokenId },
        onError: (err) => {
          console.log("error", err);
          MySwal.fire("Error", "Error retrieving required data", "warning");
          return Promise.reject(err);
        },
      })
    );
  };

  useEffect(() => {
    initializeTwoFactor(tokenId);
  }, []);

  // Prepopulate email and phone and check if we have session active or we are admin
  useEffect(() => {
    if (fileGeneration) {
      // first check if for this fileGeneration we already have the session active
      var pathLocationSliced = pathLocation.split("/");
      var adminString = pathLocationSliced[pathLocationSliced.length - 2];
      var codeString = pathLocationSliced[pathLocationSliced.length - 1];
      if (adminString == "admin" && !isNaN(codeString)) {
        // if it is admin, we check the code
        checkForAdminCode(fileGeneration, codeString);
      } else {
        // if not admin, check for valid session
        checkForValidSession(fileGeneration);
      }
      // generate only first email field
      prepopulateTwoFactoFields(fileGeneration, formikRef);
    }
  }, [fileGeneration]);

  const renderCurrentStep = (form: FormikProps<FormikValues>) => {
    const step = steps[currentIndex];

    // opportunity to extend commonProps here with other relevant information
    const commonProps = {
      name: step.name,
      form,
    };

    const StepComponent = step.component;

    return (
      <>
        <StepComponent {...commonProps} />{" "}
        <Navigation
          maxSteps={steps.length}
          currentIndex={currentIndex}
          onClickNext={goNext}
          onClickBack={goBack}
          onClickSubmit={goSubmit}
        />
      </>
    );
  };

  const renderLoading = () => {
    return (
      <>
        <Container className="mt-4">
          <Row className="align-items-center">
            <Col>
              <Card>
                <Row>
                  <Col>
                    <Typography variant="h4" className="text-center mt-5">
                      <CustomSpinner></CustomSpinner>
                    </Typography>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  const handleSubmitQuestionnaire = (values: FormikValues) => {
    // Opportunity to perform API call here
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(10);
      }, 2000);
    }).then(() => {
      //history.push(`/questionnaire/results`, { values });
      //console.log("values: ", values);
    });
  };

  const checkForAdminCode = (
    fileGeneration: FileGeneration,
    codeString: any
  ) => {
    dispatch(
      checkTwoFactorAuthThunkAction({
        data: {
          contactId: fileGeneration?.contactId,
          isAdmin: true,
          code: parseInt(codeString),
        },
        onError: (err) => {
          console.log("error", err);
          MySwal.fire(
            "Error",
            "Error retrieving Authentication data",
            "warning"
          );
          return Promise.reject(err);
        },
      })
    );
  };

  const checkForValidSession = (fileGeneration: FileGeneration) => {
    dispatch(
      getTwoFactorAuthThunkAction({
        data: {
          contactId: fileGeneration?.contactId,
          isAdmin: false,
        },
        onError: (err) => {
          console.log("error", err);
          MySwal.fire(
            "Error",
            "Error retrieving Authentication data",
            "warning"
          );
          return Promise.reject(err);
        },
      })
    );
  };
  return (
    <>
      <Progress steps={steps} currentIndex={currentIndex} />

      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={getStepSchema(currentIndex, steps)}
        onSubmit={handleSubmitQuestionnaire}
        validateOnMount
      >
        {(form) => {
          if (redirect) {
            return <Redirect to={redirect}></Redirect>;
          }
          return (
            <>
              {isFileGenerationLoading || isSavingFormData
                ? renderLoading()
                : renderCurrentStep(form)}
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default TwoFactorAuth;

function prepopulateTwoFactoFields(
  fileGeneration: FileGeneration,
  formikRef: React.MutableRefObject<any>
) {
  formikRef.current.setFieldValue("contactId", fileGeneration?.contactId);
  fileGeneration?.contact?.contactEmails
    ?.slice(0, 1)
    .map((item: ContactEmail, i: number) => {
      var email = item?.email;
      if (email) {
        var finalEmail = "";
        try {
          var mailName = email.split("@")[0];
          mailName = mailName.replace(/.(?=.{2})/g, "*");

          var mailServer = email.split("@")[1];

          var domain = mailServer.split(".")[0];
          domain = domain.replace(/.(?=.{2})/g, "*");

          var enpoint = mailServer.split(".")[1];
          enpoint = enpoint.replace(/.(?=.{0})/g, "*");

          finalEmail = mailName + "@" + domain + "." + enpoint;
        } catch (error) {}

        formikRef.current.setFieldValue("twoFactorEmailOriginal", email);
        formikRef.current.setFieldValue("twoFactorEmail", finalEmail);
      }
    });
  // generate only first phone number
  fileGeneration?.contact?.contactPhones
    ?.slice(0, 1)
    .map((item: ContactPhone, i: number) => {
      var mobile = item?.phone;
      if (mobile) {
        var finalMobile = mobile.replace(/.(?=.{4})/g, "*");
        formikRef.current.setFieldValue("twoFactorNumber", finalMobile);
        formikRef.current.setFieldValue("twoFactorNumberOriginal", mobile);
      }
    });
}
