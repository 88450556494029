// Components
import {  Typography, Card} from "@material-ui/core";
import { Row, Col, Form, Container } from "react-bootstrap";
// Types
import {
  ErrorMessage,
  useFormikContext,
} from "formik";
// Util
import {
  CommonFormProps,
  getInputProps,
  getInputPropsCustomOnChange,
} from "utils/constants/forms";


type CalculateBMI = {
  heightFt?: string;
  heightIn?: string;
  weigth?: string;
};

const BiometricsQuestions = ({ form }: CommonFormProps) => {
  // Grab what we need from formik without prop-drilling
  const { handleChange, getFieldProps, setFieldValue } = useFormikContext();
  const calculateBmi = ({ heightFt, heightIn, weigth }: CalculateBMI) => {
    const mHeightFt =
      heightFt || getFieldProps("patientBiometricsHeightFt").value;
    const mHeightIn =
      heightIn || getFieldProps("patientBiometricsHeightIn").value;
    const mWeigth = weigth || getFieldProps("patientBiometricsWeight").value;
    if (isNaN(mHeightFt) || isNaN(mHeightIn) || isNaN(mWeigth)) {
      setFieldValue("patientBiometricsBMI", 0);
    } else {
      const totalHeight = (mHeightFt * 12) + mHeightIn;
      const bmi = (mWeigth * 703) / (totalHeight * totalHeight);
      setFieldValue("patientBiometricsBMI", bmi ? Number(bmi.toFixed(2)) : null);
    }
  };
  return (
    <>
      <Container className="mt-4">
        <Row className="align-items-center">
          <Col>
            <Card>
              <Row>
                <Col>
                  <Typography variant="h4" className="text-center mt-5">
                    Biometric Questions
                  </Typography>
                </Col>
              </Row>
              <Form.Row className="mt-5 mr-3 ml-3">
                <Col xs={12} md={2} className="my-1">
                  <Form.Label column>Height (ft)</Form.Label>
                </Col>
                <Col xs={12} md={4} className="my-1">
                  <Form.Control
                    type="number"
                    placeholder="Height (ft)"
                    onChange={(e) => {
                      calculateBmi({ heightFt: e.target.value });
                      return handleChange(e);
                    }}
                    {...getInputPropsCustomOnChange(
                      "patientBiometricsHeightFt",
                      form
                    )}
                  />
                  <ErrorMessage
                    name="patientBiometricsHeightFt"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
                <Col xs={12} md={2} className="my-1">
                  <Form.Label column>Height (in)</Form.Label>
                </Col>
                <Col xs={12} md={4} className="my-1">
                  <Form.Control
                    type="number"
                    placeholder="Height (in)"
                    onChange={(e) => {
                      calculateBmi({ heightIn: e.target.value });
                      return handleChange(e);
                    }}
                    {...getInputPropsCustomOnChange(
                      "patientBiometricsHeightIn",
                      form
                    )}
                  />
                  <ErrorMessage
                    name="patientBiometricsHeightIn"
                    component="div"
                    data-testid="email-error"
                  />
                </Col>
              </Form.Row>
              <Form.Row className="mr-3 ml-3 mb-3">
                <Col xs={12} md={2} className="my-1">
                  <Form.Label column>Weight (lbs)</Form.Label>
                </Col>
                <Col xs={12} md={4} className="my-1">
                  <Form.Control
                    type="number"
                    placeholder="Weight (lbs)"
                    onChange={(e) => {
                      calculateBmi({ weigth: e.target.value });
                      return handleChange(e);
                    }}
                    {...getInputPropsCustomOnChange(
                      "patientBiometricsWeight",
                      form
                    )}
                  />
                  <ErrorMessage
                    name="patientBiometricsWeight"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
                <Col xs={12} md={2} className="my-1">
                  <Form.Label column>BMI</Form.Label>
                </Col>
                <Col xs={12} md={4} className="my-1">
                  <Form.Control
                    type="text"
                    placeholder="BMI"
                    readOnly={true}
                    {...getInputProps("patientBiometricsBMI", form)}
                  />
                  <ErrorMessage
                    name="patientBiometricsBMI"
                    component="div"
                    data-testid="email-error"
                  />
                </Col>
              </Form.Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default BiometricsQuestions;
