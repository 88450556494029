import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './auth/auth.slice';
import documentsReducer from './documents/documents.slice';

const rootReducer = combineReducers({
  auth: authReducer,
  documents: documentsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
