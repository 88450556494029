import React, { MutableRefObject, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
// Components
import { Typography, Button, Card } from "@material-ui/core";
import { Row, Col, Form, Container } from "react-bootstrap";
import { Formik, FormikProps, FormikValues } from "formik";
import { useFormikContext } from "formik";
import Navigation from "components/forms/navigation/Navigation";
import Progress from "components/forms/progress/Progress";
// Util
import { generateSteps, generateInitialValues, getStepSchema } from "./steps";
import Moment from "moment";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { PDFDocument, StandardFonts, rgb, PDFFont, PDFPage } from "pdf-lib";
// API Imports
import { useAppDispatch } from "store/store";
import {
  getFileGenerationThunkAction,
  getScreeningCaseByCaseIdThunkAction,
  saveFormDataThunkAction,
  getPdfBytesThunkAction,
  saveDocumentThunkAction,
  getInsuranceCarrierValuesThunkAction,
} from "store/documents/documents.slice";
import { useEffect } from "react";
import { useDocuments } from "utils/hooks/useDocuments.hook";
import { ContactPhone } from "utils/model/ContactPhone";
import { ContactEmail } from "utils/model/ContactEmail";
import { FileGeneration } from "utils/model/FileGeneration";
import { ScreeningCase } from "utils/model/ScreeningCase";
// MultiSelect Helpers
import {
  getOptionByLabel,
  getOptionByValue,
  painLocationOptionsArray,
  extremitiesOptionsArray,
  activitiesOptionsArray,
  activitiesDecreaseArray,
} from "utils/form/multiSelectOptions";
import { Option } from "react-multi-select-component/dist/lib/interfaces";
import CustomSpinner from "components/spinner/CustomSpinner";

// Everything file related
import logo from "../../../../utils/PDFUtils/assets/sonospine_logo.png";
import { currentHostSettings } from 'utils/currentHost.utils';
import { readFileSync, } from 'fs';
import PDFTextFieldComponent from "utils/PDFUtils/components/TextFieldComponent";
import PDFCheckboxFieldComponent from "utils/PDFUtils/components/CheckboxFieldComponent";
import { CurrentDimensions } from "utils/PDFUtils/model/CurrentDimensions";
import DemographicQuestionsSection from "utils/PDFUtils/sections/DemographicQuestionsSection";
import { PDFSectionProps } from "utils/PDFUtils/model/PDFSectionProps";
import BiometricQuestionsSection from "utils/PDFUtils/sections/BiometricQuestionsSection";
import { ChiefComplaintQuestionsSection, ChiefComplaintQuestionsSection2 } from "utils/PDFUtils/sections/ChiefComplaintSection";
import ImagingStudiesSection from "utils/PDFUtils/sections/ImagingStudiesSection";
import SpineTreatmentHistorySection from "utils/PDFUtils/sections/SpineTreatmentHistorySection";
import SpineSurgerySection from "utils/PDFUtils/sections/SpineSurgerySection";
import MedicationsSection from "utils/PDFUtils/sections/MedicationsSections";
import { OtherMedicalQuestionsSection, OtherMedicalQuestionsSection2 } from "utils/PDFUtils/sections/OtherMedicalQuestionsSection";
import SocialHistooryQuestionsSection from "utils/PDFUtils/sections/SocialHistoryQuestionsSection";
import InsuranceSection from "utils/PDFUtils/sections/InsuranceSection";
import MVASection from "utils/PDFUtils/sections/MVASection";

const Questionnaire: React.FC = (props: any) => {
  const tokenId = props.match.params.tokenId;
  const dispatch = useAppDispatch();
  const [steps] = useState(generateSteps());
  const [initialValues] = useState(generateInitialValues(steps, tokenId));
  const [currentIndex, setCurrentIndex] = useState(0);
  const history = useHistory();
  const {
    fileGeneration,
    screeningCase,
    isFileGenerationError,
    isFileGenerationLoading,
    isSavingFormData,
    pdfBytes,
  } = useDocuments();
  const formikRef = useRef<any>();
  const MySwal = withReactContent(Swal);

  const goNext = () => {
    // We should save the form here
    var values = formikRef.current.values;
    dispatch(
      saveFormDataThunkAction({
        data: {
          caseId: values.caseId,
          contactId: values.contactId,
          generationType: values.generationType,
          hasAgreedToS: values.hasAgreedToS ? 1 : 0,
          formData: JSON.stringify(formikRef.current.values),
        },
        onSuccess: (res) => {
          setCurrentIndex((oldIndex) => oldIndex + 1);
          return Promise.resolve(res);
        },
        onError: (err) => {
          console.log("error", err);
          MySwal.fire("Error", "Error Saving Data", "warning");
          return Promise.reject(err);
        },
      })
    );
  };

  const goBack = () => {
    setCurrentIndex((oldIndex) => oldIndex - 1);
  };

  const goSubmit = () => {
    // We should save the form here
    var values = formikRef.current.values;
    dispatch(
      saveFormDataThunkAction({
        data: {
          caseId: values.caseId,
          contactId: values.contactId,
          generationType: values.generationType,
          hasAgreedToS: values.hasAgreedToS ? 1 : 0,
          formData: JSON.stringify(formikRef.current.values),
        },
        onSuccess: (res) => {
          handleSigntureSaveSuccess();
          return Promise.resolve(res);
        },
        onError: (err) => {
          MySwal.fire("Error", "Error Submitting the Form", "warning");
          console.log("error", err);
          return Promise.reject(err);
        },
      })
    );
  };

  const handleSigntureSaveSuccess = () => {
    createPdf();
    //saveToPdf();
  }

  async function saveToPdf() {
    dispatch(
      // Fills the pdfBytes
      getPdfBytesThunkAction({
        data: "HealthHistory",
        onSuccess: (res) => {
          return Promise.resolve(res);
        },
        onError: (err) => {
          MySwal.fire("Error", "Unknown Error", "warning");
          return Promise.reject(err);
        },
      })
    );
  }

  useEffect(() => {
    if (pdfBytes) {
      if (pdfBytes.byteLength) {
        //fillPdf(pdfBytes);
      }
    }
  }, [pdfBytes]);

  /**
   * Function that creates the pdf
   */
  async function createPdf() {
    // Create a new PDFDocument
    const pdfDoc = await PDFDocument.create();
    // Embed the Times Roman font
    const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman)
    // Add a blank page to the document
    const page = pdfDoc.addPage();
    // Form for the PDF
    const form = pdfDoc.getForm();
    // Get the width and height of the page
    const { width, height } = page.getSize();

    const values = formikRef.current.values;

    console.log("in pdf", values);

    // POinter to keep track the height of the document
    var heightPointer = 0;

    // Header
    heightPointer += await generatePdfHeader(pdfDoc, page, width, height, timesRomanFont);

    // Header style
    const headerFontSize = 14;

    // Label Style
    const labelFontSize = 12;

    var sectionProps = {
      formValues: values,
      heightPointer: heightPointer,
      headerFontSize: headerFontSize,
      labelFontSize: labelFontSize,
      page: page,
      height: height,
      timesRomanFont: timesRomanFont,
      width: width,
      pdfDoc: pdfDoc,
      form: form
    } as PDFSectionProps;
    var currentDimensions = DemographicQuestionsSection(sectionProps);

    heightPointer = currentDimensions.height;

    sectionProps = {
      formValues: values,
      heightPointer: heightPointer,
      headerFontSize: headerFontSize,
      labelFontSize: labelFontSize,
      page: page,
      height: height,
      timesRomanFont: timesRomanFont,
      width: width,
      pdfDoc: pdfDoc,
      form: form
    } as PDFSectionProps;
    currentDimensions = BiometricQuestionsSection(sectionProps);

    heightPointer = currentDimensions.height;

    sectionProps = {
      formValues: values,
      heightPointer: heightPointer,
      headerFontSize: headerFontSize,
      labelFontSize: labelFontSize,
      page: page,
      height: height,
      timesRomanFont: timesRomanFont,
      width: width,
      pdfDoc: pdfDoc,
      form: form
    } as PDFSectionProps;
    currentDimensions = ChiefComplaintQuestionsSection(sectionProps);

    // Add a blank page to the document
    const page2 = pdfDoc.addPage();
    // POinter to keep track the height of the document
    var heightPointer = 0;

    // Header
    heightPointer += await generatePdfHeader(pdfDoc, page2, width, height, timesRomanFont);

    sectionProps = {
      formValues: values,
      heightPointer: heightPointer - 40,
      headerFontSize: headerFontSize,
      labelFontSize: labelFontSize,
      page: page2,
      height: height,
      timesRomanFont: timesRomanFont,
      width: width,
      pdfDoc: pdfDoc,
      form: form
    } as PDFSectionProps;
    currentDimensions = ChiefComplaintQuestionsSection2(sectionProps);


    heightPointer = currentDimensions.height;

    sectionProps = {
      formValues: values,
      heightPointer: heightPointer,
      headerFontSize: headerFontSize,
      labelFontSize: labelFontSize,
      page: page2,
      height: height,
      timesRomanFont: timesRomanFont,
      width: width,
      pdfDoc: pdfDoc,
      form: form
    } as PDFSectionProps;
    currentDimensions = ImagingStudiesSection(sectionProps);


    // Add a blank page to the document
    const page3 = pdfDoc.addPage();
    // POinter to keep track the height of the document
    var heightPointer = 0;

    // Header
    heightPointer += await generatePdfHeader(pdfDoc, page3, width, height, timesRomanFont);

    sectionProps = {
      formValues: values,
      heightPointer: heightPointer,
      headerFontSize: headerFontSize,
      labelFontSize: labelFontSize,
      page: page3,
      height: height,
      timesRomanFont: timesRomanFont,
      width: width,
      pdfDoc: pdfDoc,
      form: form
    } as PDFSectionProps;
    currentDimensions = SpineTreatmentHistorySection(sectionProps);

    heightPointer = currentDimensions.height;

    sectionProps = {
      formValues: values,
      heightPointer: heightPointer,
      headerFontSize: headerFontSize,
      labelFontSize: labelFontSize,
      page: page3,
      height: height,
      timesRomanFont: timesRomanFont,
      width: width,
      pdfDoc: pdfDoc,
      form: form
    } as PDFSectionProps;
    currentDimensions = SpineSurgerySection(sectionProps);


    // Add a blank page to the document
    const page4 = pdfDoc.addPage();
    // POinter to keep track the height of the document
    var heightPointer = 0;

    // Header
    heightPointer += await generatePdfHeader(pdfDoc, page4, width, height, timesRomanFont);

    sectionProps = {
      formValues: values,
      heightPointer: heightPointer,
      headerFontSize: headerFontSize,
      labelFontSize: labelFontSize,
      page: page4,
      height: height,
      timesRomanFont: timesRomanFont,
      width: width,
      pdfDoc: pdfDoc,
      form: form
    } as PDFSectionProps;
    currentDimensions = MedicationsSection(sectionProps);

    heightPointer = currentDimensions.height;

    sectionProps = {
      formValues: values,
      heightPointer: heightPointer,
      headerFontSize: headerFontSize,
      labelFontSize: labelFontSize,
      page: page4,
      height: height,
      timesRomanFont: timesRomanFont,
      width: width,
      pdfDoc: pdfDoc,
      form: form
    } as PDFSectionProps;
    currentDimensions = OtherMedicalQuestionsSection(sectionProps);


    // Add a blank page to the document
    const page5 = pdfDoc.addPage();
    // POinter to keep track the height of the document
    var heightPointer = 0;

    // Header
    heightPointer += await generatePdfHeader(pdfDoc, page5, width, height, timesRomanFont);

    sectionProps = {
      formValues: values,
      heightPointer: heightPointer,
      headerFontSize: headerFontSize,
      labelFontSize: labelFontSize,
      page: page5,
      height: height,
      timesRomanFont: timesRomanFont,
      width: width,
      pdfDoc: pdfDoc,
      form: form
    } as PDFSectionProps;
    currentDimensions = OtherMedicalQuestionsSection2(sectionProps);

    // Add a blank page to the document
    const page6 = pdfDoc.addPage();
    // POinter to keep track the height of the document
    var heightPointer = 0;

    // Header
    heightPointer += await generatePdfHeader(pdfDoc, page6, width, height, timesRomanFont);

    sectionProps = {
      formValues: values,
      heightPointer: heightPointer,
      headerFontSize: headerFontSize,
      labelFontSize: labelFontSize,
      page: page6,
      height: height,
      timesRomanFont: timesRomanFont,
      width: width,
      pdfDoc: pdfDoc,
      form: form
    } as PDFSectionProps;
    currentDimensions = SocialHistooryQuestionsSection(sectionProps);

      // Add a blank page to the document
      const page7 = pdfDoc.addPage();
      // POinter to keep track the height of the document
      var heightPointer = 0;
  
      // Header
      heightPointer += await generatePdfHeader(pdfDoc, page7, width, height, timesRomanFont);
  
      sectionProps = {
        formValues: values,
        heightPointer: heightPointer,
        headerFontSize: headerFontSize,
        labelFontSize: labelFontSize,
        page: page7,
        height: height,
        timesRomanFont: timesRomanFont,
        width: width,
        pdfDoc: pdfDoc,
        form: form
      } as PDFSectionProps;
      currentDimensions = MVASection(sectionProps);

    // Add a blank page to the document
    const page8 = pdfDoc.addPage();
    // POinter to keep track the height of the document
    var heightPointer = 0;

    // Header
    heightPointer += await generatePdfHeader(pdfDoc, page8, width, height, timesRomanFont);

    sectionProps = {
      formValues: values,
      heightPointer: heightPointer,
      headerFontSize: headerFontSize,
      labelFontSize: labelFontSize,
      page: page8,
      height: height,
      timesRomanFont: timesRomanFont,
      width: width,
      pdfDoc: pdfDoc,
      form: form
    } as PDFSectionProps;
    currentDimensions = InsuranceSection(sectionProps);


    // generateDemographicQuestions(heightPointer, page, height, timesRomanFont, width, pdfDoc);

    // Serialize the PDFDocument to bytes (a Uint8Array)

    const pdfBytes = await pdfDoc.save();
    uploadToServer(pdfBytes, "pdf");
    MySwal.fire({
      title: "Success",
      html: "Document Signed!",
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#757575",
      cancelButtonText: "OK!",
      confirmButtonText: "Download!",
    }).then((result) => {
      if (result.value) {
        download(pdfBytes, "pdf");
      }
    });
  }
  async function fillPdf(pdfData: ArrayBuffer) {
    const formik = formikRef.current;
    console.log("values", formik);
    const pdfDoc = await PDFDocument.load(pdfData);

    const form = pdfDoc.getForm();

    // Retrieve the PDF fields
    const todayDate = form.getTextField("Text30");
    var dateToday = Moment();
    todayDate.setText(dateToday.format("MM/DD/YYYY"));

    const patientName = form.getTextField("Name");
    patientName.setText(formik.getFieldProps("patientName").value);

    const dateOfBirth = form.getTextField("Date of Birth");
    var dobDate = Moment(formik.getFieldProps("patientDoB").value, "MM/DD/YYYY");
    dateOfBirth.setText(dobDate.format("MM/DD/YYYY"));

    const gender = form.getTextField("Gender");
    gender.setText(formik.getFieldProps("patientSex").value);

    const referringProvider = form.getTextField("Referring Provider");
    referringProvider.setText(formik.getFieldProps("providerName").value);

    // not used for now
    const rfAddress = form.getTextField("Address");
    const rfPhone = form.getTextField("Phone");

    const primaryCareProvider = form.getTextField("Primary Care Provider");
    primaryCareProvider.setText(formik.getFieldProps("patientOMHPCPName").value);

    const pcpAddress = form.getTextField("Address_2");
    pcpAddress.setText(formik.getFieldProps("patientOMHPCPAddress").value);

    const pcpPhone = form.getTextField("Phone_2");
    pcpPhone.setText(formik.getFieldProps("patientOMHPCPPhone").value);

    const painManagementProvider = form.getTextField("Pain Management Provider");
    painManagementProvider.setText(formik.getFieldProps("patientSTDoctorName").value);

    const pmpAddress = form.getTextField("Address_3");
    pmpAddress.setText(formik.getFieldProps("patientSTDoctorAddress").value);

    const pmpPhone = form.getTextField("Phone_3");
    pmpPhone.setText(formik.getFieldProps("patientSTDoctorPhone").value);

    const requestingConsultation = form.getTextField("For what problem are you requesting a consultation");
    requestingConsultation.setText(formik.getFieldProps("patientChiefComplaintProblem").value);

    // Not used now
    const howLongSymptoms = form.getTextField("How long have you had these symptoms");

    const symptomsRelatedToMVAYes = form.getCheckBox("Are your symptoms related to a motor vehicle");
    const symptomsRelatedToMVANo = form.getCheckBox("NO");
    formik.getFieldProps("patientMvaSource").value === "MVA" ? symptomsRelatedToMVAYes.check() : symptomsRelatedToMVAYes.uncheck();
    formik.getFieldProps("patientMvaSource").value === "MVA" ? symptomsRelatedToMVANo.uncheck() : symptomsRelatedToMVANo.check();

    const attorneyName = form.getTextField("NameRow1");
    attorneyName.setText(formik.getFieldProps("patientMVAAttorneyName").value);
    const attorneyAddress = form.getTextField("AddressRow1");
    const attorneyRow = form.getTextField("PhoneRow1");
    attorneyRow.setText(formik.getFieldProps("patientMVAAttorneyPhone").value);
    const attorneyEmail = form.getTextField("EmailRow1");

    const haveYouBeentreatedForTheseSymptomsYes = form.getCheckBox("Check Box36.0");
    const haveYouBeentreatedForTheseSymptomsNo = form.getCheckBox("Check Box36.1");
    formik.getFieldProps("patientMVAAnyTreatmnetsAdministered").value ? haveYouBeentreatedForTheseSymptomsYes.check() : haveYouBeentreatedForTheseSymptomsYes.uncheck();
    formik.getFieldProps("patientMVAAnyTreatmnetsAdministered").value ? haveYouBeentreatedForTheseSymptomsNo.uncheck() : haveYouBeentreatedForTheseSymptomsNo.check();

    const piPracticeName1 = form.getTextField("NameRow1_2");
    piPracticeName1.setText(formik.getFieldProps("patientSTPTPracticeName").value);
    const piPractiveAddress1 = form.getTextField("AddressRow1_2");
    piPractiveAddress1.setText(formik.getFieldProps("patientSTPTAddress").value);
    const piPracticePhone1 = form.getTextField("PhoneRow1_2");
    piPracticePhone1.setText(formik.getFieldProps("patientSTPTPhone").value);
    const piPracticeType1 = form.getTextField("Type of PracticeRow1");
    piPracticePhone1.setText(formik.getFieldProps("patientSTHasTriedPT") === "Yes" ? "Physical Therapy" : "");
    const piPracticeName2 = form.getTextField("NameRow2");
    piPracticeName2.setText(formik.getFieldProps("patientSTChiroPracticeName").value);
    const piPractiveAddress2 = form.getTextField("AddressRow2");
    piPractiveAddress2.setText(formik.getFieldProps("patientSTChiroAddress").value);
    const piPracticePhone2 = form.getTextField("PhoneRow2");
    piPracticePhone2.setText(formik.getFieldProps("patientSTChiroPhone").value);
    const piPracticeType2 = form.getTextField("Type of PracticeRow2");
    piPracticeType2.setText(formik.getFieldProps("patientSTHasTriedChiro") === "Yes" ? "Chiropractic Therapy" : "");
    const piPracticeName3 = form.getTextField("NameRow3");
    const piPractiveAddress3 = form.getTextField("AddressRow3");
    const piPracticePhone3 = form.getTextField("PhoneRow3");
    const piPracticeType3 = form.getTextField("Type of PracticeRow3");
    const piPracticeName4 = form.getTextField("NameRow4");
    const piPractiveAddress4 = form.getTextField("AddressRow4");
    const piPracticePhone4 = form.getTextField("PhoneRow4");
    const piPracticeType4 = form.getTextField("Type of PracticeRow4");

    const haveYouHadRecentImaging = form.getTextField("Have you had any recent imaging ie Xray MRI CT Scan");
    var response = "";
    if (formik.getFieldProps("patientISHadMri").value == "Yes") {
      response += " MRI "
    }
    if (formik.getFieldProps("patientISHadCt").value == "Yes") {
      response += " CT Scan "
    }
    if (formik.getFieldProps("patientISHadXray").value == "Yes") {
      response += " Xray "
    }
    piPracticePhone2.setText(response);

    // Fill in with the arrays of possible images
    const imagingDate1 = form.getTextField("DateRow1");
    const imagingType1 = form.getTextField("Type of ImageRow1");
    const imagingLocation1 = form.getTextField("Location Facility NameRow1");
    const imagingDate2 = form.getTextField("DateRow2");
    const imagingType2 = form.getTextField("Type of ImageRow2");
    const imagingLocation2 = form.getTextField("Location Facility NameRow2");

    const currentHeight = form.getTextField("Current Height");
    currentHeight.setText(formik.getFieldProps("patientBiometricsHeightFt").value + " ft," + formik.getFieldProps("patientBiometricsHeightFt").value + " in");

    const currentWeight = form.getTextField("Current Weight");
    currentWeight.setText(formik.getFieldProps("patientBiometricsWeight").value + " lbs");



    const painLevelToday = form.getTextField("Text31");
    painLevelToday.setText(formik.getFieldProps("patientChiefComplaintPainLevelToday").value);
    const panLevelWeek = form.getTextField("Text32");
    panLevelWeek.setText(formik.getFieldProps("patientChiefComplaintPainLevelWeek").value);

    const patientName2 = form.getTextField("PatientName");
    patientName2.setText(formik.getFieldProps("patientName").value);

    const medAllergy1 = form.getTextField("MedicationMaterial AllergyRow1");
    const medReaction1 = form.getTextField("Reaction you hadRow1");
    const medAllergy2 = form.getTextField("MedicationMaterial AllergyRow2");
    const medReaction2 = form.getTextField("Reaction you hadRow2");
    const medAllergy3 = form.getTextField("MedicationMaterial AllergyRow3");
    const medReaction3 = form.getTextField("Reaction you hadRow3");
    const medAllergy4 = form.getTextField("MedicationMaterial AllergyRow4");
    const medReaction4 = form.getTextField("Reaction you hadRow4");


    const medication1 = form.getTextField("MedicationRow1");
    const medication2 = form.getTextField("MedicationRow2");
    const medication3 = form.getTextField("MedicationRow3");
    const medication4 = form.getTextField("MedicationRow4");
    const medication5 = form.getTextField("MedicationRow5");
    const medication6 = form.getTextField("MedicationRow6");
    const medication7 = form.getTextField("MedicationRow7");
    const medication8 = form.getTextField("MedicationRow8");
    const medication9 = form.getTextField("MedicationRow9");
    const medication10 = form.getTextField("MedicationRow10");
    const medStrength1 = form.getTextField("StrengthRow1");
    const medStrength2 = form.getTextField("StrengthRow2");
    const medStrength3 = form.getTextField("StrengthRow3");
    const medStrength4 = form.getTextField("StrengthRow4");
    const medStrength5 = form.getTextField("StrengthRow5");
    const medStrength6 = form.getTextField("StrengthRow6");
    const medStrength7 = form.getTextField("StrengthRow7");
    const medStrength8 = form.getTextField("StrengthRow8");
    const medStrength9 = form.getTextField("StrengthRow9");
    const medStrength10 = form.getTextField("StrengthRow10");
    const medDose1 = form.getTextField("DoseRow1");
    const medDose2 = form.getTextField("DoseRow2");
    const medDose3 = form.getTextField("DoseRow3");
    const medDose4 = form.getTextField("DoseRow4");
    const medDose5 = form.getTextField("DoseRow5");
    const medDose6 = form.getTextField("DoseRow6");
    const medDose7 = form.getTextField("DoseRow7");
    const medDose8 = form.getTextField("DoseRow8");
    const medDose9 = form.getTextField("DoseRow9");
    const medDose10 = form.getTextField("DoseRow10");
    const medFrequency1 = form.getTextField("FrequencyRow1");
    const medFrequency2 = form.getTextField("FrequencyRow2");
    const medFrequency3 = form.getTextField("FrequencyRow3");
    const medFrequency4 = form.getTextField("FrequencyRow4");
    const medFrequency5 = form.getTextField("FrequencyRow5");
    const medFrequency6 = form.getTextField("FrequencyRow6");
    const medFrequency7 = form.getTextField("FrequencyRow7");
    const medFrequency8 = form.getTextField("FrequencyRow8");
    const medFrequency9 = form.getTextField("FrequencyRow9");
    const medFrequency10 = form.getTextField("FrequencyRow10");

    const prefPharmacyName = form.getTextField("Name_2");
    prefPharmacyName.setText(formik.getFieldProps("patientMedicationsPreferredPharmName").value);

    const prefPharmacyAddress = form.getTextField("Address_4");
    prefPharmacyAddress.setText(formik.getFieldProps("patientMedicationsPreferredPharmAddress").value);
    const prefPharmacyPhone = form.getTextField("Phone_4");
    prefPharmacyPhone.setText(formik.getFieldProps("patientMedicationsPreferredPharmPhone").value);


    const workStatusEmployed = form.getCheckBox("Employed");
    const workStatusUnemployed = form.getCheckBox("Unemployed");
    const workStatusDisabled = form.getCheckBox("Disabled");
    const workStatusRetired = form.getCheckBox("Retired");
    formik.getFieldProps("patientSHWorkStatus").value == "Employed" ? workStatusEmployed.check() : workStatusEmployed.uncheck();
    formik.getFieldProps("patientSHWorkStatus").value == "Unemployed" ? workStatusUnemployed.check() : workStatusUnemployed.uncheck();
    formik.getFieldProps("patientSHWorkStatus").value == "Disabled" ? workStatusDisabled.check() : workStatusDisabled.uncheck();
    formik.getFieldProps("patientSHWorkStatus").value == "Retired" ? workStatusRetired.check() : workStatusRetired.uncheck();

    const whereDoYouWork = form.getTextField("Where do you workRow1");
    whereDoYouWork.setText(formik.getFieldProps("patientSHWorkPlace").value);

    const whatTypeOfWork = form.getTextField("What type of work do you doRow1");
    whatTypeOfWork.setText(formik.getFieldProps("patientSHWorkType").value);

    const doYouUseTobaccoYes = form.getCheckBox("doYouUseTobbacoYes");
    const doYouUseTobaccoNo = form.getCheckBox("doYouUseTobbacoNo");

    formik.getFieldProps("patientSHUsesTobacco").value === "Yes" ? doYouUseTobaccoYes.check() : doYouUseTobaccoYes.uncheck();
    formik.getFieldProps("patientSHUsesTobacco").value === "Yes" ? doYouUseTobaccoNo.uncheck() : doYouUseTobaccoNo.check();

    const cigarretesPerDay = form.getTextField("Cigarettes per day");
    cigarretesPerDay.setText(formik.getFieldProps("patientSHCigarettesPerDay").value);
    const chewPerDay = form.getTextField("Chew per day");
    chewPerDay.setText(formik.getFieldProps("patientSHCigarsPerDay").value);
    const cigarsPerDay = form.getTextField("Cigars per day");
    cigarsPerDay.setText(formik.getFieldProps("patientSHDipsPerDay").value);
    const pipePerDay = form.getTextField("Pipe per day");
    const yearsSmoked = form.getTextField("Number of years smoked");
    const yearQuitSmoking = form.getTextField("Year Quit Smoking");
    yearQuitSmoking.setText(formik.getFieldProps("patientSHTobaccoStopSmokingYear").value);

    const drinkAlcoholYes = form.getCheckBox("drinkAlcoholYes");
    const drinkAlcoholNo = form.getCheckBox("drinkAlcoholNoNo");
    formik.getFieldProps("patientSHDrinksAlcohol").value === "Yes" ? drinkAlcoholYes.check() : drinkAlcoholYes.uncheck();
    formik.getFieldProps("patientSHDrinksAlcohol").value === "Yes" ? drinkAlcoholNo.uncheck() : drinkAlcoholNo.check();


    const howManyDrinks = form.getTextField("How many drinks per week");
    howManyDrinks.setText(formik.getFieldProps("patientSHDrinksPerWeek").value);

    const useDrugsYes = form.getCheckBox("useDrugsYesYes");
    const useDrugsNo = form.getCheckBox("useDrugsNo");
    formik.getFieldProps("patientSHStreetDrugs").value === "Yes" ? useDrugsYes.check() : useDrugsYes.uncheck();
    formik.getFieldProps("patientSHStreetDrugs").value === "Yes" ? useDrugsNo.uncheck() : useDrugsNo.check();

    const feelDepressedYes = form.getCheckBox("depressedYesYes");
    const feelDepressedNo = form.getCheckBox("depressedNo");

    const troubleSleepingYes = form.getCheckBox("troubleSleepingYesYes");
    const troubleSleepingNo = form.getCheckBox("troubleSleepingNo");
    formik.getFieldProps("patientSHTroubleSleeping").value === "Yes" ? troubleSleepingYes.check() : troubleSleepingYes.uncheck();
    formik.getFieldProps("patientSHTroubleSleeping").value === "Yes" ? troubleSleepingNo.uncheck() : troubleSleepingNo.check();

    const suicidalYes = form.getCheckBox("hurtingYourselfYes");
    const suicidalNo = form.getCheckBox("hurtingYourselfNoNo");
    formik.getFieldProps("patientSHSuicidal").value === "Yes" ? suicidalYes.check() : suicidalYes.uncheck();
    formik.getFieldProps("patientSHSuicidal").value === "Yes" ? suicidalNo.uncheck() : suicidalNo.check();


    const hihgBloodPressure = form.getCheckBox("High Blood Pressure");
    const highColesterol = form.getCheckBox("High Cholesterol");
    const gout = form.getCheckBox("undefined_6");
    const stroke = form.getCheckBox("undefined_7");
    const heartFailure = form.getCheckBox("Heart Failure");
    const diabetes = form.getCheckBox("undefined_8");
    const gerd = form.getCheckBox("undefined_9");
    const brainTumor = form.getCheckBox("undefined_10");
    const heartAttack = form.getCheckBox("undefined_11");
    const fibromyalgia = form.getCheckBox("undefined_12");
    const bleedingDisorder = form.getCheckBox("Bleeding Disorder");
    const alzheimer = form.getCheckBox("undefined_13");
    const angina = form.getCheckBox("AnginaChest Pain");
    const rsd = form.getCheckBox("undefined_14");
    const copd = form.getCheckBox("EmphysemaCOPD");
    const dementia = form.getCheckBox("undefined_15");
    const irregularHeartBeat = form.getCheckBox("Irregular Heartbeat");
    const osteorthritis = form.getCheckBox("Osteoarthritis");
    const asthma = form.getCheckBox("undefined_16");
    const parkinson = form.getCheckBox("Parkinsons Disease");
    const valveReplacement = form.getCheckBox("Valve Replacement");
    const reumathoid = form.getCheckBox("Rheumatoid Arthritis");
    const seizure = form.getCheckBox("undefined_17");
    const anxiety = form.getCheckBox("undefined_18");
    const neuropathy = form.getCheckBox("undefined_19");
    const headaches = form.getCheckBox("undefined_20");
    const hepatitis = form.getCheckBox("undefined_21");
    const depression = form.getCheckBox("undefined_22");
    const hiv = form.getCheckBox("undefined_23");
    const tuberculosis = form.getCheckBox("undefined_24");
    const kidneyDisease = form.getCheckBox("Kidney Disease");
    const thyroidDisorder = form.getCheckBox("Thyroid Disorder");

    const sleepApnea = form.getCheckBox("Sleep Apnea");
    const useBibap = form.getCheckBox("Use BIPAP");
    const useCpap = form.getCheckBox("Use CPAP");

    const bloodClots = form.getCheckBox("Blood Clots");
    const bloodCotsWhere = form.getTextField("Text33");
    const bloodCotsWhen = form.getTextField("Text34");

    const cancerType = form.getCheckBox("undefined_25");
    const cancerTypeText = form.getTextField("Cancer type");

    const seriusInjuries = form.getCheckBox("undefined_26");
    const seriusInjuriesText = form.getTextField("Serious Injuries explain");

    const other = form.getCheckBox("undefined_27");
    const otherText = form.getTextField("Other explain");

    const noneOfTheAbove = form.getCheckBox("undefined_28");

    const familyHighBloodPressure = form.getCheckBox("High Blood Pressure_2");
    const familyHighBloodPressureText = form.getTextField("Family MemberHigh Blood Pressure");

    const familyDiabetes = form.getCheckBox("Diabetes_2");
    const familyDiabetesText = form.getTextField("Family MemberDiabetes");

    const familyCancer = form.getCheckBox("Cancer list type after relative");
    const familyCancerText = form.getTextField("Family MemberCancer list type after relative");

    const familyCiribartArtery = form.getCheckBox("Coronary Artery Disease");
    const familyCiribartArteryText = form.getTextField("Family MemberCoronary Artery Disease");

    const familyStroke = form.getCheckBox("Stroke_2");
    const familyStrokeText = form.getTextField("Family MemberStroke");

    const familyBleedingDisorder = form.getCheckBox("Bleeding Disorder_2");
    const familyBleedingDisorderText = form.getTextField("Family MemberBleeding Disorder");

    const familyBloodCots = form.getCheckBox("Blood Clots_2");
    const familyBloodCotsText = form.getTextField("Family MemberBlood Clots");

    const familyHeartAttack = form.getCheckBox("Heart Attack_2");
    const familyHeartAttackText = form.getTextField("Family MemberHeart Attack");

    const familyOther = form.getCheckBox("Other specify after relative");
    const familyOtherText = form.getTextField("Family MemberOther specify after relative");


    const surgeryDate1 = form.getTextField("DateRow1_2");
    const surgeryDate2 = form.getTextField("DateRow2_2");
    const surgeryDate3 = form.getTextField("DateRow3");
    const surgeryDate4 = form.getTextField("DateRow4");
    const surgeryDate5 = form.getTextField("DateRow5");
    const surgeryDesc1 = form.getTextField("SurgeryRow1");
    const surgeryDesc2 = form.getTextField("SurgeryRow2");
    const surgeryDesc3 = form.getTextField("SurgeryRow3");
    const surgeryDesc4 = form.getTextField("SurgeryRow4");
    const surgeryDesc5 = form.getTextField("SurgeryRow5");
    const surgeryDoctor1 = form.getTextField("DoctorFacilityRow1");
    const surgeryDoctor2 = form.getTextField("DoctorFacilityRow2");
    const surgeryDoctor3 = form.getTextField("DoctorFacilityRow3");
    const surgeryDoctor4 = form.getTextField("DoctorFacilityRow4");
    const surgeryDoctor5 = form.getTextField("DoctorFacilityRow5");
    const surgeryComplications1 = form.getTextField("ComplicationsRow1");
    const surgeryComplications2 = form.getTextField("ComplicationsRow2");
    const surgeryComplications3 = form.getTextField("ComplicationsRow3");
    const surgeryComplications4 = form.getTextField("ComplicationsRow4");
    const surgeryComplications5 = form.getTextField("ComplicationsRow5");

    const anesthesiaComplicationsYes = form.getCheckBox("YES_7");
    const anesthesiaComplicationsNo = form.getCheckBox("Check Box35");
    formik.getFieldProps("patientOMHHasAnesthesiaComplications").value === "Yes" ? anesthesiaComplicationsYes.check() : anesthesiaComplicationsYes.uncheck();
    formik.getFieldProps("patientOMHHasAnesthesiaComplications").value === "Yes" ? anesthesiaComplicationsNo.uncheck() : anesthesiaComplicationsNo.check();


    const anesthesiaComplicationsType = form.getTextField("Text37");
    anesthesiaComplicationsType.setText(formik.getFieldProps("patientOMHAnesthesiaComplication").value);


    const fever = form.getCheckBox("undefined_29");
    formik.getFieldProps("patientCCFever").value ? fever.check() : fever.uncheck();

    const visionChanges = form.getCheckBox("Vision Changes");
    formik.getFieldProps("patientCCVision").value ? visionChanges.check() : visionChanges.uncheck();
    const cough = form.getCheckBox("undefined_30");
    formik.getFieldProps("patientCCCough").value ? cough.check() : cough.uncheck();
    const stomachPain = form.getCheckBox("Stomach Pain");
    formik.getFieldProps("patientCCStomach").value ? stomachPain.check() : stomachPain.uncheck();
    const hotSpells = form.getCheckBox("Hot or Cold Spells");
    formik.getFieldProps("patientCCSpells").value ? hotSpells.check() : hotSpells.uncheck();
    const hearingProblems = form.getCheckBox("Hearing Problems");
    formik.getFieldProps("patientCCHearing").value ? hearingProblems.check() : hearingProblems.uncheck();
    const shortnessBreath = form.getCheckBox("Shortness of Breath");
    formik.getFieldProps("patientCCBreath").value ? shortnessBreath.check() : shortnessBreath.uncheck();
    const nausea = form.getCheckBox("undefined_31");
    formik.getFieldProps("patientCCNausea").value ? nausea.check() : nausea.uncheck();
    const weightLoss = form.getCheckBox("undefined_32");
    formik.getFieldProps("patientCCWeightLoss").value ? weightLoss.check() : weightLoss.uncheck();
    const chestPain = form.getCheckBox("undefined_33");
    formik.getFieldProps("patientCCChestPain").value ? chestPain.check() : chestPain.uncheck();
    const musclePain = form.getCheckBox("Muscle PainAche");
    formik.getFieldProps("patientCCMusclePain").value ? musclePain.check() : musclePain.uncheck();
    const vomiting = form.getCheckBox("undefined_34");
    formik.getFieldProps("patientCCVomiting").value ? vomiting.check() : vomiting.uncheck();
    const weightGain = form.getCheckBox("undefined_35");
    formik.getFieldProps("patientCCWeightGain").value ? weightGain.check() : weightGain.uncheck();
    const heartPalpitations = form.getCheckBox("Heart Palpitations");
    formik.getFieldProps("patientCCChestPalpitations").value ? heartPalpitations.check() : heartPalpitations.uncheck();
    const muscleSpasm = form.getCheckBox("Muscle Spasm");
    formik.getFieldProps("patientCCMuscleSpasm").value ? muscleSpasm.check() : muscleSpasm.uncheck();
    const constipation = form.getCheckBox("undefined_36");
    formik.getFieldProps("patientCCConstipation").value ? constipation.check() : constipation.uncheck();
    const headache = form.getCheckBox("undefined_37");
    formik.getFieldProps("patientCCHeadache").value ? headache.check() : headache.uncheck();
    const neckPain = form.getCheckBox("undefined_38");
    formik.getFieldProps("patientCCNeckPain").value ? neckPain.check() : neckPain.uncheck();
    const backPain = form.getCheckBox("undefined_39");
    formik.getFieldProps("patientCCBackPain").value ? backPain.check() : backPain.uncheck();
    const diarrhea = form.getCheckBox("undefined_40");
    formik.getFieldProps("patientCCDiarrhea").value ? diarrhea.check() : diarrhea.uncheck();

    const numbness = form.getCheckBox("undefined_41");
    formik.getFieldProps("patientCCNumbness").value ? numbness.check() : numbness.uncheck();
    const numbnessLocation = form.getTextField("Numbness location");
    numbnessLocation.setText(formik.getFieldProps("patientCCNumbnessDesc").value);

    const weakness = form.getCheckBox("undefined_42");
    formik.getFieldProps("patientCCWeakness").value ? weakness.check() : weakness.uncheck();
    const weaknessLocation = form.getTextField("Weakness location");
    weaknessLocation.setText(formik.getFieldProps("patientCCWeaknessDesc").value);

    const othgerSymptoms = form.getCheckBox("Other Symptoms you are Experiencing_2");
    formik.getFieldProps("patientCCOtherSymptoms").value ? othgerSymptoms.check() : othgerSymptoms.uncheck();
    const otherSymptomsDesc = form.getTextField("Other Symptoms you are Experiencing");
    otherSymptomsDesc.setText(formik.getFieldProps("patientCCOtherSymptomsDesc").value);


    const important1 = form.getTextField("Please list anything else you feel it is important for us to know 1");
    const important2 = form.getTextField("Please list anything else you feel it is important for us to know 2");
    const important3 = form.getTextField("Please list anything else you feel it is important for us to know 3");
    const important4 = form.getTextField("Please list anything else you feel it is important for us to know 4");
    const important5 = form.getTextField("Please list anything else you feel it is important for us to know 5");
    const important6 = form.getTextField("Please list anything else you feel it is important for us to know 6");
    const important7 = form.getTextField("Please list anything else you feel it is important for us to know 7");
    const important8 = form.getTextField("Please list anything else you feel it is important for us to know 8");


    const patientSignature = form.getTextField("Text38"); const mPersonalSignature =
      "ESIGNED By " +
      formik.getFieldProps("patientName").value +
      " on " +
      Moment(fileGeneration?.signatureDataUpdated).format("MM/DD/YYYY");
    patientSignature.setText(mPersonalSignature);
    const date = form.getTextField("Date");
    date.setText(Moment().format("MM/DD/YYYY"));


    const pdfBytes = await pdfDoc.save();
    uploadToServer(pdfBytes, "pdf");
    MySwal.fire({
      title: "Success",
      html: "Document Signed!",
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#757575",
      cancelButtonText: "OK!",
      confirmButtonText: "Download!",
    }).then((result) => {
      if (result.value) {
        download(pdfBytes, "pdf");
      }
    });
  }

  function uploadToServer(arrayBuffer: BlobPart, type: string) {
    var blob = new Blob([arrayBuffer], { type: type });
    dispatch(
      saveDocumentThunkAction({
        data: {
          file: { fileData: blob, filename: "HealthHistoryForm.pdf" },
          contactId: fileGeneration?.contactId,
          caseId: fileGeneration?.caseId,
          fileTypeId: 5,
          fileDescription: "User Generated Form",
        },
        onSuccess: (res) => {
          return Promise.resolve(res);
        },
        onError: (err) => {
          MySwal.fire("Error", "Couldn't save File", "warning");
          return Promise.reject(err);
        },
      })
    );
  }

  function download(arrayBuffer: BlobPart, type: string) {
    var blob = new Blob([arrayBuffer], { type: type });
    var url = URL.createObjectURL(blob);
    var link = document.createElement("a");
    link.href = url;
    link.download = "HealthHistoryForm.pdf";
    link.dispatchEvent(new MouseEvent("click"));
  }

  const handleDemographicsQuestions = (tokenId: string) => {
    dispatch(
      getFileGenerationThunkAction({
        data: { tokenId: tokenId },
        onError: (err) => {
          console.log("error", err);
          MySwal.fire("Error", "Error retrieving the form", "warning");
          return Promise.reject(err);
        },
      })
    );
  };

  const handleScreeningQuestions = (caseId: number) => {
    dispatch(
      getScreeningCaseByCaseIdThunkAction({
        data: caseId,
        onError: (err) => {
          console.log("error", err);
          MySwal.fire(
            "Error",
            "Error retrieving the screening questions",
            "warning"
          );
          return Promise.reject(err);
        },
      })
    );
  };

  const handleInsuranceCarrierValues = () => {
    console.log("handle insurance carrier values")
    dispatch(

      getInsuranceCarrierValuesThunkAction({
        data: "",
        onError: (err) => {
          console.log("error", err);
          MySwal.fire("Error", "Error retrieving the form", "warning");
          return Promise.reject(err);
        },
      })
    );
  };

  useEffect(() => {
    handleDemographicsQuestions(tokenId);
    handleInsuranceCarrierValues();
  }, []);

  useEffect(() => {
    if (isFileGenerationError) {
      console.log("error?", isFileGenerationError);
      // redirigir a una pagina
      //history.push(`/NotFound`);
    }
  }, [isFileGenerationError]);

  // Prepopulate Demographics section
  useEffect(() => {
    if (fileGeneration) {
      if (fileGeneration?.formData) {
        formikRef.current.setValues(JSON.parse(fileGeneration?.formData || ""));
      } else {
        loadDemographicQuestions(formikRef, fileGeneration);
        handleScreeningQuestions(fileGeneration?.caseId || -1);
      }
      formikRef.current.setFieldValue("caseId", fileGeneration?.caseId);
      formikRef.current.setFieldValue("contactId", fileGeneration?.contactId);
      formikRef.current.setFieldValue(
        "generationType",
        fileGeneration?.generationType
      );
      formikRef.current.setFieldValue(
        "hasAgreedToS",
        fileGeneration?.hasAgreedToS == 1
      );
    }
  }, [fileGeneration]);

  // Prepopulate Biometrics
  useEffect(() => {
    if (screeningCase) {
      loadBiometricsQuestions(formikRef, screeningCase);
      loadChiefComplaintQuestions(formikRef, screeningCase);
      loadSpineTreatmentQuestions(formikRef, screeningCase);
      loadSpineSurgeryQuestions(formikRef, screeningCase);
      loadMvaQuestions(formikRef, screeningCase);
      loadInsuranceQuestions(formikRef, screeningCase);
    }
  }, [screeningCase]);

  const renderCurrentStep = (form: FormikProps<FormikValues>) => {
    const step = steps[currentIndex];

    // opportunity to extend commonProps here with other relevant information
    const commonProps = {
      name: step.name,
      form,
    };

    const StepComponent = step.component;

    return (
      <>
        <StepComponent {...commonProps} />{" "}
        <Navigation
          maxSteps={steps.length}
          currentIndex={currentIndex}
          onClickNext={goNext}
          onClickBack={goBack}
          onClickSubmit={goSubmit}
        />
      </>
    );
  };

  const renderLoading = () => {
    return (
      <>
        <Container className="mt-4">
          <Row className="align-items-center">
            <Col>
              <Card>
                <Row>
                  <Col>
                    <Typography variant="h4" className="text-center mt-5">
                      <CustomSpinner></CustomSpinner>
                    </Typography>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  const renderError = () => {
    return (
      <>
        <Container className="mt-4">
          <Row className="align-items-center">
            <Col>
              <Card>
                <Row>
                  <Col>
                    <Typography variant="h2" className="text-center mt-5">
                      Sorry, the form you are looking for couldn't be found.
                    </Typography>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  const handleSubmitQuestionnaire = (values: FormikValues) => {
    // Opportunity to perform API call here
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(10);
      }, 2000);
    }).then(() => {
      //history.push(`/questionnaire/results`, { values });
      //console.log("values: ", values);
    });
  };

  return (
    <>
      <Progress steps={steps} currentIndex={currentIndex} />

      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={getStepSchema(currentIndex, steps)}
        onSubmit={handleSubmitQuestionnaire}
        validateOnMount
      >
        {(form) => {
          return (
            <>
              {isFileGenerationError
                ? renderError()
                : isFileGenerationLoading || isSavingFormData
                  ? renderLoading()
                  : renderCurrentStep(form)}
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default Questionnaire;

const loadDemographicQuestions = (
  formikRef: React.MutableRefObject<any>,
  fileGeneration: FileGeneration
) => {
  formikRef.current.setFieldValue(
    "patientName",
    fileGeneration?.contact?.firstName +
    " " +
    fileGeneration?.contact?.lastName,
    true
  );
  const patientNameFromDB = (fileGeneration?.contact?.firstName || fileGeneration?.contact?.lastName) ? true : false;
  console.log("name," ,patientNameFromDB)
  formikRef.current.setFieldValue(
    "patientNameFromDB",
    patientNameFromDB,
    true
  );
  formikRef.current.setFieldValue(
    "patientDoB",
    Moment(fileGeneration?.contact?.doB).format("MM/DD/YYYY"),
    true
  );
  formikRef.current.setFieldValue(
    "patientMailingAddress",
    fileGeneration?.contact?.address
  );
  formikRef.current.setFieldValue("patientCity", fileGeneration?.contact?.city);
  formikRef.current.setFieldValue(
    "patientState",
    fileGeneration?.contact?.state
  );
  formikRef.current.setFieldValue("patientZip", fileGeneration?.contact?.zip);
  // generate email fields
  fileGeneration?.contact?.contactEmails?.map(
    (item: ContactEmail, i: number) => {
      formikRef.current.setFieldValue("patientEmail" + i, item?.email);
    }
  );
  // generate phone numbers
  fileGeneration?.contact?.contactPhones?.map(
    (item: ContactPhone, i: number) => {
      formikRef.current.setFieldValue("patientPhoneNumber" + i, item?.phone);
    }
  );
  formikRef.current.setFieldValue(
    "patientSex",
    fileGeneration?.contact?.gender
  );
};

const loadBiometricsQuestions = (
  formikRef: React.MutableRefObject<any>,
  screeningCase: ScreeningCase
) => {
  formikRef.current.setFieldValue(
    "patientBiometricsHeightFt",
    screeningCase?.biometricsHeightFt
  );
  formikRef.current.setFieldValue(
    "patientBiometricsHeightIn",
    screeningCase?.biometricsHeightIn
  );
  formikRef.current.setFieldValue(
    "patientBiometricsWeight",
    screeningCase?.biometricsWeightLbs
  );
  formikRef.current.setFieldValue(
    "patientBiometricsBMI",
    screeningCase?.biometricsBMI
  );
};

const loadChiefComplaintQuestions = (
  formikRef: React.MutableRefObject<any>,
  screeningCase: ScreeningCase
) => {
  const painlocationOptions: Option[] = [];
  screeningCase?.treatmentAreaLocationItems
    ?.split(";")
    .map((item: string, index: number) => {
      var option = getOptionByLabel(painLocationOptionsArray, item);
      if (option) {
        painlocationOptions.push(option);
      }
    });
  formikRef.current.setFieldValue("patientCCPainLocation", painlocationOptions);

  if (!isNaN(Number(screeningCase?.treatmentAreaOfMostConcern))) {
    const optionSelected =
      painLocationOptionsArray[Number(screeningCase.treatmentAreaOfMostConcern)]
        .label;
    formikRef.current.setFieldValue("patientCCPainMostConcern", optionSelected);
  }
  const doesItRadiate =
    screeningCase?.treatmentPainRadiatingArm ||
    screeningCase?.treatmentPainRadiatingLeg;
  formikRef.current.setFieldValue(
    "patientCCPainExtremities",
    doesItRadiate ? "Yes" : "No"
  );

  const patientCCPainExtremitiesAreas: Option[] = [];
  const selectedAreas = [];

  switch (screeningCase?.treatmentPainRadiatingArmOption) {
    case "Right":
      selectedAreas.push("RightArm");
      break;
    case "Left":
      selectedAreas.push("LeftArm");
      break;
    case "Both":
      selectedAreas.push("RightArm");
      selectedAreas.push("LeftArm");
      break;
    default:
      break;
  }
  switch (screeningCase?.treatmentPainRadiatingLegOption) {
    case "Right":
      selectedAreas.push("RightHand");
      break;
    case "Left":
      selectedAreas.push("LeftHand");

      break;
    case "Both":
      selectedAreas.push("RightHand");
      selectedAreas.push("LeftHand");
      break;
    default:
      break;
  }

  selectedAreas.map((item: string, index: number) => {
    var option = getOptionByValue(extremitiesOptionsArray, item);
    if (option) {
      patientCCPainExtremitiesAreas.push(option);
    }
  });
  formikRef.current.setFieldValue(
    "patientCCPainExtremitiesAreas",
    patientCCPainExtremitiesAreas
  );

  const activitiesIncreaseOptions: Option[] = [];
  screeningCase?.treatmentActivitiesIncreaseSymptoms
    ?.split(";")
    .map((item: string, index: number) => {
      var option = getOptionByLabel(activitiesOptionsArray, item);
      if (option) {
        activitiesIncreaseOptions.push(option);
      }
    });
  formikRef.current.setFieldValue(
    "patientCCActivitiesIncrease",
    activitiesIncreaseOptions
  );

  formikRef.current.setFieldValue(
    "patientCCIncreaseDescription",
    screeningCase?.treatmentActivitiesIncreaseSymptomsDesc
  );

  const activitiesDecreaseOptions: Option[] = [];
  screeningCase?.treatmentActivitiesDecreaseSymptoms
    ?.split(";")
    .map((item: string, index: number) => {
      var option = getOptionByLabel(activitiesDecreaseArray, item);
      if (option) {
        activitiesDecreaseOptions.push(option);
      }
    });

  formikRef.current.setFieldValue(
    "patientCCActivitiesDecrease",
    activitiesDecreaseOptions
  );

  formikRef.current.setFieldValue(
    "patientCCDecreaseDescription",
    screeningCase?.treatmentActivitiesDecreaseSymptomsDesc
  );
};

const loadSpineTreatmentQuestions = (
  formikRef: React.MutableRefObject<any>,
  screeningCase: ScreeningCase
) => {
  formikRef.current.setFieldValue(
    "patientSTPainRelief",
    screeningCase?.treatmentHasHadPainRelief ? "Yes" : "No"
  );
  formikRef.current.setFieldValue(
    "patientSTHasTriedPT",
    screeningCase?.hasTriedPT ? "Yes" : "No"
  );
  formikRef.current.setFieldValue(
    "patientSTPtHelped",
    screeningCase?.didPtHelp ? "Yes" : "No"
  );

  formikRef.current.setFieldValue(
    "patientSTPTComments",
    screeningCase?.hasTriedPTDesc
  );

  formikRef.current.setFieldValue(
    "patientSTHasTriedChiro",
    screeningCase?.hasTriedChiropractic ? "Yes" : "No"
  );

  formikRef.current.setFieldValue(
    "patientSTChiroHelped",
    screeningCase?.didChiropracticHelp ? "Yes" : "No"
  );

  formikRef.current.setFieldValue(
    "patientSTChiroComments",
    screeningCase?.hasTriedChiropracticDesc
  );
};

const loadSpineSurgeryQuestions = (
  formikRef: React.MutableRefObject<any>,
  screeningCase: ScreeningCase
) => {
  // patientSTHasHadPrevSurgery
  formikRef.current.setFieldValue(
    "patientSTHasHadPrevSurgery",
    screeningCase?.treatmentHasHadPrevSurgery ? "Yes" : "No"
  );

  if (screeningCase?.screeningCaseDynamicQuestions) {
    // patientSSPrevSurgerySegment
    if (screeningCase?.screeningCaseDynamicQuestions[0]) {
      formikRef.current.setFieldValue(
        "patientSSPrevSurgerySegment",
        screeningCase?.screeningCaseDynamicQuestions[0].questionResponse
      );
    }
    // patientSSPrevSurgeryType
    if (screeningCase?.screeningCaseDynamicQuestions[1]) {
      formikRef.current.setFieldValue(
        "patientSSPrevSurgeryType",
        screeningCase?.screeningCaseDynamicQuestions[1].questionResponse
      );
    }
  }

  // patientSSPrevSurgeryDescription
  formikRef.current.setFieldValue(
    "patientSSPrevSurgeryDescription",
    screeningCase?.treatmentNotes
  );
};

const loadMvaQuestions = (
  formikRef: React.MutableRefObject<any>,
  screeningCase: ScreeningCase
) => {
  // patientMVAAccidentDate
  formikRef.current.setFieldValue(
    "patientMVAAccidentDate",
    screeningCase?.accidentDate
  );

  // patientMVATimeOfDay
  formikRef.current.setFieldValue(
    "patientMVATimeOfDay",
    screeningCase?.accidentTimeOfDay
  );

  // patientMVARoadContions
  formikRef.current.setFieldValue(
    "patientMVARoadContions",
    screeningCase?.roadConditions
  );

  // patientMVAAccidentPlace
  formikRef.current.setFieldValue(
    "patientMVAAccidentPlace",
    screeningCase?.mvaPlace
  );

  // patientMVAVehicleType
  formikRef.current.setFieldValue(
    "patientMVAVehicleType",
    screeningCase?.mvaUserVehicle
  );

  // patientMVAVehicleCollided
  formikRef.current.setFieldValue(
    "patientMVAVehicleCollided",
    screeningCase?.mvaCollidingVehicle
  );

  // patientMVAVector
  formikRef.current.setFieldValue("patientMVAVector", screeningCase?.mvaVector);

  // patientMVAVehicleSpeed
  formikRef.current.setFieldValue(
    "patientMVAVehicleSpeed",
    screeningCase?.mvaSpeed
  );

  // patientMVAPosition
  formikRef.current.setFieldValue(
    "patientMVAPosition",
    screeningCase?.mvaUserPosition
  );

  //patientMVAHandsOnWheel
  formikRef.current.setFieldValue(
    "patientMVAHandsOnWheel",
    screeningCase?.mvaUserHadHansOnWheel ? "Yes" : "No"
  );

  // patientMVAUserRestrained
  formikRef.current.setFieldValue(
    "patientMVAUserRestrained",
    screeningCase?.mvaUserHadSeatbelt ? "Yes" : "No"
  );

  // patientMVAHeadHitAnything
  formikRef.current.setFieldValue(
    "patientMVAHeadHitAnything",
    screeningCase?.mvaUserHeadHitAnything
  );

  // patientMVAUserLostConciousness
  formikRef.current.setFieldValue(
    "patientMVAUserLostConciousness",
    screeningCase?.mvaUserLostConsciuousness ? "Yes" : "No"
  );

  // patientMVALostConsciousnessHowLong
  formikRef.current.setFieldValue(
    "patientMVALostConsciousnessHowLong",
    screeningCase?.mvaUserHowLongLostConsciousness
  );

  // patientMVAPoliceCameToMVA
  formikRef.current.setFieldValue(
    "patientMVAPoliceCameToMVA",
    screeningCase?.mvaWasPoliceInvolved ? "Yes" : "No"
  );

  // patientMVAWhoWasCited
  formikRef.current.setFieldValue(
    "patientMVAWhoWasCited",
    screeningCase?.mvaWhoCitedPolice
  );

  // patientMVAHospitalOptions
  formikRef.current.setFieldValue(
    "patientMVAHospitalOptions",
    screeningCase?.mvaUserWentToHospital
  );

  // patientMVAHowRideToHospitalOptions
  formikRef.current.setFieldValue(
    "patientMVAHowRideToHospitalOptions",
    screeningCase?.mvaUserWentToHospital
  );

  // patientMVAWasUserAdmitted
  formikRef.current.setFieldValue(
    "patientMVAWasUserAdmitted",
    screeningCase?.mvaUserWasAdmittedToHospital ? "Yes" : "No"
  );

  // patientMVADateDischarged
  formikRef.current.setFieldValue(
    "patientMVADateDischarged",
    screeningCase?.mvaUserDateDischarged
  );

  // patientMVAHowTestPerformed
  formikRef.current.setFieldValue(
    "patientMVAHowTestPerformed",
    screeningCase?.mvaTestsPerformed
  );

  // patientMVAHospitalOtherDetails
  formikRef.current.setFieldValue(
    "patientMVAHospitalOtherDetails",
    screeningCase?.mvaTestsOtherDetails ? "Yes" : "No"
  );

  // patientMVAAnyTreatmnetsAdministered
  formikRef.current.setFieldValue(
    "patientMVAAnyTreatmnetsAdministered",
    screeningCase?.mvaUserAnyTreatments ? "Yes" : "No"
  );

  // patientMVAOtherTreatmentsAdministeredDetails
  formikRef.current.setFieldValue(
    "patientMVAOtherTreatmentsAdministeredDetails",
    screeningCase?.mvaUserAnyTreatmentsDetails
  );

  // patientMVAFollowUpRecommendations
  formikRef.current.setFieldValue(
    "patientMVAFollowUpRecommendations",
    screeningCase?.mvaUserAnyRecommendations ? "Yes" : "No"
  );

  // patientMVAFollowUpRecommendationsDetails
  formikRef.current.setFieldValue(
    "patientMVAFollowUpRecommendationsDetails",
    screeningCase?.mvaUserAnyRecommendationsOther
  );
};

const loadInsuranceQuestions = (
  formikRef: React.MutableRefObject<any>,
  screeningCase: ScreeningCase
) => {
  if (!screeningCase?.case?.contact?.contactInsurances) {
    return;
  }
  var insuranceCase = screeningCase?.case?.contact?.contactInsurances[0];
  // patientInsuranceCarrier
  formikRef.current.setFieldValue(
    "patientInsuranceCarrier",
    insuranceCase.insurer?.name
  );

  // patientInsurancePolicyNumber
  formikRef.current.setFieldValue(
    "patientInsurancePolicyNumber",
    insuranceCase?.policyNumber
  );

  // patientInsurancePolicyGroupNumber
  formikRef.current.setFieldValue(
    "patientInsurancePolicyGroupNumber",
    insuranceCase?.policyGroupNumber
  );

  // patientInsurancePolicyHolderName
  formikRef.current.setFieldValue(
    "patientInsurancePolicyHolderName",
    insuranceCase?.policyHolder
  );

  // patientInsurancePolicyHolderDoB
  formikRef.current.setFieldValue(
    "patientInsurancePolicyHolderDoB",
    insuranceCase?.policyHolderDoB
  );

  // patientInsuranceStartDate
  formikRef.current.setFieldValue(
    "patientInsuranceStartDate",
    insuranceCase?.startDate
  );

  // patientInsuranceEndDate
  formikRef.current.setFieldValue(
    "patientInsuranceEndDate",
    insuranceCase?.endDate
  );

  // patientInsurancePhone
  formikRef.current.setFieldValue(
    "patientInsurancePhone",
    insuranceCase?.phoneNumber
  );
};

async function generatePdfHeader(pdfDoc: PDFDocument, page: PDFPage, width: number, height: number, timesRomanFont: PDFFont) {
  const fileUrl = logo; // provide file location

  // We get the file Bytes
  const fileBytes = await fetch(fileUrl)
    .then(r => r.arrayBuffer())
    .then(t => { return t; });

  const pngImage = await pdfDoc.embedPng(fileBytes);
  // Get the width/height of the PNG image scaled down to 50% of its original size
  const pngDims = pngImage.scale(0.1);
  // Draw the PNG image near the lower right corner of the JPG image
  var pngWidth = pngDims.width;
  var pngHeight = pngDims.height;

  var imageHeight = pngHeight + 20;
  page.drawImage(pngImage, {
    //x: page.getWidth() / 2 - pngDims.width / 2 + 75,
    //y: page.getHeight() / 2 - pngDims.height,
    x: 50,
    y: page.getHeight() - imageHeight,
    width: pngWidth,
    height: pngHeight,
  });

  const fontSize = 15;
  const textHeight = 4 * fontSize;
  page.drawText('HEALTH HISTORY QUESTIONNAIRE', {
    x: width - 300,
    y: height - textHeight,
    size: fontSize,
    font: timesRomanFont,
    color: rgb(0, 0, 0),
    opacity: 1,
  });

  // We save the highest height so the next element knows where to set the height
  var elementHeigh = (imageHeight > textHeight) ? imageHeight : textHeight;

  return elementHeigh;

}

