// React
import { useEffect, useState } from "react";
// Components
import { Typography, Card, Button } from "@material-ui/core";
import { Row, Col, Form, Container } from "react-bootstrap";
// Types
import { FormikProps, FormikValues, ErrorMessage, useFormikContext } from "formik";
// Util
import { getInputProps } from "utils/constants/forms";
// Dropzone
import Dropzone, {
  IDropzoneProps,
  ILayoutProps,
} from "react-dropzone-uploader";
import "components/dropzone/Dropzone.styles.css";
import { useAppDispatch } from "store/store";
import { saveDocumentThunkAction } from "store/documents/documents.slice";
// Swal
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
// Dates
import Moment from "moment";
import DatePicker from "react-datepicker";
import { isValidDate } from "utils/ValidationUtils";
import { insurerListSelector } from "store/documents/documents.selectors";
import { useDocuments } from "utils/hooks/useDocuments.hook";
import { Insurer } from "utils/model/Insurer";

type FirstNameProps = {
  form: FormikProps<FormikValues>;
  patientName: "InsuranceQuestions";
};

const InsuranceQuestions = ({ form, patientName }: FirstNameProps) => {

  const { handleChange, getFieldProps, setFieldValue } = useFormikContext();


  const {
    insurerList,
  } = useDocuments();

  const caseId = getFieldProps("caseId").value;
  const contactId = getFieldProps("contactId").value;
  const generationType = getFieldProps("generationType").value;
  const dispatch = useAppDispatch();
  const MySwal = withReactContent(Swal);

  
  const [insurerOptions, setInsurerOptions] = useState<Insurer[]>([]);

  useEffect(() => {
    if(insurerList){
      console.log("Insurer List", insurerList);
      setInsurerOptions(insurerList);
    }
  }, [insurerList]);


  const getUploadParamsFront: IDropzoneProps["getUploadParams"] = ({
    file,
    meta,
  }) => {
    uploadToServer(file, file.type, file.name, "Insurance Card - Front");
    return {
      url: "https://httpbin.org/post",
    };
  };

  const getUploadParamsBack: IDropzoneProps["getUploadParams"] = ({
    file,
    meta,
  }) => {
    uploadToServer(file, file.type, file.name, "Insurance Card - Back");
    return {
      url: "https://httpbin.org/post",
    };
  };

  const handleSubmit: IDropzoneProps["onSubmit"] = (files, allFiles) => {
    console.log(files.map((f) => f.meta));
    allFiles.forEach((f) => f.remove());
  };

  const handleChangeStatus: IDropzoneProps["onChangeStatus"] = (
    { meta },
    status
  ) => {
    console.log(status, meta);
  };
  function uploadToServer(
    blob: Blob,
    type: string,
    name: string,
    fileDesc: string
  ) {
    //var blob = new Blob([arrayBuffer], { type: type });
    dispatch(
      saveDocumentThunkAction({
        data: {
          file: { fileData: blob, filename: name },
          contactId: contactId,
          caseId: caseId,
          fileTypeId: 4,
          fileDescription: fileDesc,
        },
        onSuccess: (res) => {
          return Promise.resolve(res);
        },
        onError: (err) => {
          MySwal.fire("Error", "Couldn't save File", "warning");
          return Promise.reject(err);
        },
      })
    );
  }
  // Handle DoB Date
  var policyHolderDoB = getFieldProps("patientInsurancePolicyHolderDoB").value;
  var date = null;
  if (isValidDate(policyHolderDoB)) {
    const mDate = Moment(policyHolderDoB, "MM/DD/YYYY");
    date = new Date(mDate.toDate());
  }
  const [pholderDoB, setPHolderDoB] = useState<Date | null>(date);

  // Handle Start Date
  var policyStartDate = getFieldProps("patientInsuranceStartDate").value;
  var date = null;
  if (isValidDate(policyStartDate)) {
    const mDate = Moment(policyStartDate, "YYYY-MM-DD");
    date = new Date(mDate.toDate());
  }
  const [startDate, setStartDate] = useState<Date | null>(date);

  // Handle EndDate
  var policyEndDate = getFieldProps("patientInsuranceEndDate").value;
  var date = null;
  if (isValidDate(policyEndDate)) {
    const mDate = Moment(policyEndDate, "YYYY-MM-DD");
    date = new Date(mDate.toDate());
  }
  const [endDate, setEndDate] = useState<Date | null>(date);

  return (
    <>
      <Container className="mt-4">
        <Row className="align-items-center">
          <Col>
            <Card>
              <Row>
                <Col>
                  <Typography variant="h4" className="text-center mt-5">
                    Insurance Questions
                  </Typography>
                </Col>
              </Row>
              <Form.Row className="mt-5 mr-3 ml-3">
                <Col xs={12} md={3} className="my-1">
                  <Form.Label column>Insurance Carrier</Form.Label>
                </Col>
                <Col xs={12} md={3} className="my-1">
                  <Form.Control
                    as="select"
                    custom
                    type="text"
                    placeholder="Patient Insurance Carrier"
                    {...getInputProps("patientInsuranceCarrier", form)}
                  >
                    <option>-- Select Insurance Carrier --</option>
                    {insurerList.map((item: Insurer, i: number) => {
                      return <option key={i}>{item.name}</option>;
                    })}
                  </Form.Control>
                  <ErrorMessage
                    name="patientInsuranceCarrier"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
              </Form.Row>
              <Form.Row className="mr-3 ml-3">
                <Col xs={12} md={3} className="my-1">
                  <Form.Label column>Policy Number</Form.Label>
                </Col>
                <Col xs={12} md={3} className="my-1">
                  <Form.Control
                    type="text"
                    placeholder="Policy Number"
                    {...getInputProps("patientInsurancePolicyNumber", form)}
                  />
                  <ErrorMessage
                    name="patientInsurancePolicyNumber"
                    component="div"
                    data-testid="email-error"
                  />
                </Col>
                <Col xs={12} md={3} className="my-1">
                  <Form.Label column>Policy Group Number</Form.Label>
                </Col>
                <Col xs={12} md={3} className="my-1">
                  <Form.Control
                    type="text"
                    placeholder="Policy Group Number"
                    {...getInputProps(
                      "patientInsurancePolicyGroupNumber",
                      form
                    )}
                  />
                  <ErrorMessage
                    name="patientInsurancePolicyGroupNumber"
                    component="div"
                    data-testid="email-error"
                  />
                </Col>
                <Col xs={12} md={3} className="my-1">
                  <Form.Label column>Policy Holder Name</Form.Label>
                </Col>
                <Col xs={12} md={3} className="my-1">
                  <Form.Control
                    type="text"
                    placeholder="Policy Holder Name"
                    {...getInputProps("patientInsurancePolicyHolderName", form)}
                  />
                  <ErrorMessage
                    name="patientInsurancePolicyHolderName"
                    component="div"
                    data-testid="email-error"
                  />
                </Col>
                <Col xs={12} md={3} className="my-1">
                  <Form.Label column>Policy Holder DoB</Form.Label>
                </Col>
                <Col xs={12} md={3} className="my-1">
                  <label>
                    <DatePicker
                      className="form-control"
                      placeholderText="MM/dd/yyyy*"
                      dateFormat="MM/dd/yyyy"
                      isClearable={true}
                      selected={pholderDoB}
                      onChange={(date) => {
                        if (date == null || !Array.isArray(date)) {
                          form.setFieldValue("patientInsurancePolicyHolderDoB", Moment(date).format("MM/DD/YYYY"));
                          setPHolderDoB(date);
                        }
                      }
                      }
                    />
                  </label>
                  &nbsp;<i className="far fa-calendar-alt fa-2x" onClick={() => {

                  }}></i>
                </Col>
              </Form.Row>
              <Form.Row className=" mr-3 ml-3">
                <Col xs={12} md={12} className="my-1">
                  <Form.Label column>Effective Dates:</Form.Label>
                </Col>
              </Form.Row>

              <Form.Row className="mr-3 ml-3">
                <Col xs={12} md={3} className="my-1">
                  <Form.Label column>Start date</Form.Label>
                </Col>
                <Col xs={12} md={3} className="my-1">
                  <label>
                    <DatePicker
                      className="form-control"
                      placeholderText="MM/dd/yyyy*"
                      dateFormat="MM/dd/yyyy"
                      isClearable={true}
                      selected={startDate}
                      onChange={(date) => {
                        if (date == null || !Array.isArray(date)) {
                          form.setFieldValue("patientInsuranceStartDate", Moment(date).format("YYYY-MM-DD"));
                          setStartDate(date);
                        }
                      }
                      }
                    />
                  </label>
                  &nbsp;<i className="far fa-calendar-alt fa-2x" onClick={() => {

                  }}></i>
                </Col>
                <Col xs={12} md={3} className="my-1">
                  <Form.Label column>End date</Form.Label>
                </Col>

                <Col xs={12} md={3} className="my-1">
                  <label>
                    <DatePicker
                      className="form-control"
                      placeholderText="MM/dd/yyyy*"
                      dateFormat="MM/dd/yyyy"
                      isClearable={true}
                      selected={endDate}
                      onChange={(date) => {
                        if (date == null || !Array.isArray(date)) {
                          form.setFieldValue("patientInsuranceEndDate", Moment(date).format("YYYY-MM-DD"));
                          setEndDate(date);
                        }
                      }
                      }
                    />
                  </label>
                  &nbsp;<i className="far fa-calendar-alt fa-2x" onClick={() => {

                  }}></i>
                </Col>
              </Form.Row>

              <Form.Row className="mr-3 ml-3">
                <Col xs={12} md={6} className="my-1">
                  <Form.Label column>Insurance Phone</Form.Label>
                </Col>
                <Col xs={12} md={6} className="my-1">
                  <Form.Control
                    type="text"
                    placeholder="Insurance Phone"
                    {...getInputProps("patientInsurancePhone", form)}
                  />
                  <ErrorMessage
                    name="patientInsurancePhone"
                    component="div"
                    data-testid="email-error"
                  />
                </Col>
                <Col xs={12} md={6} className="my-1">
                  <Form.Label column>Insurance Card</Form.Label>
                </Col>
                <Col xs={6} md={3} className="my-1">
                  <Dropzone
                    maxFiles={1}
                    multiple={false}
                    getUploadParams={getUploadParamsFront}
                    onChangeStatus={handleChangeStatus}
                    onSubmit={handleSubmit}
                    accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                    inputContent={(files, extra) =>
                      extra.reject
                        ? "Image or files only"
                        : "Click or Drag File - Front"
                    }
                    styles={{
                      dropzoneReject: {
                        borderColor: "red",
                        backgroundColor: "#DAA",
                      },
                      inputLabel: (files, extra) =>
                        extra.reject ? { color: "red" } : {},
                    }}
                  />
                </Col>
                <Col xs={6} md={3} className="my-1">
                  <Dropzone
                    maxFiles={1}
                    multiple={false}
                    getUploadParams={getUploadParamsBack}
                    onChangeStatus={handleChangeStatus}
                    onSubmit={handleSubmit}
                    accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                    inputContent={(files, extra) =>
                      extra.reject
                        ? "Image or files only"
                        : "Click or Drag File - Back"
                    }
                    styles={{
                      dropzoneReject: {
                        borderColor: "red",
                        backgroundColor: "#DAA",
                      },
                      inputLabel: (files, extra) =>
                        extra.reject ? { color: "red" } : {},
                    }}
                  />
                </Col>
              </Form.Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default InsuranceQuestions;
