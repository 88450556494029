import moment from "moment";
import { PDFFont, PDFForm, PDFPage, rgb } from "pdf-lib";
import PDFCheckboxFieldComponent from "../components/CheckboxFieldComponent";
import PDFTextFieldComponent from "../components/TextFieldComponent";
import { CurrentDimensions } from "../model/CurrentDimensions";
import { PDFSectionProps } from "../model/PDFSectionProps";


const ImagingStudiesSection = (props: PDFSectionProps) => {
    // Header style
    const headerFontSize = props.headerFontSize;
    const textHeight = 4 * headerFontSize;

    // Label Style
    const labelFontSize = props.labelFontSize;
    const labelHeight = 4 * labelFontSize;

    // Keep track of the height and width
    var currentDimensions = { height: props.heightPointer + textHeight, width: 0 } as CurrentDimensions;
    props.page.drawText('Imaging Studies Questions', {
        x: 30,
        y: props.height - currentDimensions.height,
        size: headerFontSize,
        font: props.timesRomanFont,
        color: rgb(0, 0, 0),
        opacity: 1,
    });


    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'had.mri.field',
            fieldMargin: 155,
            fieldWidth: 10,
            fieldValue: props.formValues.patientISHadMri
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Have you had an MRI?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'area.of.spine.field',
            fieldMargin: 110,
            fieldWidth: 90,
            fieldValue: props.formValues.patientISMriArea0
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Area of spine:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });

    var patientISMriDate0 = '-';
    if (moment(props.formValues.patientISMriDate0, "YYYY-MM-DD").isValid()) { 
        patientISMriDate0 = moment(props.formValues.patientISMriDate0, "YYYY-MM-DD").format("MM/DD/YYYY") 
    }
    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'date.mri.field',
            fieldMargin: 80,
            fieldWidth: 60,
            fieldValue: patientISMriDate0
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Date:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'imaging.center.name.field',
            fieldMargin: 140,
            fieldWidth: 80,
            fieldValue: props.formValues.patientISMriCenterName0
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Imaging Center Name:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });
    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'imaging.center.phone.field',
            fieldMargin: 150,
            fieldWidth: 90,
            fieldValue: props.formValues.patientISMriCenterPhone0
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Imaging Center Phone:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'imaging.center.address.field',
            fieldMargin: 155,
            fieldWidth: 100,
            fieldValue: props.formValues.patientISMriCenterAddress0
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Imaging Center Address:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });



    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'had.ctscan.field',
            fieldMargin: 170,
            fieldWidth: 10,
            fieldValue: props.formValues.patientISHadCt
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Have you had a CT Scan?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'area.of.spine.ctscan.field',
            fieldMargin: 110,
            fieldWidth: 90,
            fieldValue: props.formValues.patientISCtArea0
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Area of spine:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });

    var patientISCtDate0 = '-';
    if (moment(props.formValues.patientISCtDate0, "YYYY-MM-DD").isValid()) { 
        patientISCtDate0 = moment(props.formValues.patientISCtDate0, "YYYY-MM-DD").format("MM/DD/YYYY") 
    }
    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'date.ctscan.field',
            fieldMargin: 80,
            fieldWidth: 60,
            fieldValue: patientISCtDate0
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Date:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'imaging.center.name.ctscan.field',
            fieldMargin: 140,
            fieldWidth: 80,
            fieldValue: props.formValues.patientISCtCenterName0
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Imaging Center Name:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });
    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'imaging.center.phone.ctscan.field',
            fieldMargin: 150,
            fieldWidth: 90,
            fieldValue: props.formValues.patientISCtCenterPhone0
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Imaging Center Phone:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'imaging.center.address.ctscan.field',
            fieldMargin: 155,
            fieldWidth: 100,
            fieldValue: props.formValues.patientISCtCenterAddress0
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Imaging Center Address:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });


    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'had.xray.field',
            fieldMargin: 210,
            fieldWidth: 10,
            fieldValue: props.formValues.patientISHadXray
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Have you had an XRay of the spine?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'area.of.spine.xray.field',
            fieldMargin: 110,
            fieldWidth: 90,
            fieldValue: props.formValues.patientISXrayArea0
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Area of spine:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });
    var patientISXrayDate0 = '-';
    if (moment(props.formValues.patientISXrayDate0, "YYYY-MM-DD").isValid()) { 
        patientISXrayDate0 = moment(props.formValues.patientISXrayDate0, "YYYY-MM-DD").format("MM/DD/YYYY") 
    }
    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'date.xray.field',
            fieldMargin: 80,
            fieldWidth: 60,
            fieldValue: props.formValues.patientISXrayDate0
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Date:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'imaging.center.name.xray.field',
            fieldMargin: 140,
            fieldWidth: 80,
            fieldValue: props.formValues.patientISXRayCenterName0
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Imaging Center Name:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });
    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'imaging.center.phone.xray.field',
            fieldMargin: 150,
            fieldWidth: 90,
            fieldValue: props.formValues.patientISXRayCenterPhone0
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Imaging Center Phone:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'imaging.center.address.xray.field',
            fieldMargin: 155,
            fieldWidth: 100,
            fieldValue: props.formValues.patientISXRayCenterAddress0
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Imaging Center Address:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });

    return currentDimensions;
};

export default ImagingStudiesSection;
