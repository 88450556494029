import { RootState } from 'store/rootReducer';

export const fileExportSelector = (state: RootState) => state.documents.fileExport;
export const fileGenerationSelector = (state: RootState) => state.documents.fileGeneration;
export const isFileGenerationLoadingSelector = (state: RootState) => state.documents.isFileGenerationLoading;
export const isFileGenerationErrorSelector = (state: RootState) => state.documents.isFileGenerationError;
export const fileGenerationPhoneIndexSelector = (state: RootState) => state.documents.fileGenerationPhoneIndex;
export const fileGenerationEmailIndexSelector = (state: RootState) => state.documents.fileGenerationEmailIndex;
export const isSavingFormDataSelector = (state: RootState) => state.documents.isSavingFormData;
export const screeningCaseSelector = (state: RootState) => state.documents.screeningCase;
export const isFileExportLoadingSelector = (state: RootState) => state.documents.isFileExportLoading;
export const canSignHipaaSelector = (state: RootState) => state.documents.canSignHipaa;
export const canDownloadPdfSelector = (state: RootState) => state.documents.canDownloadPdf;
export const documentSigningDateSelector = (state: RootState) => state.documents.documentSigningDate;
export const pdfBytesSelector = (state: RootState) => state.documents.pdfBytes;
export const insurerListSelector = (state: RootState) => state.documents.insurerList;