import moment from "moment";

import * as Yup from "yup";
const rePhoneNumber = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

const name = Yup.string()
  .min(2, "First name must have 2 characters")
  .max(20, "Invalid first name")
  .required("Required");

const lastname = Yup.string()
  .min(2, "Last name must have 2 characters")
  .max(20, "Invalid last name")
  .required("Required");

const email = Yup.string().email("Invalid email").required("Required");

const password = Yup.string()
  .min(8, "Password must have 8 characters")
  .required("Required");

const phone = Yup.string()
  .matches(rePhoneNumber, "Invalid Phone Number")
  .required("Required");

const securityToken = Yup.string().required("Required");

 const ResetPasswordSchema = Yup.object().shape({
  email,
});

 const CreateAccountSchema = Yup.object().shape({
  name,
  lastname,
  email,
  phone,
  securityToken,
});

const PatientName = Yup.string()
  .label("Patient Name")
  .min(2, "First name must have 2 characters")
  .required("Required");
const PatientDoB = Yup.string()
  .required()
  .label("Patient Date of Birth")
  .test(
    "PatientDoB",
    "Patient Date Of Birth must be a valid date and have the following format: mm/dd/yyyy",
    (value) => {
      var aDate = moment(value, "MM/DD/YYYY");
      var isValid = aDate.isValid();
      return isValid;
    }
  );
const PhoneNumber = Yup.string()
  .label("Phone Number")
  .matches(rePhoneNumber, "Invalid Phone Number")
  .required("Required");

const MriFacility = Yup.string();
const MriFacilityNumber = Yup.string().matches(
  rePhoneNumber,
  "Invalid Phone Number"
);
const PmMd = Yup.string();
const PmMdNumber = Yup.string().matches(rePhoneNumber, "Invalid Phone Number");
const PhysicalTherapy = Yup.string();
const PhysicalTherapyNumber = Yup.string().matches(
  rePhoneNumber,
  "Invalid Phone Number"
);
const ChiroCare = Yup.string();
const ChiroCareNumber = Yup.string().matches(
  rePhoneNumber,
  "Invalid Phone Number"
);
const PrimaryCarePhysician = Yup.string();
const PrimaryCarePhysicianNumber = Yup.string().matches(
  rePhoneNumber,
  "Invalid Phone Number"
);
const OtherSpecialist = Yup.string();
const OtherSpecialistNumber = Yup.string().matches(
  rePhoneNumber,
  "Invalid Phone Number"
);
const EntireRecord = Yup.boolean();
const BillingRecords = Yup.boolean();
const MedicalRecords = Yup.boolean();
const OtherRecords = Yup.boolean();
const OtherRecordsText = Yup.string();
const FurtherMedicalCare = Yup.boolean();
const ChangingPhysicans = Yup.boolean();
const Insurance = Yup.boolean();
const OtherPurpose = Yup.boolean();
const OtherPurposeText = Yup.string();
const Research = Yup.boolean();
const Attorney = Yup.boolean();
const PersonalUse = Yup.boolean();
const AgreeConfidentiality = Yup.bool().oneOf([true], "Field must be checked");
const PartientSignature = Yup.string();
//const Date = Yup.string();
//const PrintedName = Yup.string();
//const isSave = Yup.string();
//const isSaveToPdf = Yup.string();
const phiFacilities = Yup.string().label("Authorized Facilities or Providers");
const phiPatientName = Yup.string().label("First Name");
const phiPatientLastName = Yup.string().label("Last Name");
const phiPatientPhone = Yup.string()
  .label("Phone Number")
  .matches(rePhoneNumber, "Invalid Phone Number");
const phiPatientMail = Yup.string().label("email").email("Invalid email");
const phiPatientMailingAddress = Yup.string().label("mailing Address");
const phiMedicalRecords = Yup.boolean();
const phiTreatmentPlan = Yup.boolean();
const phiAppointments = Yup.boolean();
const phiOther = Yup.boolean();
const phiOtherText = Yup.string();
const phiOtherPurpose = Yup.string();
const phiExpirationDate = Yup.string()
  .label("Expiration Date")
  .test(
    "phiExpirationDate",
    "Expiration Date must be a valid date and have the following format: MM-DD-YYYY",
    (value) => {
      if (value && value === "") {
        var aDate = moment(value, "MM-DD-YYYY", true);
        var isValid = aDate.isValid();
        return isValid;
      } else {
        return true;
      }
    }
  );
const phiExpiryOther = Yup.string();

export const HipaaQuestionsStepSchema = Yup.object().shape({
  PatientName,
  PatientDoB,
  PhoneNumber,
  MriFacility,
  MriFacilityNumber,
  PmMd,
  PmMdNumber,
  PhysicalTherapy,
  PhysicalTherapyNumber,
  ChiroCare,
  ChiroCareNumber,
  PrimaryCarePhysician,
  PrimaryCarePhysicianNumber,
  OtherSpecialist,
  OtherSpecialistNumber,
  EntireRecord,
  BillingRecords,
  MedicalRecords,
  OtherRecords,
  OtherRecordsText,
  FurtherMedicalCare,
  ChangingPhysicans,
  Insurance,
  OtherPurpose,
  OtherPurposeText,
  Research,
  Attorney,
  PersonalUse,
  AgreeConfidentiality,
  PartientSignature,
  //Date,
  //PrintedName,
  //isSave,
  //isSaveToPdf,
  phiFacilities,
  phiPatientName,
  phiPatientLastName,
  phiPatientPhone,
  phiPatientMail,
  phiPatientMailingAddress,
  phiMedicalRecords,
  phiTreatmentPlan,
  phiAppointments,
  phiOther,
  phiOtherText,
  phiOtherPurpose,
  phiExpirationDate,
  phiExpiryOther,
});
