// Components
import { Typography, Card } from "@material-ui/core";
import { Row, Col, Form, Container } from "react-bootstrap";
// Types
import { ErrorMessage, useFormikContext } from "formik";
// Util
import {
  CommonFormProps,
  getInputProps,
  getInputPropsRadio,
} from "utils/constants/forms";

const RetrieveInfoStep = ({ form }: CommonFormProps) => {
  // Grab what we need from formik without prop-drilling
  const { handleChange, getFieldProps, setFieldValue } = useFormikContext();

  return (
    <>
      <Container className="mt-4">
        <Row className="align-items-center">
          <Col>
            <Card>
              <Row>
                <Col>
                  <Typography variant="h4" className="text-center mt-5">
                    Please login using two factor authentication
                  </Typography>
                </Col>
              </Row>
              <Row className="mr-1 ml-1">
                <Col xs={12} md={12} className="mt-3">
                  <Typography className="text-justify">
                    We use 2-factor verification to secure your health information. Please select either your phone or email from the options below. Please select one and click “Next”. A one-time PIN will then be texted or emailed to you. You must then type that code into the next screen in order to access the form.
                  </Typography>
                </Col>
              </Row>
              <Form.Row className="mr-3 ml-3 mb-3">
                <Col xs={12} md={12} className="my-1">
                  <Typography variant="h6" className="text-left mt-3">
                    Where do you want the code to be sent?
                  </Typography>
                </Col>
              </Form.Row>
              <Form.Row className="mt-5 mr-3 ml-3">
                <Col xs={12} md={1} className="my-1 ">
                  <Form.Check
                    label="Email:"
                    type="radio"
                    {...getInputPropsRadio("twoFactorMethodSelection", form, "email")}
                  />
                </Col>
                <Col xs={12} md={4} className="my-1">
                  <Form.Control
                    type="email"
                    placeholder="email"
                    readOnly={true}
                    {...getInputProps("twoFactorEmail", form)}
                  />
                  <ErrorMessage
                    name="twoFactorMail"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
              </Form.Row>
              <Form.Row className="mt-5 mr-3 ml-3">
                <Col xs={12} md={1} className="my-1">
                  <Form.Check
                    label="Phone:"
                    type="radio"             
                    {...getInputPropsRadio("twoFactorMethodSelection", form, "phone")}
                  />
                </Col>
                <Col xs={12} md={4} className="my-1">
                  <Form.Control
                    type="text"
                    placeholder="phone"
                    readOnly={true}
                    {...getInputProps("twoFactorNumber", form)}
                  />
                  <ErrorMessage
                    name="twoFactorPhone"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
              </Form.Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default RetrieveInfoStep;
