
import Moment from "moment";

/**
 * Checks if a date is valid for moment js
 * @param date Date to be validated
 * @returns TRUE if date is valid for MOMENT. FALSE if not
 */
export function isValidDate(date: any): boolean {
    var d = Moment(date, "YYYY-MM-DD");
    return !!d.isValid();
};