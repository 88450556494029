import { AUTH_ROUTES } from "pages/auth/AuthRouter";
import React from "react";
import { Redirect, Route, RouteProps, useLocation } from "react-router-dom";
import { useAuth } from "utils/hooks/useAuth.hook";

interface PrivateRouteProps extends RouteProps {}
const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, ...rest }) => {
  const { auth, twoFactorAuth} = useAuth();

  const location = useLocation();
  // clear local storage??
  localStorage.clear();
  // For now we use token from the path.... but is not optimal
  const pathLocation = rest.location?.pathname;
  const aux = rest.location?.pathname.split("/");
  var tokenId = "";

  if (aux) {
    var isAdmin = aux[aux?.length - 2];
    if (isAdmin == "admin") {
      tokenId = aux[aux?.length - 3];
    } else {
      tokenId = aux[aux?.length - 1];
    }
  }

  var allowAccess = twoFactorAuth != undefined && twoFactorAuth.contactId != 0;
  return allowAccess ? (
    <Route {...rest}></Route>
  ) : (
    <Redirect
      to={{
        pathname: AUTH_ROUTES.root + AUTH_ROUTES.twoFactor,
        state: { from: location, tokenId: tokenId, pathLocation: pathLocation },
      }}
    />
  );
};

export default PrivateRoute;
