import PrivateRoute from 'components/privateRoute';
import AuthRouter, { AUTH_ROUTES } from 'pages/auth/AuthRouter';
import HomeRouter, { HOME_ROUTES } from 'pages/home/HomeRouter';
import DocumentsRouter, { DOCUMENTS_ROUTES } from 'pages/documents/DocumentsRouter';
import NotFound from 'pages/notFound/views/NotFound';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

const RootRouter = () => {
  return (
    <Router>
      <Switch>
        <PrivateRoute path={DOCUMENTS_ROUTES.root} component={DocumentsRouter}></PrivateRoute>
        <Route path={AUTH_ROUTES.root} component={AuthRouter}></Route>
        <Route path="*" component={NotFound}></Route>
      </Switch>
    </Router>
  );
};

export default RootRouter;
