import store from 'store';
import { logOut } from 'store/auth/auth.slice';

export type EventsFromLegacy = 'logOut' | 'loaded' | 'none';
export type EventsToLegacy = 'navigationDoctors' | 'navigationProfile' | 'navigationReferrals';

export const messageReceiver = (event: MessageEvent<CEMessage<EventsFromLegacy>>) => {
  if (!event.data || !event.data.care) return;
  const { eventType, content } = event.data;

  switch (eventType) {
    case 'logOut': {
      store.dispatch(logOut());
    }
  }
};

export interface CEMessage<T> {
  eventType: T;
  content: string;
  care?: true;
}
let communicationTarget: HTMLIFrameElement;

export const getCommunicationTarget = () => {
  return communicationTarget;
};

export const setCommunicationTarget = (target: HTMLIFrameElement) => {
  if (!target) return;
  communicationTarget = target;
};

const noTargetError = () => {
  console.warn('No target has been specified');
};

export const messageSend = (message: CEMessage<EventsToLegacy>) => {
  if (!communicationTarget) {
    noTargetError();
    return;
  }

  communicationTarget.contentWindow?.postMessage({ ...message, care: true }, '*');
};

window.addEventListener('message', messageReceiver);
