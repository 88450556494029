import moment from "moment";
import { PDFFont, PDFForm, PDFPage, rgb } from "pdf-lib";
import { reduceOptions } from "utils/form/multiSelectOptions";
import PDFCheckboxFieldComponent from "../components/CheckboxFieldComponent";
import PDFTextFieldComponent from "../components/TextFieldComponent";
import { CurrentDimensions } from "../model/CurrentDimensions";
import { PDFSectionProps } from "../model/PDFSectionProps";


export const ChiefComplaintQuestionsSection = (props: PDFSectionProps) => {
    // Header style
    const headerFontSize = props.headerFontSize;
    const textHeight = 4 * headerFontSize;

    // Label Style
    const labelFontSize = props.labelFontSize;
    const labelHeight = 4 * labelFontSize;

    // Keep track of the height and width
    var currentDimensions = { height: props.heightPointer + textHeight + 20, width: 0 } as CurrentDimensions;
    props.page.drawText('Chief Complaint Questions', {
        x: 30,
        y: props.height - currentDimensions.height,
        size: headerFontSize,
        font: props.timesRomanFont,
        color: rgb(0, 0, 0),
        opacity: 1,
    });

    const consultationLabel = {
        labelHeight: labelHeight,
        labelMargin: 30,
        labelText: "For what problem are you requesting a consultation?:"
    };
    const consultationField = {
        fieldHeight: 20,
        fieldId: 'consultation.field',
        fieldMargin: 300,
        fieldWidth: 200,
        fieldValue: props.formValues.patientChiefComplaintProblem
    };

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: consultationField,
        labelData: consultationLabel,
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });

    currentDimensions.height += labelHeight;
    props.page.drawText('What symptoms are you currently experiencing?', {
        x: 30,
        y: props.height - currentDimensions.height,
        size: labelFontSize,
        font: props.timesRomanFont,
        color: rgb(0, 0, 0),
        opacity: 1,
    });

    const backPainLabel = {
        labelHeight: labelHeight,
        labelMargin: 30,
        labelText: "Back Pain"
    };
    const backPainField = {
        fieldHeight: 10,
        fieldId: 'backpain.field',
        fieldMargin: 80,
        fieldWidth: 10,
        fieldValue: props.formValues.patientCCBackPain
    };

    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: backPainField,
        labelData: backPainLabel,
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });


    const neckPainLabel = {
        labelHeight: labelHeight,
        labelMargin: 30,
        labelText: "Neck Pain"
    };
    const neckPainField = {
        fieldHeight: 10,
        fieldId: 'neckpain.field',
        fieldMargin: 80,
        fieldWidth: 10,
        fieldValue: props.formValues.patientCCNeckPain
    };

    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: neckPainField,
        labelData: neckPainLabel,
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });

    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'headache.field',
            fieldMargin: 80,
            fieldWidth: 10,
            fieldValue: props.formValues.patientCCHeadache
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Headache"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });

    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Muscle Pain or Ache"
        },
        fieldData: {
            fieldHeight: 10,
            fieldId: 'musclePain.field',
            fieldMargin: 140,
            fieldWidth: 10,
            fieldValue: props.formValues.patientCCMusclePain
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });


    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Muscle Spasm"
        },
        fieldData: {
            fieldHeight: 10,
            fieldId: 'musclespasms.field',
            fieldMargin: 100,
            fieldWidth: 10,
            fieldValue: props.formValues.patientCCMuscleSpasm
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });

    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Chest Pain"
        },
        fieldData: {
            fieldHeight: 10,
            fieldId: 'chestpain.field',
            fieldMargin: 85,
            fieldWidth: 10,
            fieldValue: props.formValues.patientCCChestPain
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });

    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Chest Palpitations"
        },
        fieldData: {
            fieldHeight: 10,
            fieldId: 'chestpalpitations.field',
            fieldMargin: 120,
            fieldWidth: 10,
            fieldValue: props.formValues.patientCCChestPalpitations
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });

    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Weight Loss"
        },
        fieldData: {
            fieldHeight: 10,
            fieldId: 'weightloss.field',
            fieldMargin: 100,
            fieldWidth: 10,
            fieldValue: props.formValues.patientCCWeightLoss
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });

    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Weight Gain"
        },
        fieldData: {
            fieldHeight: 10,
            fieldId: 'weightgain.field',
            fieldMargin: 100,
            fieldWidth: 10,
            fieldValue: props.formValues.patientCCWeightGain
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });

    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Fever"
        },
        fieldData: {
            fieldHeight: 10,
            fieldId: 'fever.field',
            fieldMargin: 70,
            fieldWidth: 10,
            fieldValue: props.formValues.patientCCFever
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });

    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Hot or Cold Spells"
        },
        fieldData: {
            fieldHeight: 10,
            fieldId: 'hotorcoldspells.field',
            fieldMargin: 140,
            fieldWidth: 10,
            fieldValue: props.formValues.patientCCSpells
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
    });

    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Vision Changes"
        },
        fieldData: {
            fieldHeight: 10,
            fieldId: 'visionchanges.field',
            fieldMargin: 120,
            fieldWidth: 10,
            fieldValue: props.formValues.patientCCVision
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
    });

    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Hearing Problems"
        },
        fieldData: {
            fieldHeight: 10,
            fieldId: 'hearingproblems.field',
            fieldMargin: 120,
            fieldWidth: 10,
            fieldValue: props.formValues.patientCCHearing
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
    });

    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Cough"
        },
        fieldData: {
            fieldHeight: 10,
            fieldId: 'cough.field',
            fieldMargin: 70,
            fieldWidth: 10,
            fieldValue: props.formValues.patientCCCough
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
    });

    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Diarrhea"
        },
        fieldData: {
            fieldHeight: 10,
            fieldId: 'diarrhea.field',
            fieldMargin: 80,
            fieldWidth: 10,
            fieldValue: props.formValues.patientCCDiarrhea
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });
    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Shortness of Breath"
        },
        fieldData: {
            fieldHeight: 10,
            fieldId: 'shortnessbreath.field',
            fieldMargin: 140,
            fieldWidth: 10,
            fieldValue: props.formValues.patientCCBreath
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
    });

    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Stomach Pain"
        },
        fieldData: {
            fieldHeight: 10,
            fieldId: 'stomachpain.field',
            fieldMargin: 110,
            fieldWidth: 10,
            fieldValue: props.formValues.patientCCStomach
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
    });

    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Nausea"
        },
        fieldData: {
            fieldHeight: 10,
            fieldId: 'nausea.field',
            fieldMargin: 70,
            fieldWidth: 10,
            fieldValue: props.formValues.patientCCNausea
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
    });

    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Vomiting"
        },
        fieldData: {
            fieldHeight: 10,
            fieldId: 'vomiting.field',
            fieldMargin: 80,
            fieldWidth: 10,
            fieldValue: props.formValues.patientCCVomiting
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
    });


    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Constipation"
        },
        fieldData: {
            fieldHeight: 10,
            fieldId: 'constipation.field',
            fieldMargin: 100,
            fieldWidth: 10,
            fieldValue: props.formValues.patientCCConstipation
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });


    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Numbness"
        },
        fieldData: {
            fieldHeight: 10,
            fieldId: 'numbness.field',
            fieldMargin: 85,
            fieldWidth: 10,
            fieldValue: props.formValues.patientCCNumbness
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Description:"
        },
        fieldData: {
            fieldHeight: 20,
            fieldId: 'numbness.description.field',
            fieldMargin: 100,
            fieldWidth: 380,
            fieldValue: props.formValues.patientCCNumbnessDesc
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });

    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Weakness"
        },
        fieldData: {
            fieldHeight: 10,
            fieldId: 'weakness.field',
            fieldMargin: 85,
            fieldWidth: 10,
            fieldValue: props.formValues.patientCCWeakness
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Description:"
        },
        fieldData: {
            fieldHeight: 20,
            fieldId: 'weakness.description.field',
            fieldMargin: 100,
            fieldWidth: 380,
            fieldValue: props.formValues.patientCCWeaknessDesc
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });

    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Other"
        },
        fieldData: {
            fieldHeight: 10,
            fieldId: 'other.field',
            fieldMargin: 75,
            fieldWidth: 10,
            fieldValue: props.formValues.patientCCOtherSymptoms
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Description:"
        },
        fieldData: {
            fieldHeight: 20,
            fieldId: 'other.description.field',
            fieldMargin: 100,
            fieldWidth: 380,
            fieldValue: props.formValues.patientCCOtherSymptomsDesc
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });

    return currentDimensions;
};


export const ChiefComplaintQuestionsSection2 = (props: PDFSectionProps) => {
    // Header style
    const headerFontSize = props.headerFontSize;
    const textHeight = 4 * headerFontSize;

    // Label Style
    const labelFontSize = props.labelFontSize;
    const labelHeight = 4 * labelFontSize;

    // Keep track of the height and width 
    var currentDimensions = { height: props.heightPointer + textHeight, width: 0 } as CurrentDimensions;
    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "What is your pain level today on a scale of 0-10?"
        },
        fieldData: {
            fieldHeight: 20,
            fieldId: 'pain.level.today.field',
            fieldMargin: 280,
            fieldWidth: 80,
            fieldValue: props.formValues.patientChiefComplaintPainLevelToday
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });
    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "What has your pain level averaged over the last week on a scale of 0-10?"
        },
        fieldData: {
            fieldHeight: 20,
            fieldId: 'pain.level.week.field',
            fieldMargin: 400,
            fieldWidth: 80,
            fieldValue: props.formValues.patientChiefComplaintPainLevelWeek
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });
    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "What type of pain are you experiencing?"
        },
        fieldData: {
            fieldHeight: 20,
            fieldId: 'type.pain.field',
            fieldMargin: 230,
            fieldWidth: 80,
            fieldValue: reduceOptions(props.formValues.patientCCPainType)
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });
    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Where is your pain?"
        },
        fieldData: {
            fieldHeight: 20,
            fieldId: 'pain.location.field',
            fieldMargin: 150,
            fieldWidth: 80,
            fieldValue: reduceOptions(props.formValues.patientCCPainLocation)
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });
    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "What area is of most concern?"
        },
        fieldData: {
            fieldHeight: 20,
            fieldId: 'area.concern.field',
            fieldMargin: 180,
            fieldWidth: 80,
            fieldValue: props.formValues.patientCCPainMostConcern
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });
    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Does the pain radiate into your extremities?"
        },
        fieldData: {
            fieldHeight: 10,
            fieldId: 'pain.radiate.field',
            fieldMargin: 250,
            fieldWidth: 10,
            fieldValue: props.formValues.patientCCPainExtremities
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Which Areas?"
        },
        fieldData: {
            fieldHeight: 20,
            fieldId: 'selected.areas.field',
            fieldMargin: 120,
            fieldWidth: 350,
            fieldValue: reduceOptions(props.formValues.patientCCPainExtremitiesAreas)
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });


    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Are you experiencing any associated weakness or loss of strength"
        },
        fieldData: {
            fieldHeight: 10,
            fieldId: 'loss.strength.field',
            fieldMargin: 350,
            fieldWidth: 10,
            fieldValue: props.formValues.patientCCExperiencingWeakness
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });
    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Which Areas?"
        },
        fieldData: {
            fieldHeight: 20,
            fieldId: 'selected.areas.loss.strength.field',
            fieldMargin: 120,
            fieldWidth: 350,
            fieldValue: reduceOptions(props.formValues.patientCCWeaknessExtremitiesAreas)
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });

    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Are you experiencing any numbness or tingling?"
        },
        fieldData: {
            fieldHeight: 10,
            fieldId: 'numbess.field',
            fieldMargin: 270,
            fieldWidth: 10,
            fieldValue: props.formValues.patientCCExperiencingNumbness
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });
    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Which Areas?"
        },
        fieldData: {
            fieldHeight: 20,
            fieldId: 'numbness.areas.field',
            fieldMargin: 120,
            fieldWidth: 350,
            fieldValue: reduceOptions(props.formValues.patientCCNumbnessAreas)
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });


    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Have your symptoms been associated with incontinence?"
        },
        fieldData: {
            fieldHeight: 20,
            fieldId: 'incontinence.field',
            fieldMargin: 320,
            fieldWidth: 250,
            fieldValue: reduceOptions(props.formValues.patientCCIncontinence)
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });

    var symptomsBegin = '-';
    if (moment(props.formValues.patientCCSymptomBegin, "YYYY-MM-DD").isValid()) { 
        symptomsBegin = moment(props.formValues.patientCCSymptomBegin, "YYYY-MM-DD").format("MM/DD/YYYY") 
    }
    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "When did your symptoms begin?"
        },
        fieldData: {
            fieldHeight: 20,
            fieldId: 'symtoms.begin.field',
            fieldMargin: 200,
            fieldWidth: 100,
            fieldValue: symptomsBegin
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "What activities increase your symptoms?"
        },
        fieldData: {
            fieldHeight: 20,
            fieldId: 'activities.increase.field',
            fieldMargin: 250,
            fieldWidth: 300,
            fieldValue: reduceOptions(props.formValues.patientCCActivitiesIncrease)
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Description"
        },
        fieldData: {
            fieldHeight: 20,
            fieldId: 'activities.increase.description.field',
            fieldMargin: 100,
            fieldWidth: 450,
            fieldValue: props.formValues.patientCCIncreaseDescription
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });


    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "What activities decrease your symptoms?"
        },
        fieldData: {
            fieldHeight: 20,
            fieldId: 'activities.decrease.field',
            fieldMargin: 250,
            fieldWidth: 300,
            fieldValue: reduceOptions(props.formValues.patientCCActivitiesDecrease)
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Description"
        },
        fieldData: {
            fieldHeight: 20,
            fieldId: 'activities.decrease.description.field',
            fieldMargin: 100,
            fieldWidth: 450,
            fieldValue: props.formValues.patientCCDecreaseDescription
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });



    return currentDimensions;
};


