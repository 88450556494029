import moment from "moment";
import { PDFFont, PDFForm, PDFPage, rgb } from "pdf-lib";
import { getOptionByValue, reduceOptions } from "utils/form/multiSelectOptions";
import PDFCheckboxFieldComponent from "../components/CheckboxFieldComponent";
import PDFTextFieldComponent from "../components/TextFieldComponent";
import PDFTextMultiLineFieldComponent from "../components/TextMultiLineFieldComponent";
import { CurrentDimensions } from "../model/CurrentDimensions";
import { PDFSectionProps } from "../model/PDFSectionProps";


export const OtherMedicalQuestionsSection = (props: PDFSectionProps) => {
    // Header style
    const headerFontSize = props.headerFontSize;
    const textHeight = 4 * headerFontSize;

    // Label Style
    const labelFontSize = props.labelFontSize;
    const labelHeight = 4 * labelFontSize;

    // Keep track of the height and width
    var currentDimensions = { height: props.heightPointer + textHeight, width: 0 } as CurrentDimensions;
    props.page.drawText('Other Medical History Questions', {
        x: 30,
        y: props.height - currentDimensions.height,
        size: headerFontSize,
        font: props.timesRomanFont,
        color: rgb(0, 0, 0),
        opacity: 1,
    });


    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'has.pcp.field',
            fieldMargin: 230,
            fieldWidth: 10,
            fieldValue: props.formValues.patientOMHHasPCP
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Do you have a Primary Care Provider?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });


    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'pcp.name.field',
            fieldMargin: 100,
            fieldWidth: 80,
            fieldValue: props.formValues.patientOMHPCPName
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "PCP Name:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });


    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'pcp.address.field',
            fieldMargin: 100,
            fieldWidth: 80,
            fieldValue: props.formValues.patientOMHPCPAddress
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 10,
            labelText: "PCP Address:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });
    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'pcp.phone.field',
            fieldMargin: 100,
            fieldWidth: 80,
            fieldValue: props.formValues.patientOMHPCPPhone
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 10,
            labelText: "PCP Phone:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });


    currentDimensions = PDFTextMultiLineFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 100,
            fieldId: 'medical.conditions.field',
            fieldMargin: 220,
            fieldWidth: 330,
            fieldValue: reduceOptions(props.formValues.patientOMHMedicalConditions)
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Medical Conditions you have or had:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });


    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'diagnosed.sleep.apnea.field',
            fieldMargin: 300,
            fieldWidth: 10,
            fieldValue: props.formValues.patientOMHHasSleepApnea
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Have you been diagnosed with obstructive sleep apnea?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });
    var usesBibap = false;
    var usesCpap = false;
    if (props.formValues.patientOMHSleepApneaOptions) {
        usesBibap = props.formValues.patientOMHSleepApneaOptions.label === "BIBAP";
        usesCpap = props.formValues.patientOMHSleepApneaOptions.label === "CPAP";
    }
    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'use.bibap.field',
            fieldMargin: 130,
            fieldWidth: 10,
            fieldValue: usesBibap
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Do you use BIBAP?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });


    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'use.cpap.field',
            fieldMargin: 125,
            fieldWidth: 10,
            fieldValue: usesCpap
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Do you use CPAP?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });

    props.page.drawText('Family History:', {
        x: 30,
        y: props.height - currentDimensions.height - labelHeight,
        size: labelFontSize,
        font: props.timesRomanFont,
        color: rgb(0, 0, 0),
        opacity: 1,
    });

    currentDimensions.height = currentDimensions.height + labelHeight;

    var isHighBloodPressure = false;
    var isDiabetes = false;
    var isCancer = false;
    var isCoronaryArteryDisease = false;
    var isStroke = false;
    var isBleedingDisorder = false;
    var isBloodClots = false;
    var isHeartAttack = false;
    var isOther = false;
    if (props.formValues.patientOMHFamilyHistory) {
        isHighBloodPressure = getOptionByValue(props.formValues.patientOMHFamilyHistory, "HighBloodPressure") != undefined;
        isDiabetes = getOptionByValue(props.formValues.patientOMHFamilyHistory, "Diabetes") != undefined;
        isCancer = getOptionByValue(props.formValues.patientOMHFamilyHistory, "Cancer") != undefined;
        isCoronaryArteryDisease = getOptionByValue(props.formValues.patientOMHFamilyHistory, "CoronaryArteryDisease") != undefined;
        isStroke = getOptionByValue(props.formValues.patientOMHFamilyHistory, "Stroke") != undefined;
        isBleedingDisorder = getOptionByValue(props.formValues.patientOMHFamilyHistory, "BleedingDisorder") != undefined;
        isBloodClots = getOptionByValue(props.formValues.patientOMHFamilyHistory, "BloodClots") != undefined;
        isHeartAttack = getOptionByValue(props.formValues.patientOMHFamilyHistory, "HeartAttack") != undefined;
        isOther = getOptionByValue(props.formValues.patientOMHFamilyHistory, "HeartAttack") != undefined;
    }

    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'fh.hbp.field',
            fieldMargin: 150,
            fieldWidth: 10,
            fieldValue: isHighBloodPressure
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "High Blood Pressure"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });

    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'fh.diabetes.field',
            fieldMargin: 90,
            fieldWidth: 10,
            fieldValue: isDiabetes
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Diabetes"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });


    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'fh.cancer.field',
            fieldMargin: 80,
            fieldWidth: 10,
            fieldValue: isCancer
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Cancer"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
    });


    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'fh.cad.field',
            fieldMargin: 160,
            fieldWidth: 10,
            fieldValue: isCoronaryArteryDisease
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Coronary Artery Disease"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });


    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'fh.stroke.field',
            fieldMargin: 70,
            fieldWidth: 10,
            fieldValue: isStroke
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Stroke"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });


    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'fh.bd.field',
            fieldMargin: 140,
            fieldWidth: 10,
            fieldValue: isBleedingDisorder
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Bleeding Disorders"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });

    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'fh.bc.field',
            fieldMargin: 100,
            fieldWidth: 10,
            fieldValue: isBloodClots
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Blood Clots"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });

    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'fh.hr.field',
            fieldMargin: 100,
            fieldWidth: 10,
            fieldValue: isHeartAttack
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Heart Attack"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });
    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'fh.other.field',
            fieldMargin: 70,
            fieldWidth: 10,
            fieldValue: isOther
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Other"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });


    return currentDimensions;
};


export const OtherMedicalQuestionsSection2 = (props: PDFSectionProps) => {
    // Header style
    const headerFontSize = props.headerFontSize;
    const textHeight = 4 * headerFontSize;

    // Label Style
    const labelFontSize = props.labelFontSize;
    const labelHeight = 4 * labelFontSize;

    // Keep track of the height and width
    var currentDimensions = { height: props.heightPointer + textHeight, width: 0 } as CurrentDimensions;
    props.page.drawText('Previous Surgery', {
        x: 30,
        y: props.height - currentDimensions.height,
        size: labelFontSize,
        font: props.timesRomanFont,
        color: rgb(0, 0, 0),
        opacity: 1,
    });

    var patientOMHPrevSurgDate0 = '-';
    if (moment(props.formValues.patientOMHPrevSurgDate0, "YYYY-MM-DD").isValid()) { 
        patientOMHPrevSurgDate0 = moment(props.formValues.patientOMHPrevSurgDate0, "YYYY-MM-DD").format("MM/DD/YYYY") 
    }
    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'prev.surgery.0.date.field',
            fieldMargin: 100,
            fieldWidth: 60,
            fieldValue: patientOMHPrevSurgDate0
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Date:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'prev.surgery.0.type.field',
            fieldMargin: 120,
            fieldWidth: 200,
            fieldValue: props.formValues.patientOMHPrevSurgType0
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Type of Surgery:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });


    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'prev.surgery.0.doctor.field',
            fieldMargin: 120,
            fieldWidth: 120 ,
            fieldValue: props.formValues.patientOMHPrevSurgDoctor
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Doctor:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });
    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'prev.surgery.0.complications.field',
            fieldMargin: 110,
            fieldWidth: 170,
            fieldValue: props.formValues.patientOMHPrevSurgComplications0
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Complications:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });

    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'anesthesia.complciations.field',
            fieldMargin: 270,
            fieldWidth: 10,
            fieldValue: props.formValues.patientOMHHasAnesthesiaComplications
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Have you had any complications with anesthesia?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'anesthesia.complication.type.name.field',
            fieldMargin: 200,
            fieldWidth: 200,
            fieldValue: props.formValues.patientOMHAnesthesiaComplication
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Anesthesia Complication:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });


    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'cardiac.stent.field',
            fieldMargin: 400,
            fieldWidth: 10,
            fieldValue: props.formValues.patientOMHHasHadStents
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Have you had any cardiac or vascular stents placed in the past 12 months?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });

    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'drug.eluting.stent.field',
            fieldMargin: 200,
            fieldWidth: 10,
            fieldValue: props.formValues.patientOMHIsDrugElutingStent
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Is it a drug-eluting stent?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });

    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'implanted.devices.field',
            fieldMargin: 210,
            fieldWidth: 10,
            fieldValue: props.formValues.patientOMHHasImplantedDevices
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Do you have any implanted devices?"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });


    var isSpinalCordStimulator = false; 
    var isPacemaker  = false; 
    var isDefibrillator  = false;
    var isPainMedicationPump = false;  
    var isGlucoseMonitor = false; 
    var isInsulinPump  = false; 
    var isImplantedOther = false;
   
    if (props.formValues.patientOMHImplantedDevicesOptions) {
        isSpinalCordStimulator = getOptionByValue(props.formValues.patientOMHFamilyHistory, "SpinalCordStimulator") != undefined;
        isPacemaker = getOptionByValue(props.formValues.patientOMHFamilyHistory, "Pacemaker") != undefined;
        isDefibrillator = getOptionByValue(props.formValues.patientOMHFamilyHistory, "Defibrillator") != undefined;
        isPainMedicationPump = getOptionByValue(props.formValues.patientOMHFamilyHistory, "PainMedicationPump") != undefined;
        isGlucoseMonitor = getOptionByValue(props.formValues.patientOMHFamilyHistory, "GlucoseMonitor") != undefined;
        isInsulinPump = getOptionByValue(props.formValues.patientOMHFamilyHistory, "InsulinPump") != undefined;
        isImplantedOther = getOptionByValue(props.formValues.patientOMHFamilyHistory, "Other") != undefined;
       
    }
    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'implanted.scs.field',
            fieldMargin: 150,
            fieldWidth: 10,
            fieldValue: isSpinalCordStimulator
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Spinal Cord Stimulator"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });

    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'implanted.peacemaker.field',
            fieldMargin: 100,
            fieldWidth: 10,
            fieldValue: isPacemaker
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Peacemaker"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });

    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'implanted.defribilator.field',
            fieldMargin: 100,
            fieldWidth: 10,
            fieldValue: isDefibrillator
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Defibrillator"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });

    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'implanted.pmp.field',
            fieldMargin: 200,
            fieldWidth: 10,
            fieldValue: isPainMedicationPump
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Pain Medication Pump"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });


    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'implanted.glucose.field',
            fieldMargin: 120,
            fieldWidth: 10,
            fieldValue: isGlucoseMonitor
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Glucose Monitor"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });


    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'implanted.insulin.field',
            fieldMargin: 100,
            fieldWidth: 10,
            fieldValue: isInsulinPump
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Insulin Pump"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });
    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'implanted.other.field',
            fieldMargin: 70,
            fieldWidth: 10,
            fieldValue: isImplantedOther
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Other"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });

    props.page.drawText('Please list anything else it is important for us to know!', {
        x: 30,
        y: props.height - currentDimensions.height - labelHeight,
        size: labelFontSize,
        font: props.timesRomanFont,
        color: rgb(0, 0, 0),
        opacity: 1,
    });

    currentDimensions.height = currentDimensions.height + labelHeight;

    currentDimensions = PDFTextMultiLineFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 100,
            fieldId: 'important.stuff.field',
            fieldMargin: 30,
            fieldWidth: 500,
            fieldValue: props.formValues.patientOMHAnythingImportant
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: ""
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });





    return currentDimensions;
};
