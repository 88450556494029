import { useState, useRef } from "react";
// Components

import { Typography, Card, CardHeader, makeStyles } from "@material-ui/core";
import { Row, Col, Form, Container } from "react-bootstrap";
// Util
import {
  getInputProps,
  CommonFormProps,
} from "utils/constants/forms";
// Hooks
import {
  FormikProps,
  FormikValues,
  ErrorMessage,
  useFormikContext,
} from "formik";
import Moment from "moment";
import DatePicker from "react-datepicker";
import { isValidDate } from "utils/ValidationUtils";

import "react-datepicker/dist/react-datepicker.css";

import "components/dropzone/Dropzone.styles.css";
import { copyStringIntoBuffer } from "pdf-lib";

const useStyles = makeStyles({
  error: {
    border: `2px solid red`, 
  },
  ok: {
  }
});
const FirstName = ({ form }: CommonFormProps) => {
  const { getFieldProps, setFieldValue, isValid} = useFormikContext();

  const classes = useStyles();

  const startRef = useRef<DatePicker>();

  const handleAgreeConfidentiality = (e: any) => {
    e.preventDefault();
    var currentValue = getFieldProps("AgreeConfidentiality").value;
    setFieldValue("AgreeConfidentiality", !currentValue);
  };

  // Handle DoB Date
  var formPatientDoB = getFieldProps("PatientDoB").value;

  var date = null;
  if (isValidDate(formPatientDoB)) {
    const patientDoB = Moment(formPatientDoB, "MM/DD/YYYY");
    date = new Date(patientDoB.toDate());
  }
  const [startDate, setStartDate] = useState<Date | null>(date);

  // Handle Expiry Date
  var phiExpirationDate = getFieldProps("phiExpirationDate").value;

  var phiDate = null;
  if (isValidDate(phiExpirationDate)) {
    const expirationDate = Moment(phiExpirationDate, "YYYY-MM-DD");
    date = new Date(expirationDate.toDate());
  }
  const [expirationDate, setExpirationDate] = useState<Date | null>(phiDate);


  return (
    <>  <Container className="mt-4">
      <Row className="align-items-center">
        <Col>
          <Card>
            <Row>
              <Col>
                <Typography variant="h4" className="text-center mt-5">
                  Authorization for Use and Disclosure of Protected Health Information
                </Typography>
              </Col>
            </Row>
            <Form.Row className="mt-5 mr-3 ml-3">
              <Col md={4} xs={12} className="my-1">
                <Form.Control
                  type="text"
                  placeholder="Patient Name*"
                  {...getInputProps("PatientName", form)}
                />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage
                    name="PatientName"
                    component="div"
                    data-testid="patient-name-error"
                  />
                </Form.Control.Feedback>
              </Col>
              <Col xs={12} md={1} className="my-1">
              <Typography className="mt-1">
                  DOB*a:
                </Typography>
              </Col>
              <Col md={2} xs={12} className="my-1">
                <Form.Control
                  className="form-control"
                  type="text"
                  placeholder="mm/dd/yyyy*"
                  {...getInputProps("PatientDoB", form)}
                />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage
                    name="PatientDoB"
                    component="div"
                  />
                </Form.Control.Feedback>
              </Col>
              <Col xs={12} md={5} className="my-1">
                <Form.Control
                  type="text"
                  placeholder="Patient Phone Number*"
                  {...getInputProps("PhoneNumber", form)}
                />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage
                    name="PhoneNumber"
                    component="div"
                    data-testid="patient-number-error"
                  />
                </Form.Control.Feedback>
              </Col>
            </Form.Row>
            <Form.Row className="m-3">
              <Col xs={12} md={6} className="my-1">
                <Form.Control
                  type="text"
                  placeholder="MRI/Imaging Facility"
                  {...getInputProps("MriFacility", form)}
                />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage
                    name="MriFacility"
                    component="div"
                    data-testid="password-error"
                  />
                </Form.Control.Feedback>
              </Col>

              <Col xs={12} md={6} className="my-1">
                <Form.Control
                  type="text"
                  placeholder="Phone Number"
                  {...getInputProps("MriFacilityNumber", form)}
                />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage
                    name="MriFacilityNumber"
                    component="div"
                    data-testid="password-error"
                  />
                </Form.Control.Feedback>
              </Col>
            </Form.Row>
            <Form.Row className="m-3">
              <Col md={6} xs={12} className="my-1">
                <Form.Control
                  type="text"
                  placeholder="Pain Management MD"
                  {...getInputProps("PmMd", form)}
                />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage
                    name="PmMd"
                    component="div"
                    data-testid="password-error"
                  />
                </Form.Control.Feedback>
              </Col>
              <Col md={6} xs={12} className="my-1">
                <Form.Control
                  type="text"
                  placeholder="Phone Number"
                  {...getInputProps("PmMdNumber", form)}
                />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage
                    name="PmMdNumber"
                    component="div"
                    data-testid="password-error"
                  />
                </Form.Control.Feedback>
              </Col>
            </Form.Row>
            <Form.Row className="m-3">
              <Col md={6} xs={12} className="my-1">
                <Form.Control
                  type="text"
                  placeholder="Physical Therapy"
                  {...getInputProps("PhysicalTherapy", form)}
                />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage
                    name="PhysicalTherapy"
                    component="div"
                    data-testid="password-error"
                  />
                </Form.Control.Feedback>
              </Col>
              <Col md={6} xs={12} className="my-1">
                <Form.Control
                  type="text"
                  placeholder="Phone Number"
                  {...getInputProps("PhysicalTherapyNumber", form)}
                />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage
                    name="PhysicalTherapyNumber"
                    component="div"
                    data-testid="password-error"
                  />
                </Form.Control.Feedback>
              </Col>
            </Form.Row>
            <Form.Row className="m-3">
              <Col md={6} xs={12} className="my-1">
                <Form.Control
                  type="text"
                  placeholder="Chiropractic Care"
                  {...getInputProps("ChiroCare", form)}
                />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage
                    name="ChiroCare"
                    component="div"
                    data-testid="password-error"
                  />
                </Form.Control.Feedback>
              </Col>

              <Col md={6} xs={12} className="my-1">
                <Form.Control
                  type="text"
                  placeholder="Phone Number"
                  {...getInputProps("ChiroCareNumber", form)}
                />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage
                    name="ChiroCareNumber"
                    component="div"
                    data-testid="password-error"
                  />
                </Form.Control.Feedback>
              </Col>
            </Form.Row>
            <Form.Row className="m-3">
              <Col md={6} xs={12} className="my-1">
                <Form.Control
                  type="text"
                  placeholder="Primary Care Physician"
                  {...getInputProps("PrimaryCarePhysician", form)}
                />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage
                    name="PrimaryCarePhysician"
                    component="div"
                    data-testid="password-error"
                  />
                </Form.Control.Feedback>
              </Col>
              <Col md={6} xs={12} className="my-1">
                <Form.Control
                  type="text"
                  placeholder="Phone Number"
                  {...getInputProps("PrimaryCarePhysicianNumber", form)}
                />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage
                    name="PrimaryCarePhysicianNumber"
                    component="div"
                    data-testid="password-error"
                  />
                </Form.Control.Feedback>
              </Col>
            </Form.Row>
            <Form.Row className="m-3">
              <Col md={6} xs={12} className="my-1">
                <Form.Control
                  type="text"
                  placeholder="Neurologist or Other Specialist"
                  {...getInputProps("OtherSpecialist", form)}
                />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage
                    name="OtherSpecialist"
                    component="div"
                    data-testid="password-error"
                  />
                </Form.Control.Feedback>
              </Col>
              <Col md={6} xs={12} className="my-1">
                <Form.Control
                  type="text"
                  placeholder="Phone Number"
                  {...getInputProps("OtherSpecialistNumber", form)}
                />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage
                    name="OtherSpecialistNumber"
                    component="div"
                    data-testid="password-error"
                  />
                </Form.Control.Feedback>
              </Col>
            </Form.Row>
            <Form.Row className="mt-3 ml-3 mb-1">
              <Typography>Information to be released:</Typography>
            </Form.Row>
            <Form.Row className="mr-3 ml-3">
              <Form.Group >
                <Form.Check
                  inline
                  {...getInputProps("EntireRecord", form)}
                  id="check1"
                  checked={getFieldProps("EntireRecord").value || false}
                  type="checkbox"
                  label="Entire Record"
                  className="mt-2"
                />
                <Form.Check
                  inline
                  {...getInputProps("MedicalRecords", form)}
                  disabled
                  id="check_2"
                  checked={getFieldProps("MedicalRecords").value || false}
                  type="checkbox"
                  label="Medical Records"
                  className="mt-2"
                />
                <Form.Check
                  inline
                  {...getInputProps("BillingRecords", form)}
                  id="check2"
                  checked={getFieldProps("BillingRecords").value || false}
                  type="checkbox"
                  label="Billing Records"
                />
                <Form.Check
                  inline
                  {...getInputProps("OtherRecords", form)}
                  id="check3"
                  checked={getFieldProps("OtherRecords").value || false}
                  type="checkbox"
                  label="Other:"
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Control
                  type="text"
                  placeholder="Other"
                  {...getInputProps("OtherRecordsText", form)}
                />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage
                    name="OtherRecordsText"
                    component="div"
                    data-testid="password-error"
                  />
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row className="mt-3 ml-3 mb-1">
              <Typography>Purpose of Disclosure:</Typography>
            </Form.Row>
            <Form.Row className="mr-3 ml-3">
              <Form.Group>
                <Form.Check
                  inline
                  {...getInputProps("FurtherMedicalCare", form)}
                  disabled
                  id="check_4"
                  name="FurtherMedicalCare"
                  checked={getFieldProps("FurtherMedicalCare").value || false}
                  type="checkbox"
                  label="Further Medical Care"
                  className="mt-2"
                />
                <Form.Check
                  inline
                  {...getInputProps("ChangingPhysicans", form)}
                  id="check4"
                  checked={getFieldProps("ChangingPhysicans").value || false}
                  type="checkbox"
                  label="Changing Physicians"
                  className="mt-2"
                />
                <Form.Check
                  inline
                  {...getInputProps("Insurance", form)}
                  id="check5"
                  checked={getFieldProps("Insurance").value || false}
                  type="checkbox"
                  label="Insurance"
                />
                <Form.Check
                  inline
                  {...getInputProps("Research", form)}
                  id="check7"
                  checked={getFieldProps("Research").value || false}
                  type="checkbox"
                  label="Research"
                  className="mt-2"
                />
                <Form.Check
                  inline
                  {...getInputProps("Attorney", form)}
                  id="check8"
                  checked={getFieldProps("Attorney").value || false}
                  type="checkbox"
                  label="Attorney"
                />
                <Form.Check
                  inline
                  {...getInputProps("PersonalUse", form)}
                  id="check9"
                  checked={getFieldProps("PersonalUse").value || false}
                  type="checkbox"
                  label="Personal Use"
                />
              </Form.Group>
            </Form.Row>
            <Form.Row className="mr-3 ml-3">
              <Form.Check
                inline
                {...getInputProps("OtherPurpose", form)}
                id="check6"
                checked={getFieldProps("OtherPurpose").value || false}
                type="checkbox"
                label="Other:"
              />
              <Form.Group as={Col}>
                <Form.Control
                  type="text"
                  {...getInputProps("OtherPurposeText", form)}
                  placeholder="Other"
                />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage
                    name="OtherPurposeText"
                    component="div"
                    data-testid="password-error"
                  />
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row className="mr-3 ml-3"> <Card className={isValid ?  classes.ok : classes.error } variant="outlined">
              <CardHeader subheader="Authorization*" />
              <Form.Check
                inline
                {...getInputProps("AgreeConfidentiality", form)}
                checked={getFieldProps("AgreeConfidentiality").value || false}
                type="checkbox"
                label=""
                className=""
              />
              <Typography>
                <div
                  onClick={handleAgreeConfidentiality}
                >
                  By checking this box I understand that my records
                  are confidential and may not be disclosed without my
                  authorization, except when otherwise permitted by
                  law. I understand this Authorization is voluntary
                  and I may refuse to sign it. I understand the
                  information to be released may include sensitive
                  information, such as AIDS/HIV test results and
                  diagnosis and treatment, drug screen results and
                  information about alcohol and drug use and
                  treatment, mental health, sexually transmitted
                  diseases and genetic information.
                </div>
              </Typography>
            </Card>
            </Form.Row>
            <Form.Row className="mt-5 mr-3 ml-3">
              <Col className="my-1">
                <Typography variant="h4" className="text-center mt-5">
                  HIPAA AUTHORIZATION TO RELEASE PATIENT INFORMATION
                </Typography>
              </Col>
            </Form.Row>
            <Form.Row className="mr-3 ml-3">
              <Col className="my-1">
                <Typography className="mt-1 mr-2">
                  I hereby request that Sonospine&reg; use/disclose my protected
                  health information (PHI) as directed below. Specifically, I
                  authorize SonoSpine to share my PHI:
                </Typography>
              </Col>
            </Form.Row>
            <Form.Row className="mr-3 ml-3">
              <Col xs={12} md={5} className="my-1">
                <Typography className="mt-1">
                  1. From the following facilities and/or providers:
                </Typography>
              </Col>
              <Col xs={12} md={7} className="my-1">
                <Form.Control
                  type="text"
                  placeholder="Facilities or Providers*"
                  {...getInputProps("phiFacilities", form)}
                />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage
                    name="phiFacilities"
                    component="div"
                    data-testid="email-error"
                  />
                </Form.Control.Feedback>
              </Col>
            </Form.Row>
            <Form.Row className="mr-3 ml-3">
              <Col xs={12} md={12} className="my-1">
                <Typography className="mt-1">
                  2. With the following person/entity at the address below:
                </Typography>
              </Col>
            </Form.Row>
            <Form.Row className="mr-3 ml-3">
              <Col xs={12} md={6} className="my-1">
                <Form.Control
                  type="text"
                  placeholder="First Name"
                  {...getInputProps("phiPatientName", form)}
                />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage
                    name="phiPatientName"
                    component="div"
                    data-testid="email-error"
                  />
                </Form.Control.Feedback>
              </Col>
              <Col xs={12} md={6} className="my-1">
                <Form.Control
                  type="text"
                  placeholder="Last Name"
                  {...getInputProps("phiPatientLastName", form)}
                />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage
                    name="phiPatientLastName"
                    component="div"
                    data-testid="email-error"
                  />
                </Form.Control.Feedback>
              </Col>
            </Form.Row>
            <Form.Row className="mr-3 ml-3">
              <Col xs={12} md={6} className="my-1">
                <Form.Control
                  type="text"
                  placeholder="Phone Number"
                  {...getInputProps("phiPatientPhone", form)}
                />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage
                    name="phiPatientPhone"
                    component="div"
                    data-testid="email-error"
                  />
                </Form.Control.Feedback>
              </Col>
              <Col xs={12} md={6} className="my-1">
                <Form.Control
                  type="text"
                  placeholder="Email"
                  {...getInputProps("phiPatientMail", form)}
                />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage
                    name="phiPatientMail"
                    component="div"
                    data-testid="email-error"
                  />
                </Form.Control.Feedback>
              </Col>
            </Form.Row>
            <Form.Row className="mr-3 ml-3">
              <Col xs={12} md={12} className="my-1">
                <Form.Control
                  type="text"
                  placeholder="Mailing Address"
                  {...getInputProps("phiPatientMailingAddress", form)}
                />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage
                    name="phiPatientMailingAddress"
                    component="div"
                    data-testid="email-error"
                  />
                </Form.Control.Feedback>
              </Col>
            </Form.Row>
            <Form.Row className="mr-3 ml-3">
              <Col xs={12} md={12} className="my-1">
                <Typography className=" mt-1">
                  3. I authorize disclosure of the following specific information
                </Typography>
              </Col>
            </Form.Row>
            <Form.Row className="mr-3 ml-3">
              <Form.Group >
                <Form.Check
                  inline
                  {...getInputProps("phiMedicalRecords", form)}
                  checked={getFieldProps("phiMedicalRecords").value || false}
                  type="checkbox"
                  label="Medical Records"
                  className="mt-2"
                />
                <Form.Check
                  inline
                  {...getInputProps("phiTreatmentPlan", form)}
                  checked={getFieldProps("phiTreatmentPlan").value || false}
                  type="checkbox"
                  label="Treatment Plan"
                  className="mt-2"
                />
                <Form.Check
                  inline
                  {...getInputProps("phiAppointments", form)}
                  checked={getFieldProps("phiAppointments").value || false}
                  type="checkbox"
                  label="Appointments"
                />
                <Form.Check
                  inline
                  {...getInputProps("phiOther", form)}
                  checked={getFieldProps("phiOther").value || false}
                  type="checkbox"
                  label="Other:"
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Control
                  type="text"
                  placeholder="Other"
                  {...getInputProps("phiOtherText", form)}
                  checked={getFieldProps("phiOtherText").value || false}
                />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage
                    name="phiOtherText"
                    component="div"
                    data-testid="password-error"
                  />
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row className="mr-3 ml-3">
              <Col xs={12} md={12} className="my-1">
                <Typography className=" mt-1">
                  4. I understand that I have the right to receive a copy of my PHI in
                  the form and format and manner I request, if readily producible in
                  that way, or as I may otherwise agree.{" "}
                  <strong>
                    {" "}
                    Unless otherwise specified below, I understand that my PHI will be
                    provided in paper format.
                  </strong>
                </Typography>
              </Col>
            </Form.Row>
            <Form.Row className="mr-3 ml-3">
              <Col xs={12} md={12} className="my-1">
                <Typography className=" mt-1">
                  5. I understand that the information used or disclosed may be
                  subject to re-disclosure by the person or class of persons or entity
                  receiving it and would then no longer be protected by federal
                  privacy regulations.
                </Typography>
              </Col>
            </Form.Row>
            <Form.Row className="mr-3 ml-3">
              <Col xs={12} md={12} className="my-1">
                <Typography className=" mt-1">
                  6. I understand I may revoke this authorization by notifying
                  Sonospine® in writing of my desire to revoke it.
                </Typography>
              </Col>
            </Form.Row>
            <Form.Row className="mr-3 ml-3">
              <Col xs={12} md={12} className="my-1">
                <Typography className=" mt-1">
                  7. My purpose/use of the information is for: Personal use; or other
                  (please specify)
                </Typography>
              </Col>
            </Form.Row>
            <Form.Row className="mr-3 ml-3">
              <Col xs={12} md={12} className="my-1">
                <Form.Control
                  type="text"
                  placeholder="Other Purpose"
                  {...getInputProps("phiOtherPurpose", form)}
                />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage
                    name="phiOtherPurpose"
                    component="div"
                    data-testid="email-error"
                  />
                </Form.Control.Feedback>
              </Col>
            </Form.Row>
            <Form.Row className="mr-3 ml-3">
              <Col xs={12} md={3} className="my-1">
                <Typography className=" mt-1">
                  8. This authorization expires on:
                </Typography>
              </Col>
              <Col xs={12} md={3} className="my-1">
                <label>
                  <DatePicker

                    className="form-control"
                    placeholderText="mm/dd/yyyy*"
                    dateFormat="MM/dd/yyyy"
                    isClearable={true}
                    selected={expirationDate}
                    onChange={(date) => {
                      if (date == null || !Array.isArray(date)) {
                        form.setFieldValue("phiExpirationDate", Moment(date).format("YYYY-MM-DD"));
                        setExpirationDate(date);
                      }
                    }
                    }
                  />
                </label>
                &nbsp;<i className="far fa-calendar-alt fa-2x" onClick={() => {
                  startRef.current?.setOpen(true);
                }}></i>
              </Col>

            </Form.Row>
            <Form.Row className="mr-3 ml-3">
              <Col xs={12} md={12} className="my-1">
                <Typography className=" mt-1">
                  OR upon occurrence of the following event that relates to me or the
                  purpose of the intended use or disclosure of information about me:
                </Typography>
              </Col>
              <Col xs={12} md={12} className="my-1">
                <Form.Control
                  type="text"
                  placeholder="(Please specify)"
                  {...getInputProps("phiExpiryOther", form)}
                />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage
                    name="phiExpiryOther"
                    component="div"
                    data-testid="email-error"
                  />
                </Form.Control.Feedback>
              </Col>
            </Form.Row>
            <Form.Row className="m-3">
              <Col className="my-1">
                <Typography className="text-center">
                  <strong>
                    THIS FORM MUST BE FULLY COMPLETED BEFORE SIGNING, INCOMPLETE FORMS
                    WILL NOT BE PROCESSED.
                  </strong>
                </Typography>
              </Col>
            </Form.Row>
          </Card>
        </Col>
      </Row>
    </Container>
    </>
  );
};

export default FirstName;

