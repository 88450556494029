import { CETheme } from 'utils/constants/themes/theme.types';

const careexpandTheme: CETheme = {
  colors: {
    primary: {
      base: '#3f51b5',
      100: '#56bdfa', // $malibu-blue
      200: '#2196F3', // $dodger-blue
      300: '#009efc', // $deep-sky-blue
      400: '#3f51b5', // $cornflower-blue2
      500: '#5b8def', // $cornflower-blue
      600: '#3667ac', // $azure-blue
    },
    secondary: {
      base: '#FF5C5C',
      100: '#56bdfa', // $malibu-blue
      200: '#2196F3', // $dodger-blue
      300: '#009efc', // $deep-sky-blue
      400: '#489dff', // $cornflower-blue2
      500: '#5b8def', // $cornflower-blue
      600: '#3667ac', // $azure-blue
    },
    accent: {
      base: '#489dff',
      100: '#489dff',
      200: '#489dff',
      300: '#489dff',
      400: '#489dff',
      500: '#489dff',
      600: '#489dff',
    },
    alert: '#FF5C5C',
    grey: {
      base: '#f5f5f5',
      100: '#f5f5f5',
      200: '#f5f5f5',
      300: '#f5f5f5',
      400: '#f5f5f5',
      500: '#f5f5f5',
      600: '#f5f5f5',
    },
  },
  images: {
    logo: '/assets/images/logos/sonospine_logo.png',
  },
};

export default careexpandTheme;
