import React, { useEffect } from "react";
// Components
import { CircularProgress, Box, Button, Card, Typography} from "@material-ui/core";
import { Row, Col, Form, Container } from "react-bootstrap";
// Hooks
import { useFormikContext } from "formik";
import { useDocuments } from "utils/hooks/useDocuments.hook";
import DocumentSignature from "pages/documents/views/DocumentsSignature";

type NavigationProps = {
  maxSteps: number;
  currentIndex: number;
  onClickNext: () => void;
  onClickBack: () => void;
  onClickSubmit: () => void;
};

/** Gotcha: Will need to be a child of a Formik component to have access to Formik context */
const Navigation = ({
  maxSteps,
  currentIndex,
  onClickNext,
  onClickBack,
  onClickSubmit,
}: NavigationProps) => {
  const isFirstStep = currentIndex === 0;
  const isLastStep = currentIndex === maxSteps - 1;

  // Grab what we need from formik without prop-drilling
  const {
    validateForm,
    handleSubmit,
    isSubmitting,
    isValid,
    getFieldProps
  } = useFormikContext();

  // Grab the loading information
  const { isSavingFormData } = useDocuments();

  // Will run form.validateForm() when the currentIndex prop is changed
  useEffect(() => {
    validateForm();
  }, [currentIndex, validateForm]);

  const caseId = getFieldProps("caseId").value;
  const contactId = getFieldProps("contactId").value;
  const generationType = getFieldProps("generationType").value;
  const hasAgreedToS = getFieldProps("hasAgreedToS").value;
  return (
    <Container className="">
      <Row className="align-items-center">
        <Col>
          <Card>
            <Form.Row className="mr-3 ml-3 text-right">
              <Col xs={12} md={{ span: 2, offset: 8 }} className="my-1">
                {!isFirstStep && (
                  <Button color="primary" onClick={onClickBack}>
                    <i className="fas fa-backward"> </i>&nbsp;Back
                  </Button>
                )}
              </Col>
              <Col xs={12} md={2} className="my-1">
                {isLastStep ? (
                  
                  <DocumentSignature
                  caseId={caseId}
                  contactId={contactId}
                  fileGenerationType={generationType}
                  onClickSubmit={onClickSubmit}
                  hasAgreedToS={hasAgreedToS}
                  isValid={isValid}
                ></DocumentSignature>
                ) : (
                  <Button color="primary" disabled={!isValid} onClick={() => {
                    return onClickNext()}}>
                    {isSavingFormData ? <CircularProgress size={14} /> : "Next"}
                    &nbsp; <i className="fas fa-forward"></i>
                                      </Button>
                )}
              </Col>
            </Form.Row>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Navigation;
