import { useState } from "react";
// Components
import { Typography, Card, Tooltip } from "@material-ui/core";
import { Row, Col, Form, Container } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
// Types
import {
  FormikProps,
  FormikValues,
  ErrorMessage,
  useFormikContext,
} from "formik";
// Util
import {
  CommonFormProps,
  getInputProps,
  getInputPropsMultiSelect,
} from "utils/constants/forms";
import {
  painOptionsArray,
  painLocationOptionsArray,
  extremitiesOptionsArray,
  activitiesOptionsArray,
  incontinenceOptionsArray,
  activitiesDecreaseArray
} from "utils/form/multiSelectOptions";
import { Option } from "react-multi-select-component/dist/lib/interfaces";
// Dates
import Moment from "moment";
import DatePicker from "react-datepicker";
import { isValidDate } from "utils/ValidationUtils";


type FirstNameProps = {
  form: FormikProps<FormikValues>;
  patientName: "ChiefComplaintQuestions";
};

const ChiefComplaintQuestions = ({ form }: CommonFormProps) => {
  // Grab what we need from formik without prop-drilling
  const { getFieldProps } = useFormikContext();

  const painOptions = painOptionsArray;
  const [painSelected, setPainSelected] = useState(
    getFieldProps("patientCCPainType").value || []
  );

  const painLocationOptions = painLocationOptionsArray;
  // We use as initial the values from the form
  const [painLocationSelected, setPainLocationSelected] = useState(
    getFieldProps("patientCCPainLocation").value || []
  );

  const extremitiesOptions = extremitiesOptionsArray;
  const [extremitiesSelected, setExtremitiesSelected] = useState(
    getFieldProps("patientCCPainExtremitiesAreas").value || []
  );

  const [
    weaknessExtremitiesSelected,
    setWeaknessExtremitiesSelected,
  ] = useState(getFieldProps("patientCCWeaknessExtremitiesAreas").value || []);

  const [
    numbnessExtremitiesSelected,
    setNumbnessExtremitiesSelected,
  ] = useState(getFieldProps("patientCCNumbnessAreas").value || []);

  const incontinenceOptions = incontinenceOptionsArray;

  const [incontinenceSelected, setIncontinenceSelected] = useState(
    getFieldProps("patientCCIncontinence").value || []
  );

  const activitiesOptions = activitiesOptionsArray;

  const [activitiesIncreaseSelected, setActivitiesIncreaseSelected] = useState(
    getFieldProps("patientCCActivitiesIncrease").value || []
  );

  const activitiesDecreaseOptions = activitiesDecreaseArray;
  const [activitiesDecreaseSelected, setActivitiesDecreaseSelected] = useState(
    getFieldProps("patientCCActivitiesDecrease").value || []
  );

  // Handle Symptoms Begin Date
  var patientCCSymptomBegin = getFieldProps("patientCCSymptomBegin").value;

  var date = null;
  if (isValidDate(patientCCSymptomBegin)) {
    const mDate = Moment(patientCCSymptomBegin, "YYYY-MM-DD");
    date = new Date(mDate.toDate());
  }
  const [symptomBegin, setSymptomBegin] = useState<Date | null>(date);



  return (
    <>
      <Container className="mt-4">
        <Row className="align-items-center">
          <Col>
            <Card>
              <Row>
                <Col>
                  <Typography variant="h4" className="text-center mt-5">
                    Chief Complaint Questions
                  </Typography>
                </Col>
              </Row>
              <Form.Row className="mt-5 mr-3 ml-3">
                <Col xs={12} md={6} className="my-1">
                  <Form.Label column>
                    For what problem are you requesting a consultation?
                  </Form.Label>
                </Col>
                <Col xs={12} md={6} className="my-1">
                  <Form.Control
                    as="textarea"
                    rows={4}
                    type="text"
                    placeholder="Describe your problem!"
                    {...getInputProps("patientChiefComplaintProblem", form)}
                  />
                  <ErrorMessage
                    name="patientChiefComplaintProblem"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
              </Form.Row>
              <Form.Row className="mr-3 ml-3">
                <Col xs={12} md={6} className="my-1">
                  <Form.Label column>
                    What symptoms are you currently experiencing?
                  </Form.Label>
                </Col>
                <Col xs={12} md={6} className="my-1">
                  <Form.Check
                    inline
                    {...getInputProps("patientCCBackPain", form)}
                    checked={getFieldProps("patientCCBackPain").value || false}
                    type="checkbox"
                    label="Back Pain"
                    className="m-3"
                  />
                  <Form.Check
                    inline
                    {...getInputProps("patientCCNeckPain", form)}
                    checked={getFieldProps("patientCCNeckPain").value || false}
                    type="checkbox"
                    label="Neck Pain"
                    className="m-3"
                  />
                  <Form.Check
                    inline
                    {...getInputProps("patientCCHeadache", form)}
                    checked={getFieldProps("patientCCHeadache").value || false}
                    type="checkbox"
                    label="Headache"
                    className="m-3"
                  />
                  <Form.Check
                    inline
                    {...getInputProps("patientCCMusclePain", form)}
                    checked={
                      getFieldProps("patientCCMusclePain").value || false
                    }
                    type="checkbox"
                    label="Muscle Pain or Ache"
                    className="m-3"
                  />
                  <Form.Check
                    inline
                    {...getInputProps("patientCCMuscleSpasm", form)}
                    checked={
                      getFieldProps("patientCCMuscleSpasm").value || false
                    }
                    type="checkbox"
                    label="Muscle Spasm"
                    className="m-3"
                  />
                  <Form.Check
                    inline
                    {...getInputProps("patientCCChestPain", form)}
                    checked={getFieldProps("patientCCChestPain").value || false}
                    type="checkbox"
                    label="Chest Pain"
                    className="m-3"
                  />
                  <Form.Check
                    inline
                    {...getInputProps("patientCCChestPalpitations", form)}
                    checked={
                      getFieldProps("patientCCChestPalpitations").value || false
                    }
                    type="checkbox"
                    label="Chest Palpitations"
                    className="m-3"
                  />
                  <Form.Check
                    inline
                    {...getInputProps("patientCCWeightLoss", form)}
                    checked={
                      getFieldProps("patientCCWeightLoss").value || false
                    }
                    type="checkbox"
                    label="Weight Loss"
                    className="m-3"
                  />
                  <Form.Check
                    inline
                    {...getInputProps("patientCCWeightGain", form)}
                    checked={
                      getFieldProps("patientCCWeightGain").value || false
                    }
                    type="checkbox"
                    label="Weight Gain"
                    className="m-3"
                  />
                  <Form.Check
                    inline
                    {...getInputProps("patientCCFever", form)}
                    checked={getFieldProps("patientCCFever").value || false}
                    type="checkbox"
                    label="Fever"
                    className="m-3"
                  />
                  <Form.Check
                    inline
                    {...getInputProps("patientCCSpells", form)}
                    checked={getFieldProps("patientCCSpells").value || false}
                    type="checkbox"
                    label="Hot or Cold Spells"
                    className="m-3"
                  />
                  <Form.Check
                    inline
                    {...getInputProps("patientCCVision", form)}
                    checked={getFieldProps("patientCCVision").value || false}
                    type="checkbox"
                    label="Vision Changes"
                    className="m-3"
                  />
                  <Form.Check
                    inline
                    {...getInputProps("patientCCHearing", form)}
                    checked={getFieldProps("patientCCHearing").value || false}
                    type="checkbox"
                    label="Hearing Problems"
                    className="m-3"
                  />
                  <Form.Check
                    inline
                    {...getInputProps("patientCCCough", form)}
                    checked={getFieldProps("patientCCCough").value || false}
                    type="checkbox"
                    label="Cough"
                    className="m-3"
                  />
                  <Form.Check
                    inline
                    {...getInputProps("patientCCBreath", form)}
                    checked={getFieldProps("patientCCBreath").value || false}
                    type="checkbox"
                    label="Shortness of breath"
                    className="m-3"
                  />
                  <Form.Check
                    inline
                    {...getInputProps("patientCCStomach", form)}
                    checked={getFieldProps("patientCCStomach").value || false}
                    type="checkbox"
                    label="Stomach Pain"
                    className="m-3"
                  />
                  <Form.Check
                    inline
                    {...getInputProps("patientCCNausea", form)}
                    checked={getFieldProps("patientCCNausea").value || false}
                    type="checkbox"
                    label="Nausea"
                    className="m-3"
                  />
                  <Form.Check
                    inline
                    {...getInputProps("patientCCVomiting", form)}
                    checked={getFieldProps("patientCCVomiting").value || false}
                    type="checkbox"
                    label="Vomiting"
                    className="m-3"
                  />
                  <Form.Check
                    inline
                    {...getInputProps("patientCCConstipation", form)}
                    checked={
                      getFieldProps("patientCCConstipation").value || false
                    }
                    type="checkbox"
                    label="Constipation"
                    className="m-3"
                  />
                  <Form.Check
                    inline
                    {...getInputProps("patientCCDiarrhea", form)}
                    checked={getFieldProps("patientCCDiarrhea").value || false}
                    type="checkbox"
                    label="Diarrhea"
                    className="m-3"
                  />
                </Col>
                <Col xs={12} md={{ span: 2, offset: 6 }} className="my-1">
                  <Form.Check
                    inline
                    {...getInputProps("patientCCNumbness", form)}
                    checked={getFieldProps("patientCCNumbness").value || false}
                    type="checkbox"
                    label="Numbness"
                    className="m-3"
                  />
                </Col>
                <Col xs={12} md={4} className="my-1">
                  <Form.Control
                    type="text"
                    placeholder="Numbness Description"
                    {...getInputProps("patientCCNumbnessDesc", form)}
                  />
                </Col>
                <Col xs={12} md={{ span: 2, offset: 6 }} className="my-1">
                  <Form.Check
                    inline
                    {...getInputProps("patientCCWeakness", form)}
                    checked={getFieldProps("patientCCWeakness").value || false}
                    type="checkbox"
                    label="Weakness"
                    className="m-3"
                  />
                </Col>
                <Col xs={12} md={4} className="my-1">
                  <Form.Control
                    type="text"
                    placeholder="Weakness Description"
                    {...getInputProps("patientCCWeaknessDesc", form)}
                  />
                </Col>
                <Col xs={12} md={{ span: 2, offset: 6 }} className="my-1">
                  <Form.Check
                    inline
                    {...getInputProps("patientCCOtherSymptoms", form)}
                    checked={
                      getFieldProps("patientCCOtherSymptoms").value || false
                    }
                    type="checkbox"
                    label="Other"
                    className="m-3"
                  />
                </Col>
                <Col xs={12} md={4} className="my-1">
                  <Form.Control
                    type="text"
                    placeholder="Other Description"
                    {...getInputProps("patientCCOtherSymptomsDesc", form)}
                  />
                </Col>
              </Form.Row>
              <Form.Row className="mr-3 ml-3">
                <Col xs={12} md={6} className="my-1">
                  <Form.Label column>
                    What is your pain level today on a scale of 0-10? With 0
                    being no pain and 10 being pain so severe you could lose
                    consciousness.
                  </Form.Label>
                </Col>
                <Col xs={12} md={4} className="my-1">
                  <Form.Control
                    type="range"
                    min="0.1"
                    max="10"
                    step="0.1"
                    {...getInputProps(
                      "patientChiefComplaintPainLevelToday",
                      form
                    )}
                  ></Form.Control>
                </Col>{" "}
                <Col xs={12} md={2} className="my-1">
                  <Form.Control
                    type="text"
                    readOnly={true}
                    {...getInputProps(
                      "patientChiefComplaintPainLevelToday",
                      form
                    )}
                  />

                </Col>
              </Form.Row>
              <Form.Row className="mr-3 ml-3">
                <Col xs={12} md={6} className="my-1">
                  <Form.Label column>
                    What has your pain level averaged over the last week on a
                    scale of 0-10? With 0 being no pain and 10 being pain so
                    severe you could lose consciousness.
                  </Form.Label>
                </Col>
                <Col xs={12} md={4} className="my-1">
                  <Form.Control
                    type="range"
                    min="0.1"
                    max="10"
                    step="0.1"
                    {...getInputProps(
                      "patientChiefComplaintPainLevelWeek",
                      form
                    )}
                  ></Form.Control>
                </Col>
                <Col xs={12} md={2} className="my-1">
                  <Form.Control
                    type="text"
                    readOnly={true}
                    {...getInputProps(
                      "patientChiefComplaintPainLevelWeek",
                      form
                    )}
                  />
                </Col>
              </Form.Row>
              <Form.Row className="mr-3 ml-3">
                <Col xs={12} md={6} className="my-1">
                  <Form.Label column>
                    What type of pain are you experiencing?
                  </Form.Label>
                </Col>
                <Col xs={12} md={6} className="my-1">
                  <MultiSelect
                    options={painOptions}
                    value={painSelected}
                    onChange={(values: any) => {
                      setPainSelected(values);
                      form.values.patientCCPainType = values;
                    }}
                    {...getInputPropsMultiSelect("patientCCPainType", form)}
                    labelledBy="Select Pain Type"
                  />
                </Col>
              </Form.Row>
              <Form.Row className="mr-3 ml-3">
                <Col xs={12} md={6} className="my-1">
                  <Form.Label column>Where is your pain?</Form.Label>
                </Col>
                <Col xs={12} md={6} className="my-1">
                  <MultiSelect
                    options={painLocationOptions}
                    value={painLocationSelected}
                    onChange={(values: any) => {
                      setPainLocationSelected(values);
                      form.values.patientCCPainLocation = values;
                    }}
                    {...getInputPropsMultiSelect("patientCCPainLocation", form)}
                    labelledBy="Select Pain Location"
                  />
                  <ErrorMessage
                    name={`patientCCPainLocation`}
                    component="div"
                    data-testid="email-error"
                  />
                </Col>
              </Form.Row>

              <Form.Row className="mr-3 ml-3">
                <Col xs={12} md={6} className="my-1">
                  <Form.Label column>What area is of most concern?</Form.Label>
                </Col>
                <Col xs={12} md={6} className="my-1">
                  <Form.Control
                    as="select"
                    custom
                    type="text"
                    placeholder="Area of most Concern"
                    {...getInputProps("patientCCPainMostConcern", form)}
                  >
                    <option>-- Select area of most concern --</option>
                    {painLocationOptions.map((item: Option, i: number) => {
                      return <option key={i}>{item.label}</option>;
                    })}
                  </Form.Control>
                  <ErrorMessage
                    name="patientCCPainMostConcern"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
              </Form.Row>
              <Form.Row className="mr-3 ml-3">
                <Col xs={12} md={6} className="my-1">
                  <Form.Label column>
                    Does the pain radiate into your extremities?
                  </Form.Label>
                </Col>
                <Col xs={12} md={6} className="my-1">
                  <Form.Control
                    as="select"
                    custom
                    type="text"
                    placeholder="Area of most Concern"
                    {...getInputProps("patientCCPainExtremities", form)}
                  >
                    <option>-- Select --</option>
                    <option>Yes</option>
                    <option>No</option>
                  </Form.Control>
                  <ErrorMessage
                    name="patientCCPainExtremities"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
              </Form.Row>
              <Form.Row className="mr-3 ml-3">
                <Col xs={12} md={6} className="my-1">
                  <Form.Label column>Select areas</Form.Label>
                </Col>
                <Col xs={12} md={6} className="my-1">
                  <MultiSelect
                    options={extremitiesOptions}
                    value={extremitiesSelected}
                    onChange={(values: any) => {
                      setExtremitiesSelected(values);
                      form.values.patientCCPainExtremitiesAreas = values;
                    }}
                    {...getInputPropsMultiSelect(
                      "patientCCPainExtremitiesAreas",
                      form
                    )}
                    labelledBy="Select Extremities"
                  />
                </Col>
              </Form.Row>
              <Form.Row className="mr-3 ml-3">
                <Col xs={12} md={6} className="my-1">
                  <Form.Label column>
                    Are you experiencing any associated weakness or loss of
                    strength?
                  </Form.Label>
                </Col>
                <Col xs={12} md={6} className="my-1">
                  <Form.Control
                    as="select"
                    custom
                    type="text"
                    placeholder="Area of most Concern"
                    {...getInputProps("patientCCExperiencingWeakness", form)}
                  >
                    <option>-- Select --</option>
                    <option>Yes</option>
                    <option>No</option>
                  </Form.Control>
                  <ErrorMessage
                    name="patientCCExperiencingWeakness"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
              </Form.Row>
              <Form.Row className="mr-3 ml-3">
                <Col xs={12} md={6} className="my-1">
                  <Form.Label column>Select areas</Form.Label>
                </Col>
                <Col xs={12} md={6} className="my-1">
                  <MultiSelect
                    options={extremitiesOptions}
                    value={weaknessExtremitiesSelected}
                    onChange={(values: any) => {
                      setWeaknessExtremitiesSelected(values);
                      form.values.patientCCWeaknessExtremitiesAreas = values;
                    }}
                    {...getInputPropsMultiSelect(
                      "patientCCWeaknessExtremitiesAreas",
                      form
                    )}
                    labelledBy="Select Extremities"
                  />
                </Col>
              </Form.Row>
              <Form.Row className="mr-3 ml-3">
                <Col xs={12} md={6} className="my-1">
                  <Form.Label column>
                    Are you experiencing any numbness or tingling?
                  </Form.Label>
                </Col>
                <Col xs={12} md={6} className="my-1">
                  <Form.Control
                    as="select"
                    custom
                    type="text"
                    placeholder="Area of most Concern"
                    {...getInputProps("patientCCExperiencingNumbness", form)}
                  >
                    <option>-- Select --</option>
                    <option>Yes</option>
                    <option>No</option>
                  </Form.Control>
                  <ErrorMessage
                    name="patientCCExperiencingNumbness"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />
                </Col>
              </Form.Row>
              <Form.Row className="mr-3 ml-3">
                <Col xs={12} md={6} className="my-1">
                  <Form.Label column>Select areas</Form.Label>
                </Col>
                <Col xs={12} md={6} className="my-1">
                  <MultiSelect
                    options={extremitiesOptions}
                    value={numbnessExtremitiesSelected}
                    onChange={(values: any) => {
                      setNumbnessExtremitiesSelected(values);
                      form.values.patientCCNumbnessAreas = values;
                    }}
                    {...getInputPropsMultiSelect(
                      "patientCCNumbnessAreas",
                      form
                    )}
                    labelledBy="Select Extremities"
                  />
                </Col>
              </Form.Row>
              <Form.Row className="mr-3 ml-3">
                <Col xs={12} md={6} className="my-1">
                  <Form.Label column>
                    Have your symptoms been associated with incontinence (lost
                    control of bowel and/or bladder function)?
                  </Form.Label>
                </Col>
                <Col xs={12} md={6} className="my-1">
                  <MultiSelect
                    options={incontinenceOptions}
                    value={incontinenceSelected}
                    onChange={(values: any) => {
                      setIncontinenceSelected(values);
                      form.values.patientCCIncontinence = values;
                    }}
                    {...getInputPropsMultiSelect("patientCCIncontinence", form)}
                    labelledBy="Select Extremities"
                  />
                </Col>
              </Form.Row>
              <Form.Row className="mr-3 ml-3">
                <Col xs={12} md={6} className="my-1">
                  <Form.Label column title="It's ok if you don't recall an exact date, just make your best guess">When did your symptoms begin?</Form.Label>
                </Col>

                <Col xs={10} md={5} className="my-1">
                  <label>
                    <DatePicker
                      className="form-control"
                      placeholderText="MM/dd/yyyy*"
                      dateFormat="MM/dd/yyyy"
                      isClearable={true}
                      selected={symptomBegin}
                      onChange={(date) => {
                        if (date == null || !Array.isArray(date)) {
                          form.setFieldValue("patientCCSymptomBegin", Moment(date).format("YYYY-MM-DD"));
                          setSymptomBegin(date);
                        }
                      }
                      }
                    />
                  </label>
                  &nbsp;<i className="far fa-calendar-alt fa-2x" onClick={() => {

                  }}></i>
                </Col>
                <Col xs={2} md={1} className="my-1 p-2">
                  <Tooltip arrow placement={"top"} title="It's ok if you don't recall an exact date, just make your best guess">
                    <i className="fal fa-info-circle"></i>
                  </Tooltip>
                </Col>
              </Form.Row>
              <Form.Row className="mr-3 ml-3">
                <Col xs={12} md={3} className="my-1">
                  <Form.Label column>
                    What activities increase your symptoms?
                  </Form.Label>
                </Col>
                <Col xs={12} md={3} className="my-1">
                  <MultiSelect
                    options={activitiesOptions}
                    value={activitiesIncreaseSelected}
                    onChange={(values: any) => {
                      setActivitiesIncreaseSelected(values);
                      form.values.patientCCActivitiesIncrease = values;
                    }}
                    {...getInputPropsMultiSelect(
                      "patientCCActivitiesIncrease",
                      form
                    )}
                    labelledBy="Select Extremities"
                  />
                </Col>
                <Col xs={12} md={2} className="my-1">
                  <Form.Label column>Description:</Form.Label>
                </Col>
                <Col xs={12} md={4} className="my-1">
                  <Form.Control
                    type="text"
                    placeholder="Description"
                    {...getInputProps("patientCCIncreaseDescription", form)}
                  />
                  <ErrorMessage
                    name="patientCCIncreaseDescription"
                    component="div"
                    data-testid="email-error"
                  />
                </Col>
              </Form.Row>
              <Form.Row className="mr-3 ml-3 mb-3">
                <Col xs={12} md={3} className="my-1">
                  <Form.Label column>
                    What activities decrease your symptoms?
                  </Form.Label>
                </Col>
                <Col xs={12} md={3} className="my-1">
                  <MultiSelect
                    options={activitiesDecreaseOptions}
                    value={activitiesDecreaseSelected}
                    onChange={(values: any) => {
                      setActivitiesDecreaseSelected(values);
                      form.values.patientCCActivitiesDecrease = values;
                    }}
                    {...getInputPropsMultiSelect(
                      "patientCCActivitiesDecrease",
                      form
                    )}
                    labelledBy="Select Extremities"
                  />
                </Col>
                <Col xs={12} md={2} className="my-1">
                  <Form.Label column>Description:</Form.Label>
                </Col>
                <Col xs={12} md={4} className="my-1">
                  <Form.Control
                    type="text"
                    placeholder="Description"
                    {...getInputProps("patientCCDecreaseDescription", form)}
                  />
                  <ErrorMessage
                    name="patientCCDecreaseDescription"
                    component="div"
                    data-testid="email-error"
                  />
                </Col>
              </Form.Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ChiefComplaintQuestions;
