import { doGet, doPost, doPostMultipart } from "api/carexGateway";
import { CancelToken } from "axios";
import {
  IFIleExportGet
} from "store/auth/auth.types";
import { FileGeneration } from "utils/model/FileGeneration";
import { FileSignature } from "utils/model/FileSignature";
import { Insurer } from "utils/model/Insurer";
import { MultiPartPackage } from "utils/model/MultiPartPackage";
import { ScreeningCase } from "utils/model/ScreeningCase";

const DocumentsService = {
  getFileExport: async (payload: IFIleExportGet, cancelToken?: CancelToken) => {
    try {
      const bodyFormData = new FormData();

      bodyFormData.append("tokenId", payload.tokenId || "");
      const res = await doPost("getFileExport", {
        data: payload,
        cancelToken,
      });

      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  getFileGeneration: async (payload: FileGeneration, cancelToken?: CancelToken) => {
    try {
      const bodyFormData = new FormData();

      bodyFormData.append("tokenId", payload.tokenId || "");
      const res = await doPost("getFileGeneration", {
        data: payload,
        cancelToken,
      });

      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  saveFormData: async (payload: FileGeneration, cancelToken?: CancelToken) => {
    try {
      const bodyFormData = new FormData();

      bodyFormData.append("caseId", "" + payload.caseId || "");
      bodyFormData.append("contactId", "" + payload.contactId || "");
      bodyFormData.append("generationType", "" + payload.generationType || "");
      bodyFormData.append("tokenId", payload.tokenId || "");
      bodyFormData.append("formData", payload.formData || "");
      const res = await doPost("saveFormData", {
        data: payload,
        cancelToken,
      });

      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  saveSignatureData: async (payload: FileGeneration, cancelToken?: CancelToken) => {
    try {
      const bodyFormData = new FormData();

      bodyFormData.append("caseId", "" + payload.caseId || "");
      bodyFormData.append("contactId", "" + payload.contactId || "");
      bodyFormData.append("generationType", "" + payload.generationType || "");
      bodyFormData.append("signatureData", payload.signatureData || "");
      const res = await doPost("saveSignatureData", {
        data: payload,
        cancelToken,
      });

      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  getScreeningCaseByCaseId: async (payload: number, cancelToken?: CancelToken) => {
    try {
      const res = await doGet<ScreeningCase>(
        'getScreeningCaseByCaseId',
        { params: { ":caseId": "" + payload } },
        cancelToken
      );
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  signDocument: async (payload: FileSignature, cancelToken?: CancelToken) => {
    // Update to file Generation Save signature
    try {
      const res = await doPost("signPdf", {
        data: payload,
        cancelToken,
      });

      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  saveDocument: async (payload: MultiPartPackage, cancelToken?: CancelToken) => {
    try {
      let formData = new FormData();
      formData.append("File", payload.file.fileData, payload.file.filename);
      formData.append("ContactId", "" + payload.contactId || "");
      formData.append("CaseId", "" + payload.caseId || "");
      formData.append("FileTypeId", "" + payload.fileTypeId || "");
      formData.append("FileDescription", payload.fileDescription || "");
      const res = await doPostMultipart("saveFile", {
        data: formData,
        cancelToken,
      });

      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  getPdfBytes: async (payload: string) => {
    try {
      const res = await doGet<any>("pdfBytes", { responseType: 'arraybuffer', params: { ':documentType': payload } });
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  getInsuranceCarrierValues: async (search?: String, top?: number, cancelToken?: CancelToken) => {
    try {
      const res = await doGet<Array<Insurer>>(
        'getInsurers',
        { params: {} },
        cancelToken
      );
      console.log(res)
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  },
};

export default DocumentsService;
