import moment from "moment";
import { PDFFont, PDFForm, PDFPage, rgb } from "pdf-lib";
import PDFCheckboxFieldComponent from "../components/CheckboxFieldComponent";
import PDFTextFieldComponent from "../components/TextFieldComponent";
import { CurrentDimensions } from "../model/CurrentDimensions";
import { PDFSectionProps } from "../model/PDFSectionProps";


const InsuranceSection = (props: PDFSectionProps) => {
    // Header style
    const headerFontSize = props.headerFontSize;
    const textHeight = 4 * headerFontSize;

    // Label Style
    const labelFontSize = props.labelFontSize;
    const labelHeight = 4 * labelFontSize;

    // Keep track of the height and width
    var currentDimensions = { height: props.heightPointer + textHeight, width: 0 } as CurrentDimensions;
    props.page.drawText('Insurance Questions', {
        x: 30,
        y: props.height - currentDimensions.height,
        size: headerFontSize,
        font: props.timesRomanFont,
        color: rgb(0, 0, 0),
        opacity: 1,
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'insurance.carrier.field',
            fieldMargin: 150,
            fieldWidth: 100,
            fieldValue: props.formValues.patientInsuranceCarrier
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Insurance Carrier:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page
    });
    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'insurance.policy.number.field',
            fieldMargin: 150,
            fieldWidth: 100,
            fieldValue: props.formValues.patientInsurancePolicyNumber
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Policy Number:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });
    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'insurance.policy.group.number.field',
            fieldMargin: 150,
            fieldWidth: 100,
            fieldValue: props.formValues.patientInsurancePolicyGroupNumber
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Policy Group Number:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
    });

    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'insurance.policy.holder.name.field',
            fieldMargin: 150,
            fieldWidth: 100,
            fieldValue: props.formValues.patientInsurancePolicyHolderName
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Policy Holder Name:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });

    var patientInsurancePolicyHolderDoB = '-';
    if (moment(props.formValues.patientInsurancePolicyHolderDoB, "YYYY-MM-DD").isValid()) { 
        patientInsurancePolicyHolderDoB = moment(props.formValues.patientInsurancePolicyHolderDoB, "YYYY-MM-DD").format("MM/DD/YYYY") 
    }
    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'insurance.policy.holder.dob.field',
            fieldMargin: 150,
            fieldWidth: 100,
            fieldValue: patientInsurancePolicyHolderDoB
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Policy Holder DoB"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true,
    });

    var patientInsuranceStartDate = '-';
    if (moment(props.formValues.patientInsuranceStartDate, "YYYY-MM-DD").isValid()) { 
        patientInsuranceStartDate = moment(props.formValues.patientInsuranceStartDate, "YYYY-MM-DD").format("MM/DD/YYYY") 
    }
    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'insurance.effective.start.field',
            fieldMargin: 150,
            fieldWidth: 100,
            fieldValue: patientInsuranceStartDate
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Start date:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
    });
    var patientInsuranceEndDate = '-';
    if (moment(props.formValues.patientInsuranceEndDate, "YYYY-MM-DD").isValid()) { 
        patientInsuranceEndDate = moment(props.formValues.patientInsuranceEndDate, "YYYY-MM-DD").format("MM/DD/YYYY") 
    }
    currentDimensions = PDFTextFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 20,
            fieldId: 'insurance.effective.enddate.field',
            fieldMargin: 150,
            fieldWidth: 100,
            fieldValue: patientInsuranceEndDate
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 10,
            labelText: "End Date:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine:true,
    });


    currentDimensions = PDFCheckboxFieldComponent({
        currentDimensions: currentDimensions,
        fieldData: {
            fieldHeight: 10,
            fieldId: 'insurance.phone.field',
            fieldMargin: 150,
            fieldWidth: 10,
            fieldValue: props.formValues.patientInsurancePhone
        },
        labelData: {
            labelHeight: labelHeight,
            labelMargin: 30,
            labelText: "Insurance Phone:"
        },
        font: props.timesRomanFont,
        fontSize: labelFontSize,
        form: props.form,
        page: props.page,
        jumpLine: true
    });

    return currentDimensions;
};

export default InsuranceSection;
