import {
  getIn,
  FormikProps,
  FormikValues,
  Formik,
  FormikHandlers,
  FieldInputProps,
} from "formik";

export type StatePropsType = {
  form: FormikProps<FormikValues>;
  state: [any, React.Dispatch<React.SetStateAction<any>>];
  index: number;
};

export type StateIteratorPropsType = {
  form: FormikProps<FormikValues>;
  state: [any, React.Dispatch<React.SetStateAction<any>>];
  item: IteratorHelperDB;
};


export type MultiSelectStateProps = {
  form: FormikProps<FormikValues>;
  state: [any, React.Dispatch<React.SetStateAction<any>>];
  multiSelectProps: [any, React.Dispatch<React.SetStateAction<any>>];
  index: number;
};

export type CommonFormProps = {
  form: FormikProps<FormikValues>;
};

export type FileGenerationProps = {
  caseId: number;
  contactId: number;
  fileGenerationType: number;
  hasAgreedToS: boolean;
  onClickSubmit: () => void;
  isValid: boolean;
};

export type IteratorHelperDB = {
  index: number,
  comesFromDB: boolean
};

export const getInputProps = (
  name: string,
  formik: FormikProps<FormikValues>
) => {
  const value = getIn(formik.values, name);
  const error = (getIn(formik.errors, name) && getIn(formik.touched, name)) ? true: false;

  const props = {
    id: name,
    name: name,
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
    value: value || "",
    touch: formik.touched,
    error: error.toString(),
    // helperText: getIn(formik.errors, name)
  };

  return props;
};


export const getInputPropsRadio = (
  name: string,
  formik: FormikProps<FormikValues>,
  value: string
) => {
  const mValue = value
  const error = getIn(formik.errors, name) && getIn(formik.touched, name);

  const props = {
    id: name,
    name: name,
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
    value: value || "",
    error,
    // helperText: getIn(formik.errors, name)
  };

  return props;
};


export const getInputPropsMultiSelect = (
  name: string,
  formik: FormikProps<FormikValues>
) => {
  const value = getIn(formik.values, name);
  const error = getIn(formik.errors, name) && getIn(formik.touched, name);

  const props = {
    id: name,
    name: name,
    //onChange: formik.handleChange,
    onBlur: formik.handleBlur,
    //value: value || [],
    error,
    touch: formik.touched,
    // helperText: getIn(formik.errors, name)
  };

  return props;
};
export const getInputPropsCustomOnChange = (
  name: string,
  formik: FormikProps<FormikValues>
) => {
  const value = getIn(formik.values, name);
  const error = getIn(formik.errors, name) && getIn(formik.touched, name);

  /* const handleChange = () => {
    customChange();
    console.log(value);
    return formik.handleChange(value);
  };
  */

  const props = {
    id: name,
    name: name,
    onBlur: formik.handleBlur,
    //onChange: formik.handleChange,
    value: value || "",
    error,
    // helperText: getIn(formik.errors, name)
  };

  return props;
};

export const getNumberOfElements = (
  elementName: string,
  getFieldProps: (props: any) => FieldInputProps<any>
) => {
  var count = 0;
  for (var i = 0; i <= 50; i++) {
    if (getFieldProps(elementName + i).value) {
      count++;
    } else {
      break;
    }
  }
  return count;
};
