import { Option } from "react-multi-select-component/dist/lib/interfaces";

export const getOptionByLabel = (
  optionsArray: Option[],
  label: string | undefined
): Option | undefined => {
  return optionsArray.find((t) => t.label === label);
};

export const getOptionByValue = (
  optionsArray: Option[],
  value: string | undefined
): Option | undefined => {
  return optionsArray.find((t) => t.value === value);
};

export const reduceOptions = (value: any) => {
  if (value) {
    return Array.prototype.map.call(value, s => s.label).toString()
  } else {
    return "";
  }
}

export const painOptionsArray: Option[] = [
  { label: "Dull", value: "Dull" },
  { label: "Throbbing", value: "Throbbing" },
  { label: "Pins & Needles", value: "PinsNeedles" },
  { label: "Constant", value: "Constant" },
  { label: "Comes and Goes", value: "ComesAndGoes" },
  { label: "None ", value: "None" },
];
export const painLocationOptionsArray: Option[] = [
  { label: "Lumbar (low back)", value: "Lumbar" },
  { label: "Cervical (neck)", value: "Cervical" },
  { label: "Thoracic (mid back)", value: "Thoracic" },
];

export const extremitiesOptionsArray: Option[] = [
  { label: "Right Arm", value: "RightArm" },
  { label: "Left Arm", value: "LeftArm" },
  { label: "Right Hand", value: "RightHand" },
  { label: "Left Hand", value: "LeftHand" },
  { label: "Right Leg", value: "RightLeg" },
  { label: "Left Leg", value: "LeftLeg" },
  { label: "Right Feet", value: "RightFeet" },
  { label: "Left Feet", value: "LeftFeet" },
];

export const incontinenceOptionsArray: Option[] = [
  { label: "None", value: "None" },
  { label: "Urine (bladder)", value: "Urine" },
  { label: "Stool (bowel)", value: "Stool" },
];

export const activitiesOptionsArray: Option[] = [
  { label: "Walking", value: "Walking" },
  { label: "Sitting", value: "Sitting" },
  { label: "Bending", value: "Bending" },
  { label: "Lifting", value: "Lifting" },
  { label: "Other", value: "Other" },
];

export const activitiesDecreaseArray: Option[] = [
  { label: "Resting", value: "Walking" },
  { label: "Sitting", value: "Sitting" },
  { label: "Bending Over", value: "Bending" },
  { label: "Medications", value: "Lifting" },
];

export const spineAreaOptionsArray: Option[] = [
  { label: "Lumbar", value: "Lumbar" },
  { label: "Cervical ", value: "Cervical" },
  { label: "Thoracic ", value: "Thoracic" },
];